import { Component, forwardRef, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Literals } from '@shared/logic/models/common.model';
import { LiteralSections } from '@shared/logic/const/common';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';

export type GdiCatSelectOptions = { code: string | number; name: string }[];

@Component({
  selector: 'gdi-cat-input-select',
  templateUrl: './gdi-cat-input-select.component.html',
  styleUrls: ['./gdi-cat-input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GdiCatInputSelectComponent),
      multi: true
    }
  ]
})
export class GdiCatInputSelectComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() formInput = true;
  @Input() multiple = false;

  @Input() options: GdiCatSelectOptions;
  /////////////////////
  public value: string;
  public isDisabled: boolean;
  public errors: any;
  // public isTouched = false;
  /////////////////////

  public literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();

  constructor(private injector: Injector, private _commonStoreAdapter: CommonStoreAdapter) {}

  public get classes(): string[] {
    const formInput = this.formInput ? 'cds-input-selector--form' : '';
    return [formInput];
  }

  public selectionChange(event: any): void {
    this.value = event.value;
    this.onTouch();
    this.onChange(this.value);
    this.retrieveErrors();
  }

  public onBlur(): void {
    // this.isTouched = true;
    this.retrieveErrors();
  }

  private retrieveErrors(): void {
    this.errors = this.injector.get(NgControl).errors;
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange = (_: any) => {};
  onTouch = () => {};
}
