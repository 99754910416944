import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainContainerService {
  private _mainContainerEl: HTMLElement;

  private renderer: Renderer2;

  constructor(private _rendererFactory: RendererFactory2) {
    this.renderer = this._rendererFactory.createRenderer(null, null);
  }

  public initContainer(container: HTMLElement) {
    this._mainContainerEl = container;
  }

  /**
   * Scroll the main container to top and add a class to hidde the scrolling
   * or emove the class over the main container to enable the scroll again.
   */
  public setModalMode(showScroll: boolean) {
    if (showScroll) {
      // this timeout is the key, because the animation of background takes 400ms
      setTimeout(() => {
        this.renderer.addClass(this._mainContainerEl, 'app-container--modal');
      }, 500);
    } else {
      this.renderer.removeClass(this._mainContainerEl, 'app-container--modal');
    }
  }

  /**
   * Handle the scroll over the main container based on your interest
   * @param options the native api `scrollTo`over a HTMLElement
   */
  public scrollContainerTo(options?: ScrollToOptions | undefined) {
    this._mainContainerEl.scrollTo(options);
  }
}
