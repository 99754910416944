import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { HeaderTrackingEvent } from '@ga-tracking/services/tracking-sites/header/header-track.model';

@Injectable({ providedIn: 'root' })
export class HeaderTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackClickProfile(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'header',
      element: 'login',
      link_text: 'mi perfil'
    };
    this.trackingService.pushEventClick<HeaderTrackingEvent>(eventData);
  }

  public trackClickLogo(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'header',
      element: 'login',
      link_text: 'catalonia'
    };
    this.trackingService.pushEventClick<HeaderTrackingEvent>(eventData);
  }

  public trackClickLogin(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'header',
      element: 'login',
      link_text: 'iniciar sesion'
    };
    this.trackingService.pushEventClick<HeaderTrackingEvent>(eventData);
  }

  public trackClickLogout(linkUrl: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'header',
      element: 'login_menu',
      link_text: 'cerrar sesion',
      link_url: linkUrl
    };
    this.trackingService.pushEventClick<HeaderTrackingEvent>(eventData);
  }
}
