import { CurrencyType } from '@app/shared/logic/models/common.model';

export enum Membership {
  Great = 'great',
  Circle = 'circle',
  Star = 'star',
  Top = 'top',
  Standard = 'standard'
}

export interface Info<T = string> {
  code: T;
  name?: string;
}

interface PhoneInfo {
  countryCode: string;
  phoneNumber: string;
}

export interface UserAddress {
  street: string;
  number: string;
  city: string;
  district: string;
  addressLineOne: string;
  addressLineTwo: string;
  floor: string;
  room: string;
  zipCode: string;
}

export interface User {
  address: UserAddress;
  avatar: string;
  birthdate: Date;
  languageInfo: Info;
  citizenshipInfo: Info;
  countryInfo: Info;
  preferredCurrency: CurrencyType;
  email: string;
  firstName: string;
  fullName: string;
  genreInfo: Info<number>;
  gipToken: string;
  id: string;
  lastName: string;
  membership: Membership;
  membershipNextLevel: Membership;
  membershipAsNumber: number;
  nights: number;
  nightsToLevelUp: number;
  phoneInfo: PhoneInfo;
  travelCashEUR: number;
  travelCashUSD?: number;
  travelCashToExpire: number;
  userWantsNewsletter: boolean;
  createdAt?: Date;
  emailToken: string;
}

export type Login = {
  email: string;
  password: string;
};

export type Register = {
  email: string;
  name: string;
  surname: string;
  password?: string;
  country?: string;
  termsAndConditionsAccepted?: boolean;
  communicationsRejected?: boolean;
};

export interface AuthProviderData {
  displayName: string;
  email: string;
  phoneNumber: string;
  photoURL: string;
  providerId: string;
  uid: string;
}

export type SocialProvider = 'google' | 'facebook';
