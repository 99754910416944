import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

import { Terms } from '@app/shared/ns_components/modals/hotel-modal/hotel-modal.component';

import { ContentComponent } from '@atom/free-content/content.component';
import { SafeHtmlDirectiveModule } from '@directives/safe-html/safe-html.directive.module';
import { RoomInfoRaw } from '@shared/store/search/search.model.raw';

@Component({
  standalone: true,
  selector: 'app-room-modal-info',
  templateUrl: './room-modal-info.component.html',
  styleUrls: ['./room-modal-info.component.scss'],
  imports: [NgIf, SafeHtmlDirectiveModule, NgForOf, IconItemComponent, ContentComponent]
})
export class RoomModalInfoComponent {
  @Input() set roomInfo(value: RoomInfoRaw | undefined) {
    if (!value) {
      return;
    }
    this.roomInfoData = value;
    const roomInfoServices = value.services?.map((s) => ({
      icon: s.iconClass,
      title: s.type,
      description: s.items
    }));
    this.definitionListTermsPrivileged = roomInfoServices?.filter((s) => s.title === 'Privileged');
    this.definitionListTerms = roomInfoServices?.filter((s) => s.title !== 'Privileged');
  }

  public roomInfoData: RoomInfoRaw;
  public definitionListTermsPrivileged: Terms[];
  public definitionListTerms: Terms[];
}
