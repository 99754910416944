import { NgClass, NgIf } from '@angular/common';
import { Component, forwardRef, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Literals } from '@shared/logic/models/common.model';
import { LiteralSections } from '@shared/logic/const/common';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';

@Component({
  standalone: true,
  selector: 'gdi-cat-input-date',
  templateUrl: './gdi-cat-input-date.component.html',
  styleUrls: ['./gdi-cat-input-date.component.scss'],
  imports: [MatFormFieldModule, MatInputModule, NgxMaskPipe, NgClass, NgIf],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GdiCatInputDateComponent),
      multi: true
    },
    provideNgxMask()
  ]
})
export class GdiCatInputDateComponent implements ControlValueAccessor {
  @Input() size: 'cds-input-text--l' | 'cds-input-text--m' = 'cds-input-text--l';
  @Input() style: 'cds-input-text--regular' | 'cds-input-text--highlight' = 'cds-input-text--regular';
  @Input() placeholder: string;
  @Input() oneField = false;
  @Input() mask = '';

  public value: string;
  public isDisabled: boolean;
  public errors: any;

  public literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();

  constructor(private _injector: Injector, private _commonStoreAdapter: CommonStoreAdapter) {}

  public get classes(): string[] {
    const atr = this.isDisabled ? 'disabled' : '';
    const oneField = this.oneField ? 'one-field' : '';
    return [`${this.style}`, `${this.size}`, atr, oneField];
  }

  public onInput(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.onTouch();
    this.onChange(this.value);
    this._retrieveErrors();
  }

  public onBlur(): void {
    this._retrieveErrors();
  }

  public writeValue(value: string): void {
    this.value = value || '';
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onChange = (_: any) => {};
  public onTouch = () => {};

  private _retrieveErrors(): void {
    this.errors = this._injector.get(NgControl).errors;
  }
}
