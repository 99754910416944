import { JsonPipe } from '@angular/common';
import { Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { RoutingService } from '@app/core/routing/routing.service';
import { LanguageDataService } from '@app/core/services/languages/language-data.service';
import { privacyPolicy, rewards } from '@app/legal-text.routes';

import { confirmPasswordValidator } from '@app/shared/logic/custom-validators/confirm-password-validator';
import { CommonService } from '@app/shared/logic/services/common.service';
import { Register, SocialProvider } from '@app/shared/store/auth/auth.model';

import { CommonStoreAdapter } from '@app/shared/store/common/common.store.adapter';
import { ButtonComponent } from '@atom/button/button.component';
import { RegisterTrackService } from '@ga-tracking/services/tracking-sites/register/register-track.service';
import { LiteralSections } from '@shared/logic/const/common';
import { NoWhitespaceValidator } from '@shared/logic/custom-validators/no-white-space-validator';
import { onlyTextValidator } from '@shared/logic/custom-validators/only-text.validator';
import { passwordValidator } from '@shared/logic/custom-validators/password-validator';
import { GdiCatInputPasswordModule } from '@shared/ns_components/forms/gdi-cat-input-password/gdi-cat-input-password.module';
import { GdiCatInputSelectModule } from '@shared/ns_components/forms/gdi-cat-input-select/gdi-cat-input-select.module';
import { GdiCatInputTextModule } from '@shared/ns_components/forms/gdi-cat-input-text/gdi-cat-input-text.module';
import { Literals } from '@shared/logic/models/common.model';

@Component({
  standalone: true,
  selector: 'app-login-form-register',
  templateUrl: './login-form-register.component.html',
  imports: [GdiCatInputTextModule, ReactiveFormsModule, GdiCatInputPasswordModule, GdiCatInputSelectModule, MatCheckboxModule, ButtonComponent, JsonPipe],
  styleUrls: ['./login-form-register.component.scss']
})
export class LoginFormRegisterComponent implements OnInit {
  public readonly literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();

  public currentUrl = '';

  @Input() data = {
    basicInfo: {
      separator: this.literals.label_use_options
    },
    createAccount: {
      titleLogin: this.literals.label_create,
      formData: {
        inputName: this.literals.name,
        inputSurname: this.literals.last_name,
        inputMail: this.literals.email,
        inputCountry: this.literals.labe_select_country
      },
      leganText: this.literals.label_no_receive_offer,
      ctaButton: this.literals.label_create,
      formSocialLogin: {
        input01: this.literals.label_enter_google,
        inputIcon01: 'Apple-path2',
        input02: this.literals.label_entry_with_facebook,
        inputIcon02: 'Facebook'
      }
    }
  };
  @Input() ctaLoginExpanded = true;
  @Output() public ev_registerClicked: EventEmitter<Register> = new EventEmitter<Register>();
  @Output() public ev_socialProviderClicked: EventEmitter<SocialProvider> = new EventEmitter<SocialProvider>();

  public readonly countriesSig = this._commonStoreAdapter.selectCountriesSig;

  public registerForm: FormGroup = new FormGroup(
    {
      name: new FormControl(null, [Validators.required, NoWhitespaceValidator(), onlyTextValidator(), Validators.maxLength(40)]),
      surname: new FormControl(null, [Validators.required, NoWhitespaceValidator(), onlyTextValidator(), Validators.maxLength(40)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      country: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, passwordValidator]),
      repeatPassword: new FormControl(null, [Validators.required, passwordValidator]),
      termsAndConditionsAccepted: new FormControl(false, [Validators.requiredTrue]),
      communicationsRejected: new FormControl(false)
    },
    { validators: confirmPasswordValidator('password', 'repeatPassword') }
  );

  constructor(
    private _commonService: CommonService,
    private _commonStoreAdapter: CommonStoreAdapter,
    private _registerTrackService: RegisterTrackService,
    private readonly _languageService: LanguageDataService
  ) {}

  ngOnInit() {
    this._commonService.getCountries$().subscribe();
    this._registerTrackService.trackRegisterPageDisplay();
  }

  get communicationsRejected(): AbstractControl | null {
    return this.registerForm.get('communicationsRejected');
  }

  onClickRegister() {
    this.ev_registerClicked.emit(this.registerForm.value);
  }

  onClickSocialProvider(provider: SocialProvider) {
    this.ev_socialProviderClicked.emit(provider);
  }

  public checkErrorFormFieldsToGATracking(): void {
    const name = this.registerForm.controls.name;
    const surname = this.registerForm.controls.surname;
    const email = this.registerForm.controls.email;
    const country = this.registerForm.controls.country;
    const password = this.registerForm.controls.password;
    const repeatPassword = this.registerForm.controls.repeatPassword;
    const termsAndConditionsAccepted = this.registerForm.controls.termsAndConditionsAccepted;
    if (name.errors?.required) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm('input_text', this.literals.name, this.literals.required_input);
    }
    if (surname.errors?.required) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm('input_text', this.literals.lastname, this.literals.required_input);
    }
    if (email.errors?.required || email.errors?.email) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm(
        'input_text',
        'email',
        email.errors.required ? this.literals.required_input : this.literals.invalid_email
      );
    }
    if (country.errors?.required) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm('input_text', 'pais', this.literals.required_input);
    }
    if (password.errors?.required || password.errors?.passwordError) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm(
        'input_text',
        'contrasena',
        password.errors?.required ? this.literals.required_input : this.literals.error_pwd_requirements
      );
    }
    if (repeatPassword.errors?.required || repeatPassword.errors?.passwordError) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm(
        'input_text',
        'repetir contrasena',
        repeatPassword.errors?.required ? this.literals.required_input : this.literals.error_pwd_mismatch
      );
    }
    if (termsAndConditionsAccepted.errors?.required) {
      this._registerTrackService.trackViewErrorFieldInRegisterForm('check_box', 'condiciones', this.literals.required_input);
    }
  }

  public urlRewardsConditionLanguage() {
    const currentLangue = this._languageService.getLang();
    return '/' + currentLangue + '/' + rewards[currentLangue];
  }

  public urlPrivacyPolicyLanguage() {
    const currentLangue = this._languageService.getLang();
    return '/' + currentLangue + '/' + privacyPolicy[currentLangue];
  }
}
