import { Action } from '@ngrx/store';

import { ScrollConfig } from './ui.model';

export enum UIActionsTypes {
  SetScrollConfig = '[UI] Set Scroll Config', // TODO: Delete this. This should not exist here
  SetHeaderLoginDisplayStatus = '[UI] Set Header Login Display Status',
  SetRewardsToggleStatus = '[UI] Set Rewards Toggle Active Status',
  ToggleRewardsActiveStatus = '[UI] Toggle Rewards Active Status',
  SetHeaderLoginWithEmailDisplayStatus = '[UI] Set Header Login With Email Display Status',
  SetHeaderResetPasswordDisplayStatus = '[UI] Set Header Reset Password Display Status',
  SetHeaderCreateAccountDisplayStatus = '[UI] Set Header Create Account Display Status'
}

export class SetScrollConfig implements Action {
  readonly type = UIActionsTypes.SetScrollConfig;

  constructor(readonly payload: { scrollConfig: ScrollConfig }) {}
}

export class SetHeaderLoginDisplayStatus implements Action {
  readonly type = UIActionsTypes.SetHeaderLoginDisplayStatus;

  constructor(readonly payload: { status: boolean }) {}
}

export class SetRewardsToggleStatus implements Action {
  readonly type = UIActionsTypes.SetRewardsToggleStatus;

  constructor(readonly payload: { isActive: boolean }) {}
}

export class ToggleRewardsActiveStatus implements Action {
  readonly type = UIActionsTypes.ToggleRewardsActiveStatus;
}

export class SetHeaderLoginWithEmailDisplayStatus implements Action {
  readonly type = UIActionsTypes.SetHeaderLoginWithEmailDisplayStatus;

  constructor(readonly payload: { status: boolean }) {}
}

export class SetHeaderResetPasswordDisplayStatus implements Action {
  readonly type = UIActionsTypes.SetHeaderResetPasswordDisplayStatus;

  constructor(readonly payload: { status: boolean }) {}
}

export class SetHeaderCreateAccountDisplayStatus implements Action {
  readonly type = UIActionsTypes.SetHeaderCreateAccountDisplayStatus;

  constructor(readonly payload: { status: boolean }) {}
}

export type UIActions =
  | SetScrollConfig
  | SetHeaderLoginDisplayStatus
  | SetRewardsToggleStatus
  | ToggleRewardsActiveStatus
  | SetHeaderLoginWithEmailDisplayStatus
  | SetHeaderResetPasswordDisplayStatus
  | SetHeaderCreateAccountDisplayStatus;
