export interface CartModel {
  item: string;
  price: number;
  quantity: number;
}

export function createCartEvent(item: string, price: number): CartModel {
  return {
    item: item,
    price: price,
    quantity: 1
  };
}
