import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { GdiCatInputPasswordComponent } from './gdi-cat-input-password.component';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

@NgModule({
  declarations: [GdiCatInputPasswordComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, IconItemComponent],
  exports: [GdiCatInputPasswordComponent]
})
export class GdiCatInputPasswordModule {}
