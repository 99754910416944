export const enum FirebaseAuthError {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
  UNKNOWN = 'UNKNOWN'
}
export class FirebaseFunctions {
  static getErrorFromMessage(message: string): FirebaseAuthError {
    const errorMap: { [key: string]: FirebaseAuthError } = {
      'auth/user-not-found': FirebaseAuthError.USER_NOT_FOUND,
      'auth/user-disabled': FirebaseAuthError.USER_DISABLED,
      'auth/invalid-email': FirebaseAuthError.INVALID_EMAIL,
      'auth/email-already-in-use': FirebaseAuthError.EMAIL_ALREADY_IN_USE,
      'auth/wrong-password': FirebaseAuthError.WRONG_PASSWORD
    };
    for (const key in errorMap) {
      if (message.includes(key)) {
        return errorMap[key];
      }
    }
    return FirebaseAuthError.UNKNOWN;
  }
}
