import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';
import { worker } from './mocks/browser';

console.log(
  ` ****************************
 *  APP VERSION ${environment.appVersion}
 ****************************`
);

if (environment.production) {
  enableProdMode();
} else if (environment.useMocks) {
  worker.start({ onUnhandledRequest: 'bypass' });
}

const bootstrap = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.log(err));
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
