import { Injectable } from '@angular/core';
import { EmarsysService } from '@shared/logic/services/emarsys/services/base/emarsys.service';
import { AuthStoreAdapter } from '@shared/store/auth/auth.store.adapter';
import { COMMON_COMMANDS } from '@shared/logic/services/emarsys/services/consts/emarsys-consts';
import { CustomSearchTermModel } from '@shared/logic/services/emarsys/services/models/custom-search-term.model';
import { RoomsSelectedRoomData } from '@shared/store/search-dates/rooms-store.model';
import { CartModel, createCartEvent } from '@shared/logic/services/emarsys/services/models/cart.model';
import { SearchPackRaw } from '@shared/store/search/search.model.raw';
import { CheckoutCombinedResponse, CheckoutResponse } from '@shared/store/checkout/checkout.model';
import { CustomCartModel } from '@shared/logic/services/emarsys/services/models/custom-cart.model';
import { CustomPurchaseModel } from '@shared/logic/services/emarsys/services/models/custom-purchase.model';
import { ExtendedBookingGdiData } from '@shared/logic/helpers/gdi/booking-gdi.helper';
import { createPurchaseEvent, PurchaseModel } from '@shared/logic/services/emarsys/services/models/purchase.model';

@Injectable({
  providedIn: 'root'
})
export class EmarsysTrackingService {
  private readonly userSig = this.asa.selectAuthUserSig;

  constructor(private readonly _emarsysService: EmarsysService, private asa: AuthStoreAdapter) {}

  public sendBasicPageEvent(): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent(COMMON_COMMANDS.cart, [[]]);
      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendClickCTAFlightAndHotel(): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent(COMMON_COMMANDS.cart, [[]]);
      this._emarsysService.sendEvent(COMMON_COMMANDS.tag, ['vuelo+hotel']);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendSearchEvent(event: CustomSearchTermModel): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent(COMMON_COMMANDS.cart, [[]]);
      const searchTerm = `${event.Hotel}&${event.Fecha_Llegada.split('T')[0]} – ${event.Fecha_Salida.split('T')[0]}&${
        event.NAdultos > 0 ? `${event.NAdultos}adultos` : ''
      }${event.NJunior > 0 ? `,${event.NJunior}juniors` : ''}${event.NNinos > 0 ? `,${event.NNinos}niños` : ''}${
        event.NBebes > 0 ? `,${event.NBebes}bebés` : ''
      },${event.NHabitacion} habitaciones&${event.CodigoDescuento !== '' ? event.CodigoDescuento : 'Sin código promocional'} `;
      this._emarsysService.sendEvent(COMMON_COMMANDS.searchTerm, [searchTerm]);
      this._emarsysService.sendEvent<CustomSearchTermModel>(COMMON_COMMANDS.tag, [COMMON_COMMANDS.custom_search_term, event]);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendViewHotel(hotelCode: string): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent(COMMON_COMMANDS.cart, [[]]);
      this._emarsysService.sendEvent(COMMON_COMMANDS.view, [hotelCode ?? '']);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendCartEvent(roomsCart: RoomsSelectedRoomData[], ratePlanIndex: number, isRewardsActive: boolean): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent<CartModel[]>(COMMON_COMMANDS.cart, [
        roomsCart.map((r) =>
          createCartEvent(
            r.room?.hotelCode!,
            isRewardsActive ? r.room?.ratePlans[ratePlanIndex].mealPlans[0].priceRewards ?? 0 : r.room?.ratePlans[ratePlanIndex].mealPlans[0].price ?? 0
          )
        )
      ]);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendCartPackEvent(roomsCart: SearchPackRaw, isRewardsActive: boolean): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent<CartModel[]>(COMMON_COMMANDS.cart, [
        roomsCart.groupRooms.map((r) => createCartEvent(roomsCart.hotelCode, isRewardsActive ? r.priceRewards ?? 0 : r.price ?? 0))
      ]);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendCartEventInRegimeSelection(checkoutResponse: CheckoutCombinedResponse): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent<CartModel[]>(COMMON_COMMANDS.cart, [
        checkoutResponse.hashResponse.rooms.children.map((r) =>
          createCartEvent(
            checkoutResponse.hashResponse.hotelInfo.code,
            checkoutResponse.hashResponse.isRewardsActive ? r.prices.totalPriceRewards ?? 0 : r.prices.totalPrice ?? 0
          )
        )
      ]);

      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendCustomCartCheckoutEvent(event: CustomCartModel, checkoutResponse: CheckoutResponse): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent<CartModel[]>(COMMON_COMMANDS.cart, [
        checkoutResponse.rooms.children.map((r) =>
          createCartEvent(checkoutResponse.hotelInfo.code, checkoutResponse.isRewardsActive ? r.prices.totalPriceRewards ?? 0 : r.prices.totalPrice ?? 0)
        )
      ]);
      this._emarsysService.sendEvent<CustomCartModel>(COMMON_COMMANDS.tag, [COMMON_COMMANDS.custom_cart, event]);
      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }

  public sendPurchaseEvent(event: CustomPurchaseModel, bookingConfirmationData: ExtendedBookingGdiData): void {
    try {
      if (this.userSig()) {
        this._emarsysService.sendEvent(COMMON_COMMANDS.setEmail, [this.userSig()!.email]);
      }
      this._emarsysService.sendEvent<PurchaseModel>(COMMON_COMMANDS.purchase, [
        createPurchaseEvent(bookingConfirmationData.hash ?? '', bookingConfirmationData.hotelData.code, bookingConfirmationData.rooms.children)
      ]);
      this._emarsysService.sendEvent<CustomPurchaseModel>(COMMON_COMMANDS.tag, [COMMON_COMMANDS.custom_purchase, event]);
      this._emarsysService.sendEvent(COMMON_COMMANDS.go, []);
    } catch (error) {
      console.error('Error in emarsys tracking :', error);
    }
  }
}
