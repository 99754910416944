import { KeyLocalStorage } from '@app/shared/logic/const/key-local-storage.const';
import { LocalStorageService } from '@app/shared/logic/services/storage/local-storage.service';
import { on } from '@ngrx/store';

import { setMerchantId, setUserCards } from './payment.actions';
import { UserCardPayment } from './payment.model';
import { setAuthStatus } from '../auth/auth.actions';
import { createRehydrateReducer } from '../rehydrate.reducer';

export interface State {
  isLoaded: boolean;
  userCards: UserCardPayment[];
  merchantId: string | null;
}

const initialState: State = {
  isLoaded: false,
  userCards: [],
  merchantId: null
};

export const reducer = (storage: LocalStorageService) =>
  createRehydrateReducer<State>(storage)(
    KeyLocalStorage.Payment,
    initialState,
    on(setUserCards, (state, action) => ({
      ...state,
      isLoaded: true,
      userCards: action.payload.userCards
    })),
    on(setMerchantId, (state, action) => ({
      ...state,
      merchantId: action.payload.merchantId
    })),
    on(setAuthStatus, () => ({
      ...initialState
    }))
  );
