import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

type StarsCategory = 'SUP' | 'GL';

@Component({
  standalone: true,
  selector: 'app-stars-range',
  templateUrl: './stars-range.component.html',
  styleUrls: ['./stars-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, IconItemComponent, NgIf]
})
export class StarsRangeComponent implements OnChanges {
  @Input() stars: number;

  public starsAsValidArray: number | null;
  public category: StarsCategory | null = null;

  public ngOnChanges(): void {
    if (!this.stars) {
      return;
    }
    this.starsAsValidArray = Math.floor(this.stars);
    this.category = this.getCategory(this.stars);
  }

  public trackByFn(i: number): number {
    return i;
  }

  private getCategory(stars: number): StarsCategory | null {
    if (stars % 1 === 0.5) {
      if (stars > 5) {
        return 'GL';
      } else {
        return 'SUP';
      }
    }
    return null;
  }
}
