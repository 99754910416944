<div class="cds-modal">
  <div class="cds-modal__wrapper wrapper" [ngClass]="dialogData.images ? 'cds-modal--img-content rewards' : ''">
    <div class="cds-modal__colmns">
      <button class="cds-modal__close-icon" (click)="close()" [ngClass]="dialogData.promo === true && dialogData.promoPosition ? 'promo-position' : ''">
        <app-icon-item icon="close"></app-icon-item>
      </button>
      <div class="cds-modal__overflow">
        <div class="cds-modal__container" [ngClass]="dialogData.promo === true && dialogData.promoPosition === false ? 'ribbon-bottom' : ''">
          <div *ngIf="dialogData.images" class="cds-modal__img-container">
            <ng-container *ngIf="dialogData.images?.length">
              <ng-container *ngIf="dialogData.images?.length === 1; else imageSlider">
                <img
                  dynamic-image
                  [imageRaw]="dialogData.images[0]"
                  [alt]="dialogData.images[0].atributos?.descriptivo"
                  [src]="dialogData.images[0].talla_uno"
                />
              </ng-container>
              <ng-template #imageSlider>
                <app-image-slider
                  [images]="dialogData.images!"
                  [hadDots]="true"
                  [hadArrow]="true"
                  [timeChangeSlade]="0"
                  [configCarousel]="configCarousel"
                  [isStickerHidden]="true"
                ></app-image-slider>
              </ng-template>
            </ng-container>
          </div>
          <ng-template></ng-template>
          <div class="cds-modal__ribbon" *ngIf="dialogData.promo" [ngStyle]="{ order: dialogData.promo === true && dialogData.promoPosition ? '-1' : '1' }">
            <div class="info">
              <p class="discount">{{ dialogData.offerDiscount | number : '1.0-0' }}%</p>
              <span>Catalonia</span>Rewards
            </div>
          </div>
          <div class="cds-modal__content">
            <app-icon-item [icon]="dialogData.icon" *ngIf="dialogData.icon"></app-icon-item>
            <div class="cds-modal__content-title">
              <p *ngIf="dialogData.displayTitle; else title" [directiveSafeHTML]="dialogData.displayTitle"></p>
              <ng-template #title>
                <ng-content select="[cds-modal-title]"></ng-content>
              </ng-template>
            </div>
            <div class="cds-modal__content-info">
              <ng-content></ng-content>
            </div>
          </div>
          <ng-content select="[cds-modal-actions]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
