export const COMMON_TAG_EVENT_TRACK_ACTION_MODE: Record<string, string> = {
  view: 'view_promotion',
  select: 'select_promotion'
};

export const COMMON_TAG_EVENTS: Record<string, string> = {
  selectContent: 'select_content',
  selectAlert: 'select_alert',
  viewContent: 'view_content',
  viewAlert: 'view_alert',
  virtualPage: 'virtual_page',
  errorNavigate: 'error_navegacion',
  viewPromotion: 'view_promotion',
  addToCart: 'add_to_cart',
  viewItem: 'view_item',
  selectItem: 'select_item',
  purchase: 'purchase',
  beginCheckout: 'begin_checkout',
  reserve: 'reserva',
  refund: 'refund',
  login: 'login',
  signUp: 'sign_up',
  selectPromotion: 'select_promotion',
  user: 'user',
  addPaymentInfo: 'add_payment_info',
  logout: 'logout',
  search: 'search'
};
