<div class="cancel-booking-modal">
  <app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
    <div class="cancel-booking-modal__subtitle">
      <span>{{ getDialogData().subtitle }}</span> <br />
      <br />
      <p [innerHTML]="getDialogData().description"></p>
      <br />
      <span *ngIf="getDialogData().rooms && activeRooms.length > 1">{{ literals.cancelRooms }}</span>
    </div>
    <div *ngIf="activeRooms.length > 1" class="cancel-booking-modal__selector">
      <mat-form-field class="cds-input-select--form cds-input-select">
        <mat-select [formControl]="rooms" [placeholder]="literals.allRooms">
          <mat-option [value]="null">{{ literals.allRooms }}</mat-option>
          <ng-container *ngFor="let room of getDialogData().rooms">
            <mat-option [disabled]="room.state === 'cancelled'" [value]="room.code"> {{ literals.room }} {{ room.index + 1 }} </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cancel-booking-modal__button">
      <app-button label="Cancelar reserva" [fillMobile]="true" type="cds-btn--highlight" (buttonClick)="onAccept()"></app-button>
    </div>
  </app-cdk-dialog-wrapper-new>
</div>
