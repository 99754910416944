import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { CdkDialogComponent } from './cdk-dialog.component';
import { IconItemComponent } from '../../atoms/icon-item/icon-item.component';

@NgModule({
  imports: [SharedModule, IconItemComponent],
  declarations: [CdkDialogComponent],
  exports: [CdkDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CdkDialogModule {}
