import { Destination } from '@models/sections';
import { createAction } from '@ngrx/store';
import { PreFooterDestiny } from '@shared/store/pre-footer/pre-footer.model';

export const enum PreFooterActions {
  SetPreFooter = '[PreFooter] Set PreFooter',
  SetPreFooterNormalized = '[PreFooter] Set PreFooter Normalized'
}

export const setPreFooter = createAction(
  PreFooterActions.SetPreFooter,
  ({ preFooter }: { preFooter: Record<string, Destination[]> }) => ({
    payload: { preFooter }
  })
);
export const setPreFooterNormalized = createAction(
  PreFooterActions.SetPreFooterNormalized,
  ({ preFooter }: { preFooter: Record<string, PreFooterDestiny[]> }) => ({
    payload: { preFooter }
  })
);
