import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { GdiCatInputTextComponent } from '@app/shared/ns_components/forms/gdi-cat-input-text/gdi-cat-input-text.component';

@NgModule({
  declarations: [GdiCatInputTextComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule],
  exports: [GdiCatInputTextComponent]
})
export class GdiCatInputTextModule {}
