<section class="cds-form-singin">
  <div class="cds-form-singin__form">
    <ng-container *ngIf="!recoveryPasswordSig() && !logWithEmail()">
      <div class="form-login">
        <div class="title">
          <p>{{ literals.label_sesion_account }}</p>
        </div>

        <form class="form" [formGroup]="loginForm">
          <div class="form-input-div">
            <gdi-cat-input-text
              [size]="'cds-input-text--m'"
              [style]="'cds-input-text--regular'"
              formControlName="email"
              placeholder="{{ literals.email }}"
            ></gdi-cat-input-text>
          </div>
          <div class="form-input-div">
            <gdi-cat-input-password
              [size]="'cds-input-text--m'"
              [style]="'cds-input-text--regular'"
              formControlName="password"
              placeholder="{{ literals.label_only_six_characters }}"
            ></gdi-cat-input-password>
          </div>
          <div class="auth-actions">
            <app-button type="cds-btn--highlight" [fill]="true" size="cds-btn--m" [label]="literals.init_sesion" (click)="onClickLogin()"></app-button>
            <app-button type="cds-btn--link" size="cds-btn--m" [label]="literals.label_forgot_pass" (click)="activeRecoveryPassword(false)"></app-button>
          </div>
        </form>

        <div class="separator">{{ literals.label_use_options }}</div>
        <div class="social-login">
          <div class="row">
            <button class="btn-social-login" (click)="onClickSocialProvider('google')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.6 10.2273C19.6 9.51825 19.5364 8.83643 19.4182 8.18188H10V12.0501H15.3818C15.15 13.3001 14.4455 14.3592 13.3864 15.0682V17.5773H16.6182C18.5091 15.8364 19.6 13.2728 19.6 10.2273Z"
                  fill="#4285F4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.99886 19.9999C12.6989 19.9999 14.9625 19.1044 16.617 17.5772L13.3852 15.0681C12.4898 15.6681 11.3443 16.0226 9.99886 16.0226C7.39432 16.0226 5.18977 14.2635 4.40341 11.8999H1.0625V14.4908C2.70795 17.759 6.08977 19.9999 9.99886 19.9999Z"
                  fill="#34A853"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.40455 11.8999C4.20455 11.2999 4.09091 10.659 4.09091 9.99994C4.09091 9.34085 4.20455 8.69994 4.40455 8.09994V5.50903H1.06364C0.386364 6.85903 0 8.38631 0 9.99994C0 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.8999Z"
                  fill="#FBBC05"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.99886 3.97727C11.467 3.97727 12.7852 4.48182 13.8216 5.47273L16.6898 2.60455C14.958 0.990909 12.6943 0 9.99886 0C6.08977 0 2.70795 2.24091 1.0625 5.50909L4.40341 8.1C5.18977 5.73636 7.39432 3.97727 9.99886 3.97727Z"
                  fill="#EA4335"
                />
              </svg>
              <span>{{ literals.label_enter_google }}</span>
            </button>
            <button class="btn-social-login" (click)="onClickSocialProvider('facebook')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 10.0611C20 4.50451 15.5229 0 10 0C4.47715 0 0 4.50451 0 10.0611C0 15.0829 3.65686 19.2452 8.4375 20V12.9694H5.89844V10.0611H8.4375V7.84452C8.4375 5.32296 9.93043 3.93012 12.2146 3.93012C13.3087 3.93012 14.4531 4.12663 14.4531 4.12663V6.60261H13.1921C11.9499 6.60261 11.5625 7.37816 11.5625 8.17381V10.0611H14.3359L13.8926 12.9694H11.5625V20C16.3431 19.2452 20 15.0829 20 10.0611Z"
                  fill="#1877F2"
                />
              </svg>
              <span> {{ literals.label_entry_with_facebook }} </span>
            </button>
          </div>
          <app-button
            class="app-button"
            type="cds-btn--regular"
            [fill]="true"
            size="cds-btn--m"
            [label]="literals.label_login_only_email"
            (click)="activeLogWithEmail()"
          ></app-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="recoveryPasswordSig()">
      <div class="form-recovery-password">
        <div class="title">
          <p>{{ literals.label_recover_pass }}</p>
          <p class="subtitle">{{ literals.label_mail_recover }}</p>
        </div>
        <form class="form" [formGroup]="recoveryForm">
          <!-- Input Email -->
          <div class="form-input-div">
            <gdi-cat-input-text
              [size]="'cds-input-text--m'"
              [style]="'cds-input-text--regular'"
              formControlName="email"
              placeholder="{{ literals.email }}"
            ></gdi-cat-input-text>
          </div>
          <app-button
            type="cds-btn--highlight"
            [fill]="true"
            size="cds-btn--m"
            [label]="literals.send"
            (click)="openModalResetPassword()"
            [disabled]="recoveryForm.invalid"
          ></app-button>
          <app-button type="cds-btn--link" size="cds-btn--m" [label]="literals.cancel" (click)="activeRecoveryPassword(true)"></app-button>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="logWithEmail()">
      <div class="form-recovery-password">
        <div class="title">
          <p>{{ literals.label_init_sesion_mail }}</p>
          <p class="subtitle">{{ literals.label_init_sesion_no_pass }}</p>
        </div>
        <form class="form" [formGroup]="logWithEmailForm">
          <!-- Input Email -->
          <div class="form-input-div">
            <gdi-cat-input-text
              [size]="'cds-input-text--m'"
              [style]="'cds-input-text--regular'"
              formControlName="email"
              placeholder="{{ literals.email }}"
            ></gdi-cat-input-text>
          </div>
          <app-button type="cds-btn--highlight" [fill]="true" size="cds-btn--m" label="{{ literals.send }}" (click)="openModalInitSessionEmail()"></app-button>
          <app-button type="cds-btn--link" size="cds-btn--m" [label]="literals.cancel" (click)="activeLogWithEmail()"></app-button>
        </form>
      </div>
    </ng-container>
  </div>
</section>
