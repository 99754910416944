import { Dialog } from '@angular/cdk/dialog';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Input, SimpleChanges, ViewChildren, QueryList, ElementRef, OnChanges, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IconItemComponent } from '@atom/icon-item/icon-item.component';
import { DynamicImageDirective } from '@directives/image/dynamic-image.directive';
import { ImageRaw } from '@shared/logic/models/common.model';

import { ExpandedImageModalComponent } from '@shared/ns_components/modals/expanded-image/expanded-image-modal.component';

export type Gallery = {
  category: string;
  img: string;
  imgRaw?: ImageRaw;
  galleryImages: {
    type: string;
    source: string;
    description: string;
  }[];
  images: ImageRaw[];
};

@Component({
  standalone: true,
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgForOf, NgIf, IconItemComponent, DynamicImageDirective]
})
export class GalleryComponent implements OnChanges, AfterViewInit {
  @ViewChildren('listItem') listItems!: QueryList<ElementRef>;

  @Input() gallery: Gallery[] = [];
  @Input() hasGalleryLayout = false;
  @Input() scrollPosition!: number;

  public visibleIndex = 0;

  constructor(public ExpandedImage: Dialog, private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    const scrollBlock = document.getElementById('scrollBlockElement');
    scrollBlock?.addEventListener('scroll', this.onScroll.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['scrollPosition']) {
      this.checkElementsInView();
    }
  }

  private checkElementsInView() {
    this.visibleIndex = 0;
    const viewportMiddle = window.innerHeight / 2;
    this.listItems?.forEach((item: ElementRef, index) => {
      const element = item.nativeElement;
      const boundingRect = element.getBoundingClientRect();
      const elementTop = boundingRect.top;
      const isInView = elementTop < viewportMiddle;
      if (isInView) {
        this.visibleIndex = index;
      }
    });
  }

  public handleClick(i: number, id: string): void {
    this.visibleIndex = i;
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  onScroll(): void {
    // const scrollOffset = (event?.target as HTMLElement).scrollTop || 0;
  }

  public getSafeVideoUrl(videoUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  //FIX LAYOUT IF NOT 1-2-2
  fixLayout(num: number, items: number) {
    return num == items - 2 && (items - 2) % 3 === 0 && (items - 1) % 3 !== 0;
  }

  public trackByCategory(index: number, item: any): any {
    return item.category;
  }

  public trackByImage(index: number, image: any): any {
    return image.source;
  }

  public openExpandedImage(categoryName: string, imageIndex: number): void {
    const categoryIndex = this.gallery.findIndex((category) => category.category === categoryName);
    const found = categoryIndex !== -1;

    if (!found) {
      return;
    }

    this.ExpandedImage.open<string>(ExpandedImageModalComponent, {
      data: { galleryMosaic: this.gallery, categoryIndex, imageIndex }
    });
  }
}
