import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatSelectModule } from '@angular/material/select';

import { ButtonComponent } from '@atom/button/button.component';
import { PrivateAreaTrackService } from '@ga-tracking/services/tracking-sites/private-area/private-area-track.service';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { CdkDialogWrapperNewModalInput, Literals, ModalResponse } from '@shared/logic/models/common.model';

import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';

import { BreakdownChildState } from '../../breakdowns/models/breakdown.model';

export interface CancelBookingModalInput extends CdkDialogWrapperNewModalInput {
  subtitle: string;
  description: string;
  rooms: CancelRoom[];
}

export interface CancelRoom {
  index: number;
  code: number;
  state: BreakdownChildState;
}

export interface CancellBookingModalResponse extends ModalResponse {
  selectedRoom?: number | null;
}

@Component({
  standalone: true,
  selector: 'app-cancel-booking-modal',
  templateUrl: './cancel-booking-modal.component.html',
  imports: [CdkDialogWrapperNewModule, NgIf, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, NgForOf, ButtonComponent],
  styleUrls: ['./cancel-booking-modal.component.scss']
})
export class CancelBookingModalComponent
  extends ModalElement<CancelBookingModalComponent, CancelBookingModalInput, CancellBookingModalResponse>
  implements AfterViewInit
{
  literals: Literals = {
    cancelRooms: '¿Qué habitaciones quieres cancelar?',
    allRooms: 'Todas las habitaciones',
    room: 'Habitación'
  };

  rooms = new FormControl<number | null>(null);
  activeRooms: CancelRoom[] = [];

  constructor(
    @Inject(DIALOG_DATA) dialogData: CancelBookingModalInput,
    dialogRef: DialogRef<CancellBookingModalResponse, CancelBookingModalComponent>,
    private _privateAreaTrackService: PrivateAreaTrackService
  ) {
    super(dialogData, dialogRef);
    this.activeRooms = this.dialogData.rooms.filter((room) => room.state !== 'cancelled');
  }

  ngAfterViewInit(): void {
    this._privateAreaTrackService.trackViewCancelReserveModal();
  }

  onAccept(): void {
    // customData only returns a value if is partial cancellation (room selected) otherwise returns null
    this.closeDialog({ success: true, selectedRoom: this.rooms.value });
  }

  onCancel(): void {
    this.closeDialog({ success: false });
  }
}
