import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { RoutingPipe } from '@core/routing/routing.pipe';
import { Observable, of, Subject } from 'rxjs';

import Map = google.maps.Map;

import { RoutingService } from '@core/routing/routing.service';

interface Marker {
  markerImg: string;
  adultsOnly: string | null;
  hotelName: string;
  hotelLocation?: string;
  hotelURL: string;
  position: { lat: number; lng: number };
}
interface MapConfig {
  zoom: number;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions;
}

export const DEFAULT_ZOOM = 17;
const SELECTED_MARKER_ZOOM = 17;

@Component({
  standalone: true,
  selector: 'cat-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [GoogleMapsModule, NgForOf, AsyncPipe, NgIf, RoutingPipe]
})
export class MapComponent {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  selectedMarker$: Subject<Marker | null> = new Subject<Marker | null>();
  markers$: Observable<Marker[]>;
  map: Map;
  mapConfig: MapConfig;

  public selectedMarkerIndex: number | null = null;
  public readonly defaultMarkerOption = { icon: '/assets/icon/marker-unselected.svg' };
  public readonly selectedMarkerOption = { icon: '/assets/icon/marker-selected.svg' };

  @Input() hotelName: string;
  @Input() adultsOnly: string | null;
  @Input() hotelLocation: string;
  @Input() markerImg?: string;
  @Input() hotelURL: string;

  @Input() coords: { lat: number; lng: number };

  @Output() ev_markerActionClick: EventEmitter<void> = new EventEmitter<void>();

  // mapId = '8e0a97af9386fef'; // what id is this?
  // mapId = 'd1986a1a37aab844'; // what id is this?

  public ngOnInit(): void {
    this.mapConfig = {
      zoom: DEFAULT_ZOOM,
      center: { lat: this.coords.lat, lng: this.coords.lng },
      options: {
        mapId: 'd1986a1a37aab844',
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControl: false,
        streetViewControl: false
      }
    };
    this.markers$ = of([
      {
        hotelName: this.hotelName,
        markerImg: this.markerImg || '',
        adultsOnly: this.adultsOnly,
        hotelLocation: this.hotelLocation,
        hotelURL: [RoutingService.LANDING_HOTEL_URL(), this.hotelURL].join('/'),
        position: { lat: this.coords.lat, lng: this.coords.lng }
      }
    ]);
  }

  public mapInitialized(map: any): void {
    this.map = map;
  }

  public markerClick(marker: Marker, mapMarkerComponentReference: MapMarker, index: number): void {
    if (this.selectedMarkerIndex === index) {
      this.closeInfoWindow();
    } else {
      this._openInfoWindow(marker, mapMarkerComponentReference, index);
    }
  }

  public closeInfoWindow(): void {
    this.infoWindow.close();
    this.selectedMarkerIndex = null;
    this.selectedMarker$.next(null);
    this._resetMapZoomAndCenter();
  }

  public markerActionClick(): void {
    this.ev_markerActionClick.emit();
  }

  private _openInfoWindow(marker: Marker, mapMarkerComponentReference: MapMarker, index: number): void {
    this.map.setZoom(SELECTED_MARKER_ZOOM);
    this.map.panTo(marker.position);
    this.selectedMarker$.next(marker);
    this.infoWindow.open(mapMarkerComponentReference);
    this.selectedMarkerIndex = index;
  }

  private _resetMapZoomAndCenter(): void {
    this.map.setZoom(DEFAULT_ZOOM);
    this.map.panTo({ lat: this.coords.lat, lng: this.coords.lng });
  }
}
