import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, inject, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ImageRaw } from '@shared/logic/models/common.model';
import { WindowRefService } from '@shared/logic/services/window-ref.service';

interface Image {
  width: number;
  src?: string;
}

@Directive({
  selector: 'img[dynamic-image]',
  standalone: true
})
export class DynamicImageDirective implements AfterViewInit, OnChanges {
  @Input({ required: true }) set imageRaw(imgRaw: ImageRaw) {
    // All image sizes
    this._verticalImages = [
      { width: 88, src: imgRaw.talla_ocho },
      { width: 598, src: imgRaw.talla_siete }
    ];
    this._horizontalImages = [
      { width: 88, src: imgRaw.talla_ocho },
      { width: 592, src: imgRaw.talla_dos },
      { width: 600, src: imgRaw.talla_nueve },
      { width: 767, src: imgRaw.talla_tres },
      { width: 960, src: imgRaw.talla_hero },
      { width: 1279, src: imgRaw.talla_uno }
    ];
  }
  @Input({ required: true }) src: string; // Img by default (it's required by <img> element)
  @Input() isHero: boolean = false; // For header hero banners
  @HostBinding('attr.src') dynamicSrc: string | undefined;

  private _elementRef: ElementRef = inject(ElementRef);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _windowRefService: WindowRefService = inject(WindowRefService);

  private _imgElement: HTMLImageElement = this._elementRef.nativeElement;
  private _verticalImages: Image[] = [];
  private _horizontalImages: Image[] = [];

  @HostListener('window:resize')
  onResize() {
    this._getSrcBasedOnContainerMeasurements();
  }

  @HostListener('load')
  onLoad() {
    this._getSrcBasedOnContainerMeasurements();
  }

  ngAfterViewInit(): void {
    // SetTimeout is needed to avoid a timing issue or the fact that the element is not fully rendered when trying to access its dimensions
    setTimeout(() => {
      this._getSrcBasedOnContainerMeasurements();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If the source changes dynamically, we need to get it based on container again.
    if (!!changes.src?.previousValue && changes.src.previousValue !== changes.src.currentValue) {
      this._getSrcBasedOnContainerMeasurements();
    }
  }

  private _getSrcBasedOnContainerMeasurements(): void {
    // Specific for Hero images: img width has to be the same as window width
    // For some modals: if !clientWidth get window width as reference
    let contentWidth: number = this.isHero || !this._imgElement.clientWidth ? this._windowRefService.nativeWindow.innerWidth : this._imgElement.clientWidth;
    const contentHeight: number = this._imgElement.clientHeight;

    const isHorizontal = contentWidth > contentHeight;

    this.dynamicSrc = isHorizontal ? this._getHorizontalImage(contentWidth) : this._getVerticalImage(contentWidth);
    this._cdr.detectChanges();
  }

  private _getVerticalImage(contentWidth: number): string | undefined {
    const firstWiderVerticalImageSrc: string | undefined = this._verticalImages.find((img) => img.width >= contentWidth)?.src;
    return firstWiderVerticalImageSrc ?? this.src;
  }

  private _getHorizontalImage(contentWidth: number): string | undefined {
    const firstWiderHorizontalImageSrc: string | undefined = this._horizontalImages.find((img) => img.width > contentWidth)?.src;
    return firstWiderHorizontalImageSrc ?? this.src;
  }
}
