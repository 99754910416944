import { NgModule } from '@angular/core';

import { environment } from '@env/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers } from './app.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 10,
          autoPause: true,
          trace: true
        })
      : []
  ],
  exports: [StoreModule]
})
export class NgRxStoreModule {}
