import { Injectable, inject } from '@angular/core';
import { RoutingPaths } from '@app/core/routing/routing.const';
import { RoutingService } from '@app/core/routing/routing.service';

import { LiteralSections } from '@shared/logic/const/common';
import { CommonApiService } from '@shared/logic/http/api/common-api.service';
import { CollectiveBanner, Literals } from '@shared/logic/models/common.model';
import { CommonStoreNormalizer } from '@shared/store/common/common-store.normalizer';
import { Country, DeactivateReasonRaw, Footer, Genre, Language, StaticBanner } from '@shared/store/common/common.model';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';
import { catchError, map, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private _commonApiService: CommonApiService, private _commonStoreAdapter: CommonStoreAdapter, private routingService: RoutingService) {}

  public getCountries$(): Observable<Country[]> {
    return this._commonApiService.getCountries$().pipe(
      map((response) => CommonStoreNormalizer.normalizeCountry(response)),
      tap((countries) => this._commonStoreAdapter.setCountries(countries)),
      catchError(() => of([]))
    );
  }

  public getGenres$(): Observable<Genre[]> {
    return this._commonApiService.getGenres$().pipe(
      map((response) => CommonStoreNormalizer.normalizeGenre(response)),
      tap((genres) => this._commonStoreAdapter.setGenres(genres)),
      catchError(() => of([]))
    );
  }

  public getLanguages$(): Observable<Language[]> {
    return this._commonApiService.getLanguages$().pipe(
      map((response) => CommonStoreNormalizer.normalizeLanguage(response)),
      tap((languages) => this._commonStoreAdapter.setLanguages(languages)),
      catchError(() => of([]))
    );
  }

  public getLiteralsBySection$(section: LiteralSections): Observable<Literals | null | unknown[]> {
    return this._commonApiService.getLiteralsBySection$(section).pipe(tap((languages) => this._commonStoreAdapter.setLiterals(section, languages)));
  }

  public getStaticBannerByMembership$(membershipLevel?: number, hotelType: string | null = null): Observable<StaticBanner | null> {
    if (!membershipLevel) {
      membershipLevel = 1;
    }
    return this._commonApiService.getStaticBannerByMembership$(membershipLevel, hotelType).pipe(
      map((response) => CommonStoreNormalizer.normalizeStaticBanner(response)),
      catchError((error) => {
        return of(null);
      })
    );
  }

  public getCollectivesBanner$(code: string): Observable<CollectiveBanner> {
    return this._commonApiService.getCollectivesBanner$(code).pipe(map((r) => CommonStoreNormalizer.normalizeCollectivesBanner(r.data)));
  }

  public getAppHeader$(): Observable<any> {
    return this._commonApiService.getAppHeader$().pipe(map((response) => CommonStoreNormalizer.normalizeHeader(response.data)));
  }

  public getAppFooter$(): Observable<Footer> {
    return this._commonApiService.getAppFooter$().pipe(map((response) => CommonStoreNormalizer.normalizeFooter(response.data)));
  }

  public getDeactivationReasons$(): Observable<DeactivateReasonRaw[]> {
    return this._commonApiService.getDeactivationReasons$().pipe(
      map((response) => response?.data?.selectors),
      catchError(() => [])
    );
  }
}
