import { createAction } from '@ngrx/store';

import { UserCardPayment } from './payment.model';

export const enum PaymentActionsTypes {
  SetUserCards = '[Payment] Set User Cards',
  SetMerchantId = '[Payment] Set MerchantId'
}

export const setUserCards = createAction(
  PaymentActionsTypes.SetUserCards,
  ({ userCards }: { userCards: UserCardPayment[] }) => ({
    payload: { userCards }
  })
);

export const setMerchantId = createAction(
  PaymentActionsTypes.SetMerchantId,
  ({ merchantId }: { merchantId: string }) => ({
    payload: { merchantId }
  })
);
