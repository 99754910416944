import { Injectable } from '@angular/core';

interface Script {
  name: string;
  src: string;
}

export interface ScriptStatus {
  script: string;
  loaded: boolean;
  status: string;
}

type Scripts = Record<string, { loaded: boolean; src: string }>;

const ScriptStore: Script[] = [
  { name: 'secure-fields', src: 'https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js' },
  { name: 'secure-fields-test', src: 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js' }
];

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: Scripts = {};

  constructor() {
    this.loadScriptStore();
  }

  public load(...scripts: string[]): Promise<ScriptStatus[]> {
    const promises: Promise<ScriptStatus>[] = scripts.map((script: string) => this.loadScript(script));
    return Promise.all(promises);
  }

  private loadScriptStore(): void {
    this.scripts = ScriptStore.reduce((scripts: Scripts, script: Script) => {
      scripts[script.name] = { loaded: false, src: script.src };
      return scripts;
    }, {});
  }

  private loadScript(name: string): Promise<ScriptStatus> {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        return resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }

      //load script
      const script = (document as any).createElement('script');
      script.type = 'text/javascript';
      script.src = this.scripts[name].src;
      if (script.readyState) {
        //IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          }
        };
      } else {
        //Others
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
      }
      script.onerror = () => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
