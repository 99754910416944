import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, signal, WritableSignal } from '@angular/core';

import { DeviceType, ScreenWidth } from '@shared/logic/const/common';
import { distinctUntilChanged, fromEvent, map, Observable, startWith, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  public currentDeviceByScreen: DeviceType = DeviceType.Desktop;
  public currentDeviceByScreenSig: WritableSignal<DeviceType> = signal(DeviceType.Desktop);
  // public currentDeviceByScreen$: ReplaySubject<DeviceType> = new ReplaySubject<DeviceType>();

  private readonly _nativeWindow: Window;
  private screenResize$: Observable<Event>;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this._nativeWindow = isPlatformBrowser(platformId) ? window : ({} as Window);
    if (isPlatformBrowser(platformId)) {
      this.screenResize$ = fromEvent(this.nativeWindow, 'resize').pipe(debounceTime(500));
    }
  }

  get nativeWindow(): Window {
    return this._nativeWindow;
  }

  public initWindowListeners(): void {
    this.initDeviceTypeByScreenHandler();
    // If it is necessary to know the actual device type (not by screen width) in future => use ngx-device-detector library
  }

  private initDeviceTypeByScreenHandler(): void {
    this.screenResize$
      .pipe(
        debounceTime(500),
        startWith({ target: { innerWidth: this.nativeWindow.innerWidth } } as unknown as Event),
        map((event: Event) => this.getDeviceTypeByScreenWidth(event)),
        distinctUntilChanged(),
        tap((deviceType: DeviceType) => (this.currentDeviceByScreen = deviceType)),
        tap((deviceType) => this.currentDeviceByScreenSig.set(deviceType))
        // tap((deviceType: DeviceType) => this.currentDeviceByScreen$.next(deviceType))
      )
      .subscribe();
  }

  private getDeviceTypeByScreenWidth(event: Event): DeviceType {
    const width: number = (event.target as Window)?.innerWidth;
    if (!width) {
      return DeviceType.Desktop;
    }
    if (width < ScreenWidth.Mobile) {
      return DeviceType.Mobile;
    }
    if (width < ScreenWidth.Tablet) {
      return DeviceType.Tablet;
    }
    return DeviceType.Desktop;
  }
}
