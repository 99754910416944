import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { ModalElement } from '@app/shared/logic/abstract/modal.abstract';
import { AuthStoreAdapter } from '@app/shared/store/auth/auth.store.adapter';
import { PaymentService } from '@app/shared/store/payment/payment.service';
import { ButtonComponent } from '@atom/button/button.component';
import { CheckoutTrackService } from '@ga-tracking/services/tracking-sites/checkout/checkout-track.service';
import { CdkDialogWrapperNewModalInput, Literals, ModalResponse } from '@shared/logic/models/common.model';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';

export interface DeleteCCModalInput extends CdkDialogWrapperNewModalInput {
  token: string;
  literals?: Literals;
}

@Component({
  standalone: true,
  selector: 'app-delete-credit-card-modal',
  templateUrl: './delete-credit-card-modal.component.html',
  imports: [CdkDialogWrapperNewModule, ButtonComponent],
  styleUrls: ['./delete-credit-card-modal.component.scss']
})
export class DeleteCreditCardModalComponent extends ModalElement<DeleteCreditCardModalComponent, DeleteCCModalInput> implements OnInit, OnDestroy {
  public data: DeleteCCModalInput = this.getDialogData();
  constructor(
    @Inject(DIALOG_DATA) dialogData: DeleteCCModalInput,
    dialogRef: DialogRef<ModalResponse, DeleteCreditCardModalComponent>,
    private readonly _paymentService: PaymentService,
    private readonly _authStoreAdapter: AuthStoreAdapter,
    private readonly _checkoutTrackService: CheckoutTrackService
  ) {
    super(dialogData, dialogRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this._checkoutTrackService.trackViewModalDeleteCard();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onAccept(): void {
    this._paymentService
      .removeUserCard$(this._authStoreAdapter.selectAuthUserSig()!.id, this.getDialogData().token, this._authStoreAdapter.selectAuthUserSig()!.gipToken)
      .subscribe(() => {
        this._checkoutTrackService.trackClickDeleteCardConfirm();
        this.closeDialog({ success: true });
      });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }
}
