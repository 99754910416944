import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TooltipComponent } from '@atom/tooltip/tooltip.component';
import { DynamicImageDirective } from '@directives/image/dynamic-image.directive';
import { Tooltip } from '@shared/store/home/rewards/rewards.model';

@Component({
  standalone: true,
  selector: 'app-adv-rewards',
  templateUrl: './adv-rewards.component.html',
  styleUrls: ['./adv-rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipComponent, NgIf, DynamicImageDirective]
})
export class AdvRewardsComponent {
  @Input({ required: true }) tooltip: Tooltip;
  @Output() ev_tooltipClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() ev_tooltipOnMouseEnter: EventEmitter<void> = new EventEmitter<void>();

  public click(): void {
    this.ev_tooltipClick.emit();
  }

  public onMouseEnter(): void {
    this.ev_tooltipOnMouseEnter.emit();
  }
}
