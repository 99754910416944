import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafeHtmlDirectiveModule } from '@app/shared/directives/safe-html/safe-html.directive.module';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';
import { DynamicImageDirective } from '@directives/image/dynamic-image.directive';
import { ImageSliderComponent } from '@molecules/image-slider/image-slider.component';

import { CdkDialogWrapperNewComponent } from './cdk-dialog-wrapper-new.component';
import { ModalActionsNewDirective } from './cdk-dialog-wrapper-new.directive';

@NgModule({
  declarations: [CdkDialogWrapperNewComponent],
  imports: [CommonModule, IconItemComponent, ImageSliderComponent, SafeHtmlDirectiveModule, ModalActionsNewDirective, DynamicImageDirective],
  exports: [CdkDialogWrapperNewComponent, ModalActionsNewDirective]
})
export class CdkDialogWrapperNewModule {}
