import moment, { Moment } from 'moment';

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  month = month.length < 2 ? '0' + month : month;
  day = day.length < 2 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}

export function getFirstDateAndLastOfNextYear(): { firstDay: string; lastDay: string } {
  const currentDate = new Date();

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const nextYear = currentDate.getFullYear() + 1;
  const nextMonth = currentDate.getMonth() + 1;
  const lastDayOfNextMonth = new Date(nextYear, nextMonth + 1, 0);

  return {
    firstDay: formatDate(firstDayOfMonth),
    lastDay: formatDate(lastDayOfNextMonth)
  };
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date.getFullYear()) && !isNaN(date.getMonth() + 1) && !isNaN(date.getDate());
}

export function createMomentDate(date?: Date | string): Moment {
  return date ? (date instanceof Date ? moment(date.toISOString()) : moment(date)) : moment();
}
