import { Action } from '@ngrx/store';

import { HotelRaw } from './hotel.model.raw';

export enum HotelsActionsTypes {
  SetHotelMultiroomOpenBar = '[HOTEL-With-Dates] Set state HotelWithDatesMultiroom OpenBar',
  SetHotel = '[HOTEL] Set Hotel'
}

export class SetHotelWithtDatesOpenBar implements Action {
  readonly type = HotelsActionsTypes.SetHotelMultiroomOpenBar;
  constructor(readonly payload: { hotelWithDatesOpenBar: boolean }) {}
}

export class SetHotel implements Action {
  readonly type = HotelsActionsTypes.SetHotel;

  constructor(readonly payload: { hotel: HotelRaw }) {}
}

export type HotelsActions = SetHotelWithtDatesOpenBar | SetHotel;
