import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import {
  InformativeBanner,
  InformativeBannerItem,
  InformativeBannerItemRaw,
  InformativeBannerRaw
} from '@shared/store/home/informative-banner/informative-banner.model';

export class InformativeBannerNormalizer {
  static normalize(informativeBannerItemRaw: InformativeBannerItemRaw): InformativeBannerItem {
    const item = {} as InformativeBannerItem;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.title = informativeBannerItemRaw.title;
    item.items = informativeBannerItemRaw.lista_banners_informativos.map((i) => InformativeBannerNormalizer.normalizeInformativeBanner(i));
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  static normalizeInformativeBanner(informativeBannerRaw: InformativeBannerRaw): InformativeBanner {
    const item = {} as InformativeBanner;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.user = informativeBannerRaw.user;
    item.images = informativeBannerRaw.imagen;
    item.title = informativeBannerRaw.titulo;
    item.description = informativeBannerRaw.descripcion;
    item.link = {
      label: informativeBannerRaw.enlaces.desktop[0].title,
      url: GeneralFunctions.extractFinalUrl(informativeBannerRaw.enlaces.desktop[0].uri)
    };
    item.gtmTracking = {
      hotelName: 'na',
      hotelId: 'na',
      hotelCity: 'na',
      hotelCategory: 'na', // urbano, vacacional o resort
      hotelStars: 'na',
      promotionId: 'cards news home',
      labelText: GoogleTagFunctions.reduceTag(informativeBannerRaw.titulo),
      ctaText: GoogleTagFunctions.reduceTag(informativeBannerRaw.enlaces.desktop[0].title),
      displayedContentLocation: 'middle home',
      index: null // To be filled on ngFor side...
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
