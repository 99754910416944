import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  imports: [NgClass, MatTooltipModule]
})
export class TooltipComponent {
  @Input() tooltipText!: string;

  tooltipElement: HTMLElement | null = null;
  tooltipMessage = 'tooltipMessage: Prueba de Tooltip';
  showTooltip = false;

  constructor() {
    //todo
  }

  @Input()
  desc = '';

  @Input()
  title = '';

  @Input()
  white = false;

  ngOnInit() {
    //todo
  }
}
