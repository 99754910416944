import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Literals } from '@shared/logic/models/common.model';
import { setCountries, setGenres, setLanguages, setLiterals } from '@shared/store/common/common.actions';
import { Country, Genre, Language } from '@shared/store/common/common.model';

import { CommonSelectors } from './common.selectors';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class CommonStoreAdapter {
  constructor(private store: Store<State>) {}

  public setLanguages(languages: Language[]): void {
    this.store.dispatch(setLanguages({ languages }));
  }

  public setLiterals(section: string, literals: Literals): void {
    this.store.dispatch(setLiterals({ section, literals }));
  }

  public setCountries(countries: Country[]): void {
    this.store.dispatch(setCountries({ countries }));
  }

  public setGenres(genres: Genre[]): void {
    this.store.dispatch(setGenres({ genres }));
  }

  public readonly selectLanguagesSig = this.store.selectSignal(CommonSelectors.getLanguages);
  public readonly selectLiteralsBySectionSig = (section: string) => this.store.selectSignal(CommonSelectors.getLiteralsBySection(section));

  public readonly selectCountriesSig = this.store.selectSignal(CommonSelectors.getCountries);

  public readonly selectGenresSig = this.store.selectSignal(CommonSelectors.getGenres);
}
