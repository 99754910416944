import { createAction } from '@ngrx/store';

import { Literals } from '@shared/logic/models/common.model';
import { Country, Genre, Language } from '@shared/store/common/common.model';

export const enum CommonActionsTypes {
  SetLanguages = '[Common] Set Languages',
  SetLiterals = '[Common] Set Literals',
  SetCountries = '[Common] Set Countries',
  SetGenres = '[Common] Set Genres'
}

export const setLanguages = createAction(
  CommonActionsTypes.SetLanguages,
  ({ languages }: { languages: Language[] }) => ({ payload: { languages } })
);
export const setLiterals = createAction(
  CommonActionsTypes.SetLiterals,
  ({ section, literals }: { section: string; literals: Literals }) => ({ payload: { section, literals } })
);
export const setCountries = createAction(
  CommonActionsTypes.SetCountries,
  ({ countries }: { countries: Country[] }) => ({ payload: { countries } })
);
export const setGenres = createAction(CommonActionsTypes.SetGenres, ({ genres }: { genres: Genre[] }) => ({
  payload: { genres }
}));
