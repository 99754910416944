import { UrlMatchResult, UrlSegment } from '@angular/router';

// I know. This is bullshit. But we have no choice without time or adequate tools.
// For legal text and contact page
export function createMatcher(routes: Record<string, string>[]): (segments: UrlSegment[]) => UrlMatchResult | null {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const path = decodeURIComponent(segments.join('/'));
    const matchingRoute = routes.find((route) => Object.values(route).includes(path));
    return matchingRoute ? { consumed: segments } : null;
  };
}
