<div class="add-credit-card-modal">
  <app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
    <div class="add-credit-card-modal__inputs">
      <app-datatrans-widget
        [literals]="data.literals"
        [merchantId]="data.merchantId"
        [receivedFormGroup]="data.receivedFormGroup"
        [hideCVV]="data.hideCVV"
      ></app-datatrans-widget>
    </div>
    <div class="add-credit-card-modal__pci">
      <img src="assets/img/modals/pci.png" alt="" />
    </div>
    <div class="add-credit-card-modal__button">
      <app-button
        label="Guardar tarjeta"
        [fillMobile]="true"
        type="cds-btn--highlight"
        [disabled]="data.receivedFormGroup.invalid"
        (click)="onAccept()"
      ></app-button>
    </div>
  </app-cdk-dialog-wrapper-new>
</div>
