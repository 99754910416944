import { Pipe, PipeTransform } from '@angular/core';

import { RoutingService } from './routing.service';

@Pipe({
  name: 'routing',
  standalone: true
})
export class RoutingPipe implements PipeTransform {
  constructor(private routingService: RoutingService) {}

  transform(path: string | string[]): string | string[] {
    if (!path || path.includes('javascript:void(0)') || path.includes('#')) {
      return 'javascript:void(0)';
    }
    if (path.includes('http')) {
      return path;
    }
    if (Array.isArray(path)) {
      return this.routingService._localizePaths(path);
    }
    return this.routingService._localizePath(path);
  }
}
