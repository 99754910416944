import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2, Inject, signal } from '@angular/core';

import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { auditTime, debounceTime, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OneTrustService {
  public oneTrustLoaded = signal(false);
  private _renderer2: Renderer2;
  private unsubscribe$ = new Subject<void>();

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private readonly _document: Document) {
    this._renderer2 = rendererFactory.createRenderer(null, null);
  }

  /**
   * Loads OneTrust scripts into the document's 'head' if not already loaded.
   * Inserts 'OptanonWrapper', 'otSDKStub', and 'OtAutoBlock' scripts in order.
   *
   * @public
   * @returns {void}
   */
  public loadScript(): void {
    // https://handbook.gitlab.com/handbook/marketing/digital-experience/onetrust-cookie-consent/
    if (!this.oneTrustLoaded()) {
      const head = this._document.head;

      //[INFO] this code will be located at the beginning of the <head> tag

      // third block 3º
      const scriptEmptyFunc = this._renderer2.createElement('script');
      scriptEmptyFunc.id = 'tree';
      scriptEmptyFunc.type = 'text/javascript';
      scriptEmptyFunc.innerHTML = 'function OptanonWrapper() {}';
      head.insertBefore(scriptEmptyFunc, head.firstChild);

      // second block 2º
      const scriptSdkStub = this._renderer2.createElement('script');
      scriptSdkStub.id = 'two';
      scriptSdkStub.type = 'text/javascript';
      scriptSdkStub.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      scriptSdkStub.charset = 'UTF-8';
      scriptSdkStub.setAttribute('data-domain-script', environment.oneTrustTenantId);
      head.insertBefore(scriptSdkStub, head.firstChild);

      // first block 1º
      const scriptAutoBlock = this._renderer2.createElement('script');
      scriptAutoBlock.id = 'one';
      scriptAutoBlock.type = 'text/javascript';
      scriptAutoBlock.src = `https://cdn.cookielaw.org/consent/${environment.oneTrustTenantId}/OtAutoBlock.js`;
      scriptAutoBlock.charset = 'UTF-8';

      head.insertBefore(scriptAutoBlock, head.firstChild);

      this.oneTrustLoaded.set(true);
    }
  }

  private observeHeadMutations(): Observable<MutationRecord[]> {
    return new Observable((observer) => {
      const mutationObserver = new MutationObserver((mutations) => {
        observer.next(mutations);
      });

      mutationObserver.observe(this._document.head, { childList: true });

      return () => mutationObserver.disconnect();
    });
  }

  public observeAndReorderScripts() {
    this.observeHeadMutations()
      .pipe(auditTime(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.reorderScripts();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      });
  }

  /**
   * Reorders scripts in the document's 'head'.
   * Removes all scripts, then inserts scripts with IDs in 'orderedIds', and appends the rest.
   *
   * @public
   * @returns {void}
   */
  public reorderScripts(): void {
    const head = this._document.head;
    const scripts = Array.from(head.getElementsByTagName('script'));
    const orderedIds = ['emarsys_webpersonalization_es6', 'GTMscript', 'tree', 'two', 'one'];

    scripts.forEach((script) => head.removeChild(script));

    orderedIds.map((id) => {
      const script = scripts.find((script) => script.id === id);
      if (script) {
        head.insertBefore(script, head.firstChild);
      }
    });

    scripts.forEach((script) => {
      if (!orderedIds.includes(script.id)) {
        head.appendChild(script);
      }
    });

    // // Asynchronous loading is eliminated GTMscript
    this.removeAsyncAttributeForID('GTMscript');
  }

  /**
   * Removes the async attribute from the script with the given id
   * @param scriptId The id of the script to remove the async attribute
   */
  public removeAsyncAttributeForID(scriptId: string) {
    const script = this._document.getElementById(scriptId);
    if (script) {
      this._renderer2.removeAttribute(script, 'async');
    } else {
      console.error(`Script with id ${scriptId} not found`);
    }
  }

  /**
   * Removes duplicate script tags from the document's head.
   *
   * Checks script `src` attributes and removes duplicates.
   * @method removeDuplicateScripts
   * @returns {void}
   * @public
   */
  public removeDuplicateScripts(): void {
    const head = this._document.head;
    const scripts = Array.from(head.getElementsByTagName('script'));
    const srcSet = new Set();

    scripts.forEach((script) => {
      const src = script.src;

      if (srcSet.has(src)) {
        head.removeChild(script);
      } else {
        srcSet.add(src);
      }
    });
  }
}
