import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './ga.reducer';

const getSearchState = createFeatureSelector<State>('ga');

export const GaSelectors = {
  getPrevContentInfo: createSelector(getSearchState, (state: State) => state.prevContentInfo),
  getFirstSearchDoneStatus: createSelector(getSearchState, (state: State) => state.isFirstSearchDone),
  getFirstSearchType: createSelector(getSearchState, (state: State) => state.firstSearchType)
};
