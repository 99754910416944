import { Literals } from '@app/shared/logic/models/common.model';
import { UserCardPayment } from './payment.model';
import { UserCardPaymentRaw } from './payment.model.raw';

export class PaymentNormalizer {
  public static normalizeUserCardPayment(itemRaw: UserCardPaymentRaw): UserCardPayment {
    const item = {} as UserCardPayment;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.partnerId = itemRaw.PartnerId;
    item.brandCode = itemRaw.CardType;
    item.token = itemRaw.Token;
    item.cardMask = itemRaw.TokenMask;
    item.aliasCvv = itemRaw.Aliascvv;
    item.holderName = itemRaw.Holder;
    item.expirationDate = itemRaw.ExpirationDate;
    item.block = itemRaw.Block;
    item.isSetAsDefault = itemRaw.IsDefault;
    item.registrationDate = itemRaw.RegisteredDate;
    item.registrationTime = itemRaw.RegisteredTime;
    item.fingerprint = itemRaw.Fingerprint;
    item.remarks = itemRaw.Remarks;
    item.formMask = itemRaw.MaskForm;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static normalizePaymentsLiterals(literals: Literals): Record<string, string> {
    return {
      paymentData: '2. Datos de pago', // TODO: Missing
      noAvailableCards: 'Tarjetas no disponibles', // TODO Missing
      formPhCardholder: literals.card_holder_placeholder,
      formPhCard: literals.card_number_placeholder,
      formPhExpiryDate: literals.card_expiration_placeholder,
      formPhCvv: literals.card_cvv_placeholder,
      saveCardForFutureBookings: literals.save_card_radio,
      maxCardSaved: literals.max_cards_saved,
      addNewCard: literals.add_card_msg
    };
  }
}
