import { RoutingService } from '@core/routing/routing.service';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { FeaturedHotel, FeaturedHotelItem, FeaturedHotelItemRaw, FeaturedHotelRaw } from '@shared/store/home/featured-hotels/featured-hotel.model';

export class FeaturedHotelNormalizer {
  static normalize(itemRaw: FeaturedHotelItemRaw, language: string | null): FeaturedHotelItem {
    const item = {} as FeaturedHotelItem;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.title = itemRaw?.title || '';

    item.items = [];
    if (GeneralFunctions.isArrayLike(itemRaw.lista_hoteles, 1)) {
      item.items = itemRaw?.lista_hoteles?.map((i) => FeaturedHotelNormalizer.normalizeHighlightedHotel(i, language));
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  /*
    Both home and hotel-without-dates pages use this normalizer.
    =====> BE CAREFUL WHEN TOUCHING THIS. <=====
  */
  private static normalizeHighlightedHotel(itemRaw: FeaturedHotelRaw, language: string | null): FeaturedHotel {
    const item = {} as FeaturedHotel;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.id = itemRaw.hotel_id;
    item.hotelCode = itemRaw.backcode;
    item.hotelType = itemRaw.tipo_hotel;
    item.hotelName = itemRaw.hotel;
    item.hotelCity = itemRaw.ciudad;
    item.hotelStars = Number(itemRaw.estrellas);
    item.isAdultsOnly = !!itemRaw.adults_only;
    item.images = itemRaw.imagen;
    const url = GeneralFunctions.extractFinalUrl(itemRaw.url);
    item.internalUrl = [RoutingService.LANDING_HOTEL_URL(), url].join('/');
    item.displayRating = parseFloat((itemRaw.hotelRating || 0).toFixed(1));
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    let priceLabel = null;
    const { minPrice, currency, currencySymbol } = itemRaw;
    if (minPrice && (currency || currencySymbol)) {
      priceLabel = `${minPrice} ${currency || ''}${currencySymbol || ''}`;
    }
    item.displayPrice = priceLabel;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.gtmTracking = {
      hotelName: GoogleTagFunctions.reduceTag(itemRaw.hotel),
      hotelId: GoogleTagFunctions.reduceTag(itemRaw.backcode),
      hotelCity: GoogleTagFunctions.reduceTag(itemRaw.ciudad),
      hotelCategory: itemRaw.tipo_hotel,
      hotelStars: itemRaw.estrellas,
      promotionId: 'cards hotels home',
      promotionName: 'hotel destacado',
      hotelLabel: `Desde ${priceLabel} /noche`, //TODO
      displayedContentLocation: 'bottom home',
      index: null // To be filled on ngFor side...
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
