import { DecimalPipe, NgClass, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, Renderer2, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { RouterLink } from '@angular/router';

import { AppConfig } from '@app/app.config';
import { LanguageDataService } from '@app/core/services/languages/language-data.service';
import { EmarsysService } from '@app/shared/logic/services/emarsys/services/base/emarsys.service';
import { AuthProviderData } from '@app/shared/store/auth/auth.model';
import { ButtonComponent } from '@atom/button/button.component';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';
import { RoutingPaths } from '@core/routing/routing.const';
import { RoutingPipe } from '@core/routing/routing.pipe';
import { RoutingService } from '@core/routing/routing.service';
import { HeaderTrackService } from '@ga-tracking/services/tracking-sites/header/header-track.service';
import { LoginTrackService } from '@ga-tracking/services/tracking-sites/login/login-track.service';
import { PrivateAreaTrackService } from '@ga-tracking/services/tracking-sites/private-area/private-area-track.service';
import { DeviceService } from '@services/device-detection/device-detection.service';
import { LiteralSections } from '@shared/logic/const/common';
import { AvoidHrefDirectiveModule } from '@shared/logic/directives/avoid-href/avoid-href.directive.module';
import { Literals } from '@shared/logic/models/common.model';

import { CommonService } from '@shared/logic/services/common.service';
import { LoginFormBasicComponent } from '@shared/ns_components/gdi_extracted/login-form-basic/login-form-basic.component';
import { LoggedNamePipe } from '@shared/ns_components/header/pipes/logged-name.pipe';
import { AuthService } from '@shared/store/auth/auth.service';
import { AuthStoreAdapter } from '@shared/store/auth/auth.store.adapter';
import { Header } from '@shared/store/common/common.model';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';
import { ConstantsHeaderTypePosition } from '@shared/store/header/header.const';
import { HeaderService } from '@shared/store/header/header.service';
import { HeaderStoreAdapter } from '@shared/store/header/header.store.adapter';
import { HeaderStyleService } from '@shared/store/header/header.styles.service';

import { UiStoreAdapter } from '@shared/store/ui/ui.store.adapter';
import { timer } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    NgIf,
    NgClass,
    ButtonComponent,
    LoggedNamePipe,
    IconItemComponent,
    RouterLink,
    NgForOf,
    RoutingPipe,
    AvoidHrefDirectiveModule,
    LoginFormBasicComponent,
    DecimalPipe,
    TitleCasePipe
  ]
})
export class HeaderComponent implements OnInit {
  public readonly literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();
  public loggedHeaderSections = [
    {
      title: this.literals?.label_my_bookings,
      icon: 'calendar',
      routerLink: '/private-area/my-bookings',
      isNew: false
    },
    {
      title: this.literals?.label_my_account,
      icon: 'person',
      routerLink: '/private-area/my-account',
      isNew: false
    },
    {
      title: this.literals?.label_travel_cash,
      icon: 'Travel-Cash_small',
      routerLink: '/private-area/my-travel-cash',
      isNew: false
    }
    /* NOT AVAILABLE */
    /* {
      title: this.literals?.label_my_offer,
      icon: 'premium',
      routerLink: '',
      isNew: true
    } */
  ];
  public loggedMenuActive = false;
  public header: Header;
  // SIGNALS
  public isHeaderLoginVisible = this._uiStoreAdapter.selectHeaderLoginDisplayStatusSig;
  public userSig = this._authStoreAdapter.selectAuthUserSig;
  public readonly authProviderDataSig: Signal<AuthProviderData | null> = this._authStoreAdapter.selectAuthProviderData;
  public deviceSig = toSignal(this._deviceService.device$);
  public showOptionsContainerSig = this._headerService.showOptionsContainerSig;

  private pagesRedirectHome = [RoutingPaths.PrivateArea, RoutingPaths.BookingConfirmation, RoutingPaths.BookingManagement, RoutingPaths.BookingCancellation];

  constructor(
    private _headerService: HeaderService,
    private _authStoreAdapter: AuthStoreAdapter,
    private _headerTrackService: HeaderTrackService,
    private _headerStoreAdapter: HeaderStoreAdapter,
    private _uiStoreAdapter: UiStoreAdapter,
    private _authService: AuthService,
    private _routingService: RoutingService,
    private _deviceService: DeviceService,
    private _loginTrackService: LoginTrackService,
    private _commonService: CommonService,
    private _commonStoreAdapter: CommonStoreAdapter,
    private _privateAreaTrackService: PrivateAreaTrackService,
    private _headerStyleService: HeaderStyleService,
    private _destroyRef: DestroyRef,
    private _renderer: Renderer2,
    private _languageDataService: LanguageDataService,
    private _emarsysService: EmarsysService
  ) {}

  public ngOnInit(): void {
    this._commonService.getAppHeader$().subscribe((response) => {
      this.header = response;

      let positionHero: string = '';
      const url = this._routingService.getUrl();
      if (url === RoutingPaths.Home || url.includes('hotel')) {
        positionHero = ConstantsHeaderTypePosition.TYPE_ON_HERO;
      } else if (url.includes(RoutingPaths.PrivateArea)) {
        positionHero = ConstantsHeaderTypePosition.TYPE_WITHOUT_HERO;
      }
      this._headerStyleService.setData(positionHero);
    });
  }

  public closeExpandedHeader(): void {
    this._renderer.removeClass(document.querySelector('.app-container'), 'app-container--modal');

    this.setExpandedStatus(false);
    this.loggedMenuActive = false;
  }

  public setExpandedStatus(status: boolean): void {
    if (status) {
      this._renderer.addClass(document.querySelector('.app-container'), 'app-container--modal');
    } else {
      this._renderer.removeClass(document.querySelector('.app-container'), 'app-container--modal');
    }

    this._uiStoreAdapter.setHeaderLoginDisplayStatus(status);
    if (status) {
      this._headerTrackService.trackClickLogin();
      this._loginTrackService.trackLoginPageDisplay();
    }
  }

  public myProfile(): void {
    // TODO: hide on scroll too ==========================
    this.loggedMenuActive = !this.loggedMenuActive;
    // Close after 10 sec
    timer(AppConfig.config.headerMenuDisplayTimeMs)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => (this.loggedMenuActive = false));
    // track service only by clicking
    this._headerTrackService.trackClickProfile();
  }

  public logo(event: Event): void {
    event.preventDefault();
    this._headerStoreAdapter.setHeaderActive(true);
    this._headerTrackService.trackClickLogo();
  }

  public logout(): void {
    const href = location?.href ?? '/';
    this._headerTrackService.trackClickLogout(href);
    const providerId = this.authProviderDataSig()?.providerId ?? 'email';
    this._authService.logout$().subscribe(() => {
      // clean emersys session
      //this._emarsysService.restartSession();

      if (this.pagesRedirectHome.some((path) => this._routingService.getUrl().includes(path))) {
        this._routingService.GO_HOME().then(() => {
          this._loginTrackService.trackLogOut(providerId, location?.href ?? '/');
          this._authService.logoutTracking();
        });
      } else {
        this._loginTrackService.trackLogOut(providerId, href);
        this._authService.logoutTracking();
      }
    });
  }

  public clickMenuLoginOption(option: string, link: string): void {
    this._privateAreaTrackService.trackClickMenuOption(option, link);
  }
}
