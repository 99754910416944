import { CommonModule, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '@env/environment';
import { GoogleTagManagerOverlordService } from '@ga-tracking/services/google-tag-manager/google-tag-manager-overlord.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ConfigService } from '@services/config.service';
import { HttpUtils } from '@services/http-utils/http-utils';
import { LanguageDataService } from '@services/languages/language-data.service';
import { GoogleMapsLoaderService } from '@services/maps/google-maps.service';
import { DeviceDetectorDirective } from '@shared/logic/directives/device-detection.directive';
import { LoadingInterceptor } from '@shared/logic/http/interceptors/loader.interceptor';
import { SymfonyTokenInterceptor } from '@shared/logic/http/interceptors/symfony-token.interceptor';
import { MainLoader } from '@shared/ns_components/loaders/main-loader/main-loader';
import { AuthService } from '@shared/store/auth/auth.service';

import { NgRxStoreModule } from '@shared/store/store.module';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/ns_components/header/header.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    HeaderComponent,
    HttpClientModule,
    NgRxStoreModule,
    AngularFireModule.initializeApp({
      apiKey: environment.identityProviderApiKey,
      authDomain: environment.identityProviderAuthDomain
    }),
    MainLoader,
    DeviceDetectorDirective
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.init(),
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.init(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (gtmOs: GoogleTagManagerOverlordService) => () => gtmOs.init(),
      deps: [GoogleTagManagerOverlordService],
      multi: true
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    { provide: HTTP_INTERCEPTORS, useClass: SymfonyTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (langService: LanguageDataService) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', langService.getLang());
          return { url };
        }
      }),
      deps: [LanguageDataService]
    },
    GoogleMapsLoaderService,
    HttpClient,
    HttpUtils
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
  // initialize new Relic for browser
  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      const NREUM = (window as any).NREUM;

      if (NREUM) {
        NREUM.loader_config = environment.newRelic.loader_config;
        NREUM.info = environment.newRelic.info;
      }
    }
  }
}
