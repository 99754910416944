import { Pipe, PipeTransform } from '@angular/core';

import { User } from '@shared/store/auth/auth.model';

@Pipe({
  standalone: true,
  name: 'loggedName'
})
export class LoggedNamePipe implements PipeTransform {
  transform(user: User, maxChars: number, device?: string): string {
    const isMobile = device === 'mobile';
    const isNameTooLong = user.firstName.length > maxChars;
    return isMobile || isNameTooLong ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}` : user.firstName;
  }
}
