import { environment } from 'src/environments/environment';

export interface ApiUrl {
  path: string;
  server: string;
  context?: any;
}

export const API_KEY_DRUPAL = {
  GET_LANGUAGE: 'getLanguage',
  GET_USER: 'getUser',
  GET_PAGE: 'getPage',
  GET_SECTIONS: 'getSections',
  GET_PREFOOTER: 'getPreFooter',
  GET_HOTEL: 'getHotel',
  GET_HOTEL_BY_ALIAS: 'getHotelByAlias',
  GET_ALL_ROOMS: 'getAllRooms',
  GET_FLIGHT_AND_HOTEL: 'getFlightAndHotel',
  GET_GENRES: 'getGenres',
  GET_APP_HEADER: 'GET_APP_HEADER',
  GET_APP_FOOTER: 'GET_APP_FOOTER',
  // Landing
  GET_LANDING: 'GET_LANDING',
  GET_LANDING_DESTINY: 'GET_LANDING_DESTINY',
  GET_LANDING_REWARDS_TABLE: 'GET_LANDING_REWARDS_TABLE',
  GET_LANDING_COLLECTIVES_BANNER: 'GET_LANDING_COLLECTIVES_BANNER',
  // Common
  GET_LITERALS: 'GET_LITERALS',
  GET_LITERALS_BY_SECTION: 'GET_LITERALS_BY_SECTION',
  GET_STATIC_BANNER_BY_MEMBERSHIP: 'GET_STATIC_BANNER_BY_MEMBERSHIP',
  GET_BENEFITS_BANNER_BY_MEMBERSHIP: 'GET_BENEFITS_BANNER_BY_MEMBERSHIP',
  // Contact
  GET_CONTACT_INFO: 'GET_CONTACT_INFO',
  GET_CONTACT_OPTIONS: 'GET_CONTACT_OPTIONS',
  GET_HOTEL_DATA: 'GET_HOTEL_DATA',
  // FAQS
  GET_FAQS: 'GET_FAQS',
  GET_DEACTIVATION_REASONS: 'GET_DEACTIVATION_REASONS',
  // LEGAL
  GET_LEGAL_NOTICE: 'GET_LEGAL_NOTICE',
  GET_POLICY_PRIVACY: 'GET_POLICY_PRIVACY',
  GET_ABOUT_US: 'GET_ABOUT_US',
  GET_REWARDS: 'GET_REWARDS',
  GET_COOKIES: 'GET_COOKIES',
  GET_TERMS_CONTRACT: 'GET_TERMS_CONTRACT',
  GET_GENERAL_REWARDS_CONDITIONS: 'GET_GENERAL_REWARDS_CONDITIONS',
  GET_LEGAL_WARNING_BERLIN: 'GET_LEGAL_WARNING_BERLIN',
  GET_ETHICAL_CHANNEL: 'GET_ETHICAL_CHANNEL',
  GET_EXPANSION: 'GET_EXPANSION',
  GET_CORPORATE_SOCIAL_RESPONSABILITY: 'GET_CORPORATE_SOCIAL_RESPONSABILITY',
  GET_WORK_WITH_US: 'GET_WORK_WITH_US',
  GET_PRIVACY_POLICY_FOR_CANDIDATES: 'GET_PRIVACY_POLICY_FOR_CANDIDATES',
  GET_RESPONSIBLE_PROCESSING: 'GET_RESPONSIBLE_PROCESSING',
  GET_REWARDS2: 'GET_REWARDS2'
};

export const API_KEY_SYMFONY = {
  GET_TOKEN: 'bearerToken',
  GET_CALENDAR_PRICES: 'getCalendarPrices',
  GET_ROOMS: 'getRooms',
  GET_CHECKOUT_HASH: 'getCheckoutHash',
  GET_CHECKOUT_PBL_HASH: 'getCheckouPBLtHash',
  GET_MEAL_PLANS: 'getMealPlans',
  GET_EXISTING_EMAIL: 'getExistingEmail',
  GET_PAYMENT_METHODS: 'getPaymentMethods',
  GET_COUNTRIES: 'getCountries',
  GET_TRANSACTION_PAYMENT: 'getTransactionsPayment',
  GET_BOOKING_CONFIRMATION: 'getConfirmation',
  GET_SEND_BOOKING_CONFIRMATION: 'getSendBookingConfirmation',
  GET_COUPON: 'postCoupon',
  POST_HASH_ROOMS: 'postHashRoom',
  POST_CREATE_BOOKING: 'postCreateBooking',
  POST_PROCESS_PBL: 'postProcessPBL',
  CHECK_PAYMENT_SUCCESSFUL: 'checkPaymentSuccessful',
  POST_CREATE_USER_ON_CHECKOUT: 'postCreateUserOnCheckout',
  PUT_REACTIVATE_USER_ON_CHECKOUT: 'putReactivateUserOnCheckout',
  POST_CHECK_TOKEN: 'postCheckToken',
  POST_CREATE_USER_ON_REGISTER: 'postCreateUserOnRegister',
  PUT_CONFIRM_EMAIL: 'putConfirmEmail',
  PUT_RECOVER_PASSWORD: 'putRecoverPassword',
  POST_RECOVER_EMAIL: 'postRecoverEmail',
  POST_INIT_SESSION_EMAIL: 'postOtpEmail',
  POST_LOGIN_SESSION_OTP: 'postLoginSessionOtp',
  GET_VERIFY_TOKEN_VALID: 'getVerifyTokenValid',
  GET_USER_CARDS: 'getUserCards',
  POST_USER_CARDS: 'postUserCards',
  GET_CONVERT_CURRENCY: 'GET_CONVERT_CURRENCY',
  GET_BOOKING_MANAGEMENT: 'GET_BOOKING_MANAGEMENT',
  PUT_UPDATE_BOOKING: 'PUT_UPDATE_BOOKING',
  DELETE_CANCEL_BOOKING: 'DELETE_CANCEL_BOOKING',

  // Private area
  GET_ACTIVE_BOOKINGS: 'GET_ACTIVE_BOOKINGS',
  GET_BOOKING_HISTORY: 'GET_BOOKING_HISTORY',
  GET_TRAVEL_CASH_HISTORY: 'GET_TRAVEL_CASH_HISTORY',
  GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
  GET_WALLET: 'GET_WALLET',
  REQUEST_USER_EDITION: 'REQUEST_USER_EDITION',
  REQUEST_USER_PASSWORD_CHANGE: 'REQUEST_USER_PASSWORD_CHANGE',
  REQUEST_ACCOUNT_DEACTIVATION: 'REQUEST_ACCOUNT_DEACTIVATION',

  // CONTACT
  POST_CONTACT_FORM: 'POST_CONTACT_FORM',
  POST_RECAPTCHA: 'POST_RECAPTCHA',

  GET_USER_DATA_BY_UID: 'GET_USER_DATA_BY_UID',
  POST_USER_PREFERENCES: 'POST_USER_PREFERENCES'
};

const LANDINGS_URL = {
  [API_KEY_DRUPAL.GET_LANDING]: {
    path: '/api/landing/{lang}/{id}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_LANDING_DESTINY]: {
    path: '/api/landing_destinos/{lang}/{destination}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_LANDING_REWARDS_TABLE]: {
    path: '/api/table_rewards/{lang}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_LANDING_COLLECTIVES_BANNER]: {
    path: '/api/banner-colectivo/{lang}/{code}?_format=json',
    server: environment.drupal
  }
};

const PRIVATE_AREA_URL = {
  [API_KEY_SYMFONY.GET_ACTIVE_BOOKINGS]: {
    path: '/api/get_mybookings?email={email}&idToken={idToken}&language={lang}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_BOOKING_HISTORY]: {
    path: '/api/get_historic?email={email}&idToken={idToken}&language={lang}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_TRAVEL_CASH_HISTORY]: {
    path: '/api/members/historic?rewards_historic_request_dto_r[idClient]={idClient}&rewards_historic_request_dto_r[email]={email}&rewards_historic_request_dto_r[idToken]={idToken}&rewards_historic_request_dto_r[language]={lang}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_ACCOUNT_INFO]: {
    path: '/api/members/info?idClient={idClient}&email={email}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_WALLET]: {
    path: '/api/wallet?idClient={idClient}&langcode={lang}&email={email}&idToken={idToken}',
    server: environment.symfony
  },
  [API_KEY_DRUPAL.GET_DEACTIVATION_REASONS]: {
    path: '/api/unsubscribe_options/{lang}',
    server: environment.drupal
  },
  [API_KEY_SYMFONY.REQUEST_USER_EDITION]: {
    path: '/api/members/update',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.REQUEST_USER_PASSWORD_CHANGE]: {
    path: '/api/members/update_pass',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.REQUEST_ACCOUNT_DEACTIVATION]: {
    path: '/api/members/disable',
    server: environment.symfony
  }
};

const COMMON = {
  [API_KEY_DRUPAL.GET_APP_HEADER]: {
    path: '/api/secciones_web/{lang}/header',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_APP_FOOTER]: {
    path: '/api/secciones_web/{lang}/footer',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_GENRES]: {
    path: '/api/taxonomy/identidades_genero/{lang}',
    server: environment.drupal
  },
  [API_KEY_SYMFONY.GET_COUNTRIES]: {
    path: '/api/countries?language={lang}',
    server: environment.symfony
  },
  [API_KEY_DRUPAL.GET_LITERALS]: {
    path: '/api/literales/{lang}/all',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_LITERALS_BY_SECTION]: {
    path: '/api/literales/{lang}/{categoria_literales}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_STATIC_BANNER_BY_MEMBERSHIP]: {
    path: '/api/static_banner_offers?membershipLevel={membershipLevel}&language={lang}&hotelType={hotelType}',
    server: environment.symfony
  },
  [API_KEY_DRUPAL.GET_BENEFITS_BANNER_BY_MEMBERSHIP]: {
    path: '/api/beneficios/{lang}/{membership}',
    server: environment.drupal
  }
};

const LEGAL_PAGES = {
  [API_KEY_DRUPAL.GET_LEGAL_NOTICE]: { path: '/api/basic_page/{lang}/aviso-legal', server: environment.drupal },
  [API_KEY_DRUPAL.GET_POLICY_PRIVACY]: { path: '/api/basic_page/{lang}/politica-de-privacidad', server: environment.drupal },
  [API_KEY_DRUPAL.GET_ABOUT_US]: { path: '/api/basic_page/{lang}/sobre-nosotros', server: environment.drupal },
  [API_KEY_DRUPAL.GET_REWARDS]: { path: '/api/basic_page/{lang}/condiciones-rewards', server: environment.drupal },
  [API_KEY_DRUPAL.GET_COOKIES]: { path: '/api/basic_page/{lang}/politica-de-cookies', server: environment.drupal },
  [API_KEY_DRUPAL.GET_TERMS_CONTRACT]: { path: '/api/basic_page/{lang}/condiciones-generales-contratacion', server: environment.drupal },
  [API_KEY_DRUPAL.GET_GENERAL_REWARDS_CONDITIONS]: { path: '/api/basic_page/{lang}/condiciones-generales-contratacion-rewards', server: environment.drupal },
  [API_KEY_DRUPAL.GET_LEGAL_WARNING_BERLIN]: { path: '/api/basic_page/{lang}/aviso-legal-berlin', server: environment.drupal },
  [API_KEY_DRUPAL.GET_ETHICAL_CHANNEL]: { path: '/api/basic_page/{lang}/canal-etico', server: environment.drupal },
  [API_KEY_DRUPAL.GET_EXPANSION]: { path: '/api/basic_page/{lang}/expansion', server: environment.drupal },
  [API_KEY_DRUPAL.GET_CORPORATE_SOCIAL_RESPONSABILITY]: { path: '/api/basic_page/{lang}/responsabilidad-social', server: environment.drupal },
  [API_KEY_DRUPAL.GET_WORK_WITH_US]: { path: '/api/basic_page/{lang}/trabaja-con-nosotros', server: environment.drupal },
  [API_KEY_DRUPAL.GET_PRIVACY_POLICY_FOR_CANDIDATES]: { path: '/api/basic_page/{lang}/politica-de-privacidad-candidatos', server: environment.drupal },
  [API_KEY_DRUPAL.GET_RESPONSIBLE_PROCESSING]: { path: '/api/basic_page/{lang}/responsable-del-tratamiento', server: environment.drupal },
  [API_KEY_DRUPAL.GET_REWARDS2]: { path: '/api/basic_page/{lang}/condiciones-generales-contratacion-rewards-2', server: environment.drupal }
};
export const APIS_URL = {
  [API_KEY_DRUPAL.GET_LANGUAGE]: {
    path: '/api/idiomas?_format=json',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_USER]: {
    path: '/api/user/{lang}?_format=json',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_PAGE]: {
    path: '/api/pages/{lang}/{id}?_format=json',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_SECTIONS]: {
    path: '/api/secciones_web/{lang}?_format=json',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_ALL_ROOMS]: {
    path: '/api/habitaciones/{lang}/{alias}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_PREFOOTER]: {
    path: '/api/prefooter/{lang}/{id}?_format=json',
    server: environment.drupal
  },
  [API_KEY_SYMFONY.POST_HASH_ROOMS]: {
    path: '/api/checkout-hash',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_CHECKOUT_HASH]: {
    path: '/api/checkout-hash?checkoutHash={hash}&language={lang}&idMember={idMember}&promoCode={promoCode}&dt={dt}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_CHECKOUT_PBL_HASH]: {
    path: '/api/pay-by-link?hashPBL={hash}&language={lang}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_MEAL_PLANS]: {
    path: '/api/get-meal-plans?checkoutHash={hash}&language={lang}&dt={dt}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_PAYMENT_METHODS]: {
    path: '/api/payments/payment-methods?checkoutHash={hash}&origin={origin}&language={lang}',
    server: environment.token
  },
  [API_KEY_SYMFONY.GET_TRANSACTION_PAYMENT]: {
    path: '/api/payments/pci_token/{transactionId}',
    server: environment.token
  },
  [API_KEY_SYMFONY.GET_EXISTING_EMAIL]: {
    path: '/api/members/check?email={email}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_BOOKING_CONFIRMATION]: {
    path: '/api/confirmation-hash?checkoutHash={hash}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_SEND_BOOKING_CONFIRMATION]: {
    path: '/api/confirmation-hash?checkoutHash={hash}&em={numberRetries}&ce={email}',
    server: environment.symfony
  },
  [API_KEY_DRUPAL.GET_HOTEL]: {
    path: '/api/hotel/{lang}/{backCode}?_format=json&virtual={virtual}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_HOTEL_BY_ALIAS]: {
    path: '/api/hotel/{lang}/{alias}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_FLIGHT_AND_HOTEL]: {
    path: '/api/vuelo_hotel/{lang}/{backCode}?_format=json',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_CONTACT_INFO]: {
    path: '/api/contact_info/{lang}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_CONTACT_OPTIONS]: {
    path: '/api/contact_options/{lang}',
    server: environment.drupal
  },
  [API_KEY_DRUPAL.GET_HOTEL_DATA]: {
    path: '/api/hoteldata/{language}/{backcode}?_format=json&code={requirements}',
    server: environment.drupal
  },

  [API_KEY_DRUPAL.GET_FAQS]: {
    path: '/api/faqs/{lang}/{code}',
    server: environment.drupal
  },

  [API_KEY_SYMFONY.GET_ROOMS]: {
    path: '/api/get_rooms?hotel_avail_dto_r[HotelCode]={code}&dt={dt}&hotel_avail_dto_r[Start]={startDate}&hotel_avail_dto_r[End]={endDate}&hotel_avail_dto_r[membershipLevel]={membershipLevel}&hotel_avail_dto_r[ContractSwitcher][registeredUser]={registeredUser}&hotel_avail_dto_r[ContractSwitcher][promotionalCode]={promoCode}&hotel_avail_dto_r[ContractSwitcher][membershipLevel]={membershipLevelSwitcher}&hotel_avail_dto_r[ContractSwitcher][hotel]={hotel}&{roomStayCandidates}&hotel_avail_dto_r[language]={lang}&hotel_avail_dto_r[Virtual]={virtual}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_CALENDAR_PRICES]: {
    path: '/api/getCalendarPrices?hotelBackCode={code}&startDate={startDate}&finishDate={endDate}&_format=json',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_TOKEN]: {
    path: '/api/login_check',
    server: environment.token
  },
  [API_KEY_SYMFONY.POST_CHECK_TOKEN]: {
    path: '/api/members/checkToken',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_USER_CARDS]: {
    path: '/api/payments/retrieve_card',
    server: environment.token
  },
  [API_KEY_SYMFONY.GET_COUPON]: {
    path: '/api/validateCode?code={couponCode}',
    server: environment.token
  },
  [API_KEY_SYMFONY.POST_USER_CARDS]: {
    path: '/api/payments/save_card',
    server: environment.token
  },
  [API_KEY_SYMFONY.POST_CREATE_BOOKING]: {
    path: '/api/create_booking',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_PROCESS_PBL]: {
    path: '/api/process_pbl',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_CREATE_USER_ON_CHECKOUT]: {
    path: '/api/members/init-registration',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.PUT_REACTIVATE_USER_ON_CHECKOUT]: {
    path: '/api/members/reactivate',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_CREATE_USER_ON_REGISTER]: {
    path: '/api/members/register-tms-form',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.PUT_CONFIRM_EMAIL]: {
    path: '/api/members/confirm_email',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.PUT_RECOVER_PASSWORD]: {
    path: '/api/members/recover_pass',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_RECOVER_EMAIL]: {
    path: '/api/members/recover_email',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_INIT_SESSION_EMAIL]: {
    path: '/api/members/otp_email',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_LOGIN_SESSION_OTP]: {
    path: '/api/members/otp_login',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_VERIFY_TOKEN_VALID]: {
    path: '/api/members/verify_ott?ott={ott}&email={email}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.CHECK_PAYMENT_SUCCESSFUL]: {
    path: '/api/process-callback-pasarela',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_CONVERT_CURRENCY]: {
    path: '/api/currency_conversion?currency_conversion_dto_r[currencyBase]={currencyBase}&currency_conversion_dto_r[toCurrency]={toCurrency}&currency_conversion_dto_r[amount]={amount}&currency_conversion_dto_r[round]={round}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_BOOKING_MANAGEMENT]: {
    path: '/api/manage_booking?idTms={idTms}&hotelCode={hotelCode}&email={email}&externalId={externalId}&language={lang}&idToken={idToken}',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.PUT_UPDATE_BOOKING]: {
    path: '/api/update_booking',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.DELETE_CANCEL_BOOKING]: {
    path: '/api/cancel_booking',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_CONTACT_FORM]: {
    path: '/api/contact-form',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.POST_RECAPTCHA]: {
    path: '/api/contact-form-recaptcha',
    server: environment.symfony
  },
  [API_KEY_SYMFONY.GET_USER_DATA_BY_UID]: {
    path: '/api/emarsys/get-data?uid={uid}',
    server: environment.token
  },
  [API_KEY_SYMFONY.POST_USER_PREFERENCES]: {
    path: '/api/emarsys/newsletters/create',
    server: environment.token
  },
  ...PRIVATE_AREA_URL,
  ...LANDINGS_URL,
  ...COMMON,
  ...LEGAL_PAGES
};
