<app-cdk-dialog-wrapper-new (ev_close)="cancelAction(true)">
  <ng-container *ngFor="let message of getDialogData().messages">
    <p [innerHTML]="message"></p>
  </ng-container>
  <div cds-modal-actions>
    <ng-container *ngFor="let action of actions">
      <app-button
        size="cds-btn--m"
        [label]="action.text"
        [type]="action.action === Action.Accept ? 'cds-btn--highlight' : 'cds-btn--regular'"
        (buttonClick)="activeAction(action.action)"
      ></app-button>
    </ng-container>
  </div>
</app-cdk-dialog-wrapper-new>
