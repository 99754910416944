import { Action, ActionCreator, ActionReducer, ActionType, ReducerTypes, createReducer } from '@ngrx/store';

import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { LocalStorageService } from '../logic/services/storage/local-storage.service';

type Config<S> = {
  hooks?: { onRehydrate?: (state: S) => S };
  ttl?: number;
};

type Storage<S> = { state: S; expires: number };

// Check if cached item is expired
const isExpired = (expireMs: number) => new Date().getTime() >= expireMs;

/*
Get initial state
1. Check into localStorage for '@xxx' item.
2. Check item is expired
  . It is expired => returns initial state and update localStorage
  . It is not => rehydrate localStorage item
* */
const getInitialState = <S>(
  item: Storage<S> | null,
  initialState: S,
  storage: LocalStorageService,
  key: string,
  config: Config<S>
) => {
  let newInitialState: S = initialState;
  if (item) {
    if (item.expires && isExpired(item.expires)) {
      newInitialState = initialState;
      storage.set(key, { state: initialState });
    } else {
      newInitialState = config.hooks?.onRehydrate ? config.hooks.onRehydrate(item.state) : item.state;
    }
  }
  return newInitialState;
};

export const createRehydrateReducer =
  <S>(storage: LocalStorageService, config: Config<S> = {}) =>
  <A extends Action = Action>(
    key: string,
    initialState: S,
    ...ons: ReducerTypes<S, ActionCreator[]>[]
  ): ActionReducer<S, A> => {
    const item = storage.get<Storage<S>>(key);
    const newInitialState = getInitialState(item, initialState, storage, key, config);
    const newOns: ReducerTypes<S, ActionCreator[]>[] = [];
    ons.forEach((oldOn: ReducerTypes<S, ActionCreator[]>) => {
      const newReducer: OnReducer<S, ActionCreator[]> = (state: S, action: ActionType<ActionCreator[][number]>) => {
        const newState = oldOn.reducer(state, action);
        storage.set(key, {
          state: newState,
          ...(config.ttl && { expires: new Date().getTime() + config.ttl })
        });
        return newState;
      };
      newOns.push({ ...oldOn, reducer: newReducer });
    });
    return createReducer(newInitialState, ...newOns);
  };
