import { Injectable } from '@angular/core';

import { PaymentApiService } from '@app/shared/logic/http/api/payment.api.service';

import { CardTokenizedResponseRaw, DataTransSuccessEvent, SecureFieldsInstance } from '@app/shared/ns_components/datatrans-widget/secure-fields.model';
import { SecureFieldsService, SFInstances } from '@app/shared/ns_components/datatrans-widget/secure-fields.service';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';

import { catchError, EMPTY, map, Observable, of, switchMap, take, throwError } from 'rxjs';

import { PaymentDenormalizer } from './payment.denormalizer';
import { PaymentNormalizer } from './payment.normalizer';
import { PaymentStoreAdapter } from './payment.store.adapter';
import { ModalService } from '@app/shared/logic/services/modals/modal.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private readonly _paymentApiService: PaymentApiService,
    private readonly _paymentStoreAdapter: PaymentStoreAdapter,
    private readonly _secureFieldsService: SecureFieldsService,
    private modalService: ModalService
  ) {}

  public loadUserCardsById$(idMember: string, userToken: string): Observable<void> {
    return this._paymentApiService.getCardsByUserId$(idMember, userToken).pipe(
      map((response) => {
        if (response && GeneralFunctions.isArrayLike(response.data.cards)) {
          this._paymentStoreAdapter.setUserCards(response.data.cards.map((i) => PaymentNormalizer.normalizeUserCardPayment(i)));
        }
        this._paymentStoreAdapter.setMerchantId(response.data.merchantId);
      }),
      catchError((error) => {
        console.error('[UserCards] error', error);
        return EMPTY;
      })
    );
  }

  public addNewUserCard$(idMember: string, holderName: string, expireDate: string, userToken: string) {
    return this.tokenizeCard$(expireDate).pipe(
      switchMap((cardTokenized) => this.updateUserCards$(idMember, holderName, expireDate, cardTokenized, userToken)),
      switchMap(() => this.loadUserCardsById$(idMember, userToken)),
      catchError(() => {
        console.error('An error has ocurred creating the user card');
        return of(null);
      })
    );
  }

  public updateUserCards$(idMember: string, holderName: string, expireDate: string, cardTokenized: CardTokenizedResponseRaw, userToken: string) {
    return this._paymentApiService.updateUserCards$(
      idMember,
      PaymentDenormalizer.denormalizeAddCardsRequest(idMember, holderName, expireDate, this._paymentStoreAdapter.selectUserCardsSig(), cardTokenized),
      userToken
    );
  }

  public removeUserCard$(idMember: string, cardToken: string, userToken: string) {
    return this._paymentApiService
      .updateUserCards$(idMember, PaymentDenormalizer.denormalizeRemoveCardRequest(this._paymentStoreAdapter.selectUserCardsSig(), cardToken), userToken)
      .pipe(
        switchMap(() => this.loadUserCardsById$(idMember, userToken)),
        catchError(() => {
          console.error('An error has ocurred deleting the user card');
          return of(null);
        })
      );
  }

  public tokenizeCard$(expireDate: string) {
    const secureFieldsInstance: SecureFieldsInstance | undefined = this._secureFieldsService.getInstanceByName(SFInstances.CreditCardForm);
    if (!secureFieldsInstance) {
      return throwError(() => ({ message: 'No SF instance' }));
    }

    const [expm, expy] = expireDate.split('/');
    secureFieldsInstance.secureFieldsInstance.submit({ expm, expy });

    secureFieldsInstance.secureFieldsInstance.on('error', (data: { error: string; action: string }) => {
      this.modalService.displayMessageModal$({
        displayTitle: 'Error',
        messages: [data.error],
        icon: 'KO',
        promo: false
      });
      secureFieldsInstance.successSubject?.error(new Error(data.error));
    });

    return secureFieldsInstance.successSubject!.asObservable().pipe(
      take(1),
      switchMap((dataTransSuccessEvent: DataTransSuccessEvent) => this.getTransactionId$(dataTransSuccessEvent)),
      catchError((e) => {
        return throwError(() => ({ message: e.message }));
      })
    );
  }

  public getTransactionId$(dataTransSuccessEvent: DataTransSuccessEvent): Observable<CardTokenizedResponseRaw> {
    return this._paymentApiService.getCardTokenized$(dataTransSuccessEvent.transactionId).pipe(
      map((response: CardTokenizedResponseRaw) => {
        if (!response.success) {
          throw { message: 'Card Tokenized retrieve info not success' };
        }
        return response;
      })
    );
  }
}
