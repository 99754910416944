import { HeaderActions, HeaderActionsTypes } from './header.actions';

export interface State {
  headerActive: boolean | null;
}

const initialState: State = {
  headerActive: null
};

export function reducer(state = initialState, action: HeaderActions): State {
  switch (action.type) {
    case HeaderActionsTypes.SetActiveHeader:
      return { ...state, headerActive: action.payload.headerActive };
    default:
      return state;
  }
}
