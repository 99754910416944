import { InjectionToken, inject } from '@angular/core';

import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from './auth/auth.reducer';
import * as fromCheckout from './checkout/checkout.reducer';
import * as fromCommon from './common/common.reducer';
import * as fromGa from './ga/ga.reducer';
import * as fromHeader from './header/header.reducer';
import * as fromHotel from './hotel/hotel.reducer';
import * as fromPayment from './payment/payment.reducer';
import * as fromPreFooter from './pre-footer/pre-footer.reducer';
import * as fromUi from './ui/ui.reducer';

import { LocalStorageService } from '../logic/services/storage/local-storage.service';

export interface State {
  common: fromCommon.State;
  auth: fromAuth.State;
  ga: fromGa.State;
  header: fromHeader.State;
  hotel: fromHotel.State;
  checkout: fromCheckout.State;
  ui: fromUi.State;
  payment: fromPayment.State;
  preFooter: fromPreFooter.State;
}

export const reducers = new InjectionToken<ActionReducerMap<State>>('NgRxReducers', {
  factory: () => {
    const storage = inject(LocalStorageService);
    return {
      common: fromCommon.reducer,
      auth: fromAuth.reducer(storage),
      ga: fromGa.reducer,
      header: fromHeader.reducer,
      hotel: fromHotel.reducer,
      checkout: fromCheckout.reducer,
      ui: fromUi.reducer,
      payment: fromPayment.reducer(storage),
      preFooter: fromPreFooter.reducer(storage)
    } as ActionReducerMap<State>;
  }
});
