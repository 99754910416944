import { NgIf } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { DynamicImageDirective } from '@directives/image/dynamic-image.directive';
import { ImageRaw } from '@shared/logic/models/common.model';

export type HotelAward = {
  tooltipTitle?: string;
  tooltipDescription?: string;
  image: ImageRaw;
};

@Component({
  standalone: true,
  selector: 'app-hotel-award',
  templateUrl: './hotel-award.component.html',
  styleUrls: ['./hotel-award.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DynamicImageDirective],
  host: {
    class: 'cds-hotel-award'
  }
})
export class HotelAwardComponent {
  @Input() data: HotelAward;
}
