import { Injectable } from '@angular/core';

import { API_KEY_SYMFONY } from '@services/http-utils/apis-url';
import { HttpUtils } from '@services/http-utils/http-utils';
import { ResponseRaw } from '@shared/logic/models/common.model';
import {
  ConfirmEmailResponseRaw,
  GetUserByOtpResponseRaw,
  GetUserByTokenResponseRaw,
  InitSessionEmailRaw,
  LoginWithOtpRaw,
  RecoverPasswordRaw,
  RegisterUserRaw,
  ResetPasswordRaw,
  UpdateUserPreferencesRaw,
  UserDataByUidRaw,
  UserDataByUiResponseRaw,
  VerifyTokenValidRaw
} from '@shared/store/auth/auth.model.raw';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private httpUtils: HttpUtils) {}

  public getUserByToken$(token: string): Observable<GetUserByTokenResponseRaw> {
    return this.httpUtils.post<GetUserByTokenResponseRaw>(API_KEY_SYMFONY.POST_CHECK_TOKEN, {}, { idToken: token });
  }

  public registerUser$(payload: RegisterUserRaw): Observable<void> {
    return this.httpUtils.post<void>(API_KEY_SYMFONY.POST_CREATE_USER_ON_REGISTER, {}, payload);
  }

  public confirmEmail$(token: string): Observable<ConfirmEmailResponseRaw> {
    return this.httpUtils.put<ConfirmEmailResponseRaw>(API_KEY_SYMFONY.PUT_CONFIRM_EMAIL, {}, { token });
  }

  public resetPassword$(payload: ResetPasswordRaw): Observable<ResponseRaw<{ msg: string; idToken: string | null }>> {
    return this.httpUtils.put<ResponseRaw<{ msg: string; idToken: string | null }>>(API_KEY_SYMFONY.PUT_RECOVER_PASSWORD, {}, payload);
  }

  public recoverEmailPassword$(payload: RecoverPasswordRaw): Observable<void> {
    return this.httpUtils.post<void>(API_KEY_SYMFONY.POST_RECOVER_EMAIL, {}, payload);
  }

  public initSessionEmail$(payload: InitSessionEmailRaw): Observable<void> {
    return this.httpUtils.post<void>(API_KEY_SYMFONY.POST_INIT_SESSION_EMAIL, {}, payload);
  }

  public loginSessionWithOtp(payload: LoginWithOtpRaw): Observable<GetUserByOtpResponseRaw> {
    return this.httpUtils.post<GetUserByOtpResponseRaw>(API_KEY_SYMFONY.POST_LOGIN_SESSION_OTP, {}, payload);
  }

  public verifyTokenValid(payload: VerifyTokenValidRaw): Observable<void> {
    return this.httpUtils.get<void>(API_KEY_SYMFONY.GET_VERIFY_TOKEN_VALID, { ott: payload.ott, email: payload.email }, payload);
  }

  public getUserDataByUid(payload: UserDataByUidRaw): Observable<UserDataByUiResponseRaw> {
    return this.httpUtils.get<UserDataByUiResponseRaw>(API_KEY_SYMFONY.GET_USER_DATA_BY_UID, { uid: payload.uid }, {});
  }

  public updateUserPrerences(payload: UpdateUserPreferencesRaw): Observable<void> {
    return this.httpUtils.post<void>(API_KEY_SYMFONY.POST_USER_PREFERENCES, {}, payload);
  }
}
