import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, EventEmitter, Input, Output, Signal } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Login, Register } from '@app/shared/store/auth/auth.model';
import { ButtonComponent } from '@atom/button/button.component';
import { FeatureItemModule } from '@molecules/feature-item/feature-item.component.module';
import { FirebaseAuthError } from '@shared/logic/functions/firebase.functions';
import { ModalService } from '@shared/logic/services/modals/modal.service';
import { LoginFormRegisterComponent } from '@shared/ns_components/gdi_extracted/login-form-register/login-form-register.component';
import { LoginFormSingInComponent } from '@shared/ns_components/gdi_extracted/login-form-singin/login-form-singin.component';
import { ActionType } from '@shared/ns_components/modals/simple-modal/simple-modal.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { catchError, of, switchMap } from 'rxjs';
import { LoginTrackService } from '@ga-tracking/services/tracking-sites/login/login-track.service';
import { RegisterTrackService } from '@ga-tracking/services/tracking-sites/register/register-track.service';
import { UiStoreAdapter } from '@shared/store/ui/ui.store.adapter';
import { Literals } from '@app/shared/logic/models/common.model';
import { LiteralSections } from '@app/shared/logic/const/common';
import { CommonStoreAdapter } from '@app/shared/store/common/common.store.adapter';
import { EmarsysTrackingService } from '@app/shared/logic/services/emarsys/services/tracking/emarsys-tracking.service';

@Component({
  standalone: true,
  selector: 'app-login-form-basic',
  templateUrl: './login-form-basic.component.html',
  styleUrls: ['./login-form-basic.component.scss'],
  imports: [NgIf, ButtonComponent, FeatureItemModule, LoginFormSingInComponent, LoginFormRegisterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormBasicComponent {
  @Input() ctaLoginExpanded = true;
  @Output() ev_closeLoginForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() ev_createAccountClicked: EventEmitter<void> = new EventEmitter<void>();
  public readonly literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();
  public readonly isHeaderLoginVisible = this._uiStoreAdapter.selectHeaderLoginDisplayStatusSig;

  initSession = true;
  public readonly createAccountSig = this._uiStoreAdapter.selectHeaderCreateAccountDisplayStatusSig;

  constructor(
    private authService: AuthService,
    private _modalService: ModalService,
    private _destroyRef: DestroyRef,
    private _loginTrackService: LoginTrackService,
    private readonly _registerTrackService: RegisterTrackService,
    private readonly _uiStoreAdapter: UiStoreAdapter,
    private _commonStoreAdapter: CommonStoreAdapter,
    private _emarsysTrackingService: EmarsysTrackingService
  ) {
    effect(
      () => {
        const visible = this.isHeaderLoginVisible();
        if (!visible) {
          this.initSession = true;
          this._uiStoreAdapter.setHeaderCreateAccountDisplayStatus(false);
          this._uiStoreAdapter.setHeaderResetPasswordDisplayStatus(false);
        }
      },
      { allowSignalWrites: true }
    );
    effect(() => {
      const viewCreateAccount = this.createAccountSig();
      if (viewCreateAccount) {
        this.initSession = false;
      }
    });
  }

  public login(login: Login): void {
    this._loginTrackService.trackClickAnyActionLoginPage('email', '');

    this.authService
      .signInWithEmailAndPassword$(login)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        catchError((error) => {
          if (error?.code && [FirebaseAuthError.USER_NOT_FOUND, FirebaseAuthError.WRONG_PASSWORD].includes(error.code)) {
            this._displayModalPasswordIncorrect();
          }
          return of(null);
        })
      )
      .subscribe((success) => {
        if (success) {
          const href = location?.href ?? '/';
          this._loginTrackService.trackLoginSuccess('email', href);
          this._emarsysTrackingService.sendBasicPageEvent();
          this.ev_closeLoginForm.emit();
          return;
        }
      });
  }

  public register(register: Register): void {
    this.authService
      .signUpManually$(register)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          this._registerTrackService.trackViewModalSuccess();
          return this._modalService.displayMessageModal$({
            icon: 'Confirmation',
            displayTitle: this.literals.label_almost,
            messages: [this.literals.label_activate_email]
          });
        })
      )
      .subscribe(() => {
        this._registerTrackService.trackRegisterSuccess('email', '/');
        this._registerTrackService.trackClickBtnModalSuccess();
        this.ev_closeLoginForm.emit();
      });
  }

  public loginWithProvider(providerId: 'google' | 'facebook', isRegister: boolean): void {
    this.initSession
      ? this._loginTrackService.trackClickAnyActionLoginPage(
          providerId,
          providerId === 'google' ? 'https://accounts.google.com/o/oauth2/auth' : 'https://www.facebook.com/login.php'
        )
      : this._registerTrackService.trackClickAnyActionRegisterPage(
          providerId,
          providerId === 'google' ? 'https://accounts.google.com/o/oauth2/auth' : 'https://www.facebook.com/login.php'
        );
    this.authService
      .signInWithProvider$(providerId)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        const href = location?.href ?? '/';
        isRegister
          ? this._registerTrackService.trackRegisterSuccess(
              providerId,
              providerId === 'google' ? 'https://accounts.google.com/o/oauth2/auth' : 'https://www.facebook.com/login.php'
            )
          : this._loginTrackService.trackLoginSuccess(providerId, href);
        this._emarsysTrackingService.sendBasicPageEvent();
        this.ev_closeLoginForm.emit();
      });
  }

  public onClickCreateAccount(): void {
    this.initSession
      ? this._loginTrackService.trackClickAnyActionLoginPage('crear cuenta', '')
      : this._registerTrackService.trackClickAnyActionRegisterPage('iniciar sesion', '');

    this.initSession = !this.initSession;
    this._uiStoreAdapter.setHeaderCreateAccountDisplayStatus(!this.createAccountSig());
    this.ev_createAccountClicked.emit();
  }

  public tooltipActive(label: string): void {
    this._loginTrackService.trackViewTooltipInfoRewards(label);
  }

  private _displayModalPasswordIncorrect(): void {
    this._loginTrackService.trackViewModalErrorLogin();
    this._modalService
      .displayMessageModal$({
        icon: 'KO',
        displayTitle: this.literals.label_username_error,
        messages: [this.literals.label_recover_instruction],
        actions: [
          { text: this.literals.label_recover_pass, action: ActionType.Cancel },
          { text: this.literals.label_login_no_password, action: ActionType.Accept }
        ]
      })
      .subscribe((response) => {
        if (response.customData?.wrapperAction) {
          return;
        }
        if (response.success) {
          this._uiStoreAdapter.setHeaderLoginWithEmailDisplayStatus(true);
          this._loginTrackService.trackClickBtnModalUserOrPassIncorrect('iniciar sesion sin contrasena');
          return;
        }
        this._loginTrackService.trackClickBtnModalUserOrPassIncorrect('recuperar contrasena');
        this._uiStoreAdapter.setHeaderResetPasswordDisplayStatus(true);
      });
  }
}
