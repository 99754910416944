import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './ui.reducer';

const getUIState = createFeatureSelector<State>('ui');

export const UISelectors = {
  getScrollConfig: createSelector(getUIState, (state: State) => state.scrollConfig),
  getHeaderLoginDisplayStatus: createSelector(getUIState, (state: State) => state.headerLoginDisplayStatus),
  getRewardsActiveStatus: createSelector(getUIState, (state: State) => state.isRewardsActive),
  getHeaderLoginWithEmailDisplayStatus: createSelector(getUIState, (state: State) => state.headerLoginWithEmailDisplayStatus),
  getHeaderResetPasswordDisplayStatus: createSelector(getUIState, (state: State) => state.headerResetPasswordDisplayStatus),
  getHeaderCreateAccountDisplayStatus: createSelector(getUIState, (state: State) => state.headerCreateAccountDisplayStatus)
};
