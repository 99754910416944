<app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
  <div class="cds-collective-modal__content">
    <p class="head-title" [innerHTML]="data.headTitle | safeHtml"></p>
    <div class="list-block">
      <ng-container *ngFor="let item of data.items">
        <div class="list-item">
          <p class="title">{{ item.displayTitle }}</p>
          <div [innerHTML]="item.displayText | safeHtml"></div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="data.isCtaVisible">
    <div cds-modal-actions>
      <app-button label="Ver más información" type="cds-btn--highlight" size="cds-btn--m" [fullWidth]="true" (buttonClick)="onAccept()"></app-button>
    </div>
  </ng-container>
</app-cdk-dialog-wrapper-new>
