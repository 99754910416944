import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer } from '@shared/logic/helpers/common/common.normalizer';
import { LandingDestinySeo, LandingHotelBlockTab } from '@shared/store/landing/model/destiny-seo.model';
import { LandingDestinySeoRaw, LandingHotelBlockTabRaw } from '@shared/store/landing/model-raw/destiny-seo.model.raw';
import { LandingsCommonNormalizer } from '@shared/store/landing/normalizers/landings.common.normalizer';

export class LandingDestinySeoNormalizer {
  public static normalize(itemRaw: LandingDestinySeoRaw): LandingDestinySeo {
    const item = {
      ...itemRaw
    } as any;
    ///////////////////////////////////////// ///////////////////////////////////////////////////////////
    item.welcomeBlock = {
      title: itemRaw.welcomeBlock?.title,
      subtitle: itemRaw.welcomeBlock?.description
    };
    item.descriptions = {
      title: itemRaw.descriptions?.title,
      subtitle: itemRaw.descriptions?.short,
      subtitleLongDescription: itemRaw.descriptions?.long
    };

    item.roomsBlock = {
      title: itemRaw.hotelBlock?.title,
      tabs: GeneralFunctions.isArrayLike(itemRaw.hotelBlock?.hotels) ? itemRaw.hotelBlock.hotels.map((i) => LandingDestinySeoNormalizer.normalizeTab(i)) : []
    };

    if (itemRaw.instalaciones && GeneralFunctions.isArrayLike(itemRaw.instalaciones, 1)) {
      item.hotelInstallationCategories = itemRaw.instalaciones.map(LandingsCommonNormalizer.normalizeInstallationCategories);
    }

    if (itemRaw.breadcrums?.link) {
      item.parentLanding = {
        title: itemRaw.breadcrums.title,
        url: itemRaw.breadcrums.link
      };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static normalizeTab(itemRaw: LandingHotelBlockTabRaw): LandingHotelBlockTab {
    const item = {} as LandingHotelBlockTab;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.displayTabTitle = itemRaw.tabTitle;
    item.displayTitle = itemRaw.mainTitle;
    item.destination = itemRaw.destino;
    item.displayHighlightedText = itemRaw.highlightHotel;
    item.tabLink = GeneralFunctions.isArrayLike(itemRaw.link) ? itemRaw.link!.map((i) => CommonNormalizer.normalizeLandingLink(i)) : null;
    item.hotelList = GeneralFunctions.isArrayLike(itemRaw.hotels) ? itemRaw.hotels.map((i) => LandingsCommonNormalizer.normalizeHotelTab(i)) : [];
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
