import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgForOf, NgIf } from '@angular/common';
import { Component, OnInit, Inject, Input } from '@angular/core';

import { ButtonComponent } from '@atom/button/button.component';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { CdkDialogWrapperNewModalInput, Literals, ModalResponse } from '@shared/logic/models/common.model';
import { SafeHtmlPipe } from '@shared/logic/pipes/safeHtml.pipe';
import { ModalActionsNewDirective } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.directive';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';

export interface CollectiveModalInput extends CdkDialogWrapperNewModalInput {
  isCtaVisible: boolean;
  headTitle: string;
  items: {
    displayTitle: string;
    displayText: string;
    single: string;
    double: string;
  }[];
  literals: Literals;
}

@Component({
  standalone: true,
  selector: 'app-collective-banner-modal',
  templateUrl: './collective-banner-modal.component.html',
  styleUrls: ['./collective-banner-modal.component.scss'],
  imports: [ButtonComponent, ModalActionsNewDirective, CdkDialogWrapperNewModule, NgForOf, SafeHtmlPipe, NgIf]
})
export class CollectiveBannerModalComponent extends ModalElement<CollectiveBannerModalComponent, CollectiveModalInput> implements OnInit {
  public readonly data = this.getDialogData();

  constructor(@Inject(DIALOG_DATA) dialogData: CollectiveModalInput, dialogRef: DialogRef<ModalResponse, CollectiveBannerModalComponent>) {
    super(dialogData, dialogRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public onAccept(): void {
    this.closeDialog({ success: true });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }
}
