import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';

import { API_KEY_DRUPAL, API_KEY_SYMFONY } from '@services/http-utils/apis-url';
import { HttpUtils } from '@services/http-utils/http-utils';
import { LiteralSections } from '@shared/logic/const/common';
import { Literals, ResponseRaw } from '@shared/logic/models/common.model';
import { TransferStateService } from '@shared/logic/services/transfer-state.service';
import {
  CountriesResponseRaw,
  GenreRaw,
  LanguageRaw,
  StaticBannerResponseRaw,
  DeactivateReasonRaw,
  HeaderRaw,
  FooterRaw
} from '@shared/store/common/common.model';
import { CollectiveBannerRaw } from '@shared/store/common/common.model.raw';
import { Observable } from 'rxjs';

const TRANSFER_STATE_KEYS = {
  LANGUAGES: makeStateKey<LanguageRaw[]>('CommonApiService.languages'),
  COUNTRIES: makeStateKey<CountriesResponseRaw>('CommonApiService.countries'),
  GENDERS: makeStateKey<GenreRaw[]>('CommonApiService.genders'),
  APP_HEADER: makeStateKey<any>('CommonApiService.appHeader'),
  APP_FOOTER: makeStateKey<any>('CommonApiService.appFooter'),
  LITERALS: (section: string) => makeStateKey<Literals>('HotelApiService.bySection.' + section)
};

@Injectable({
  providedIn: 'root'
})
export class CommonApiService extends TransferStateService {
  constructor(@Inject(PLATFORM_ID) platformId: string, transferState: TransferState, private _httpUtils: HttpUtils, private _http: HttpClient) {
    super(platformId, transferState);
  }

  public getLanguages$(): Observable<LanguageRaw[]> {
    return this.useTransferState(TRANSFER_STATE_KEYS.LANGUAGES, [] as LanguageRaw[], () => {
      return this._httpUtils.get<LanguageRaw[]>(API_KEY_DRUPAL.GET_LANGUAGE);
    });
  }

  public getCountries$(): Observable<CountriesResponseRaw> {
    return this.useTransferState(TRANSFER_STATE_KEYS.COUNTRIES, {} as CountriesResponseRaw, () => {
      return this._httpUtils.get<CountriesResponseRaw>(API_KEY_SYMFONY.GET_COUNTRIES);
    });
  }

  public getGenres$(): Observable<GenreRaw[]> {
    return this.useTransferState(TRANSFER_STATE_KEYS.GENDERS, [] as GenreRaw[], () => {
      return this._httpUtils.get<GenreRaw[]>(API_KEY_DRUPAL.GET_GENRES);
    });
  }

  // public getLiterals$(): Observable<Literals> {
  //   return this._httpUtils.get<Literals>(API_KEY_DRUPAL.GET_LITERALS);
  // }

  public getLiteralsBySection$(section: LiteralSections): Observable<Literals> {
    return this.useTransferState(TRANSFER_STATE_KEYS.LITERALS(section), {} as Literals, () => {
      return this._httpUtils.get<Literals>(API_KEY_DRUPAL.GET_LITERALS_BY_SECTION, { categoria_literales: section });
    });
  }

  public getStaticBannerByMembership$(membershipLevel: number, hotelType: string | null): Observable<StaticBannerResponseRaw> {
    return this._httpUtils.get<StaticBannerResponseRaw>(API_KEY_DRUPAL.GET_STATIC_BANNER_BY_MEMBERSHIP, {
      membershipLevel,
      hotelType
    });
  }

  public getCollectivesBanner$(code: string): Observable<ResponseRaw<CollectiveBannerRaw>> {
    return this._httpUtils.get<ResponseRaw<CollectiveBannerRaw>>(API_KEY_DRUPAL.GET_LANDING_COLLECTIVES_BANNER, { code });
  }

  public getAppHeader$(): Observable<ResponseRaw<HeaderRaw[]>> {
    return this.useTransferState(TRANSFER_STATE_KEYS.APP_HEADER, {} as ResponseRaw<HeaderRaw[]>, () => {
      return this._httpUtils.get<ResponseRaw<HeaderRaw[]>>(API_KEY_DRUPAL.GET_APP_HEADER);
    });
  }

  public getAppFooter$(): Observable<ResponseRaw<FooterRaw[]>> {
    return this.useTransferState(TRANSFER_STATE_KEYS.APP_FOOTER, {} as ResponseRaw<FooterRaw[]>, () => {
      return this._httpUtils.get<ResponseRaw<ResponseRaw<FooterRaw[]>>>(API_KEY_DRUPAL.GET_APP_FOOTER);
    });
  }

  public getDeactivationReasons$(): Observable<ResponseRaw<{ selectors: DeactivateReasonRaw[] }>> {
    return this._httpUtils.get<ResponseRaw<{ selectors: DeactivateReasonRaw[] }>>(API_KEY_DRUPAL.GET_DEACTIVATION_REASONS);
  }
}
