import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, Inject, OnDestroy, OnInit } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ModalElement } from '@app/shared/logic/abstract/modal.abstract';

import { AuthStoreAdapter } from '@app/shared/store/auth/auth.store.adapter';
import { PaymentService } from '@app/shared/store/payment/payment.service';

import { ButtonComponent } from '@atom/button/button.component';
import { CheckoutTrackService } from '@ga-tracking/services/tracking-sites/checkout/checkout-track.service';

import { DatatransWidgetModule } from '@shared/ns_components/datatrans-widget/datatrans-widget.module';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';

import { AddCreditCardModalData } from './add-credit-card-modal.component.typing';

@Component({
  standalone: true,
  selector: 'app-add-credit-card-modal',
  templateUrl: './add-credit-card-modal.component.html',
  imports: [CdkDialogWrapperNewModule, DatatransWidgetModule, ButtonComponent],
  styleUrls: ['./add-credit-card-modal.component.scss']
})
export class AddCreditCardModalComponent extends ModalElement<AddCreditCardModalComponent, AddCreditCardModalData, any> implements OnInit, OnDestroy {
  protected data: AddCreditCardModalData;

  constructor(
    @Inject(DIALOG_DATA) dialogData: AddCreditCardModalData,
    dialogRef: DialogRef<any, AddCreditCardModalComponent>,
    private readonly _paymentService: PaymentService,
    private readonly _authStoreAdapter: AuthStoreAdapter,
    private readonly _destroyRef: DestroyRef,
    private readonly _checkoutTrackService: CheckoutTrackService
  ) {
    super(dialogData, dialogRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.data = this.getDialogData();
    this._checkoutTrackService.trackViewAddCard();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onAccept(): void {
    this._paymentService
      .addNewUserCard$(
        this._authStoreAdapter.selectAuthUserSig()!.id,
        this.data.receivedFormGroup.get('cardOwner').value,
        this.data.receivedFormGroup.get('expiryDate').value,
        this._authStoreAdapter.selectAuthUserSig()!.gipToken
      )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => this.closeDialog({ success: true }),
        error: (error) => {
          console.error('Error on addNewUserCard$', error);
          this.closeDialog({ success: false });
        }
      });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }
}
