import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';

import { aboutUs, legalTextRoutes } from '@app/legal-text.routes';
import { contactUs, RoutingPaths } from '@core/routing/routing.const';
import { LiteralSections } from '@shared/logic/const/common';

import { authGuard } from '@shared/logic/http/guards/auth.guard';

import { hashGuard } from '@shared/logic/http/guards/checkout.guard';
import { languageGuard } from '@shared/logic/http/guards/language.guard';
import { createMatcher } from '@shared/logic/http/matcher/createMatcher';
import { LandingsResolver } from '@shared/logic/http/resolvers/landings.resolver';
import { LiteralsResolver } from '@shared/logic/http/resolvers/literals.resolver';

import { AppConfig } from './app.config';

const contactUsMatcher = createMatcher([contactUs]);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppConfig.setConfigDefaultLang()
  },
  {
    path: ':language',
    canActivate: [languageGuard],
    resolve: { literals: LiteralsResolver },
    data: { lsk: LiteralSections.Common },
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage)
      },
      {
        path: RoutingPaths.Maintenance,
        loadComponent: () => import('./pages/maintenance/maintenance.component').then((m) => m.MaintenanceComponent)
      },
      {
        path: RoutingPaths.Login,
        loadComponent: () => import('./pages/auth/login/login.component').then((m) => m.LoginComponent)
      },
      {
        path: RoutingPaths.PasswordChange,
        loadComponent: () => import('./pages/auth/password-change/password-change.component').then((m) => m.PasswordChangeComponent)
      },
      {
        path: RoutingPaths.Register,
        loadComponent: () => import('./pages/auth/register/register.component').then((m) => m.RegisterComponent)
      },
      {
        path: RoutingPaths.SingleUseAccess,
        loadChildren: () => import('./pages/auth/single-use-access/single-use-access.module').then((m) => m.SingleUseAccessModule)
      },
      {
        path: RoutingPaths.LoginWithOtp,
        loadChildren: () => import('./pages/auth/login-with-otp/login-with-otp.module').then((m) => m.LoginWithOtpModule)
      },
      {
        path: RoutingPaths.ConfirmChangeEmail,
        loadChildren: () => import('./pages/auth/login-with-otp/login-with-otp.module').then((m) => m.LoginWithOtpModule)
      },
      {
        path: RoutingPaths.Search,
        loadComponent: () => import('./pages/hotel/search-with-dates/search-dates/search-dates.page').then((m) => m.SearchDatesPage),
        resolve: { literals: LiteralsResolver },
        data: { lsk: LiteralSections.Search }
      },
      {
        path: RoutingPaths.SearchMultiRoom,
        loadComponent: () => import('./pages/hotel/search-with-dates/search-dates-multiroom/search-dates.page').then((c) => c.SearchDatesPage),
        resolve: { literals: LiteralsResolver },
        data: { lsk: LiteralSections.Search }
      },
      {
        path: RoutingPaths.Checkout,
        loadChildren: () => import('./pages/checkout/checkout.routes').then((m) => m.CHECKOUT_ROUTES)
      },
      {
        path: RoutingPaths.BookingConfirmation,
        canActivate: [hashGuard],
        loadComponent: () => import('./pages/booking/booking-confirmation/booking-confirmation.page').then((p) => p.BookingConfirmationPage)
      },
      {
        path: RoutingPaths.BookingManagement,
        loadComponent: () => import('./pages/booking/booking-management/booking-management.page').then((p) => p.BookingManagementPage)
      },
      {
        path: RoutingPaths.BookingCancellation,
        loadComponent: () => import('./pages/booking/booking-cancellation/booking-cancellation.page').then((p) => p.BookingCancellationPage)
      },
      {
        path: RoutingPaths.SearchWithoutDates,
        loadComponent: () => import('./pages/hotel/search-without-dates/search-without-dates.component').then((m) => m.SearchWithoutDatesComponent)
      },
      {
        // path: RoutingPaths.Contact,
        matcher: contactUsMatcher,
        resolve: { literals: LiteralsResolver },
        data: { lsk: LiteralSections.Contact },
        loadComponent: () => import('./pages/contact/contact.page').then((p) => p.ContactPage)
      },
      {
        path: RoutingPaths.PrivateArea,
        canActivateChild: [authGuard],
        resolve: { literals: LiteralsResolver },
        data: { lsk: LiteralSections.PrivateArea },
        children: [
          {
            path: RoutingPaths.MyAccount,
            loadComponent: () => import('./pages/private-area/my-account/my-account.component').then((m) => m.MyAccountComponent),
            resolve: { literals: LiteralsResolver },
            data: { lsk: LiteralSections.Rewards }
          },
          {
            path: RoutingPaths.MyBookings,
            loadComponent: () => import('./pages/private-area/my-bookings/my-bookings.component').then((m) => m.MyBookingsComponent),
            resolve: { literals: LiteralsResolver },
            data: { lsk: LiteralSections.Rewards }
          },
          {
            path: RoutingPaths.MyTravelCash,
            loadComponent: () => import('./pages/private-area/my-travel-cash/my-travel-cash.component').then((m) => m.MyTravelCashComponent)
          }
        ]
      },

      {
        path: RoutingPaths.IPP,
        loadComponent: () => import('./pages/ipp/ipp.component').then((p) => p.IppComponent)
      },
      {
        path: RoutingPaths.ProfileCenter,
        loadComponent: () => import('./pages/auth/profile-center/profile-center.component').then((m) => m.ProfileCenterComponent)
      },
      ...legalTextRoutes,
      {
        path: RoutingPaths.NotFound,
        loadComponent: () => import('@app/pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent)
      },
      {
        path: ':p1/:p2',
        loadComponent: () => import('./pages/landing/landing.component').then((m) => m.LandingComponent),
        resolve: { landing: LandingsResolver }
      },
      {
        path: ':p1',
        loadComponent: () => import('./pages/landing/landing.component').then((m) => m.LandingComponent),
        resolve: { landing: LandingsResolver, literals: LiteralsResolver },
        data: { lsk: [LiteralSections.LandingDestinos, LiteralSections.Rewards] }
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('@app/pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
