<app-cdk-dialog-wrapper (ev_close)="onCancel()">
  <div #scrollBlockElement class="dialog-wrapper">
    <div class="cds-hotel-modal__head" [ngClass]="modalHeadClass" #modalHead>
      <div class="cds-hotel-modal__head-container">
        <div class="cds-hotel-modal__head-title">
          <ng-container *ngIf="!isHeadScrolling && stars">
            <app-stars-range [stars]="stars"></app-stars-range>
          </ng-container>
          <h2 class="hotel-name">
            {{ hotelData?.title }}<span *ngIf="hotelData?.adults_only">{{ hotelData.literales.search_adults_only }}</span>
          </h2>
          <div class="hotel-place">{{ hotelData?.ciudad }}</div>
        </div>
        <ng-container *ngIf="displayPrice">
          <div class="cds-hotel-modal__head-actions">
            <app-button
              label="{{ hotelData.literales.label_book_from }} {{ displayPrice | number : '1.0-0' }} {{ currency }}"
              type="cds-btn--highlight"
              (buttonClick)="onClickReservation()"
            ></app-button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="cds-hotel-modal__content">
      <div class="tabs-container" #tabs>
        <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="changeTab($event)">
          <mat-tab label="{{ hotelData?.literales?.label_information }}">
            <div class="info info__container tab-content">
              <div class="hotel">
                <h3 class="hotel__title block__title">{{ hotelData?.literales?.hotel_info_title }}</h3>
                <div class="hotel__container">
                  <div class="hotel__tag-container">
                    <app-tag label="{{ hotelData?.literales?.hotel_historical }}" size="cds-tag--l"></app-tag>
                    <app-tag label="{{ hotelData?.literales?.hotel_center }}" size="cds-tag--l"></app-tag>
                    <ng-container *ngIf="!hotelData.newHotel.new">
                      <ng-container *ngFor="let key of ratingKeys; let i = index">
                        <ng-container *ngIf="hotelData?.info_review_pro?.rating as rating">
                          <ng-container *ngIf="i === 0">
                            <app-tag label="{{ rating[key] | singleDecimal }}/10" size="cds-tag--l" iconTag="excelent"></app-tag>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div class="cds-free-content" [innerHTML]="hotelData?.descripcion"></div>
                </div>
              </div>
              <div class="contact">
                <h3 class="contact__title block__title">{{ hotelData?.literales?.hotel_room_contact }}</h3>
                <div class="contact__container">
                  <app-link
                    href="#"
                    appAvoidHref
                    label="{{ fullAddress }}"
                    variant="cds-link--primary"
                    [icon]="true"
                    iconName="localization"
                    (click)="navigateToMap()"
                  ></app-link>
                  <ng-container *ngIf="hotelData?.email as email">
                    <app-link
                      href="mailto:{{ email }}"
                      label="{{ hotelData?.literales?.label_contact_mail }}"
                      [icon]="true"
                      iconName="mail"
                      (click)="trackContactDataClick('email', 'mailto:' + email)"
                    ></app-link>
                  </ng-container>
                  <ng-container *ngIf="hotelData?.telefono as telefono">
                    <app-link
                      href="tel:{{ telefono }}"
                      label="{{ telefono }}"
                      [icon]="true"
                      iconName="phone"
                      (click)="trackContactDataClick('telefono', 'tel:' + telefono)"
                    ></app-link>
                  </ng-container>
                </div>
              </div>
              <ng-container *ngIf="hotelServices.highlighted || hotelServices.secondary">
                <div class="services">
                  <h3 class="facilities-services__title block__title">
                    {{ hotelData?.literales?.hotel_room_services }}
                  </h3>
                  <ng-container *ngIf="hotelServices.highlighted as hotelServicesFeatured">
                    <ul class="facilities__container">
                      <ng-container *ngFor="let service of hotelServicesFeatured">
                        <li>
                          <app-description-item [align]="'column'">
                            <div class="icon" data-icon>
                              <app-icon-item [icon]="service.cssClass"></app-icon-item>
                            </div>
                            <app-typography [type]="'b-m'" [weight]="'light'" data-title>{{ service.displayTitle }}</app-typography>
                          </app-description-item>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                  <ng-container *ngIf="hotelServices.secondary as hotelServices">
                    <div class="services__container">
                      <ul>
                        <ng-container *ngFor="let service of hotelServices">
                          <li>
                            <app-description-item [align]="'row'">
                              <div class="icon" data-icon>
                                <app-icon-item [icon]="service.cssClass"></app-icon-item>
                              </div>
                              <app-typography [type]="'b-s'" [weight]="'light'" data-title>{{ service.displayTitle }}</app-typography>
                            </app-description-item>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="usefulInfo as usefulInfo">
                <div class="useful">
                  <h3 class="useful__title block__title">{{ hotelData?.literales?.hotel_room_useful_info }}</h3>
                  <div class="useful__container">
                    <ul>
                      <ng-container *ngFor="let item of usefulInfo">
                        <li>
                          <app-description-item [align]="'row'">
                            <ng-container *ngIf="item.icon" data-icon>
                              <div class="icon">
                                <app-icon-item [icon]="item.icon"></app-icon-item>
                              </div>
                            </ng-container>
                            <div class="content" data-title>
                              <app-typography [type]="'b-s'" [weight]="'medium'">{{ item.title }}</app-typography>
                              <ng-container *ngFor="let desc of item.description">
                                <app-typography [type]="'b-s'" [weight]="'light'">{{ desc }}</app-typography>
                              </ng-container>
                            </div>
                          </app-description-item>
                        </li>
                      </ng-container>
                    </ul>
                    <ul>
                      <ng-container *ngFor="let item of paymentsInfo">
                        <li>
                          <app-description-item [align]="'row'">
                            <ng-container *ngIf="item.icon as icon" data-icon>
                              <div class="icon">
                                <app-icon-item [icon]="icon"></app-icon-item>
                              </div>
                            </ng-container>
                            <div class="content" data-title>
                              <app-typography [type]="'b-s'" [weight]="'medium'">{{ item.title }}</app-typography>
                              <ng-container *ngFor="let desc of item.description">
                                <app-typography [type]="'b-s'" [weight]="'light'">{{ desc }}</app-typography>
                              </ng-container>
                            </div>
                          </app-description-item>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="hotelData?.a_tener_en_cuenta">
                <div class="info-relevant">
                  <h3 class="useful__title block__title">{{ hotelData?.literales?.hotel_room_keep_in_mind }}</h3>
                  <div class="cds-free-content" [innerHTML]="hotelData?.a_tener_en_cuenta"></div>
                </div>
              </ng-container>
              <ng-container *ngIf="awardsItems as hotelAwards">
                <div class="awards">
                  <h3 class="awards__title block__title">{{ hotelData.literales?.hotel_prices }}</h3>
                  <div class="awards__container">
                    <ng-container *ngFor="let award of hotelAwards">
                      <app-hotel-award
                        [data]="{
                          tooltipTitle: award.tooltipTitle,
                          tooltipDescription: award.tooltipDescription,
                          image: award.image
                        }"
                      ></app-hotel-award>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="{{ hotelData?.literales?.label_gallery }}">
            <div class="gallery gallery__container tab-content">
              <app-gallery [scrollPosition]="scrollPosition" [gallery]="gallery" [hasGalleryLayout]="true"></app-gallery>
            </div>
          </mat-tab>
          <mat-tab label="{{ hotelData?.literales?.label_map }}">
            <div class="map map__container tab-content">
              <ng-container *ngIf="isGMapsLoadedSig()">
                <div class="app-map">
                  <cat-map
                    [hotelName]="hotelData.title"
                    [adultsOnly]="hotelData.adults_only"
                    [hotelLocation]="hotelData.ciudad"
                    [markerImg]="hotelData.ficha_imagen?.webp"
                    [hotelURL]="hotelData.url_hotel"
                    [coords]="coordinates"
                    (ev_markerActionClick)="markerActionClick()"
                  ></cat-map>
                </div>
              </ng-container>
              <div class="transport">
                <ng-container *ngIf="transportations">
                  <h3 class="facilities-transport__title block__title">{{ hotelData.literales?.hotel_room_contact }}</h3>
                  <div class="transport__content">
                    <ul>
                      <ng-container *ngFor="let item of transportations">
                        <li>
                          <app-description-item [align]="'row'">
                            <ng-container *ngIf="item.icon" data-icon>
                              <div class="icon">
                                <app-icon-item [icon]="item.icon"></app-icon-item>
                              </div>
                            </ng-container>
                            <div class="content" data-title>
                              <app-typography [type]="'b-s'" [weight]="'medium'">{{ item.title }}</app-typography>
                              <ng-container *ngFor="let desc of item.description">
                                <app-typography [type]="'b-s'" [weight]="'light'">{{ desc }}</app-typography>
                              </ng-container>
                            </div>
                          </app-description-item>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <div class="transport__banner">
                    <div class="transport__banner__info">
                      <div class="transport__banner__info__title">
                        <p>{{ hotelData?.literales?.label_need_transportation_to_hotel }}</p>
                      </div>
                      <div class="transport__banner__info__subtitle">
                        <p>{{ hotelData?.literales?.label_transfer_from_to_airport_station }}</p>
                      </div>
                    </div>
                    <div class="transport__banner__button">
                      <app-button label="{{ hotelData?.literales?.label_transfer }}" (buttonClick)="reserveTransfer()"></app-button>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="contact map__contact">
                <h3 class="contact__title block__title">{{ hotelData?.literales?.label_address_contact }}</h3>
                <div class="contact__container">
                  <app-link label="{{ fullAddress }}" [icon]="true" iconName="localization" (click)="openGoogleMapsWithIndications()"></app-link>
                  <ng-container *ngIf="hotelData?.email as email">
                    <app-link href="mailto:{{ email }}" label="Contacta por mail" [icon]="true" iconName="mail"></app-link>
                  </ng-container>
                  <ng-container *ngIf="hotelData?.telefono as phone">
                    <app-link href="tel:{{ phone }}" label="{{ phone }}" [icon]="true" iconName="phone"></app-link>
                  </ng-container>
                </div>
              </div>
              <ng-container *ngIf="pointOfInterest as pointOfInterest">
                <div class="interest">
                  <h3 class="interest__title block__title">{{ hotelData?.literales?.point_of_interest }}</h3>
                  <div class="services__container">
                    <app-point-list [list]="pointOfInterest" numColumns="three-col"></app-point-list>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="{{ hotelData?.literales?.label_ratings }}" *ngIf="!hotelData.newHotel.new">
            <div class="ratings ratings__container tab-content">
              <div class="ratings__info">
                <ng-container *ngIf="mainScoreData">
                  <div class="ratings__general-rate">
                    <app-main-score
                      [mainScoreData]="mainScoreData"
                      leyendText="{{ hotelData.info_review_pro.numReviews }} {{
                        hotelData.info_review_pro.numReviews === 1
                          ? hotelData?.literales?.['reviewpro-review-txt']
                          : hotelData?.literales?.['reviewpro-reviews-txt']
                      }}. {{ hotelData?.literales?.['reviewpro-modal-link-txt'] }}"
                    ></app-main-score>
                  </div>
                </ng-container>
                <ng-container *ngIf="metrics">
                  <div class="ratings__specific-rate">
                    <ng-container *ngFor="let metric of metrics">
                      <app-metric [metricData]="metric"></app-metric>
                    </ng-container>
                  </div>
                </ng-container>
              </div>

              <ng-container *ngIf="reviews as hotelReviews">
                <div class="ratings__opinions">
                  <h3 class="opnion-title">{{ hotelData?.literales?.['reviewpro-title-reviews'] }}</h3>
                  <ng-container *ngFor="let review of hotelReviews">
                    <app-review-comment
                      [nameUser]="review.author"
                      [dateCommentary]="review.date"
                      [markCommentary]="review.rating"
                      [titleCommentary]="review.title"
                      [textCommentary]="review.opinion"
                    >
                    </app-review-comment>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <ng-container *ngIf="displayPrice">
      <div class="cds-hotel-modal__footer" [ngClass]="modalHeadClass">
        <app-button
          class="finish-btn"
          [fullWidth]="true"
          label="{{ hotelData?.literales?.label_book_from }} {{ displayPrice | number : '1.0-0' }} {{ currency }}"
          type="cds-btn--highlight"
          (buttonClick)="onClickReservation()"
        ></app-button>
      </div>
    </ng-container>
  </div>
</app-cdk-dialog-wrapper>
