import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

export enum ConstantsControlShowToast {
  TOAST_SET_PLAN = 'setPlanSelectedOnMultiroom',
  TOAST_NO_SHOW_CAR = 'noShowSummaryCarOnMultiroom',
  TIMEOUT_LOGIN_OTP = 'timeoutLoginOTP',
  SAVE_PREFERENCES = 'savePreferences'
}

enum ConstantNameIcon {
  TOAST_OK = 'green-check toast',
  TOAST_SET_PLAN = 'error-filled toast blue',
  TOAST_NO_SHOW_CAR = 'error-filled toast red'
}

@Component({
  standalone: true,
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  imports: [CommonModule, IconItemComponent],
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  public descriptionToast = '';
  public iconToast = '';

  constructor(public snackBarRef: MatSnackBarRef<ToastComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: ConstantsControlShowToast) {
    this._matchMessageToast();
  }

  private _matchMessageToast(): void {
    if (this.data) {
      switch (this.data) {
        case ConstantsControlShowToast.TOAST_SET_PLAN:
          this.descriptionToast = 'Aplicaremos esta tarifa en el resto de habitaciones';
          this.iconToast = ConstantNameIcon.TOAST_SET_PLAN;
          break;
        case ConstantsControlShowToast.TOAST_NO_SHOW_CAR:
          this.descriptionToast = 'Aún no has seleccionado todas las habitaciones';
          this.iconToast = ConstantNameIcon.TOAST_NO_SHOW_CAR;
          break;
        case ConstantsControlShowToast.TIMEOUT_LOGIN_OTP:
          this.descriptionToast = 'Espera 1 minuto para enviar una nueva solicitud';
          this.iconToast = ConstantNameIcon.TOAST_NO_SHOW_CAR;
          break;
        case ConstantsControlShowToast.SAVE_PREFERENCES:
          this.descriptionToast = 'Tus preferencias han sido guardadas correctamente';
          this.iconToast = ConstantNameIcon.TOAST_OK;
          break;
        default:
          this.descriptionToast = '';
      }
    }
  }
}
