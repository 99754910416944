import { DateFunctions } from '@shared/logic/functions/date.functions';
import { HotelRaw } from '@shared/store/hotel/hotel.model.raw';

import { HotelsActions, HotelsActionsTypes } from './hotel.actions';

export type CachedHotelRaw = {
  creationDate: number;
  hotel: HotelRaw;
};

export interface State {
  hotelWithDatesOpenBar: boolean;
  hotels: Record<string, CachedHotelRaw>;
}

const initialState: State = {
  hotelWithDatesOpenBar: false,
  hotels: {}
};

export function reducer(state = initialState, action: HotelsActions): State {
  switch (action.type) {
    case HotelsActionsTypes.SetHotelMultiroomOpenBar:
      return { ...state, hotelWithDatesOpenBar: action.payload.hotelWithDatesOpenBar };
    case HotelsActionsTypes.SetHotel:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          [action.payload.hotel.backcode]: {
            creationDate: DateFunctions.getNow(),
            hotel: action.payload.hotel
          }
        }
      };
    default:
      return state;
  }
}
