import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { LandingCollectives } from '@shared/store/landing/model/collectives.model';
import { LandingCollectivesRaw } from '@shared/store/landing/model-raw/collectives.model.raw';
import { LandingsCommonNormalizer } from '@shared/store/landing/normalizers/landings.common.normalizer';

export class LandingCollectivesNormalizer {
  public static normalize(itemRaw: LandingCollectivesRaw): LandingCollectives {
    const item = {} as LandingCollectives;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.landingTitle = itemRaw.title;
    item.landingCode = itemRaw.code;
    item.landingImage = itemRaw.image;

    // Not used yet
    // item.travelWindowStartDate = itemRaw.travelWindowDates?.date || '';
    // item.travelWindowEndDate = itemRaw.travelWindowDates?.endDate || '';
    // item.bookingWindowStartDate = itemRaw.bookingWindowDates?.date || '';
    // item.bookingWindowEndDate = itemRaw.bookingWindowDates?.endDate || '';

    item.roomsBlock = {
      title: itemRaw.hotelSection?.title,
      tabs: GeneralFunctions.isArrayLike(itemRaw.hotelSection?.tabsSection)
        ? itemRaw.hotelSection.tabsSection.map((i) => LandingsCommonNormalizer.normalizeTab(i))
        : []
    };
    item.roomsBlock.tabs = LandingsCommonNormalizer._sortItemsByCombinedSection(item.roomsBlock.tabs);
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
