export class GoogleTagFunctions {
  static reduceTag(input: unknown): string {
    // Check if input is neither a string nor a number
    if (typeof input !== 'string' && typeof input !== 'number') {
      return 'na';
    }
    // Convert to string if input is a number
    const inputStr = String(input);

    // Remove content within <>
    const noTagsInput = inputStr.replace(/<[^>]*>/g, '');

    // Normalize characters to their decomposed form (removing accents and diacritics)
    const normalizedInput = noTagsInput.toLowerCase().normalize('NFD');
    const escapedInput = normalizedInput.replace('-', '_');

    // Regex to filter out letters, numbers, and spaces
    const regex = /[\p{Letter}\d\s,.|$€!:@\-_\/\\?=%]/gu;

    // Filter characters and rejoin them into a string
    const finalTag = (escapedInput.match(regex) ?? []).join('');
    return finalTag !== undefined && finalTag !== '' ? finalTag : 'na';
  }

  static prepareDiscountTag(input: string | number): number {
    let discountNumber = typeof input === 'string' ? Number(input) : input;

    if (discountNumber < 0) {
      discountNumber = discountNumber * -1;
    }

    return Number((discountNumber / 100).toFixed(2));
  }

  static numberTag(input: unknown): number {
    // Check if input is neither a string nor a number
    if (typeof input !== 'string' && typeof input !== 'number') {
      return 0;
    }
    return Number(Number(input).toFixed(2));
  }
}
