import { Injectable } from '@angular/core';

import { AddCreditCardModalComponent } from '@app/shared/ns_components/modals/checkout/add-credit-card-modal/add-credit-card-modal.component';
import { AddCreditCardModalData } from '@app/shared/ns_components/modals/checkout/add-credit-card-modal/add-credit-card-modal.component.typing';
import {
  DeleteCCModalInput,
  DeleteCreditCardModalComponent
} from '@app/shared/ns_components/modals/checkout/delete-credit-card-modal/delete-credit-card-modal.component';
import { RoomModalComponent, RoomModalInput } from '@molecules/modals/room-modal/room-modal.component';
import { CommonGdiHelper } from '@shared/logic/helpers/gdi/common-gdi.helper';

import { Literals, ModalResponse } from '@shared/logic/models/common.model';
import { ModalAbstractService } from '@shared/logic/services/modals/modal.abstract.service';

import {
  CancelBookingModalComponent,
  CancelBookingModalInput,
  CancellBookingModalResponse
} from '@shared/ns_components/modals/cancel-booking-modal/cancel-booking-modal.component';
import { OfferBreakdownModalComponent, OfferModalInput } from '@shared/ns_components/modals/checkout/offer-breakdown/offer-breakdown-modal.component';
import { CollectiveBannerModalComponent, CollectiveModalInput } from '@shared/ns_components/modals/collective-banner/collective-banner-modal.component';
import { DeactivateAccountModalComponent } from '@shared/ns_components/modals/deactivate-account/deactivate-account-modal.component';
import { ExpandedImageModalComponent, ExpandedImageModalInput } from '@shared/ns_components/modals/expanded-image/expanded-image-modal.component';
import { HotelModalComponent, HotelModalInput } from '@shared/ns_components/modals/hotel-modal/hotel-modal.component';
import { ActionType, SimpleModalComponent, SimpleModalInput } from '@shared/ns_components/modals/simple-modal/simple-modal.component';
import { GalleryMosaicItem } from '@shared/store/hotel/hotel.model';
import { HotelRaw } from '@shared/store/hotel/hotel.model.raw';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalService extends ModalAbstractService {
  public displayMessageModal$(data: SimpleModalInput): Observable<ModalResponse> {
    return this.openModal$<SimpleModalInput>(SimpleModalComponent, data);
  }

  public openDiscountsModal$(offerModalInput?: OfferModalInput): Observable<ModalResponse> {
    return this.dialog.open(OfferBreakdownModalComponent, {
      data: offerModalInput || CommonGdiHelper.createDefaultModalOffer()
    }).closed as Observable<ModalResponse>;
  }

  public openDeactivateAccountModal$(literals: Literals): Observable<ModalResponse> {
    return this.openModal$<any>(DeactivateAccountModalComponent, {
      icon: 'error-red',
      literals
    });
  }

  public openCancelBookingModal$(data: CancelBookingModalInput): Observable<CancellBookingModalResponse> {
    return this.dialog.open(CancelBookingModalComponent, { data }).closed as Observable<CancellBookingModalResponse>;
  }

  public openAddNewCardModal(data: AddCreditCardModalData) {
    return this.dialog.open(AddCreditCardModalComponent, { data }).closed as Observable<any>;
  }

  public openRemoveCardModal(token: string) {
    return this.openModal$<DeleteCCModalInput>(DeleteCreditCardModalComponent, {
      icon: 'KO',
      displayTitle: this.literals()?.label_sure_delete_card,
      token,
      literals: this.literals()
    });
  }

  public openRoomModalV1(data: RoomModalInput): Observable<ModalResponse | undefined> {
    return this.openModal$<RoomModalInput>(RoomModalComponent, data);
  }

  public openHotelModal$(data: HotelRaw, index?: number, displayPrice?: number | null, currency?: string | null): Observable<ModalResponse | undefined> {
    return this.openModal$<HotelModalInput>(HotelModalComponent, { hotelData: data, index, displayPrice, currency });
  }

  public openCollectiveBannerModal$(data: CollectiveModalInput): Observable<ModalResponse | undefined> {
    return this.openModal$<CollectiveModalInput>(CollectiveBannerModalComponent, {
      ...data,
      literals: this.literals()
    });
  }

  public openModalTokenExpired(): Observable<ModalResponse> {
    return this.openModal$<SimpleModalInput>(SimpleModalComponent, {
      displayTitle: this.literals()?.link_not_valid,
      messages: [this.literals()?.link_not_valid_request_new_link],
      icon: 'Last-call_Small',
      actions: [
        {
          text: this.literals()?.label_ini_sesion,
          action: ActionType.Cancel
        },
        {
          text: this.literals()?.request_new_link,
          action: ActionType.Accept
        }
      ]
    });
  }

  public _openGalleryModal(galleryMosaic: GalleryMosaicItem[], categoryIndex: number): Observable<ModalResponse> {
    return this.openModal$<ExpandedImageModalInput>(ExpandedImageModalComponent, { galleryMosaic, categoryIndex });
  }

  public openInactivityModal$(): Observable<ModalResponse> {
    return this.openModal$<SimpleModalInput>(SimpleModalComponent, {
      icon: 'Last-call_Small',
      displayTitle: this.searchLiterals()?.inactivity_modal_title,
      messages: [this.searchLiterals()?.inactivity_modal_text],
      actions: [
        { action: ActionType.Cancel, text: this.searchLiterals().inactivity_modal_keep_browsing },
        { action: ActionType.Accept, text: this.searchLiterals().inactivity_modal_check_availability }
      ]
    });
  }
}
