import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Tag } from '@shared/store/ga/ga.model';
import { GoogleAnalyticsStoreAdapter } from '@shared/store/ga/ga.store.adapter';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerOverlordService {
  constructor(
    @Inject(PLATFORM_ID) public platformId: string,
    private gtmService: GoogleTagManagerService,
    private gaStoreAdapter: GoogleAnalyticsStoreAdapter
  ) {}

  public init(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (isPlatformServer(this.platformId)) {
        resolve(true);
        return;
      }
      this.gtmService
        .addGtmToDom()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  public pushTag(tag: Partial<Tag>): void {
    this.updatePrevContentInfo(tag);
    this.gtmService.pushTag(tag);
  }

  private updatePrevContentInfo(tag: Partial<Tag>): void {
    const { content_group, content_type } = tag;
    if (content_group && content_type) {
      this.gaStoreAdapter.setPrevContentInfo({ prevContentGroup: content_group, prevContentType: content_type });
    }
  }
}
