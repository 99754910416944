import { State } from '@shared/store/auth/auth.reducer';

export const onRehydrateAuthState = (state: State) => ({
  ...state,
  user: state.user
    ? {
        ...state.user,
        birthdate: new Date(state.user.birthdate) // new Date(null) returns 1970-1-1
      }
    : null
});
