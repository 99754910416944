import { CartModel, createCartEvent } from '@shared/logic/services/emarsys/services/models/cart.model';
import { BreakdownChildItem } from '@shared/ns_components/breakdowns/models/breakdown.model';

export interface PurchaseModel {
  orderId: string;
  items: CartModel[];
}

export function createPurchaseEvent(orderId: string, hotelCode: string, rooms: BreakdownChildItem[]): PurchaseModel {
  return {
    orderId: orderId,
    items: rooms.map((r) => createCartEvent(hotelCode, r.price))
  };
}
