import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

/**
 * Component Usage:
 * set @type {('row' | 'column' | 'hybrid')} align Input()
 * This component has 3 slots, title , subtitle and icon. To use content projection should have these attributes:
 * data-title
 * data-subtitle
 * data-icon
 * @example
 * <app-description-item [align]="'hybrid'"
 * >
 *  <div class="icon" data-icon>
 *    <app-icon-item [icon]="item.icon"></app-icon-item>
 *  </div>
 *  <app-typography [type]="'b-s'" [weight]="'light'" data-title>{{content}}</app-typography>
 * </app-description-item>
 *
 */

@Component({
  standalone: true,
  selector: 'app-description-item',
  templateUrl: './description-item.component.html',
  styleUrls: ['./description-item.component.scss'],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionItemComponent {
  /**
   * Input Usage.
   * 'row', to set horizontal align.
   * 'column', to set a vertial align.
   * 'hybrid', to set vertial align on desktop devices and horizontal on tablet and mobile
   *
   * @type {('row' | 'column' | 'hybrid')}
   */
  @Input() align: 'row' | 'column' | 'hybrid' = 'row';
}
