export const COMMON_COMMANDS: Record<string, string> = {
  go: 'go',
  cart: 'cart',
  setEmail: 'setEmail',
  tag: 'tag',
  searchTerm: 'searchTerm',
  custom_search_term: 'custom_searchTerm',
  view: 'view',
  custom_cart: 'custom_cart',
  purchase: 'purchase',
  custom_purchase: 'custom_purchase'
};
