import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { LoginTag, LoginTrackingEvent } from '@ga-tracking/services/tracking-sites/login/login-track.model';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';

@Injectable({ providedIn: 'root' })
export class LoginTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackLoginPageDisplay(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'login y registro',
      content_type: 'login',
      content_category: 'na',
      content_subcategory: 'na',
      content_detail: 'na',
      content_id: 'na',
      title: 'catalonia hotels | login',
      path: 'login'
    };
    this.trackingService.pushEventClickFromExternalSources<LoginTag>(eventData, '1_search');
  }

  public trackLoginSuccess(method: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.login,
      method: GoogleTagFunctions.reduceTag(method),
      section: 'login',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickForgetPassword(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'login',
      link_text: 'contrasena olvidada',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewModalReviewEmail(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'reestablecer contrasena_email',
      link_text: 'revisa bandeja entrada'
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickModalReviewEmailBtn(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'reestablecer contrasena_email',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickLoginAfterUpdatePassword(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: 'login_reestablecer contrasena',
      link_text: 'iniciar sesion',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickSaveNewPassword(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: 'login_reestablecer contrasena',
      link_text: 'guardar nueva contrasena',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewErrorFieldInResetPassword(status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.errorNavigate,
      element: 'input_text',
      section: 'login_reestablecer contrasena',
      link_text: 'contrasena',
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewModalSuccessChangePassword(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'reestablecer contrasena_exito',
      link_text: 'cambio guardado con exito'
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickBtnSuccessChangePassword(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'reestablecer contrasena_exito',
      link_text: 'entendido'
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickAnyActionLoginPage(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: 'login',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewTooltipInfoRewards(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'info_tooltip',
      section: 'rewards',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickBtnRecoverPassWithEmail(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: 'login_recuperar contrasena',
      link_text: 'enviar'
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewModalErrorLogin(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.errorNavigate,
      element: 'modal',
      section: 'login_error',
      status: 'nombre usuario o contrasena no coinciden'
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackClickBtnModalUserOrPassIncorrect(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'login_error',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackLogOut(method: string, linkUrl: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.logout,
      method: GoogleTagFunctions.reduceTag(method),
      section: 'header',
      link_url: GoogleTagFunctions.reduceTag(linkUrl)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }
}
