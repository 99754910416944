import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import {
  RegimeSelectionEcommerceItem,
  RegimeSelectionTrackDisplayTag,
  RegimeSelectionTrackingEvent
} from '@ga-tracking/services/tracking-sites/regime-selection-track/regime-selection-track.model';
import { CheckoutCombinedResponse, CheckoutMealPlan } from '@shared/store/checkout/checkout.model';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { createMomentDate } from '@shared/utils/dates/dates.utils';
import { getHotelContinent } from '@app/shared/utils/continents/continents.utils';

@Injectable({ providedIn: 'root' })
export class RegimeSelectionTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackRegimeSelectionDisplay(checkoutResponse: CheckoutCombinedResponse): void {
    const allGuestInRooms = checkoutResponse.hashResponse.rooms.children.flatMap((r) => r.guestCounts);
    const promotion = checkoutResponse.mealsResponse?.options.flatMap((m) => m.discountInfo.discounts).filter((d) => d.promotionCode !== 'REWARDS07')[0];
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'checkout',
      content_type: 'selector regimen',
      content_tag1: promotion?.displayText ? GoogleTagFunctions.reduceTag(promotion.displayText) : 'na',
      content_tag2: promotion?.percent ? GoogleTagFunctions.reduceTag(GoogleTagFunctions.prepareDiscountTag(Number(promotion.percent))) : 'na',
      content_category: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelType) ?? 'no info',
      content_subcategory: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelDestination) ?? 'no info',
      content_detail: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name) ?? 'no info',
      content_id: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.code) ?? 'no info',
      content_place: GoogleTagFunctions.reduceTag(getHotelContinent(checkoutResponse.hashResponse.hotelInfo.code)) ?? 'no info',
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(checkoutResponse.hashResponse.hotelInfo.stars)) ?? 'no info',
      content_score: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.hotelInfo.hotelRating) ?? 0,
      content_chekin_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).format('YYYYMMDD') ?? 'no info',
      content_chekin_day:
        GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).locale('es').format('dddd')) ?? 'no info',
      content_chekout_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).format('YYYYMMDD') ?? 'no info',
      content_chekout_day:
        GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).locale('es').format('dddd')) ?? 'no info',
      content_chekin_advance_days:
        GoogleTagFunctions.numberTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).diff(createMomentDate(), 'days')) ?? 0,
      content_room_nights: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.totalNights) ?? 0,
      content_room_quantity: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.rooms.children.length) ?? 0,
      content_n_adult: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 10).reduce((sum, guest) => sum + guest.count, 0)),
      content_n_child: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 8).reduce((sum, guest) => sum + guest.count, 0)),
      content_n_junior: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 9).reduce((sum, guest) => sum + guest.count, 0)),
      content_n_baby: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 7).reduce((sum, guest) => sum + guest.count, 0)),
      title: `catalonia hotels | selector regimen ${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name)}`,
      path: `/checkout/regime_selection?hash=${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hash)}`
    };
    this.trackingService.pushEventClickFromExternalSources<RegimeSelectionTrackDisplayTag>(eventData, '1_search');
  }

  public trackClickAddToCart(checkoutResponse: CheckoutCombinedResponse, mealSelected: number): void {
    const meal: CheckoutMealPlan | undefined = checkoutResponse.mealsResponse?.options[mealSelected];
    const promotions = checkoutResponse.mealsResponse?.options.flatMap((m) => m.discountInfo.discounts).filter((d) => d.promotionCode !== 'REWARDS07');
    const eventData = {
      event: COMMON_TAG_EVENTS.addToCart,
      ecommerce: {
        currency: checkoutResponse.hashResponse.currency === 'US$' || checkoutResponse.hashResponse.currency === '$' ? 'USD' : 'EUR',
        value: checkoutResponse.hashResponse.isRewardsActive
          ? GoogleTagFunctions.numberTag(checkoutResponse.mealsResponse.options[mealSelected].prices.totalPriceRewards ?? 0)
          : GoogleTagFunctions.numberTag(checkoutResponse.mealsResponse.options[mealSelected].prices.totalPrice ?? 0),
        items: checkoutResponse.hashResponse.rooms.children.map((r, index) => {
          return {
            item_name: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.rate.title) ?? 'no info',
            item_id: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.isRewardsActive ? r?.ratePlanCodeRewards : r?.ratePlanCode) ?? 'no info',
            price: checkoutResponse.hashResponse.isRewardsActive
              ? GoogleTagFunctions.numberTag(checkoutResponse.mealsResponse.options[mealSelected].rooms.children[index].prices.totalPriceRewards ?? 0)
              : GoogleTagFunctions.numberTag(checkoutResponse.mealsResponse.options[mealSelected].rooms.children[index].prices.totalPrice ?? 0),
            discount:
              checkoutResponse.hashResponse.isRewardsActive || checkoutResponse.hashResponse.promoCode !== ''
                ? GoogleTagFunctions.numberTag(checkoutResponse.mealsResponse.options[mealSelected].rooms.children[index].discountRewards * -1)
                : 0,
            item_regimen: GoogleTagFunctions.reduceTag(meal?.displayName) ?? 'no info',
            item_regimen2: GoogleTagFunctions.reduceTag(meal?.mealId) ?? 'no-info',
            item_brand: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelCity) ?? 'no info',
            item_place: GoogleTagFunctions.reduceTag(getHotelContinent(checkoutResponse.hashResponse.hotelInfo.code)) ?? 'no info',
            item_category: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelType) ?? 'no info',
            item_category2: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.rate.subtitle),
            item_category3: GoogleTagFunctions.reduceTag(Math.floor(checkoutResponse.hashResponse.hotelInfo.stars)) ?? 'no info',
            item_category4: GoogleTagFunctions.reduceTag(promotions?.map((p) => p.displayText).join('_')) ?? 'no info',
            item_category5: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.totalNights) ?? 0,
            item_category6: checkoutResponse.hashResponse.hotelInfo.isAdultsOnly ? '1' : '0',
            item_category7: GoogleTagFunctions.numberTag(r?.prices.totalPriceRewards ?? 0),
            item_rw_discount: GoogleTagFunctions.prepareDiscountTag(Number(meal?.discountInfo.percent)) ?? 0,
            item_variant: GoogleTagFunctions.reduceTag(r.roomName) ?? 'no info',
            item_variant2: GoogleTagFunctions.reduceTag(r.roomTypeCode) ?? 'no info',
            item_chekin_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).format('YYYYMMDD') ?? 'no info',
            item_chekin_day:
              GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).locale('es').format('dddd')) ?? 'no info',
            item_chekout_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).format('YYYYMMDD') ?? 'no info',
            item_chekout_day:
              GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).locale('es').format('dddd')) ?? 'no info',
            item_chekin_advance_days:
              GoogleTagFunctions.numberTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).diff(createMomentDate(), 'days')) ?? 0,
            item_n_adult: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 10)?.count) ?? 0,
            item_n_child: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 8)?.count) ?? 0,
            item_n_junior: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 9)?.count) ?? 0,
            item_n_babie: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 7)?.count) ?? 0,
            item_list_name: `selector regimen_${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name)}`,
            item_list_id: `selector regimen_${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.code)}`,
            index: GoogleTagFunctions.numberTag(index + 1),
            quantity: 1
          };
        }) as RegimeSelectionEcommerceItem[]
      }
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickBeforeStep(link_text: string, link_url: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'step',
      section: 'checkout_selector_regimen',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickInfoButton(link_text: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'regimen',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackViewModalMoreInfoRegime(link_text: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'info regimen',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickMoreInfoRoom() {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'card habitacion',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackViewModalInfoRoom(roomSelected: string, link_text: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}`,
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickReserveRoom(tariffSelected: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: `info_tarifa_${tariffSelected}`,
      link_text: 'reserva habitacion'
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  // TODO: Currently room info modal haven´t sections (use tariff modal)
  public trackClickSectionModalInfoRoom(roomSelected: string, oldSection: string, newSection: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal',
      section: `info regimen_${GoogleTagFunctions.reduceTag(roomSelected)}_${GoogleTagFunctions.reduceTag(oldSection)}`,
      link_text: GoogleTagFunctions.reduceTag(newSection)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickMoreInfoTariff() {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'tarifa',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackViewModalInfoTariff(roomSelected: string, section: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info tarifa_${GoogleTagFunctions.reduceTag(roomSelected)}`,
      link_text: GoogleTagFunctions.reduceTag(section)
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackClickMoreInfoRewards() {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'rewards',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }

  public trackViewModalMoreInfoRewards(discount: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'info rewards',
      link_text: GoogleTagFunctions.reduceTag(GoogleTagFunctions.prepareDiscountTag(discount))
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }
}
