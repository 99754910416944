import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';

import { AfterViewInit } from '@angular/core';

import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';
import { Subject } from 'rxjs';

@Directive({
  selector: '[carouselBase]',
  exportAs: 'carousel'
})
export class CarouselBaseDirective implements OnDestroy, OnInit, AfterViewInit {
  slider: KeenSliderInstance | null = null;
  protected currentSlideValue = 0;
  private slideInterval: any;

  @Output() currentSlideChange = new EventEmitter<number>();

  @Input()
  get currentSlide() {
    return this.currentSlideValue;
  }
  set currentSlide(slideIndex: number) {
    if (slideIndex == null) {
      this.currentSlideValue = 0;
    } else {
      this.currentSlideValue = slideIndex;
    }

    if (this.slider?.track?.details && this.slider.track.details.rel !== this.currentSlideValue) {
      this.goTo(this.currentSlideValue);
    }
  }
  @Input() dotHelper: Array<number> = [];
  @Input('clickSubject') clickSubject: Subject<any> | null = null;
  @Input() numberOfClicks = 0;
  @Input() timeChangeSlade = 0;
  @Input() configCarousel: KeenSliderOptions = {};

  constructor(protected readonly element: ElementRef<HTMLElement>, @Inject(PLATFORM_ID) public platformId: string, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.dotHelper = [...Array(this.slider?.track?.details?.slides?.length)?.keys()];

        this.changeSlideTime();
      }, 0);
    }
  }

  prev() {
    if (this.slider) {
      this.slider.prev();
    }
  }

  next() {
    if (this.slider) {
      this.slider.next();
    }
  }

  goTo(index: number) {
    if (isPlatformBrowser(this.platformId) && this.slider) {
      this.slider.moveToIdx(index);
    }
  }

  changeSlideTime() {
    if (this.timeChangeSlade > 299) {
      this.slideInterval = setInterval(() => {
        this.goTo(this.currentSlideValue + 1);
      }, this.timeChangeSlade);
    }
  }

  slideActive(sliderObj: any) {
    const slideAct = sliderObj?.slides?.[sliderObj?.track?.details?.rel];
    sliderObj?.slides?.forEach((slide: any) => {
      slide?.classList?.remove('slide-active');
    });
    slideAct?.classList?.add('slide-active');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.configCarousel && this.configCarousel.breakpoints) {
      // hide the element during initializaiton
      this.element.nativeElement.style.opacity = '0';
      // add the required classes by keen-slider
      this.element.nativeElement.classList.add('keen-slider');

      // IMPORTANT! Here you have to change the creation of the slider over the setTimeout
      // cause you move the execution on the tasks stack and prevent the scrolling generated
      // when this slider is created in the main thread
      setTimeout(() => {
        this.slider = new KeenSlider(this.element.nativeElement, this.configCarousel, [
          (slider) => {
            slider.on('slideChanged', (s) => {
              this.currentSlideValue = s.track.details.rel;
              this.currentSlideChange.emit(this.currentSlideValue);
              clearInterval(this.slideInterval);
              this.changeSlideTime();
              this.slideActive(s);
            });
            slider.on('created', (s) => {
              this.slideActive(s);
            });
          }
        ]);
        this.cdRef.markForCheck();
      }, 0);

      let cont = 0;
      const loadHero = setInterval(() => {
        const contentPage = document.querySelector('.ng-star-inserted.ion-page');
        if (this.slider && contentPage && contentPage.clientWidth > 0) {
          this.slider.update();
          clearInterval(loadHero);
        }
        if (cont > 15) {
          clearInterval(loadHero);
        }
        cont++;
      }, 500);

      this.element.nativeElement.style.opacity = '1';

      if (this.clickSubject) {
        this.clickSubject.subscribe((e) => {
          this.numberOfClicks++;
        });
      }
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.slider) {
        this.slider.destroy();
      }
      clearInterval(this.slideInterval);
    }
  }
}
