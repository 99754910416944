import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';

import { CdkDialogWrapperNewModalInput } from '@shared/logic/models/common.model';
import { KeenSliderOptions } from 'keen-slider';

@Component({
  selector: 'app-cdk-dialog-wrapper-new',
  templateUrl: './cdk-dialog-wrapper-new.component.html',
  styleUrls: ['./cdk-dialog-wrapper-new.component.scss']
})
export class CdkDialogWrapperNewComponent implements OnInit {
  @Output() readonly ev_close = new EventEmitter<void>();

  public configCarousel: KeenSliderOptions = {
    loop: true,
    mode: 'snap',
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 1, spacing: 0 }
      },
      '(min-width: 1280px)': {
        slides: { perView: 1, spacing: 0 }
      }
    },
    slides: { perView: 1, spacing: 0 }
  };

  public dialogData: CdkDialogWrapperNewModalInput;

  // PromoPosition: to true puts the blue ribbon at the top and if false it puts it at the bottom
  // Promo: activate or deactivate the blue ribbon
  // Images: shows a carousel of images
  // OfferDiscount: discount to show in the ribbon
  // Icon: icon to display above the title
  // DisplayTitle: the title of the modal

  constructor(@Inject(DIALOG_DATA) public data: CdkDialogWrapperNewModalInput) {}

  public ngOnInit(): void {
    this.dialogData = this.data;
  }

  public close(): void {
    this.ev_close.emit();
  }
}
