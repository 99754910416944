<!-- TODO GDI check: this click event is not supported by accesibility -->
<div class="bg-modal" (click)="onCancel()">
  <app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
    <ul class="modal-content">
      <ng-container *ngFor="let item of getDialogData().offers">
        <li>
          <app-description-item [align]="'row'">
            <ng-container *ngIf="item.icon" data-icon>
              <div class="icon">
                <app-icon-item [icon]="item.icon"></app-icon-item>
              </div>
            </ng-container>
            <div class="content" data-title>
              <app-typography [type]="'b-s'" [weight]="'medium'">{{ item.title }}</app-typography>
              <ng-container *ngFor="let desc of item.description">
                <app-typography [type]="'b-s'" [weight]="'light'"><div [innerHTML]="desc"></div></app-typography>
              </ng-container>
            </div>
          </app-description-item>
        </li>
      </ng-container>
    </ul>
  </app-cdk-dialog-wrapper-new>
</div>
