import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer } from '@shared/logic/helpers/common/common.normalizer';
import { InformativeBannerNormalizer } from '@shared/store/home/informative-banner/informative-banner.normalizer';
import { RewardsNormalizer } from '@shared/store/home/rewards/rewards.normalizer';
import { LandingRewards, LandingRewardsChrome, RewardsTable } from '@shared/store/landing/model/rewards.model';
import { LandingRewardsChromeRaw, LandingRewardsRaw, RewardsTableRaw } from '@shared/store/landing/model-raw/rewards.model.raw';

export class LandingRewardsNormalizer {
  public static normalize(itemRaw: LandingRewardsRaw): LandingRewards {
    const item = {} as LandingRewards;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.heroTitle = itemRaw.herobanner.title;
    item.heroSubtitle = itemRaw.herobanner.subtitle;
    item.heroImage = itemRaw.herobanner.image;
    item.mainTitle = itemRaw.blockDescription.title;
    item.mainSubtitle = itemRaw.blockDescription.description;
    item.benefitsTitle = itemRaw.infoRewards.title;
    item.benefitsList = itemRaw.infoRewards.lista_banners_informativos.map((i) => InformativeBannerNormalizer.normalizeInformativeBanner(i));
    item.bannerRewards = RewardsNormalizer.normalize(itemRaw.bannerRewards);
    item.chromeTitle = itemRaw.cromoRewards.title;
    item.stepsTitle = itemRaw.stepsCataloniaRewards.title;

    const mapChromes = (data: { cromos: LandingRewardsChromeRaw[] }): LandingRewardsChrome[] => data.cromos.map(this.normalizeChrome);
    if (itemRaw.cromoRewards?.cromos) {
      item.chromeItems = mapChromes(itemRaw.cromoRewards);
    }
    if (itemRaw.stepsCataloniaRewards?.cromos) {
      item.stepsItems = mapChromes(itemRaw.stepsCataloniaRewards);
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static normalizeRewardsTables(itemRaw: RewardsTableRaw[]): RewardsTable[] {
    if (!itemRaw || !GeneralFunctions.isArrayLike(itemRaw, 1)) {
      return [];
    }
    return itemRaw.map((i) => LandingRewardsNormalizer._normalizeRewardsTable(i));
  }

  private static _normalizeRewardsTable(itemRaw: RewardsTableRaw): RewardsTable {
    const item = {} as RewardsTable;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.caption = itemRaw.leyenda;
    item.header = [
      { type: 'great', displayImage: 'r-great.svg', value: itemRaw.tableHead[0] },
      { type: 'circle', displayImage: 'r-circle.svg', value: itemRaw.tableHead[1] },
      { type: 'star', displayImage: 'r-star.svg', value: itemRaw.tableHead[2] },
      { type: 'top', displayImage: 'r-top.svg', value: itemRaw.tableHead[3] }
    ];
    item.body = itemRaw.tableRow.map((row) => ({
      concept: row.title,
      type: row.check ? 'icons' : 'text',
      values: [
        { type: 'great', value: row.columGreat },
        { type: 'circle', value: row.columCircle },
        { type: 'star', value: row.columStar },
        { type: 'top', value: row.columTop }
      ]
    }));
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static normalizeChrome(itemRaw: LandingRewardsChromeRaw): LandingRewardsChrome {
    const item = {} as LandingRewardsChrome;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { title, description, image, link } = itemRaw;
    item.title = title;
    item.description = description;
    if (image) {
      item.image = image;
    }
    if (GeneralFunctions.isArrayLike(link, 1)) {
      item.links = link.map((i) => CommonNormalizer.normalizeLandingLink(i));
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
