import { Component, forwardRef, HostBinding, HostListener, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { Literals } from '@app/shared/logic/models/common.model';
import { LiteralSections } from '@shared/logic/const/common';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';

@Component({
  selector: 'gdi-cat-input-text',
  templateUrl: './gdi-cat-input-text.component.html',
  styleUrls: ['./gdi-cat-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GdiCatInputTextComponent),
      multi: true
    }
  ]
})
export class GdiCatInputTextComponent implements ControlValueAccessor {
  @HostBinding('attr.noNumbersOrSpecialChars') @Input() noNumbersOrSpecialChars: boolean | string | null = null;

  private regex: RegExp = new RegExp(/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜöÖçÇïÏ\s]*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'];

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // if the attribute is present, it is considered true.
    if (this.noNumbersOrSpecialChars !== '' && this.noNumbersOrSpecialChars !== true && this.noNumbersOrSpecialChars !== 'true') {
      return; // if the property is no active, do nothing
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return; // allow special chars
    }

    const inputChar = event.key;
    if (!this.regex.test(inputChar)) {
      event.preventDefault(); // prevent the entry if not pass the validation
    }
  }

  @Input() size: 'cds-input-text--l' | 'cds-input-text--m' = 'cds-input-text--l';
  @Input() style: 'cds-input-text--regular' | 'cds-input-text--highlight' = 'cds-input-text--regular';
  @Input() placeholder: string;
  @Input() oneField = false;
  @Input() type: 'text' | 'number' = 'text';

  public literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();

  /////////////////////
  public value: string;
  public isDisabled: boolean;
  public errors: any;
  // public isTouched = false;
  /////////////////////

  constructor(private injector: Injector, private _commonStoreAdapter: CommonStoreAdapter) {}

  public get classes(): string[] {
    const atr = this.isDisabled ? 'disabled' : '';
    const oneField = this.oneField ? 'one-field' : '';
    return [`${this.style}`, `${this.size}`, atr, oneField];
  }

  public onInput(event: any): void {
    this.value = event.target.value;
    this.onTouch();
    this.onChange(this.value);
    this.retrieveErrors();
  }

  public onBlur(): void {
    // this.isTouched = true;
    this.retrieveErrors();
  }

  private retrieveErrors(): void {
    this.errors = this.injector.get(NgControl).errors;
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange = (_: any) => {};
  onTouch = () => {};
}
