import { Literals } from '@shared/logic/models/common.model';

interface HotelRatingInfo {
  displayIcon: string;
  displayText: string;
}

export function getHotelRatingInfo(score: number, literals: Literals): HotelRatingInfo {
  const scoreRanges: { [key: string]: { min: number; max: number; icon: string; text: string } } = {
    nice: { min: 0, max: 6.5, icon: 'nice', text: 'reviewpro-nice' },
    good: { min: 6.5, max: 7.5, icon: 'good', text: 'reviewpro-good' },
    'so-good': { min: 7.5, max: 8.5, icon: 'so-good', text: 'reviewpro-very-good' },
    excellent: { min: 8.5, max: Infinity, icon: 'excelent', text: 'reviewpro-excellent' }
  };

  for (const range in scoreRanges) {
    const { min, max, icon, text } = scoreRanges[range];
    if (score >= min && score < max) {
      return { displayIcon: icon, displayText: literals[text] };
    }
  }
  return { displayIcon: 'excelent', displayText: literals['reviewpro-excellent'] };
}
