import { Injectable } from '@angular/core';

import { API_KEY_DRUPAL } from '@services/http-utils/apis-url';
import { HttpUtils } from '@services/http-utils/http-utils';
import { ResponseRaw } from '@shared/logic/models/common.model';
import { LandingCollectivesRaw } from '@shared/store/landing/model-raw/collectives.model.raw';
import { LandingCommercialRaw } from '@shared/store/landing/model-raw/commercial.model.raw';
import { LandingDestinySeoRaw } from '@shared/store/landing/model-raw/destiny-seo.model.raw';
import { LandingRewardsRaw, RewardsTableRaw } from '@shared/store/landing/model-raw/rewards.model.raw';
import { Observable } from 'rxjs';

export interface LandingTypeResponseRaw<T> {
  type: string;
  data: T;
}
export interface LandingTypeError {
  errors: {
    title: string;
    detail: string;
  };
}

export type LandingRaw = LandingTypeResponseRaw<LandingDestinySeoRaw | LandingCommercialRaw | LandingRewardsRaw | LandingCollectivesRaw> | LandingTypeError;

@Injectable({
  providedIn: 'root'
})
export class LandingApiService {
  constructor(private readonly httpUtils: HttpUtils) {}

  public getLandingById$(id: string): Observable<LandingRaw> {
    const base64Id = btoa(id);
    return this.httpUtils.get<LandingRaw>(API_KEY_DRUPAL.GET_LANDING, { id: base64Id });
  }

  public getLandingRewardsTable$(): Observable<ResponseRaw<RewardsTableRaw[]>> {
    return this.httpUtils.get<ResponseRaw<RewardsTableRaw[]>>(API_KEY_DRUPAL.GET_LANDING_REWARDS_TABLE);
  }
}
