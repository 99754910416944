import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ButtonComponent } from '@atom/button/button.component';

@Component({
  selector: 'app-slider-arrow',
  templateUrl: './slider-arrow.component.html',
  styleUrls: ['./slider-arrow.component.scss'],
  standalone: true,
  imports: [NgClass, ButtonComponent]
})
export class SliderArrowComponent implements OnInit {
  @Output() prevFunction: EventEmitter<any> = new EventEmitter();
  @Output() nextFunction: EventEmitter<any> = new EventEmitter();
  @Input() dark = false;
  @Input() isPrevArrowHidden = false;
  @Input() isNextArrowHidden = false;

  constructor() {
    //todo
  }

  ngOnInit() {
    //todo
  }
  prevOnClick() {
    this.prevFunction.emit();
  }
  nextOnClick() {
    this.nextFunction.emit();
  }
}
