import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './auth.reducer';

const getAuthState = createFeatureSelector<State>('auth');

export const AuthSelectors = {
  getAuthStatus: createSelector(getAuthState, (state: State) => state.isLoggedIn),
  getAuthUser: createSelector(getAuthState, (state: State) => state.user),
  getAuthUserId: createSelector(getAuthState, (state: State) => state.user?.id),
  getAuthProviderData: createSelector(getAuthState, (state: State) => state.providerData)
};
