import packageJson from '../../package.json';

export const environment = {
  appVersion: packageJson.version,
  environmentType: 'prod',
  production: true,
  useMocks: false,
  baseUrl: 'https://www.cataloniahotels.com',
  drupal: 'https://contenidos.cataloniahotels.com',
  symfony: 'https://apiweb.cataloniahotels.com',
  token: 'https://apicomun.cataloniahotels.com',
  symfonyreader: 'dXNlckBlbWFpbC5jb206RDUzX3k2MjA3RUQ1',
  identityProviderApiKey: 'AIzaSyCoOfiO-7Jjr51sdbrIKH3ktZNQR-PMNpI',
  identityProviderAuthDomain: 'prj-p-web.firebaseapp.com',
  identityUrl: 'https://identity.cataloniahotels.com',
  oneTrustTenantId: '018ebd3d-5282-7784-8713-d95dbf005386',
  sessionExpiration: 1 * 60 * 60 * 1000,
  googleTagManagerId: 'GTM-WDWPKKNK',
  emarsysTenantId: '1E605BFB47EF4ABA',
  homeId: '1675445',
  recaptcha: {
    siteKey: '6LceALkpAAAAAAQzEHb3ufXI5ArCyMuK7EQisqBc'
  },
  mapsApiKey: 'AIzaSyCvFMSkx3bX53pdyS_skAikDfizp2nxD38',
  newRelic: {
    loader_config: {
      accountID: '4475680',
      trustKey: '4475680',
      agentID: '538626459',
      licenseKey: 'NRJS-36680614642a070625e',
      applicationID: '538626459'
    },
    info: {
      beacon: 'bam.eu01.nr-data.net',
      errorBeacon: 'bam.eu01.nr-data.net',
      licenseKey: 'NRJS-36680614642a070625e',
      applicationID: '538626459',
      sa: 1
    }
  }
};
