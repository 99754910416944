import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthStoreAdapter } from '@app/shared/store/auth/auth.store.adapter';
import { SkipLoading } from '@shared/logic/http/interceptors/loader.interceptor';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { APIS_URL, ApiUrl } from './apis-url';
import { LanguageDataService } from '../languages/language-data.service';

type ResponseType = 'arraybuffer' | 'text' | 'blob' | 'json';
type RequestOptions = Record<string, any>;

// TODO: This http overlord needs to be reworked

@Injectable()
export class HttpUtils {
  constructor(public http: HttpClient, private languageDataService: LanguageDataService, private readonly _authStoreAdapter: AuthStoreAdapter) {}

  public getWithCustomResponse<T>(keyApi: string, params = {}, responseType: ResponseType, options: RequestOptions = {}): Observable<T> {
    const api: ApiUrl = this._getApi(keyApi);
    const apiKey = this._getApiMatched(api.path, params);
    const context = this._buildContext(options.context);
    // @ts-ignore
    return this.http.get<T>(api.server + apiKey, { responseType, context });
  }

  public get<T>(keyApi: string, params = {}, options: RequestOptions = {}): Observable<T> {
    const api: ApiUrl = this._getApi(keyApi);
    const apiKey = this._getApiMatched(api.path, params);
    const context = this._buildContext(options.context);
    return this.http.get<T>(api.server + apiKey, { ...options, context });
    // return this.http.get<T>(api.server + apiKey, { ...options, context }).pipe(shareReplay(1));
  }

  public post<T>(keyApi: string, params = {}, body = {}, options: RequestOptions = {}): Observable<T> {
    const api: ApiUrl = this._getApi(keyApi);
    const apiKey = this._getApiMatched(api.path, params);
    const context = this._buildContext(options.context);
    return this.http.post<T>(api.server + apiKey, body, { ...options, context });
  }

  public put<T>(keyApi: string, params = {}, body = {}, options: RequestOptions = {}): Observable<T> {
    const api: ApiUrl = this._getApi(keyApi);
    const apiKey = this._getApiMatched(api.path, params);
    const context = this._buildContext(options.context);
    return this.http.put<T>(api.server + apiKey, body, { ...options, context });
  }

  public delete<T>(keyApi: string, params = {}, options: RequestOptions = {}): Observable<T> {
    const api: ApiUrl = this._getApi(keyApi);
    const apiKey = this._getApiMatched(api.path, params);
    const context = this._buildContext(options.context);
    return this.http.delete<T>(api.server + apiKey, { ...options, context });
  }

  private _getApi(keyApi: string): ApiUrl {
    for (const [key, value] of Object.entries(APIS_URL)) {
      if (keyApi === key) {
        return value;
      }
    }
    return { path: keyApi, server: environment.drupal };
  }

  private _getApiMatched(url: string, params: any = {}): string {
    let roomStayCandidatesQueryString = '';
    const gipToken = this._authStoreAdapter.selectAuthUserSig()?.gipToken;

    Object.keys(params).forEach((key) => {
      if (key.startsWith('hotel_avail_dto_r[RoomStayCandidates]')) {
        roomStayCandidatesQueryString += `&${key}=${params[key]}`;
      } else {
        if (url.includes(`{${key}}`)) {
          url = url.replace(`{${key}}`, params[key]);
        }
      }
    });

    if (roomStayCandidatesQueryString) {
      url += roomStayCandidatesQueryString;
    }

    const lang = this.languageDataService.getLang();
    url = url.replace('{roomStayCandidates}', '');
    url = url.replace(/&&/g, '&');
    url = url.replace('{lang}', lang === 'pt' ? 'pt-pt' : lang);
    url = url.replace('{idToken}', gipToken ? gipToken : '');
    return url;
  }

  private _buildContext(apiContext: any): HttpContext {
    if (!apiContext) {
      return new HttpContext();
    }
    const { skipLoading } = apiContext;
    return new HttpContext().set(SkipLoading, !!skipLoading);
  }
}
