import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { setAuthProviderData, setAuthStatus, setAuthUser, setCurrencyPreferred, setTravelCashUSD } from './auth.actions';
import { AuthProviderData, User } from './auth.model';
import { AuthSelectors } from './auth.selectors';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class AuthStoreAdapter {
  constructor(private store: Store<State>) {}

  public setAuthStatus(isLoggedIn: boolean): void {
    this.store.dispatch(setAuthStatus({ isLoggedIn }));
  }

  public setAuthUser(user: User | null): void {
    this.store.dispatch(setAuthUser({ user }));
  }

  public setCurrencyPreferred(currency: string): void {
    this.store.dispatch(setCurrencyPreferred({ currency }));
  }

  public setTravelCashUSD(travelCashUSD: number | undefined): void {
    this.store.dispatch(setTravelCashUSD({ travelCashUSD }));
  }

  public setAuthProviderData(providerData: AuthProviderData | null): void {
    this.store.dispatch(setAuthProviderData({ providerData }));
  }

  public readonly selectAuthStatus$ = (): Observable<boolean> => this.store.select(AuthSelectors.getAuthStatus);

  public readonly selectAuthUser$ = (): Observable<User | null> => this.store.select(AuthSelectors.getAuthUser);

  public readonly selectAuthUserSig = this.store.selectSignal(AuthSelectors.getAuthUser);

  public readonly selectAuthStatusSig = this.store.selectSignal(AuthSelectors.getAuthStatus);

  public readonly selectAuthUserIdSig = this.store.selectSignal(AuthSelectors.getAuthUserId);

  public readonly selectAuthProviderData = this.store.selectSignal(AuthSelectors.getAuthProviderData);
}
