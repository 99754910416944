import { isPlatformBrowser } from '@angular/common';
import { Directive, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';

import { DeviceService } from '@services/device-detection/device-detection.service';
import { WindowRefService } from '@shared/logic/services/window-ref.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  standalone: true,
  selector: '[directiveDeviceDetector]'
})
export class DeviceDetectorDirective implements OnInit, OnDestroy {
  private _resizeSubscription!: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private _deviceService: DeviceService, private _windowRefService: WindowRefService) {}

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._detectDevice();
      this._deviceSuscription();
    }
  }

  public ngOnDestroy(): void {
    if (this._resizeSubscription) {
      this._resizeSubscription.unsubscribe();
    }
  }

  private _deviceSuscription(): void {
    if (this._windowRefService.nativeWindow) {
      this._resizeSubscription = fromEvent(this._windowRefService.nativeWindow, 'resize')
        .pipe(debounceTime(100))
        .subscribe(() => this._detectDevice());
    }
  }

  private _detectDevice(): void {
    if (this._windowRefService.nativeWindow) {
      const width = this._windowRefService.nativeWindow.innerWidth;
      if (width < 768) {
        // assuming devices with width less than 768 are mobile devices
        this._deviceService.updateDevice('mobile');
      } else {
        this._deviceService.updateDevice('desktop');
      }
    }
  }
}
