import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AppConfig } from '@app/app.config';
import { RoutingService } from '@app/core/routing/routing.service';
import { LanguageDataService } from '@app/core/services/languages/language-data.service';

export const languageGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const language = route.paramMap.get('language');
  const languages = AppConfig.config.language.all;
  const router = inject(Router);
  const routingService = inject(RoutingService);
  const langService = inject(LanguageDataService);

  const langMatched = !!(language && languages.includes(language));

  if (!langMatched) {
    langService.setLang(AppConfig.config.language.default);
    return router.createUrlTree(routingService._localizePaths(RoutingService.HOME_URL()));
  } else {
    langService.setLang(language);
    return true;
  }
};
