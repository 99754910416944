import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-icon-item',
  templateUrl: './icon-item.component.html',
  styleUrls: ['./icon-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
  host: {
    class: 'app-icon'
  }
})
export class IconItemComponent {
  @Input()
  name = '';

  @Input()
  icon: string | undefined | null = '';

  @Input()
  clear = false;

  @Input()
  type = 'cds-icon-item';
}
