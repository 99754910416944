import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoutingPipe } from '@app/core/routing/routing.pipe';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

@Component({
  standalone: true,
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, RoutingPipe, IconItemComponent]
})
export class LinkComponent {
  @Input() singin = false;
  @Input() label!: string | null;
  @Input() variant: 'cds-link--primary' | 'cds-link--secondary' = 'cds-link--primary';
  @Input() fontType: 'cds-link--f-s' | 'cds-link--f-xs' | 'cds-link--f-xxs' | undefined;
  @Input() clear = false;
  // @Input() highlight = false;
  @Input() href = '';
  @Input() icon = false;
  @Input() iconName = 'adult';
  @Input() disabled = false;
  @Input() reverse = false;
  // SIN REDIRECCIÓN
  @Input() internal = false;

  @Input() localizeRouting: boolean = false;

  public get classes(): string[] {
    const revIcon = this.reverse ? 'reverse-icon' : '';
    const atr = this.disabled ? 'disabled' : '';
    const icon = this.icon ? 'cds-link--w-icon' : '';
    const singin = this.singin ? 'cds-link--singin' : '';
    const clear = this.clear ? 'cds-link--clear' : '';
    // const highlight = this.highlight ? 'cds-link--highlight' : '';
    return ['cds-link', icon, atr, revIcon, singin, clear, `${this.variant}`, `${this.fontType}`];
  }
}
