import { UIActions, UIActionsTypes } from '@shared/store/ui/ui.actions';

import { ScrollConfig } from './ui.model';

export interface State {
  scrollConfig: ScrollConfig | null;
  headerLoginDisplayStatus: boolean;
  isRewardsActive: boolean;
  headerLoginWithEmailDisplayStatus: boolean;
  headerResetPasswordDisplayStatus: boolean;
  headerCreateAccountDisplayStatus: boolean;
}

const initialState: State = {
  scrollConfig: null,
  headerLoginDisplayStatus: false,
  isRewardsActive: true,
  headerLoginWithEmailDisplayStatus: false,
  headerResetPasswordDisplayStatus: false,
  headerCreateAccountDisplayStatus: false
};

export function reducer(state = initialState, action: UIActions): State {
  switch (action.type) {
    case UIActionsTypes.SetScrollConfig:
      return { ...state, scrollConfig: action.payload.scrollConfig };
    case UIActionsTypes.SetHeaderLoginDisplayStatus:
      return { ...state, headerLoginDisplayStatus: action.payload.status };
    case UIActionsTypes.ToggleRewardsActiveStatus:
      return { ...state, isRewardsActive: !state.isRewardsActive };
    case UIActionsTypes.SetRewardsToggleStatus:
      return { ...state, isRewardsActive: action.payload.isActive };
    case UIActionsTypes.SetHeaderLoginWithEmailDisplayStatus:
      return { ...state, headerLoginWithEmailDisplayStatus: action.payload.status };
    case UIActionsTypes.SetHeaderResetPasswordDisplayStatus:
      return { ...state, headerResetPasswordDisplayStatus: action.payload.status };
    case UIActionsTypes.SetHeaderCreateAccountDisplayStatus:
      return { ...state, headerCreateAccountDisplayStatus: action.payload.status };
    default:
      return state;
  }
}
