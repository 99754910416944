import { CurrencyType } from '@app/shared/logic/models/common.model';
import { Info, Membership, User, UserAddress } from '@shared/store/auth/auth.model';
import { MembershipRaw, UserRaw } from '@shared/store/auth/auth.model.raw';

const membershipMap: { [key: string]: Membership } = {
  GREAT: Membership.Great,
  CIRCLE: Membership.Circle,
  STAR: Membership.Star,
  TOP: Membership.Top
};
const membershipAsNumberValues: { [key in Membership]: number } = {
  [Membership.Standard]: 1,
  [Membership.Great]: 1,
  [Membership.Circle]: 2,
  [Membership.Star]: 3,
  [Membership.Top]: 4
};
const membershipAvatarMap: { [key in Membership]: string } = {
  [Membership.Standard]: 'great.svg',
  [Membership.Great]: 'great.svg',
  [Membership.Circle]: 'circle.svg',
  [Membership.Star]: 'star.svg',
  [Membership.Top]: 'top.svg'
};
const BASE_IMAGE_PATH = '/assets/img/rewards/reward-';

export class AuthNormalizer {
  public static normalize(userRaw: UserRaw, gipToken: string): User {
    const membership = AuthNormalizer.getMemberShip(userRaw.ZNivelMember_KUT);
    const membershipNextLevel = AuthNormalizer.getMemberShip(userRaw.ZProximoniveladadehoy_KUT);

    return {
      id: userRaw?.ZIDLoyalty_KUT,
      membership,
      membershipNextLevel,
      membershipAsNumber: membershipAsNumberValues[membership],
      gipToken,
      address: AuthNormalizer.normalizeAddress(userRaw),
      countryInfo: AuthNormalizer.getInfo<string>(userRaw.CountryCode, userRaw.CountryCodeText),
      citizenshipInfo: AuthNormalizer.getInfo<string>(userRaw.NationalityCountryCode, userRaw.NationalityCountryCodeText),
      languageInfo: AuthNormalizer.getInfo<string>(userRaw.LanguageCode, userRaw.LanguageCodeText),
      avatar: AuthNormalizer.getAvatarImage(membership),
      phoneInfo: { countryCode: userRaw.phonePrefix, phoneNumber: userRaw.phoneNumber },
      email: userRaw.Email,
      birthdate: userRaw.BirthDate ? new Date(userRaw.BirthDate) : new Date(1970, 1, 1),
      firstName: userRaw.FirstName,
      lastName: userRaw.LastName,
      fullName: userRaw.FormattedName,
      genreInfo: AuthNormalizer.getInfo<number>(Number(userRaw.GenderCode), userRaw.GenderCodeText),
      nights: Number(userRaw.ZNoches_KUT),
      nightsToLevelUp: Number(userRaw.ZNochesparaproximocambiodenivel_KUT),
      travelCashToExpire: Number(userRaw.TravelCashprximoacaducar_KUT),
      travelCashEUR: Number(userRaw.ZTravelCashmiembro_KUT),
      travelCashUSD: undefined,
      preferredCurrency: userRaw.ZCurrencypreferente_KUT as CurrencyType, // TODO Check response!!
      userWantsNewsletter: userRaw.ZOptin_KUT,
      createdAt: userRaw.ZTimeStamp_KUT ? new Date(userRaw.ZTimeStamp_KUT) : new Date(1970, 1, 1),
      emailToken: userRaw.emailToken
    };
  }

  private static getMemberShip(membershipRaw: MembershipRaw): Membership {
    return membershipMap[membershipRaw.toUpperCase()] || Membership.Great;
  }

  private static normalizeAddress(userRaw: UserRaw): UserAddress {
    return {
      addressLineOne: userRaw.AddressLine1,
      addressLineTwo: userRaw.AddressLine2,
      city: userRaw.City,
      district: userRaw.District,
      floor: userRaw.Floor,
      number: userRaw.HouseNumber,
      room: userRaw.Room,
      street: userRaw.Street,
      zipCode: userRaw.StreetPostalCode
    };
  }

  private static getAvatarImage(membership: Membership): string {
    return `${BASE_IMAGE_PATH}${membershipAvatarMap[membership]}`;
  }

  private static getInfo<T>(code: T, name: string = 'Unknown'): Info<T> {
    return { code, name };
  }
}
