import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { SafeHtmlDirectiveModule } from '@directives/safe-html/safe-html.directive.module';

import { PointListComponent } from './point-list.component';

@NgModule({
  imports: [SharedModule, SafeHtmlDirectiveModule],
  declarations: [PointListComponent],
  exports: [PointListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PointListModule {}
