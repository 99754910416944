import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router, UrlCreationOptions } from '@angular/router';

import { LanguageDataService } from '@core/services/languages/language-data.service';

import { environment } from '@env/environment.prod';

import { contactUs, RoutingPaths } from './routing.const';

export type Qs = UrlCreationOptions['queryParams'];

@Injectable({ providedIn: 'root' })
export class RoutingService {
  constructor(private readonly _router: Router, private readonly _languageService: LanguageDataService, private activatedRoute: ActivatedRoute) {}

  getUrlProduction() {
    return environment.baseUrl;
  }

  getRouter() {
    return this._router;
  }

  getUrl() {
    return this._router.url.replace(`/${this._languageService.getLang()}/`, '');
  }

  getActivatedRoute() {
    return this.activatedRoute;
  }

  addQueryParams(qs: Qs, handling?: QueryParamsHandling) {
    return this._router.navigate([], { queryParams: qs, skipLocationChange: true, queryParamsHandling: handling });
  }

  removeQueryParams(qs: Qs) {
    return this._router.navigate([], { queryParams: qs, queryParamsHandling: 'merge' });
  }

  navigateByUrl(url: string) {
    return this._router.navigateByUrl(this._localizePath(url));
  }

  href(url: string) {
    window.location.href = url;
  }

  public get isPayBylinkPage(): boolean {
    return this.getUrl().includes(RoutingPaths.PayByLink);
  }

  public _localizePaths(paths: string[]) {
    return ['/', this._languageService.getLang(), ...paths].filter(Boolean);
  }

  public _localizePath(path: string): string {
    return this._sanitizePath(`/${this._languageService.getLang()}/${path}`);
  }

  public getSnapshotQueryParam(key: string): string | null {
    return this.activatedRoute.snapshot.queryParams?.[key] ?? null;
  }

  private _sanitizePath(path: string): string {
    return path.replace(/\/\//g, '/').replace(/\/$/, '');
  }

  static GO_BLANK = (url: string) => window.open(url, '_blank');
  GO_BLANK = (url: string) => window.open(url, '_blank');

  static HOME_URL = (): string[] => [];

  GO_HOME(qs?: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.HOME_URL()), { queryParams: qs });
  }

  static REGISTER_URL = () => [RoutingPaths.Register];

  GO_REGISTER() {
    return this._router.navigate(this._localizePaths(RoutingService.REGISTER_URL()));
  }

  static LOGIN_URL = () => [RoutingPaths.Login];

  GO_LOGIN() {
    return this._router.navigate(this._localizePaths(RoutingService.LOGIN_URL()));
  }

  static BOOKING_MANAGEMENT_URL = () => [RoutingPaths.BookingManagement];

  GO_BOOKING_MANAGEMENT(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.BOOKING_MANAGEMENT_URL()), { queryParams: qs });
  }

  static BOOKING_CANCELLATION_URL = () => [RoutingPaths.BookingCancellation];

  GO_BOOKING_CANCELLATION() {
    return this._router.navigate(this._localizePaths(RoutingService.BOOKING_CANCELLATION_URL()));
  }

  static BOOKING_CONFIRMATION_URL = () => [RoutingPaths.BookingConfirmation];

  GO_BOOKING_CONFIRMATION(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.BOOKING_CONFIRMATION_URL()), { queryParams: qs });
  }

  static CHECKOUT_COMPLETE_DATA_URL = () => [RoutingPaths.Checkout, RoutingPaths.CompleteYourData];

  GO_CHECKOUT_COMPLETE_DATA(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.CHECKOUT_COMPLETE_DATA_URL()), { queryParams: qs });
  }

  static CHECKOUT_PAY_BY_LINK_URL = () => [RoutingPaths.Checkout, RoutingPaths.PayByLink];

  GO_CHECKOUT_PAY_BY_LINK(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.CHECKOUT_PAY_BY_LINK_URL()), { queryParams: qs });
  }

  static CHECKOUT_REGIME_SELECTION_URL = () => [RoutingPaths.Checkout, RoutingPaths.RegimeSelection];

  GO_CHECKOUT_REGIME_SELECTION(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.CHECKOUT_REGIME_SELECTION_URL()), {
      queryParams: qs
    });
  }

  static CHECKOUT_BREADCRUMB_URL = (path: string) => [RoutingPaths.Checkout, path];

  GO_CHECKOUT_BREADCRUMB(path: string, qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.CHECKOUT_BREADCRUMB_URL(path)), {
      queryParams: qs
    });
  }

  static SEARCH_URL = () => [RoutingPaths.Search];

  GO_SEARCH(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.SEARCH_URL()), { queryParams: qs });
  }

  static SEARCH_MULTIROOM_URL = () => [RoutingPaths.SearchMultiRoom];

  GO_SEARCH_MULTIROOM(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.SEARCH_MULTIROOM_URL()), { queryParams: qs });
  }

  static MY_BOOKINGS_URL = () => [RoutingPaths.PrivateArea, RoutingPaths.MyBookings];

  GO_MY_BOOKINGS(qs?: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.MY_BOOKINGS_URL()), { queryParams: qs });
  }

  static MY_ACCOUNT_URL = () => [RoutingPaths.PrivateArea, RoutingPaths.MyAccount];

  GO_MY_ACCOUNT() {
    return this._router.navigate(this._localizePaths(RoutingService.MY_ACCOUNT_URL()));
  }

  static MY_TRAVEL_CASH_URL = () => [RoutingPaths.PrivateArea, RoutingPaths.MyTravelCash];

  GO_MY_TRAVEL_CASH() {
    return this._router.navigate(this._localizePaths(RoutingService.MY_TRAVEL_CASH_URL()));
  }

  static LANDING_URL = (path: string) => [path];

  GO_LANDING(path: string) {
    return this._router.navigate(this._localizePaths(RoutingService.LANDING_URL(path)));
  }

  static CONTACT_URL = (lang: string) => [contactUs[lang]];

  GO_CONTACT(qs: Qs) {
    return this._router.navigate(this._localizePaths(RoutingService.CONTACT_URL(this._languageService.getLang())), { queryParams: qs });
  }

  static LANDING_HOTEL_URL = () => [RoutingPaths.Hotel];

  GO_LANDING_HOTEL(hotelName: string, qs?: Qs) {
    return this._router.navigate(this._localizePaths([RoutingPaths.Hotel, hotelName]), { queryParams: qs });
  }

  GO_MAINTENANCE(msg: string) {
    return this._router.navigate(this._localizePaths([RoutingPaths.Maintenance]), { state: { msg } });
  }

  SERIALIZE_URL(path: RoutingPaths | string, qs: Qs): string {
    const urlTree = this._router.createUrlTree(['/', this._languageService.getLang(), path], {
      queryParams: qs
    });
    return this._router.serializeUrl(urlTree);
  }
}
