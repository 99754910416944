import { GaActions, SearchActionsTypes } from '@shared/store/ga/ga.actions';
import { FirstSearchType, PrevContentInfo } from '@shared/store/ga/ga.model';

export interface State {
  prevContentInfo: PrevContentInfo;
  isFirstSearchDone: boolean;
  firstSearchType: FirstSearchType | null;
}

const initialState: State = {
  prevContentInfo: {
    prevContentGroup: null,
    prevContentType: null
  },
  isFirstSearchDone: false,
  firstSearchType: null
};

export function reducer(state = initialState, action: GaActions): State {
  switch (action.type) {
    case SearchActionsTypes.SetPrevContentInfo:
      return { ...state, prevContentInfo: action.payload.prevContentInfo };
    case SearchActionsTypes.SetFirstSearchDoneStatus:
      return { ...state, isFirstSearchDone: action.payload.status };
    case SearchActionsTypes.SetFirstSearchType:
      return { ...state, firstSearchType: action.payload.type };
    default:
      return state;
  }
}
