import { Injectable } from '@angular/core';

import { CardTokenizedResponseRaw } from '@app/shared/ns_components/datatrans-widget/secure-fields.model';

import { API_KEY_SYMFONY } from '@services/http-utils/apis-url';
import { HttpUtils } from '@services/http-utils/http-utils';
import { GetUserCardsPaymentResponseRaw, UserCardPaymentRaw } from '@shared/store/payment/payment.model.raw';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentApiService {
  constructor(private httpUtils: HttpUtils) {}

  public getCardsByUserId$(idMember: string, userToken: string): Observable<GetUserCardsPaymentResponseRaw> {
    return this.httpUtils.post<GetUserCardsPaymentResponseRaw>(API_KEY_SYMFONY.GET_USER_CARDS, {}, { idMember: idMember, token: userToken });
  }

  public updateUserCards$(idMember: string, userCards: UserCardPaymentRaw[], userToken: string): Observable<any> {
    return this.httpUtils.post<any>(API_KEY_SYMFONY.POST_USER_CARDS, {}, { idMember, cards: JSON.stringify(userCards), token: userToken });
  }

  public getCardTokenized$(transactionId: string): Observable<CardTokenizedResponseRaw> {
    return this.httpUtils.get<CardTokenizedResponseRaw>(API_KEY_SYMFONY.GET_TRANSACTION_PAYMENT, { transactionId });
  }
}
