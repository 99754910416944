import packageJson from '../../package.json';

export const environment = {
  appVersion: packageJson.version,
  environmentType: 'qa',
  production: false,
  useMocks: false,
  baseUrl: 'https://www.cataloniahotels.com',
  drupal: 'https://contenidos-qa.cataloniahotels.com',
  symfony: 'https://apiweb-qa.cataloniahotels.com',
  token: 'https://apicomun-qa.cataloniahotels.com',
  symfonyreader: 'dXNlckBlbWFpbC5jb206cGFzc3dvcmQxMjM=',
  identityProviderApiKey: 'AIzaSyB-B0_aI6dRA8lTsamx7J3Zvs9VkgpKYhg',
  identityProviderAuthDomain: 'prj-n-qa-web-env.firebaseapp.com',
  identityUrl: 'https://identity-qa.cataloniahotels.com',
  oneTrustTenantId: '018ebd3d-5282-7784-8713-d95dbf005386-test',
  sessionExpiration: 1 * 60 * 60 * 1000,
  googleTagManagerId: 'GTM-54J7LLCD',
  emarsysTenantId: '143065BFFCDCE0C0',
  homeId: '1675445',
  recaptcha: {
    siteKey: '6LceALkpAAAAAAQzEHb3ufXI5ArCyMuK7EQisqBc'
  },
  mapsApiKey: 'AIzaSyCvFMSkx3bX53pdyS_skAikDfizp2nxD38',
  newRelic: {
    loader_config: {
      accountID: '4475680',
      trustKey: '4475680',
      agentID: '538626460',
      licenseKey: 'NRJS-36680614642a070625e',
      applicationID: '538626460'
    },
    info: {
      beacon: 'bam.eu01.nr-data.net',
      errorBeacon: 'bam.eu01.nr-data.net',
      licenseKey: 'NRJS-36680614642a070625e',
      applicationID: '538626460',
      sa: 1
    }
  }
};
