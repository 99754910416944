import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Directive({
  selector: '[directiveSafeHTML]'
})
export class SafeHtmlDirective implements OnInit {
  private _valueReceivedToString = '';

  @Input()
  set directiveSafeHTML(value: SafeHtml) {
    if (value) {
      this._valueReceivedToString = value.toString();
      this._updateContent();
    }
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this._updateContent();
  }

  private _updateContent(): void {
    if (this._valueReceivedToString) {
      this.elementRef.nativeElement.innerHTML = this._valueReceivedToString;
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }
}
