import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconItemComponent } from '@atom/icon-item/icon-item.component';

@Component({
  standalone: true,
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconItemComponent, NgIf],
  host: {
    class: 'cds-list-item'
  }
})
export class ItemListComponent {
  @Input() icon?: string = '';
  @Input() extraContent = '';
  @Input() ishighlighted = false;
}
