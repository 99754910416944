import { Injectable } from '@angular/core';

import { Buffer } from 'buffer';

import { StorageService } from '@app/core/storage/storage.service';
import { environment } from '@env/environment';
import { Observable, of, map } from 'rxjs';

import { HttpUtils } from '../http-utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export class SymfonyService {
  constructor(private httpUtils: HttpUtils, private storage: StorageService) {}
  public getTokenSymfony(forceRefresh = false): Observable<string> {
    if (!forceRefresh) {
      const token = this.storage.getItem('bearer_token');
      if (token) {
        return of(token);
      }
    }

    const creds = Buffer.from(environment.symfonyreader, 'base64').toString('binary');
    const [username, password] = creds.split(':');
    const credentials = { username, password };
    return this.httpUtils.post<{ token: string }>('bearerToken', {}, credentials).pipe(
      map((res) => {
        this.storage.setItem('bearer_token', res.token);
        return res.token;
      })
    );
  }
}
