import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { StorageService } from '@shared/logic/services/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  public set(key: string, value: any): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  public get<T>(key: string): T {
    if (isPlatformServer(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return;
    }
    const storedValue = localStorage.getItem(key);
    let value = null;
    if (storedValue) {
      value = JSON.parse(storedValue).value;
    }
    return value;
  }

  public remove(key: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    localStorage.removeItem(key);
  }

  public clear(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    localStorage.clear();
  }
}
