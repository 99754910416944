import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID, StateKey, TransferState } from '@angular/core';

import { Observable, of, tap } from 'rxjs';

export abstract class TransferStateService {
  protected constructor(@Inject(PLATFORM_ID) private platformId: string, protected transferState: TransferState) {}

  protected useTransferState<T>(key: StateKey<T>, defaultValue: T, cb: () => Observable<T>): Observable<T> {
    if (isPlatformBrowser(this.platformId) && this.transferState.hasKey(key)) {
      const response = this.transferState.get(key, defaultValue);
      this.transferState.remove(key);
      return of(response);
    }

    return cb().pipe(
      tap((response) => {
        if (!isPlatformBrowser(this.platformId) && !this.transferState.hasKey(key)) {
          this.transferState.set(key, response);
        }
      })
    );
  }
}
