<ng-container *ngIf="!internal && label !== ''">
  <ng-container *ngIf="localizeRouting; else noLocalize">
    <a [href]="href | routing" [ngClass]="classes" target="_self">
      <app-icon-item class="icon-item" [icon]="iconName"></app-icon-item>
      {{ label }}
    </a>
  </ng-container>
  <ng-template #noLocalize>
    <a [href]="href" [ngClass]="classes" target="_self">
      <app-icon-item class="icon-item" [icon]="iconName"></app-icon-item>
      {{ label }}
    </a>
  </ng-template>
</ng-container>
<ng-container *ngIf="internal && label !== ''">
  <a [ngClass]="classes" target="_self">
    <app-icon-item class="icon-item" [icon]="iconName"></app-icon-item>
    {{ label }}
  </a>
</ng-container>
