import { CheckoutActions, CheckoutActionsTypes } from './checkout.actions';
import { Breadcrumb } from './checkout.model';

export interface State {
  breadcrumbs: Breadcrumb[];
  activeBreadcrumb: string | null;
  isShoppingCartExpanded: boolean;
}

const initialState: State = {
  breadcrumbs: [],
  activeBreadcrumb: null,
  isShoppingCartExpanded: false
};

export function reducer(state = initialState, action: CheckoutActions): State {
  switch (action.type) {
    case CheckoutActionsTypes.SetBreadcrumbs:
      return { ...state, breadcrumbs: action.payload.breadcrumbs };
    case CheckoutActionsTypes.SetActiveBreadcrumb:
      return { ...state, activeBreadcrumb: action.payload!.activeBreadcrumb };
    case CheckoutActionsTypes.ToggleShoppingCartExpandedStatus:
      return { ...state, isShoppingCartExpanded: !state.isShoppingCartExpanded };
    default:
      return state;
  }
}
