import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { setMerchantId, setUserCards } from './payment.actions';
import { UserCardPayment } from './payment.model';
import { PaymentSelectors } from './payment.selectors';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class PaymentStoreAdapter {
  constructor(private store: Store<State>) {}

  public setUserCards(userCards: UserCardPayment[]): void {
    this.store.dispatch(setUserCards({ userCards }));
  }

  public setMerchantId(merchantId: string): void {
    this.store.dispatch(setMerchantId({ merchantId }));
  }

  public readonly selectUserCardsSig = this.store.selectSignal(PaymentSelectors.getUserCards);

  public readonly selectUserDefaultCardSig = this.store.selectSignal(PaymentSelectors.getUserDefaultCard);

  public readonly selectUserCardsLoadedSig = this.store.selectSignal(PaymentSelectors.getUserCardsLoaded);

  public readonly selectMerchantIdSig = this.store.selectSignal(PaymentSelectors.getMerchantId);

  public readonly selectUserCardByTokenSig = (token: string) =>
    this.store.selectSignal(PaymentSelectors.getUserCardByToken(token))();
}
