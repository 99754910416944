import { KeyLocalStorage } from '@app/shared/logic/const/key-local-storage.const';
import { LocalStorageService } from '@app/shared/logic/services/storage/local-storage.service';
import { on } from '@ngrx/store';

import { onRehydrateAuthState } from '@shared/store/auth/auth.rehydrate';

import { setAuthProviderData, setAuthStatus, setAuthUser, setCurrencyPreferred, setTravelCashUSD } from './auth.actions';
import { AuthProviderData, User } from './auth.model';
import { createRehydrateReducer } from '../rehydrate.reducer';

export interface State {
  isLoggedIn: boolean;
  user: User | null;
  providerData: AuthProviderData | null;
}

const initialState: State = {
  isLoggedIn: false,
  user: null,
  providerData: null
};

const CONFIG = {
  hooks: {
    onRehydrate: onRehydrateAuthState
  }
};

export const reducer = (storage: LocalStorageService) =>
  createRehydrateReducer<State>(storage, CONFIG)(
    KeyLocalStorage.Auth,
    initialState,
    on(setAuthStatus, (state, action) => ({
      ...state,
      isLoggedIn: action.payload.isLoggedIn
    })),
    on(setAuthUser, (state, action) => ({
      ...state,
      user: action.payload.user
    })),
    on(setCurrencyPreferred, (state, action) => ({
      ...state,
      user: { ...state.user, currencyPreferred: action.payload.currency } as User
    })),
    on(setTravelCashUSD, (state, action) => ({
      ...state,
      user: { ...state.user, travelCashUSD: action.payload.travelCashUSD } as User
    })),
    on(setAuthProviderData, (state, action) => ({
      ...state,
      providerData: action.payload.providerData
    }))
  );
