import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer } from '@shared/logic/helpers/common/common.normalizer';
import { Literals, ResponseRaw } from '@shared/logic/models/common.model';
import { HotelAward } from '@shared/ns_components/gdi_extracted/hotel-award/hotel-award.component';
import { FeaturedHotelNormalizer } from '@shared/store/home/featured-hotels/featured-hotel.normalizer';
import { GalleryMosaicItemRaw, HotelRaw } from '@shared/store/hotel/hotel.model.raw';

import { LandingsCommonNormalizer } from '@shared/store/landing/normalizers/landings.common.normalizer';
import { getHotelRatingInfo } from '@shared/utils/valorations/valorations.utils';

import { GalleryMosaicItem, HotelResponse, RatingInfo, Ratings } from './hotel.model';

export class HotelNormalizer {
  static normalizeHotel(itemRaw: ResponseRaw<HotelRaw>, language: string): HotelResponse {
    const item = {} as HotelResponse;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item._original = itemRaw.data; // TODO: Needed to maintain hotel modal
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const literals: Literals = itemRaw.data.literales;

    item.literals = literals;
    item.metatags = itemRaw.data.metatags;
    item.hotelInfo = {
      code: itemRaw.data.backcode, // TODO Eliminar duplicidad hotel_back_code
      name: itemRaw.data.title,
      stars: Number(itemRaw.data.estrellas),
      isAdultsOnly: !!itemRaw.data.adults_only,
      category: itemRaw.data.hotelType, // urbano, vacacional o resort
      isNewHotel: itemRaw.data.newHotel.new,
      phone: itemRaw.data.telefono,
      email: itemRaw.data.email,
      coords: {
        latitude: itemRaw.data.coordenadas.latitud ? Number(itemRaw.data.coordenadas.latitud) : 0,
        longitude: itemRaw.data.coordenadas.longitud ? Number(itemRaw.data.coordenadas.longitud) : 0
      },
      addressComplete: HotelNormalizer.normalizeAddress(itemRaw.data),
      address: {
        street: itemRaw.data.direccion,
        zipCode: itemRaw.data.codigo_postal,
        city: itemRaw.data.ciudad,
        country: itemRaw.data.pais,
        continent: itemRaw.data.continente
      },
      etiquetas_hotel: itemRaw.data.etiquetas_hotel
    };

    item.ratingInfo = HotelNormalizer.normalizeRatingInfo(itemRaw.data);
    item.images = {
      main: itemRaw.data.ficha_imagen,
      map: itemRaw.data.imagen_mapa_hotel
    };
    if (GeneralFunctions.isArrayLike(itemRaw.data.imagenes_asociadas, 1)) {
      item.images.galleryMosaic = HotelNormalizer.normalizeMosaicImages(itemRaw.data.imagenes_asociadas);
    }

    item.policies = CommonNormalizer.normalizeItems(itemRaw.data.info_util);
    item.hotelDestination = itemRaw.data.destino;
    item.hotelDescription = itemRaw.data.descripcion;
    item.hotelDescriptionTwo = itemRaw.data.descripcion_dos;
    item.hotelUrl = itemRaw.data.url_hotel;
    item.hotelTagList = itemRaw.data.etiquetas_hotel;
    item.featuredHotels = FeaturedHotelNormalizer.normalize(
      {
        title: literals.home_highlighted_hotels,
        lista_hoteles: itemRaw.data.hoteles_relacionados
      },
      language
    );

    let inspirationalSentences = null;
    if (itemRaw.data.inspirationalModule && GeneralFunctions.isArrayLike(itemRaw.data.inspirationalModule, 1)) {
      inspirationalSentences = itemRaw.data.inspirationalModule.map((i) => ({
        displayTitle: i.title,
        displayDescription: i.description,
        displayImage: i.image
      }));
    }
    item.inspirationalSentences = inspirationalSentences;

    if (GeneralFunctions.isArrayLike(itemRaw.data?.premios, 1)) {
      item.awardsItems = HotelNormalizer.normalizeAwards(itemRaw.data);
    }

    item.hotelServices = {
      highlighted: CommonNormalizer.normalizeItems(itemRaw.data.servicios_destacados),
      secondary: CommonNormalizer.normalizeItems(itemRaw.data.servicios_ficha_hotel)
    };

    if (itemRaw.data.instalaciones && GeneralFunctions.isArrayLike(itemRaw.data.instalaciones, 1)) {
      item.hotelInstallationCategories = itemRaw.data.instalaciones.map(LandingsCommonNormalizer.normalizeInstallationCategories);
    }

    // Hide only if hotel is new
    if (!item.hotelInfo.isNewHotel) {
      item.mainScore = {
        valueScore: item?.ratingInfo.totalScore,
        valueIconText: item?.ratingInfo.displayText,
        valueIcon: item?.ratingInfo.displayIcon,
        minimal: true
      };
      const aspectScores = HotelNormalizer.normalizeRatingsDynamically(itemRaw.data.info_review_pro.rating);
      item.metrics = Object.keys(aspectScores).map((key) => ({
        id: key,
        typeMetric: literals[`reviewpro-${key}`],
        numberMetric: Math.round(Number(aspectScores[key])),
        compressed: true
      }));
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static normalizeRatingInfo(itemRaw: HotelRaw): RatingInfo {
    const aspectScores = HotelNormalizer.normalizeRatingsDynamically(itemRaw.info_review_pro.rating);
    const { displayIcon, displayText } = getHotelRatingInfo(aspectScores.overall, itemRaw.literales);
    return {
      totalScore: Math.round(aspectScores.overall * 10) / 10,
      displayIcon,
      displayText,
      aspectScores,
      totalReviews: itemRaw.info_review_pro.numReviews,
      reviews: itemRaw.info_review_pro.reviews
    };
  }

  public static normalizeMosaicImages(itemRaw: GalleryMosaicItemRaw[]): GalleryMosaicItem[] {
    return [...itemRaw].sort((a, b) => a.order - b.order);
  }

  public static normalizeAddress(itemRaw: HotelRaw): string {
    const { direccion, codigo_postal, ciudad, pais } = itemRaw;
    return `${direccion} ${codigo_postal} ${ciudad}, ${pais}`;
  }

  public static normalizeCoords({ coordenadas }: HotelRaw): { lat: number; lng: number } {
    return {
      lat: coordenadas?.latitud ? Number(coordenadas.latitud) : 0,
      lng: coordenadas?.longitud ? Number(coordenadas.longitud) : 0
    };
  }

  public static normalizeAwards(itemRaw: HotelRaw): HotelAward[] {
    return itemRaw.premios.map((award) => ({
      tooltipTitle: award.title,
      tooltipDescription: award.description,
      image: award.icon
    }));
  }

  public static normalizeRatingsDynamically(staticRatings: Ratings): Ratings {
    const dynamicRatings: Ratings = {};
    for (const key in staticRatings) {
      dynamicRatings[key.toLowerCase()] = staticRatings[key];
    }
    return dynamicRatings;
  }
}
