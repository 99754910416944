import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer } from '@shared/logic/helpers/common/common.normalizer';
import { HotelInstallation, HotelInstallationCategories } from '@shared/store/hotel/hotel.model';
import {
  HotelSectionTabItemRaw,
  HotelSectionTabRaw,
  LandingInstallationCategoriesRaw,
  LandingInstallationRaw,
  LandingTabHotelRaw
} from '@shared/store/landing/landing-common.model';
import { HotelSectionTab, HotelSectionTabItem } from '@shared/store/landing/model/commercial.model';
import { LandingTabHotel } from '@shared/store/landing/model/destiny-seo.model';

export class LandingsCommonNormalizer {
  public static normalizeInstallationCategories(itemRaw: LandingInstallationCategoriesRaw): HotelInstallationCategories {
    const normalizeInstallation = (landingInstallationRaw: LandingInstallationRaw): HotelInstallation => {
      return {
        displayTitle: landingInstallationRaw.titulo,
        link: CommonNormalizer.normalizeLandingLink(landingInstallationRaw.enlace),
        displayImage: landingInstallationRaw.imagen,
        displayDescription: landingInstallationRaw.texto_descriptivo,
        displayLongDescription: landingInstallationRaw.texto_descriptivo_largo,
        tags: landingInstallationRaw.etiquetas
      };
    };
    return {
      isGrid: itemRaw.visualizacion === 'grid',
      categoryDisplayTitle: itemRaw.categoria,
      installations: itemRaw.instalaciones.map(normalizeInstallation)
    };
  }

  // Back will not send us same response than the one in landings type/seo so we need to make a specific normalizer
  // Used in: Commercial | Collectives
  public static normalizeTab(itemRaw: HotelSectionTabRaw): HotelSectionTab {
    const item = {} as HotelSectionTab;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.displayTabTitle = itemRaw.tabTitle;
    item.isHotelHighlighted = !!itemRaw.highlightHotel;

    if (GeneralFunctions.isArrayLike(itemRaw.sections, 1)) {
      // We need this to differentiate when should display "Todos" on list;
      item.isCombinedHotelSection = !('title' in itemRaw.sections[0]);

      item.sections = itemRaw.sections.map((section) => {
        const _item = {} as HotelSectionTabItem;
        _item.displayTitle = item.isCombinedHotelSection ? null : (section as unknown as HotelSectionTabItemRaw).title;
        if (item.isCombinedHotelSection) {
          _item.hotelList = [LandingsCommonNormalizer.normalizeHotelTab(section as unknown as LandingTabHotelRaw)];
        } else {
          _item.hotelList = (section as unknown as HotelSectionTabItemRaw).hotels.map((hotel) => LandingsCommonNormalizer.normalizeHotelTab(hotel));
        }
        return _item;
      });
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  // Used in: normalizeTab (for Commercial and Collectives) and Destiny-Seo-Type
  public static normalizeHotelTab(itemRaw: LandingTabHotelRaw): LandingTabHotel {
    const item = {} as LandingTabHotel;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.hotelInfo = {
      id: itemRaw.hotel_id,
      code: itemRaw.backcode,
      name: itemRaw.hotel,
      stars: itemRaw.estrellas,
      isAdultsOnly: itemRaw.adults_only,
      category: itemRaw.tipo_hotel, // urbano, vacacional o resort
      isNewHotel: itemRaw.newHotel.new,
      address: {
        street: itemRaw.direccion + ' ' + itemRaw.direccion2,
        city: itemRaw.ciudad,
        country: itemRaw.region
      },
      isVirtual: itemRaw.virtual
    };
    item.images = {
      main: itemRaw.imagen,
      hotelImages: GeneralFunctions.isArrayLike(itemRaw.images, 1) ? itemRaw.images! : []
    };
    item.tags = itemRaw.tags;
    item.hotelDescription = itemRaw.short_description;
    item.hotelDescriptionTwo = itemRaw.long_description;
    item.hotelUrl = itemRaw.url;
    item.currency = itemRaw.currencySymbol;
    item.minimalPrice = itemRaw.minPrice;
    item.ratingInfo = {
      totalScore: Math.round(itemRaw.scores?.value * 10) / 10,
      totalReviews: itemRaw.scores?.numReviews
    };
    item.highlightedServices = CommonNormalizer.normalizeItems(itemRaw.servicios_destacados);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static _sortItemsByCombinedSection(items: HotelSectionTab[]): HotelSectionTab[] {
    return items.sort((a, b) => (a.isCombinedHotelSection === b.isCombinedHotelSection ? 0 : a.isCombinedHotelSection ? -1 : 1));
  }
}
