export const enum RoutingPaths {
  Home = '/',
  Maintenance = 'maintenance',

  // Auth
  Login = 'access',
  PasswordChange = 'change-password',
  Register = 'register',
  SingleUseAccess = 'single-use-access',
  LoginWithOtp = 'login-with-otp',
  ProfileCenter = 'profile-center',
  ConfirmChangeEmail = 'confirm-change-email',

  // Pages
  Search = 'search',
  SearchMultiRoom = 'search-multiroom',
  SearchWithoutDates = 'hotel/:hotelName',
  SearchWithoutDatesControlRoute = 'hotel',

  IPP = 'ipp',
  Hotel = 'hotel',

  // Checkout
  Checkout = 'checkout',
  PayByLink = 'paybylink',
  RegimeSelection = 'regime-selection',
  CompleteYourData = 'complete-your-data',

  // Booking
  BookingConfirmation = 'booking-confirmation',
  BookingManagement = 'booking-management',
  BookingCancellation = 'booking-cancellation',

  // Private Area
  PrivateArea = 'private-area',
  MyAccount = 'my-account',
  MyBookings = 'my-bookings',
  MyTravelCash = 'my-travel-cash',
  NotFound = 'not-found'
}

export const contactUs: Record<string, string> = {
  es: 'contacto',
  ca: 'contacte',
  en: 'contact-us',
  fr: 'contacts',
  de: 'kontakt',
  it: 'contatti',
  pt: 'contato',
  ru: 'contact-us',
  nl: 'contacto'
};
