import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconItemComponent } from '../icon-item/icon-item.component';
import { NgIf } from '@angular/common';

// Pending
// export interface TagType {
//   type: 'cds-tag--basic' | 'cds-tag--promo' | 'cds-tag--alert' | 'cds-tag--killer';
// }

@Component({
  standalone: true,
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, IconItemComponent]
})
export class TagComponent {
  @Input() type = 'cds-tag--basic';
  @Input() size: 'cds-tag--l' | 'cds-tag--m' = 'cds-tag--m';
  @Input() label = '';
  @Input() iconTag = '';
  @Input() important = false;
}
