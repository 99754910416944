import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, DialogModule],
  exports: [CommonModule, MatSnackBarModule, DialogModule]
})
export class SharedModule {}
