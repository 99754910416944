<div [class.main-score--minimal]="mainScoreData.minimal" class="main-score">
  <div class="rate">
    <p class="rate__value">{{ mainScoreData.valueScore }}<span class="bar">/</span><span class="ten">10</span></p>
    <app-item-list class="rate__icon" icon="{{ mainScoreData.valueIcon }}">{{ mainScoreData.valueIconText }}</app-item-list>
  </div>
  <ng-container *ngIf="!mainScoreData.minimal">
    <div class="leyend">
      <p>{{ leyendText }}</p>
      <app-tooltip></app-tooltip>
    </div>
  </ng-container>
</div>
