import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer } from '@shared/logic/helpers/common/common.normalizer';
import { HeroNormalizer } from '@shared/store/home/hero/hero.normalizer';
import { LandingCommercial } from '@shared/store/landing/model/commercial.model';
import { LandingCommercialRaw } from '@shared/store/landing/model-raw/commercial.model.raw';
import { LandingsCommonNormalizer } from '@shared/store/landing/normalizers/landings.common.normalizer';

export class LandingCommercialNormalizer {
  public static normalize(itemRaw: LandingCommercialRaw): LandingCommercial {
    const item = {} as LandingCommercial;
    // TODO: Condiciones de participacion (modal) | Que modal es => En funcion de lo que mande back
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.landingTitle = itemRaw.title;
    item.landingCode = itemRaw.code;

    item.publicationStartDate = itemRaw.publicationDates?.date || '';
    item.publicationEndDate = itemRaw.publicationDates?.endDate || '';
    item.travelWindowStartDate = itemRaw.travelWindowDates?.date || '';
    item.travelWindowEndDate = itemRaw.travelWindowDates?.endDate || '';
    item.bookingWindowStartDate = itemRaw.bookingWindowDates?.date || '';
    item.bookingWindowEndDate = itemRaw.bookingWindowDates?.endDate || '';

    if (itemRaw.herobanner) {
      item.heroBanner = HeroNormalizer.normalize(itemRaw.herobanner);
      if (itemRaw.code) {
        item.heroBannerTags = [{ type: 'cds-tag--killer', label: itemRaw.code }];
      }
    }

    if (itemRaw.welcomeBanner) {
      item.welcomeBanner = {
        title: itemRaw.welcomeBanner.title,
        description: itemRaw.welcomeBanner.description,
        link: itemRaw.welcomeBanner.link,
        linkUrl: '#' // should open modal
      };
    }

    item.modalTitle = itemRaw.modal.title;
    item.modalDescription = itemRaw.modal.description;
    if (GeneralFunctions.isArrayLike(itemRaw.modal.link, 1)) {
      item.modalLinks = itemRaw.modal.link.map((i) => CommonNormalizer.normalizeLandingLink(i));
    }

    item.roomsBlock = {
      title: itemRaw.hotelSection?.title,
      isMenuVisible: itemRaw.hotelSection?.showMenu,
      isPricesFromVisible: itemRaw.hotelSection?.shorPricesFrom,
      tabs: GeneralFunctions.isArrayLike(itemRaw.hotelSection?.tabsSection)
        ? itemRaw.hotelSection.tabsSection.map((i) => LandingsCommonNormalizer.normalizeTab(i))
        : []
    };

    item.roomsBlock.tabs = LandingsCommonNormalizer._sortItemsByCombinedSection(item.roomsBlock.tabs);
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
