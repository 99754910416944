import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { RoutingService } from '@app/core/routing/routing.service';
import { LanguageDataService } from '@app/core/services/languages/language-data.service';

import { AuthStoreAdapter } from '@app/shared/store/auth/auth.store.adapter';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const authStoreAdapter = inject(AuthStoreAdapter);
  const router = inject(Router);
  const routingService = inject(RoutingService);

  return authStoreAdapter.selectAuthStatus$().pipe(
    map((isLoggedIn) => {
      if (!isLoggedIn) {
        return router.createUrlTree(routingService._localizePaths(RoutingService.HOME_URL()));
      }
      return true;
    })
  );
};
