import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  // TODO: this should have a default value, future refactor.
  private _deviceSubject = new BehaviorSubject<string>('desktop'); // Default value
  device$ = this._deviceSubject.asObservable();

  updateDevice(device: string) {
    this._deviceSubject.next(device);
  }
}
