import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LiteralSections } from '@app/shared/logic/const/common';
import { Literals } from '@app/shared/logic/models/common.model';

import { Login, SocialProvider } from '@app/shared/store/auth/auth.model';
import { AuthService } from '@app/shared/store/auth/auth.service';
import { CommonStoreAdapter } from '@app/shared/store/common/common.store.adapter';
import { ButtonComponent } from '@atom/button/button.component';
import { ConstantsControlShowToast, ToastComponent } from '@atom/toast/toast.component';
import { RoutingService } from '@core/routing/routing.service';
import { LoginTrackService } from '@ga-tracking/services/tracking-sites/login/login-track.service';
import { ModalService } from '@shared/logic/services/modals/modal.service';
import { GdiCatInputPasswordModule } from '@shared/ns_components/forms/gdi-cat-input-password/gdi-cat-input-password.module';
import { GdiCatInputTextModule } from '@shared/ns_components/forms/gdi-cat-input-text/gdi-cat-input-text.module';
import { UiStoreAdapter } from '@shared/store/ui/ui.store.adapter';
import { catchError, EMPTY, of, switchMap } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-login-form-singin',
  templateUrl: './login-form-singin.component.html',
  imports: [GdiCatInputTextModule, NgIf, GdiCatInputPasswordModule, ButtonComponent, ReactiveFormsModule],
  styleUrls: ['./login-form-singin.component.scss']
})
export class LoginFormSingInComponent {
  public readonly recoveryPasswordSig = this._uiStoreAdapter.selectHeaderResetPasswordDisplayStatusSig;
  public readonly logWithEmail = this._uiStoreAdapter.selectHeaderLoginWithEmailDisplayStatusSig;

  @Output() public ev_loginClicked: EventEmitter<Login> = new EventEmitter<Login>();
  @Output() public ev_socialProviderClicked: EventEmitter<SocialProvider> = new EventEmitter<SocialProvider>();
  public readonly literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required])
  });

  public recoveryForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  public logWithEmailForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  constructor(
    public _modalService: ModalService,
    private _authService: AuthService,
    private readonly _uiStoreAdapter: UiStoreAdapter,
    private _loginTrackService: LoginTrackService,
    public snackBar: MatSnackBar,
    private _routingService: RoutingService,
    private _commonStoreAdapter: CommonStoreAdapter
  ) {}

  public onClickLogin(): void {
    this.ev_loginClicked.emit({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    });
  }

  public onClickSocialProvider(provider: SocialProvider): void {
    this.ev_socialProviderClicked.emit(provider);
  }

  public activeRecoveryPassword(cancel: boolean): void {
    this._uiStoreAdapter.setHeaderResetPasswordDisplayStatus(!this.recoveryPasswordSig());
    if (!cancel) {
      this._loginTrackService.trackClickForgetPassword('/');
    }
  }

  public activeLogWithEmail(): void {
    this._loginTrackService.trackClickAnyActionLoginPage(this.literals.label_no_pass, '');
    this._uiStoreAdapter.setHeaderLoginWithEmailDisplayStatus(!this.logWithEmail());
  }

  copyEmail(copy: string, email: string): string {
    return copy.replace('{email}', email);
  }

  public openModalResetPassword(): void {
    this._loginTrackService.trackClickBtnRecoverPassWithEmail();
    this._loginTrackService.trackViewModalReviewEmail();
    this._authService.recoverEmailPassword$(this.recoveryForm.get('email')!.value).subscribe(() => {
      this._modalService
        .displayMessageModal$({
          displayTitle: this.literals.label_inbox,
          messages: [this.copyEmail(this.literals.label_invalid_pass_text, this.recoveryForm.get('email')!.value)],
          icon: 'Confirmation',
          promo: false
        })
        .subscribe(() => {
          this._loginTrackService.trackClickModalReviewEmailBtn('Aceptar');
          this._uiStoreAdapter.setHeaderResetPasswordDisplayStatus(false);
          this.recoveryForm.reset();
        });
    });
  }

  public openModalInitSessionEmail(): void {
    this._authService
      .initSessionEmail(this.logWithEmailForm.get('email')!.value, this._routingService.getUrl())
      .pipe(
        catchError((err: any) => {
          if (err.status === 485) {
            this.snackBar.openFromComponent(ToastComponent, { data: ConstantsControlShowToast.TIMEOUT_LOGIN_OTP, duration: 5000 });
            return EMPTY;
          } else {
            // Return observable null to be continue in switchMap
            return of(null);
          }
        }),
        switchMap(() => {
          this._loginTrackService.trackViewModalReviewEmail();
          return this._modalService.displayMessageModal$({
            displayTitle: this.literals.label_inbox,
            messages: [this.copyEmail(this.literals.label_email_validity_check, this.logWithEmailForm.get('email')!.value)],
            icon: 'Confirmation',
            promo: false
          });
        })
      )
      .subscribe({
        next: () => {
          this._loginTrackService.trackClickModalReviewEmailBtn('Aceptar');
          this._uiStoreAdapter.setHeaderLoginWithEmailDisplayStatus(false);

          this.logWithEmailForm.reset();
          this._uiStoreAdapter.setHeaderLoginDisplayStatus(false);
        },
        error: (err: any) => {}
      });
  }
}
