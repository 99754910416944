import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { DataTransChangeEvent, DataTransSuccessEvent, SecureFieldsInstance } from '@shared/ns_components/datatrans-widget/secure-fields.model';
import { BehaviorSubject, catchError, from, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScriptService, ScriptStatus } from './script.service';

export const enum SFInstances {
  CreditCardForm = 'CreditCardForm'
}

@Injectable({
  providedIn: 'root'
})
export class SecureFieldsService {
  private secureFieldInstances: Record<string, SecureFieldsInstance> = {};
  resetField$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly scriptService: ScriptService) {}

  public loadDataTransferSecureFieldsScript$(): Observable<{ isLoaded: boolean }> {
    const scriptToLoad: string = environment.production ? 'secure-fields' : 'secure-fields-test';
    return from(this.scriptService.load(scriptToLoad)).pipe(
      catchError((error) => {
        console.error('DaTrans', error);
        return of(null);
      }),
      map((loadResponse: ScriptStatus[] | null) => ({ isLoaded: !!loadResponse?.[0]?.loaded }))
    );
  }

  public getInstanceByName(name: string): SecureFieldsInstance | undefined {
    if (!this.secureFieldInstances[name]) {
      console.warn('SF instance can not be retrieved cause it does not exists');
      return;
    }
    return this.secureFieldInstances[name];
  }

  public getNewInstance(name: string): SecureFieldsInstance {
    this.createInstance(name);
    return this.secureFieldInstances[name];
  }

  public destroyInstanceByName(name: string): void {
    const instance = this.secureFieldInstances[name];

    if (!instance) {
      console.warn('SF instance does not exists and can not be destroyed');
      return;
    }
    // instance.readySubject!.complete();
    instance.changeSubject!.complete();
    // instance.validateSubject!.complete();
    instance.successSubject!.complete();

    // instance.readySubject = null;
    // @ts-ignore
    instance.changeSubject = null;
    // instance.validateSubject = null;
    // @ts-ignore
    instance.successSubject = null;

    instance.secureFieldsInstance.destroy();
    delete this.secureFieldInstances[name];
    console.log('SF instance destroyed successfully');
  }

  public submitInstanceByName(name: string, data?: any): void {
    if (!this.secureFieldInstances[name]) {
      console.warn('SF instance can not be submitted cause it does not exists');
      return;
    }
    this.secureFieldInstances[name].secureFieldsInstance.submit(data);
  }

  private createInstance(name: string): void {
    try {
      const secureFieldsInstance = new (window as any).SecureFields();

      this.secureFieldInstances[name] = {
        secureFieldsInstance,
        // readySubject: new Subject<any>(),
        changeSubject: new Subject<DataTransChangeEvent>(),
        // validateSubject: new Subject<any>(),
        successSubject: new Subject<DataTransSuccessEvent>()
      } as SecureFieldsInstance;

      // secureFieldsInstance.on('ready', (e: any) => this.secureFieldInstances[name].readySubject!.next(e));
      secureFieldsInstance.on('change', (e: DataTransChangeEvent) => this.secureFieldInstances[name].changeSubject!.next(e));
      // secureFieldsInstance.on('validate', (e: any) => this.secureFieldInstances[name].validateSubject!.next(e));
      secureFieldsInstance.on('success', (e: DataTransSuccessEvent) => this.secureFieldInstances[name].successSubject!.next(e));

      console.log('SF instance created successfully');
    } catch (error) {
      console.error('Error creating SF instance:', error);
    }
  }
}
