<div class="cds-hotel-award__item" hotelAward>
  <ng-container *ngIf="data?.image as image">
    <img dynamic-image [imageRaw]="image" [alt]="image.atributos?.descriptivo" [src]="image.talla_uno" />
  </ng-container>
  <ng-container *ngIf="data.tooltipTitle || data.tooltipDescription">
    <div class="cds-hotel-award__item__tooltip">
      <div class="cds-hotel-award__item__tooltip__title">
        <p>
          {{ data?.tooltipTitle }}
        </p>
      </div>
      <div class="cds-hotel-award__item__tooltip__description">
        <p>
          {{ data?.tooltipDescription }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
