import { Register } from './auth.model';
import { RegisterUserRaw } from './auth.model.raw';
import { Country } from '../common/common.model';

export class AuthDenormalizer {
  static denormalizeUserCreation(register: Register, countries: Country[], token?: string): RegisterUserRaw {
    const country = countries.find((c) => c.code === register.country);
    return {
      name: register.name,
      surname: register.surname,
      email: register.email,
      memberLevel: '1',
      currency: country?.currency ?? '€',
      country: country?.code ?? 'ES',
      gdprRewards: String(register.termsAndConditionsAccepted || true),
      gdpr: String(!register.communicationsRejected),
      calt: 'WEB',
      cact: 'RAM',
      language: 'es', // TODO: check
      token
    };
  }
}
