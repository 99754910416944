import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TypographyComponent } from '@app/shared/components/atoms/typography/typography.component';
import { DescriptionItemComponent } from '@app/shared/components/molecules/description-item/description-item.component';

import { RegimeSelectionTrackService } from '@ga-tracking/services/tracking-sites/regime-selection-track/regime-selection-track.service';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { CdkDialogWrapperNewModalInput, ModalResponse } from '@shared/logic/models/common.model';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';
import { Terms } from '../../hotel-modal/hotel-modal.component';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

export interface OfferModalInput extends CdkDialogWrapperNewModalInput {
  offers: Terms[];
}

@Component({
  standalone: true,
  selector: 'app-offer-breakdown-modal',
  templateUrl: './offer-breakdown-modal.component.html',
  styleUrls: ['./offer-breakdown-modal.component.scss'],
  imports: [CommonModule, CdkDialogWrapperNewModule, DescriptionItemComponent, TypographyComponent, IconItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferBreakdownModalComponent extends ModalElement<OfferBreakdownModalComponent, OfferModalInput, ModalResponse> implements OnInit, OnDestroy {
  constructor(
    @Inject(DIALOG_DATA) dialogData: OfferModalInput,
    dialogRef: DialogRef<ModalResponse, OfferBreakdownModalComponent>,
    private regimeSelectionTrackService: RegimeSelectionTrackService
  ) {
    super(dialogData, dialogRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.regimeSelectionTrackService.trackViewModalMoreInfoRewards(this.getDialogData().offerDiscount?.toString() ?? '');
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onAccept(): void {
    this.closeDialog({ success: true });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }
}
