<form class="form-pay-display" [formGroup]="receivedFormGroup">
  <!-- Input para el titular de la tarjeta-->
  <!--    <div class="form-input-div">-->
  <!--        <label class="input-label">Titular de la tarjeta</label>-->
  <!--        <div class="input-box">-->
  <!--            <input-->
  <!--                    [ngClass]="{ succes: messageSuccess, error: false }"-->
  <!--                    autocomplete="off"-->
  <!--                    class="input-basic input-namecard"-->
  <!--                    placeholder="Titular de la tarjeta"-->
  <!--                    formControlName="cardOwner"-->
  <!--                    type="text"-->
  <!--            />-->
  <!--            <div [ngClass]="messageSuccess || false ? 'active' : ''" class="input-box__message">-->
  <!--                <ng-container *ngIf="false">-->
  <!--                    <div class="message-box">-->
  <!--                        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">-->
  <!--                            <g id="icon description">-->
  <!--                                <path-->
  <!--                                        clip-rule="evenodd"-->
  <!--                                        d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333ZM9.43229 5.4545H10.5687V12.2727H9.43229V5.4545ZM9.43229 13.409H10.5687V14.5454H9.43229V13.409Z"-->
  <!--                                        fill="#DD554D"-->
  <!--                                        fill-rule="evenodd"-->
  <!--                                        id="Substract"-->
  <!--                                />-->
  <!--                            </g>-->
  <!--                        </svg>-->
  <!--                        <p class="message"></p>-->
  <!--                    </div>-->
  <!--                </ng-container>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
  <div class="form-input-div">
    <gdi-cat-input-text
      size="cds-input-text--m"
      [style]="'cds-input-text--regular'"
      formControlName="cardOwner"
      placeholder="{{ _literals.formPhCardholder }}"
      noNumbersOrSpecialChars
    ></gdi-cat-input-text>
  </div>

  <!-- Input para el numero de tarjeta -->
  <div class="form-input-div">
    <label class="input-label">Card Number</label>
    <div class="input-box">
      <div [ngClass]="{ succes: messageSuccess, error: cardNumberHasError }" class="input-box__iframe input-number" id="card-number"></div>
      <div [ngClass]="messageSuccess || cardNumberHasError ? 'active' : ''" class="input-box__message">
        <ng-container *ngIf="cardNumberHasError">
          <div class="message-box">
            <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
              <g id="icon description">
                <path
                  clip-rule="evenodd"
                  d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333ZM9.43229 5.4545H10.5687V12.2727H9.43229V5.4545ZM9.43229 13.409H10.5687V14.5454H9.43229V13.409Z"
                  fill="#DD554D"
                  fill-rule="evenodd"
                  id="Substract"
                />
              </g>
            </svg>
            <p class="message">Revisa el número de tarjeta</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Row de inputs -->
  <div class="row">
    <div class="form-input-div">
      <gdi-cat-input-date
        size="cds-input-text--m"
        [style]="'cds-input-text--regular'"
        formControlName="expiryDate"
        placeholder="{{ _literals.formPhExpiryDate }}"
        mask="00/00"
      ></gdi-cat-input-date>
    </div>

    <!-- Input para ccv -->
    <ng-container *ngIf="!_hideCVV">
      <div class="form-input-div">
        <label class="input-label">CCV</label>
        <div class="input-box">
          <div [ngClass]="{ succes: messageSuccess, error: cvvHasError }" class="input-box__iframe input-ccv" id="cvv-number"></div>
          <div [ngClass]="messageSuccess || cvvHasError ? 'active' : ''" class="input-box__message">
            <ng-container *ngIf="cvvHasError">
              <div class="message-box">
                <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <g id="icon description">
                    <path
                      clip-rule="evenodd"
                      d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333ZM9.43229 5.4545H10.5687V12.2727H9.43229V5.4545ZM9.43229 13.409H10.5687V14.5454H9.43229V13.409Z"
                      fill="#DD554D"
                      fill-rule="evenodd"
                      id="Substract"
                    />
                  </g>
                </svg>
                <p class="message">Revisa el código de verificación</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
