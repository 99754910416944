import { computed, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import {
  SetHeaderCreateAccountDisplayStatus,
  SetHeaderLoginDisplayStatus,
  SetHeaderLoginWithEmailDisplayStatus,
  SetHeaderResetPasswordDisplayStatus,
  SetRewardsToggleStatus,
  SetScrollConfig,
  ToggleRewardsActiveStatus
} from '@shared/store/ui/ui.actions';
import { UISelectors } from '@shared/store/ui/ui.selectors';
import { Observable } from 'rxjs';

import { ScrollConfig } from './ui.model';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class UiStoreAdapter {
  constructor(private store: Store<State>) {}

  // TODO: Remember to delete this.
  public setScrollConfig(scrollConfig: ScrollConfig): void {
    this.store.dispatch(new SetScrollConfig({ scrollConfig }));
  }

  public readonly selectScrollConfig$ = (): Observable<ScrollConfig | null> => this.store.select(UISelectors.getScrollConfig);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  // Used to display login/register form when we click on "Iniciar sesión"
  public setHeaderLoginDisplayStatus(status: boolean): void {
    this.store.dispatch(new SetHeaderLoginDisplayStatus({ status }));
  }

  public setHeaderLoginWithEmailDisplayStatus(status: boolean): void {
    this.store.dispatch(new SetHeaderLoginWithEmailDisplayStatus({ status }));
  }

  public setHeaderResetPasswordDisplayStatus(status: boolean): void {
    this.store.dispatch(new SetHeaderResetPasswordDisplayStatus({ status }));
  }

  public setHeaderCreateAccountDisplayStatus(status: boolean): void {
    this.store.dispatch(new SetHeaderCreateAccountDisplayStatus({ status }));
  }

  /**
   * Changes Rewards status
   * Used to change rewards status along web app.
   * When user is logged in, rewards status is always True.
   * When user is not logged in, rewards status is decided by user (rewards toggle)
   * When is a collective flux, rewards status is always False.
   */
  public setRewardsToggleStatus(isActive: boolean): void {
    this.store.dispatch(new SetRewardsToggleStatus({ isActive }));
  }

  // Use to toggle rewards active status => This could disappear in the future if technical debt is addressed
  public toggleRewardsActiveStatus(): void {
    this.store.dispatch(new ToggleRewardsActiveStatus());
  }

  public readonly selectHeaderLoginDisplayStatusSig = this.store.selectSignal(UISelectors.getHeaderLoginDisplayStatus);
  public readonly selectRewardsActiveStatusSig = this.store.selectSignal(UISelectors.getRewardsActiveStatus);
  public readonly selectHeaderLoginWithEmailDisplayStatusSig = this.store.selectSignal(UISelectors.getHeaderLoginWithEmailDisplayStatus);
  public readonly selectHeaderResetPasswordDisplayStatusSig = this.store.selectSignal(UISelectors.getHeaderResetPasswordDisplayStatus);
  public readonly selectHeaderCreateAccountDisplayStatusSig = this.store.selectSignal(UISelectors.getHeaderCreateAccountDisplayStatus);
  public readonly selectScrollConfigSig = this.store.selectSignal(UISelectors.getScrollConfig);
  // COMPUTED SIGNALS
  public readonly searchClassMultiRoomSig = computed(() => this.selectScrollConfigSig()?.searchClassMultiroom);
}
