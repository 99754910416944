<div class="delete-credit-card-modal">
  <app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
    <div class="delete-credit-card-modal__subtitle">
      <p>{{ data?.literals?.label_info_delete_data }}</p>
    </div>
    <div class="delete-credit-card-modal__button">
      <app-button label="{{ data?.literals?.label_remove_card }}" [fillMobile]="true" type="cds-btn--highlight" (click)="onAccept()"></app-button>
    </div>
  </app-cdk-dialog-wrapper-new>
</div>
