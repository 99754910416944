import { Action } from '@ngrx/store';

export enum HeaderActionsTypes {
  SetActiveHeader = '[HEADER] Set state ActiveHeader'
}
export class SetActiveHeader implements Action {
  readonly type = HeaderActionsTypes.SetActiveHeader;
  constructor(readonly payload: { headerActive: boolean }) {}
}

export type HeaderActions = SetActiveHeader;
