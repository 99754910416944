import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _activeRequests: Set<string> = new Set();
  private _loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this._loadingSubject.asObservable();

  get activeRequests(): number {
    return this._activeRequests.size;
  }

  public requestStarted(requestId: string) {
    this._activeRequests.add(requestId);
  }

  public requestCompleted(requestId: string) {
    this._activeRequests.delete(requestId);
  }

  public isLoading(): boolean {
    return this._activeRequests.size > 0;
  }

  public loadingOn(): void {
    this._loadingSubject.next(true);
  }

  public loadingOff(): void {
    this._loadingSubject.next(false);
  }
}
