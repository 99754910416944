<div class="navigation-wrapper">
  <div
    carouselBase
    (currentSlideChange)="changeSlide($event)"
    [currentSlide]="currentSlide"
    #carouselDir="carousel"
    [timeChangeSlade]="timeChangeSlade"
    [configCarousel]="configCarousel"
    class="keen-slider keen-slider--vertical"
  >
    <ng-content></ng-content>
  </div>
  <div *ngIf="hadArrow" class="keen-slider__arrows">
    <app-slider-arrow
      [dark]="true"
      (prevFunction)="carouselDir.prev()"
      (nextFunction)="carousel.next()"
      [isPrevArrowHidden]="isPrevArrowHidden"
      [isNextArrowHidden]="isNextArrowHidden"
    ></app-slider-arrow>
  </div>
</div>

<div class="dots-content" [style]="styleContentDots">
  <div *ngIf="hadDots" class="dots" [style]="styleDots">
    <app-carousel-dot
      [slideIndex]="i"
      *ngFor="let slide of carouselDir.dotHelper; let i = index"
      class="dot"
      [style]="animationDuration"
      [ngClass]="getIndicatorClass(i)"
    ></app-carousel-dot>
  </div>
</div>
