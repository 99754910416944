import { Injectable } from '@angular/core';
import { getHotelContinent } from '@app/shared/utils/continents/continents.utils';

import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { HomeTrackingEvent } from '@ga-tracking/services/tracking-sites/home/home-track.model';
import {
  HotelWithoutDatesDisplayTag,
  HotelWithoutDatesTrackingEvent
} from '@ga-tracking/services/tracking-sites/hotel-without-dates-track/hotel-without-dates-track.model';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { RoomAndHotelItem } from '@shared/ns_components/gdi_extracted/rooms-hotels/rooms-hotel.props';
import { HotelResponse } from '@shared/store/hotel/hotel.model';
import { HotelRoomRaw } from '@shared/store/hotel/hotel.model.raw';

@Injectable({ providedIn: 'root' })
export class HotelWithoutDatesTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackHotelWithoutDatesDisplay(hotelData: HotelResponse): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'ficha hotel',
      content_type: 'sin fechas',
      content_tag1: GoogleTagFunctions.reduceTag(hotelData.hotelTagList[0]) ?? 'no info',
      content_tag2: GoogleTagFunctions.reduceTag(hotelData.hotelTagList[1]) ?? 'no info',
      content_category: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.category),
      content_subcategory: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.address.city),
      content_detail: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.name),
      content_id: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.code),
      content_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelData.hotelInfo.code)),
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(hotelData.hotelInfo.stars)),
      content_score: GoogleTagFunctions.numberTag(hotelData.ratingInfo.totalScore),
      title: `catalonia hotels | ficha sin fechas ${GoogleTagFunctions.reduceTag(hotelData.hotelInfo.name)}`,
      path: GoogleTagFunctions.reduceTag(hotelData.hotelUrl)
    };
    this.trackingService.pushEventClickFromExternalSources<HotelWithoutDatesDisplayTag>(eventData, '1_search');
  }

  public trackGalleryClick(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'galeria',
      element: 'foto',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackReviewsClick(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'valoraciones',
      element: 'button',
      link_text: 'ver valoraciones'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackViewModalReviews(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'valoraciones',
      element: 'modal'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackContactDataClick(element: string, link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'contacto',
      element: GoogleTagFunctions.reduceTag(element),
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  // TODO: Review when develop mapping about tooltips rewards
  public trackViewTooltipRewards(porcentual: string, link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: `rewards_on_${GoogleTagFunctions.prepareDiscountTag(porcentual)}`,
      element: 'info_tooltip',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickInfoRewards(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'rewards',
      element: 'info_button',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  // TODO: Apply when develop modal info rewards
  public trackViewModalInfoRewards(discount: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: 'info rewards',
      element: 'modal',
      link_text: GoogleTagFunctions.reduceTag(GoogleTagFunctions.prepareDiscountTag(discount))
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickInfoRoom(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'card habitacion',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackViewInfoRoomModal(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}`,
      link_text: GoogleTagFunctions.reduceTag(sectionView)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickInfoRoomModalSection(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'seccion',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}_${GoogleTagFunctions.reduceTag(sectionView)}`,
      link_text: GoogleTagFunctions.reduceTag(sectionView)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickRoomInfoTariff(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'card tarifa',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickInstallationsSections(installation: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'instalaciones',
      link_text: GoogleTagFunctions.reduceTag(installation)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickReadMoreAboutHotel(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'sobre el hotel',
      link_text: 'leer mas',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  // TODO: In this moment not exist this tooltip
  public trackClickTooltipHotelServices(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'info_tooltip',
      section: 'servicios el hotel',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickViewMoreAwards(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'premios',
      link_text: 'ver todos'
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackFeaturedHotels(event: string, hotelData: HotelResponse, room: RoomAndHotelItem, index: number): void {
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>({ ecommerce: null });
    const eventData = {
      event: event,
      ecommerce: {
        items: [
          {
            item_name: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.name),
            item_id: GoogleTagFunctions.reduceTag(hotelData.hotelInfo.code),
            price: GoogleTagFunctions.numberTag((room._original as HotelRoomRaw).minPrice ?? 0),
            discount: 0,
            item_regimen: '',
            item_regimen2: '',
            item_brand: GoogleTagFunctions.reduceTag(hotelData.hotelDestination) ?? 'no info',
            item_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelData?._original.backcode)) ?? 'no info',
            item_category: hotelData._original.hotelType ?? 'no info',
            item_category2: '',
            item_category3: GoogleTagFunctions.reduceTag(Math.floor(Number(hotelData?._original.estrellas))) ?? 'no info',
            item_category4: GoogleTagFunctions.reduceTag(room.dataCard.header.tags.map((t) => t.text).join('_')) ?? 'no info',
            item_category5: 0,
            item_category6: hotelData?._original.adults_only ? '1' : '0',
            item_category7: GoogleTagFunctions.numberTag((room._original as HotelRoomRaw).minPrice ?? 0),
            item_rw_discount: 0,
            item_variant: GoogleTagFunctions.reduceTag(room.dataCard.header.roomTitle) ?? 'no info',
            item_variant2: GoogleTagFunctions.reduceTag(room.dataCard.header.roomId) ?? 'no info',
            item_n_adult: 0,
            item_n_child: 0,
            item_n_junior: 0,
            item_n_babie: 0,
            item_list_name: `ficha sin fechas_${GoogleTagFunctions.reduceTag(hotelData?._original.title)}_room${index + 1}`,
            item_list_id: `ficha sin fechas_${GoogleTagFunctions.reduceTag(hotelData?._original.backcode)}_room${index + 1}`,
            index: GoogleTagFunctions.numberTag(index + 1),
            quantity: 1
          }
        ]
      }
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackClickInstallationsSection(oldSelectedInstallation: string, installation: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'seccion',
      section: `instalaciones_${GoogleTagFunctions.reduceTag(oldSelectedInstallation)}`,
      link_text: GoogleTagFunctions.reduceTag(installation)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  // TODO: In this moment not exist this functionality
  public trackClickInstallation(element: string, section: string, link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: GoogleTagFunctions.reduceTag(element),
      section: `instalaciones_${GoogleTagFunctions.reduceTag(section)}`,
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }

  public trackMultiModule(event: string, gtmTracking: any, index: number): void {
    const eventData = {
      event: event,
      ecommerce: {
        items: [
          {
            item_name: GoogleTagFunctions.reduceTag(gtmTracking.hotelName) || 'na',
            item_id: GoogleTagFunctions.reduceTag(gtmTracking.hotelId) || 'na',
            item_brand: GoogleTagFunctions.reduceTag(gtmTracking.hotelCity) || 'na',
            item_category: GoogleTagFunctions.reduceTag(gtmTracking.hotelCategory) || 'na',
            item_category3: GoogleTagFunctions.reduceTag(Math.floor(Number(gtmTracking.hotelStars))) || 'na',
            promotion_id: 'cards hoteles relacionados',
            promotion_name: 'hotel relacionado',
            creative_name: GoogleTagFunctions.reduceTag(gtmTracking.hotelLabel) || 'na',
            location_id: 'bottom ficha sin fechas',
            index: GoogleTagFunctions.numberTag(index + 1) || 0,
            discount: 0
          }
        ]
      }
    };
    this.trackingService.pushEventClick<HomeTrackingEvent>(eventData);
  }

  public trackClickPrefooter(element: string, link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: GoogleTagFunctions.reduceTag(element),
      section: 'prefooter',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithoutDatesTrackingEvent>(eventData);
  }
}
