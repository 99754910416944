import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { ExtendedBookingGdiData } from '@shared/logic/helpers/gdi/booking-gdi.helper';
import { createMomentDate } from '@shared/utils/dates/dates.utils';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { PrivateAreaTag, PrivateAreaTrackingEvent } from '@ga-tracking/services/tracking-sites/private-area/private-area-track.model';
import { CancelledBooking } from '@app/pages/booking/booking-cancellation/booking-cancellation.page';
import { getHotelContinent } from '@app/shared/utils/continents/continents.utils';

@Injectable({ providedIn: 'root' })
export class PrivateAreaTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  // ----- AREA PRIVADA GLOBAL ------
  public trackPrivateAreaPageDisplay(section: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'area privada',
      content_type: GoogleTagFunctions.reduceTag(section),
      content_category: 'na',
      content_subcategory: 'na',
      content_detail: 'na',
      content_id: 'na',
      title: `catalonia hotels | area privada ${GoogleTagFunctions.reduceTag(section)}`,
      path: `/area_privada_${GoogleTagFunctions.reduceTag(section)}`
    };
    this.trackingService.pushEventClickFromExternalSources<PrivateAreaTag>(eventData, '1_search');
  }

  public trackClickMenuOption(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'login_menu',
      section: 'header',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTAManageReserve(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: 'info reserva_opciones',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // ----- GESTION RESERVA ------

  public trackManageReservePageDisplay(bookingConfirmationData: ExtendedBookingGdiData): void {
    const startDate = createMomentDate(bookingConfirmationData.data.startDate);
    const endDate = createMomentDate(bookingConfirmationData.data.endDate);
    const item_n_adult = bookingConfirmationData.rooms.children.reduce((sum, guest) => sum + (guest.host.numAdults ?? 0), 0);
    const item_n_child = bookingConfirmationData.rooms.children.reduce((sum, guest) => sum + (guest.host.numChildren ?? 0), 0);
    const item_n_junior = bookingConfirmationData.rooms.children.reduce((sum, guest) => sum + (guest.host.numJuniors ?? 0), 0);
    const item_n_babie = bookingConfirmationData.rooms.children.reduce((sum, guest) => sum + (guest.host.numBabies ?? 0), 0);
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'gestion reserva',
      content_type: 'reserva web',
      order_id: GoogleTagFunctions.reduceTag(!!bookingConfirmationData.hash ? bookingConfirmationData.hash : bookingConfirmationData.data.code) ?? 'no info',
      content_tag1: bookingConfirmationData.data.payHotel > 0 ? 'pago en hotel' : 'tarjeta',
      content_tag2: bookingConfirmationData.data.isRewardsActive ? 'con rewards' : 'sin rewards',
      content_category: GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData.hotelType) ?? 'no info',
      content_subcategory: GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData.hotelDestination) ?? 'no info',
      content_detail: GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData.name) ?? 'no info',
      content_id: GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData.code) ?? 'no info',
      content_place: GoogleTagFunctions.reduceTag(getHotelContinent(bookingConfirmationData.hotelData.code)) ?? 'no info',
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(bookingConfirmationData.hotelData.stars)) ?? 'no info',
      content_score: GoogleTagFunctions.numberTag(bookingConfirmationData.hotelData.hotelRating) ?? 0,
      content_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
      content_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
      content_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
      content_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
      content_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0,
      content_room_nights: GoogleTagFunctions.numberTag(endDate.diff(startDate, 'days')) ?? 0,
      content_room_quantity: GoogleTagFunctions.numberTag(bookingConfirmationData.rooms.children.length) ?? 0,
      content_n_adult: !isNaN(item_n_adult) ? GoogleTagFunctions.numberTag(item_n_adult) : 0,
      content_n_child: !isNaN(item_n_child) ? GoogleTagFunctions.numberTag(item_n_child) : 0,
      content_n_junior: !isNaN(item_n_junior) ? GoogleTagFunctions.numberTag(item_n_junior) : 0,
      content_n_baby: !isNaN(item_n_babie) ? GoogleTagFunctions.numberTag(item_n_babie) : 0,
      price: GoogleTagFunctions.numberTag(bookingConfirmationData.data.totalPrice ?? 0),
      discount: GoogleTagFunctions.prepareDiscountTag(Number(bookingConfirmationData.discountInfo?.percent)) ?? 0,
      title: `catalonia hotels | gestion reserva ${GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData.name)}`,
      path: `/gestion_reserva_${GoogleTagFunctions.reduceTag(bookingConfirmationData.hotelData).replace(' ', '_').toLowerCase()}`
    };
    this.trackingService.pushEventClickFromExternalSources<PrivateAreaTag>(eventData, '1_search');
  }

  // TODO: Not implemented
  public trackViewErrorNavigationSendRequest(element: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.errorNavigate,
      element: GoogleTagFunctions.reduceTag(element),
      section: 'info reserva',
      link_text: 'navegacion',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // TODO: Not implemented
  public trackClickBtnErrorNavigation(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'error_navegacion',
      link_text: 'intentar de nuevo',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickExportBooking(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'icon',
      section: 'info reserva_exportar',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickInfoTariff(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'tarifa',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackViewModalInfoTariff(section: string, link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info tarifa_${GoogleTagFunctions.reduceTag(section)}`,
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickBtnCancelReserve(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'banner',
      section: 'info reserva_cancelar',
      link_text: 'reserva_cancelar'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackViewCancelReserveModal(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'info reserva_cancelar',
      link_text: 'cancelar'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickBtnCancelReserveModal(link_url: string, status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'info reserva_cancelar',
      link_text: 'cancelar',
      link_url: GoogleTagFunctions.reduceTag(link_url),
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickBtnReserveTransfer(link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'banner',
      section: 'info reserva_transfer',
      link_text: 'reserva_transfer',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickInfoRewards(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'rewards',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickAddComment(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'cta_button',
      section: 'comentario habitacion',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // ----- FLUJO DE CANCELACION ------

  public trackReserveCancelationPageDisplay(cancelledBooking: CancelledBooking): void {
    const startDate = createMomentDate(cancelledBooking.booking.data.startDate);
    const endDate = createMomentDate(cancelledBooking.booking.data.endDate);
    const item_n_adult = cancelledBooking.booking.rooms.children.reduce((sum, guest) => sum + (guest.host.numAdults ?? 0), 0);
    const item_n_child = cancelledBooking.booking.rooms.children.reduce((sum, guest) => sum + (guest.host.numChildren ?? 0), 0);
    const item_n_junior = cancelledBooking.booking.rooms.children.reduce((sum, guest) => sum + (guest.host.numJuniors ?? 0), 0);
    const item_n_babie = cancelledBooking.booking.rooms.children.reduce((sum, guest) => sum + (guest.host.numBabies ?? 0), 0);
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'cancelacion reserva',
      content_type: `${cancelledBooking.isPartial ? 'parcial ' : 'total'}_reserva web`,
      transaction_id:
        GoogleTagFunctions.reduceTag(!!cancelledBooking.booking.hash ? cancelledBooking.booking.hash : cancelledBooking.booking.data.code) ?? 'no info',
      content_tag1: cancelledBooking.booking.data.payHotel > 0 ? 'pago en hotel' : 'tarjeta',
      content_tag2: cancelledBooking.booking.data.isRewardsActive ? 'con rewards' : 'sin rewards',
      content_category: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.hotelType) ?? 'no info',
      content_subcategory: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.hotelDestination) ?? 'no info',
      content_detail: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.name) ?? 'no info',
      content_id: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.code) ?? 'no info',
      content_place: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.hotelContinent) ?? 'no info',
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(cancelledBooking.booking.hotelData.stars)) ?? 'no info',
      content_score: GoogleTagFunctions.numberTag(cancelledBooking.booking.hotelData.hotelRating) ?? 0,
      content_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
      content_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
      content_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
      content_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
      content_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0,
      content_room_nights: GoogleTagFunctions.numberTag(endDate.diff(startDate, 'days')) ?? 0,
      content_room_quantity: GoogleTagFunctions.numberTag(cancelledBooking.booking.rooms.children.length) ?? 0,
      content_n_adult: !isNaN(item_n_adult) ? GoogleTagFunctions.numberTag(item_n_adult) : 0,
      content_n_child: !isNaN(item_n_child) ? GoogleTagFunctions.numberTag(item_n_child) : 0,
      content_n_junior: !isNaN(item_n_junior) ? GoogleTagFunctions.numberTag(item_n_junior) : 0,
      content_n_baby: !isNaN(item_n_babie) ? GoogleTagFunctions.numberTag(item_n_babie) : 0,
      price: GoogleTagFunctions.numberTag(cancelledBooking.cancelOperationCost ?? 0),
      discount: GoogleTagFunctions.prepareDiscountTag(Number(cancelledBooking.booking.discountInfo?.percent)) ?? 0,
      title: `catalonia hotels | cancelacion reserva ${GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.name)}`,
      path: `/cancelacion_reserva_${GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData).replace(' ', '_').toLowerCase()}`
    };
    this.trackingService.pushEventClickFromExternalSources<PrivateAreaTag>(eventData, '1_search');
  }

  public trackReserveCancellationEcommerce(cancelledBooking: CancelledBooking): void {
    const startDate = createMomentDate(cancelledBooking.booking.data.startDate);
    const endDate = createMomentDate(cancelledBooking.booking.data.endDate);
    const roomsCancelled = cancelledBooking.booking.rooms.children.filter(
      (r) => r.state === 'cancelled' && ((r.totalPriceRewards && r.totalPriceRewards > 0) || (r.totalPrice && r.totalPrice > 0))
    );

    const eventData = cancelledBooking.isPartial
      ? {
          event: COMMON_TAG_EVENTS.refund,
          hotel_name: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.name),
          hotel_id: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.code),
          hotel_price:
            GoogleTagFunctions.numberTag(
              roomsCancelled.reduce(
                (sum, room) => sum + (cancelledBooking.booking.data.isRewardsActive ? Number(room.totalPriceRewards) : Number(room.totalPrice) ?? 0),
                0
              )
            ) ?? 0,
          total_pax: GoogleTagFunctions.numberTag(roomsCancelled.reduce((sum, r) => sum + r.host.num, 0)),
          total_rooms: GoogleTagFunctions.numberTag(roomsCancelled.length),
          payment_type: cancelledBooking.booking.data.payNow > 0 ? 'tarjeta' : 'pago en hotel',
          ecommerce: {
            transaction_id:
              GoogleTagFunctions.reduceTag(!!cancelledBooking.booking.hash ? cancelledBooking.booking.hash : cancelledBooking.booking.data.code) ?? 'no info',
            affiliation: 'web',
            value:
              GoogleTagFunctions.numberTag(
                roomsCancelled.reduce(
                  (sum, room) => sum + (cancelledBooking.booking.data.isRewardsActive ? Number(room.totalPriceRewards) : Number(room.totalPrice) ?? 0),
                  0
                )
              ) ?? 0,
            currency: cancelledBooking.booking.hotelData.currency === '€' ? 'EUR' : 'USD',
            items: roomsCancelled.map((room, index) => {
              return {
                item_name: GoogleTagFunctions.reduceTag(cancelledBooking.booking.data.ratePlanTitle) ?? 'no info',
                item_id: GoogleTagFunctions.reduceTag(cancelledBooking.booking.data.ratePlanCode) ?? 'no info',
                price: GoogleTagFunctions.numberTag(
                  cancelledBooking.booking.data.isRewardsActive ? Number(room.totalPriceRewards) : Number(room.totalPrice) ?? 0
                ),
                item_regimen: GoogleTagFunctions.reduceTag(room.roomMeal) ?? 'no info',
                item_regimen2: GoogleTagFunctions.reduceTag(room.mealPlanCode) ?? 'no info',
                item_brand: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.hotelDestination) ?? 'no info',
                item_place: GoogleTagFunctions.reduceTag(getHotelContinent(cancelledBooking.booking.hotelData.code)) ?? 'no info',
                item_category: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.hotelType) ?? 'no info',
                item_category2: GoogleTagFunctions.reduceTag(cancelledBooking.booking.data.ratePlanSubtitle) ?? 'no info',
                item_category3: GoogleTagFunctions.reduceTag(Math.floor(cancelledBooking.booking.hotelData.stars)) ?? 'no info',
                item_category4: 'na',
                item_category5: GoogleTagFunctions.numberTag(cancelledBooking.booking.data.nights) ?? 0,
                item_category6: cancelledBooking.booking.hotelData.isAdultsOnly ? '1' : '0',
                item_category7: GoogleTagFunctions.numberTag(Number(room.totalPriceRewards) ?? 0),
                item_rw_discount: GoogleTagFunctions.prepareDiscountTag(Number(cancelledBooking.booking.discountInfo?.percent)) ?? 0,
                item_variant: GoogleTagFunctions.reduceTag(room.extraInfo) ?? 'no info',
                item_variant2: GoogleTagFunctions.reduceTag(room.roomType) ?? 'no info',
                item_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
                item_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
                item_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
                item_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
                item_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0,
                item_n_adult: GoogleTagFunctions.numberTag(room.host.numAdults) ?? 0,
                item_n_child: GoogleTagFunctions.numberTag(room.host.numChildren) ?? 0,
                item_n_junior: GoogleTagFunctions.numberTag(room.host.numJuniors) ?? 0,
                item_n_babie: GoogleTagFunctions.numberTag(room.host.numBabies) ?? 0,
                index: GoogleTagFunctions.numberTag(index + 1),
                quantity: 1,
                item_list_name: `reserva_${GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.name)}_room${index + 1}`,
                item_list_id: `reserva_${GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.code)}_room${index + 1}`
              };
            })
          }
        }
      : {
          event: COMMON_TAG_EVENTS.refund,
          hotel_name: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.name),
          hotel_id: GoogleTagFunctions.reduceTag(cancelledBooking.booking.hotelData.code),
          hotel_price:
            GoogleTagFunctions.numberTag(
              roomsCancelled.reduce(
                (sum, room) => sum + (cancelledBooking.booking.data.isRewardsActive ? Number(room.totalPriceRewards) : Number(room.totalPrice) ?? 0),
                0
              )
            ) ?? 0,
          total_pax: GoogleTagFunctions.numberTag(roomsCancelled.reduce((sum, r) => sum + r.host.num, 0)),
          total_rooms: GoogleTagFunctions.numberTag(roomsCancelled.length),
          payment_type: cancelledBooking.booking.data.payNow > 0 ? 'tarjeta' : 'pago en hotel',
          ecommerce: {
            transaction_id:
              GoogleTagFunctions.reduceTag(!!cancelledBooking.booking.hash ? cancelledBooking.booking.hash : cancelledBooking.booking.data.code) ?? 'no info',
            affiliation: 'web',
            value:
              GoogleTagFunctions.numberTag(
                roomsCancelled.reduce(
                  (sum, room) => sum + (cancelledBooking.booking.data.isRewardsActive ? Number(room.totalPriceRewards) : Number(room.totalPrice) ?? 0),
                  0
                )
              ) ?? 0,
            currency: cancelledBooking.booking.hotelData.currency === '€' ? 'EUR' : 'USD'
          }
        };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // ----- CUENTA ------

  public trackClickAddCardToWallet(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'cta_button',
      section: 'rewards wallet',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickChangeTypeCurrency(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'switch',
      section: 'cuenta_moneda',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickEditOrAddCard(section: string, link_text: string) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: GoogleTagFunctions.reduceTag(`cuenta_${section}`),
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackViewErrorField(section: string, link_text: string, status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewAlert,
      section: GoogleTagFunctions.reduceTag(`cuenta_${section}`),
      element: 'input_text',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTASavePreferenceSuccess(section: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: GoogleTagFunctions.reduceTag(`cuenta_${section}`),
      link_text: 'guardar'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickRemoveAccount(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'cuenta_eliminar',
      link_text: 'eliminar'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackViewModalRemoveAccount(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'cuenta_eliminar'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTARemoveAccount(status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'cuenta_eliminar',
      link_text: 'eliminar',
      status: GoogleTagFunctions.reduceTag(`cuenta_${status}`)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // TODO: Not implemented in front
  public trackViewTooltipsRewards(porcentual: string, link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'info_tooltip',
      section: `rewards_on_${porcentual}`,
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTATooltipsRewards(porcentual: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'button',
      section: `rewards_on_${porcentual}`,
      link_text: 'ver beneficios rewards',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTAModalConfirmEmail(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'cambio email',
      link_text: 'entendido'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  public trackClickCTATravelCash(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'cta_button',
      section: 'travel cash',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // TODO: Not implemented in front
  public trackClickTooltipInfoTravelCash(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'travel cash',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }

  // TODO: Not implemented in front
  public trackViewModalInfoTravelCash(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'info travel cash',
      link_text: 'travel cash'
    };
    this.trackingService.pushEventClick<PrivateAreaTrackingEvent>(eventData);
  }
}
