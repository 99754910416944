import { BreakdownDiscountInfo } from '@app/shared/ns_components/breakdowns/models/breakdown.model';
import { CurrencyTypeSymbol, GuestCountRaw, ImageRaw, Literals } from '@shared/logic/models/common.model';

import { RateInfoRaw } from '@shared/store/checkout/checkout.model.raw';

import { TaxType } from '../booking/models/booking-extended.model';

export interface Breadcrumb {
  id: string;
  displayText?: string;
  redirectUrl?: string;
}

export interface CheckoutPrices {
  totalPrice: number;
  totalPriceRewards: number;
  totalPriceMXN: number | undefined;
  totalPriceRewardsMXN: number | undefined;
}

export interface CheckoutRoomsInfo {
  children: CheckoutRoomsChild[];
}

export interface MealPlanRoomChildren {
  roomName: null;
  roomCode: null;
  roomDescription: null;
  prices: CheckoutPrices;
  guestInfo: null;
  roomTypeCode: string;
  mealPlanCode: null;
  ratePlanCode: null;
  guestCounts: null;
  rateInfo: null;
  priceNights: PriceNight[];
  textKillerMealPlan?: string;
  discountRewards: number;
}

export interface MealPlanRoomsInfo {
  children: MealPlanRoomChildren[];
}

export enum SPaymentOption {
  PayNow = 'PayNow',
  PayHotel = 'PayHotel',
  PayPBLGuarantee = 'PayPBLGuarantee',
  PayPBLPartial = 'PayPBLPartial',
  PayPBLTotal = 'PayPBLTotal'
}

export enum RatePlanCodes {
  WEB = 'WEB', // cancelable rate
  WGRE_PD = 'FWGRE_PD', // cancelable rate + rewards status
  WEB_ADV = 'WEB_ADV', // economic rate
  WGREPDADV = 'WGREPDADV' // economic rate + rewards status
}

export enum BreadcrumbId {
  ROOM_SELECTION = 'ROOM_SELECTION',
  REGIME_SELECTION = 'REGIME_SELECTION',
  COMPLETE_DATA = 'COMPLETE_DATA'
}

export interface PriceNight extends CheckoutPrices {
  date: string;
  totalPriceBeforeTax: string;
  totalPriceBeforeTaxRewards: string;
  totalRoundedPriceBeforeTax: string;
  totalRoundedPriceBeforeTaxRewards: string;
  touristTax: any;
  price?: string;
}

export interface CheckoutRoomsChild {
  roomName: string;
  roomCode: number;
  roomDescription: string;
  prices: CheckoutPrices;
  guestInfo: {
    persons: number;
  };
  roomTypeCode: string;
  mealPlanCode: number;
  ratePlanCode: string;
  ratePlanCodeRewards: string;
  guestCounts: GuestCountRaw[];
  rateInfo: RateInfoRaw;
  priceNights: PriceNight[];
  urlPrecheckin?: string[];
  totalPriceRewards?: number;
  totalPrice?: number;
  discount: number;
}

export interface CheckoutResponse {
  promoCode: string;
  isCollective: boolean;
  hash: string;
  language: string;
  currency: CurrencyTypeSymbol;
  currencyMXN: string;
  totalNights: number;
  membershipLevel: number;
  originalRooms: any[]; // Temporal solution to enable back navigation, delete and do it properly
  isRewardsActiveOriginalStatus: boolean;
  isRewardsActive: boolean;
  hotelInfo: {
    code: string;
    name: string;
    address: string;
    stars: number;
    image: ImageRaw;
    isAdultsOnly: boolean;
    personNightTaxes: number;
    roomNightTaxes: number;
    personNightType: TaxType;
    roomNightType: TaxType;
    hotelType: string;
    hotelDestination?: string;
    hotelContinent?: string;
    hotelRating?: string;
    hotelCity?: string;
    isMexicanHotel: boolean;
  };
  bookingInfo: {
    startDate: string;
    endDate: string;
  };
  prices: CheckoutPrices;
  ratePlanCode: string;
  ratePlanCodeRewards: string;
  rate: {
    subtitle: string;
    title: string;
    confirmationSubtitle: string;
    policies: any;
  };
  rooms: CheckoutRoomsInfo;
  discountInfo: BreakdownDiscountInfo;
  travelCash?: number;
  literals: Literals;
  todayBooking: boolean;
  booker?: {
    name: string;
    email: string;
    country: string;
    phone: string;
  };
  totalPBLHotel?: number;
  totalPBLNow?: number;
  bookingCode?: number;
  priceWithTravelCashApplied: number;
  externalId?: string;
}

export interface MealPlanModalInfo {
  images: ImageRaw[];
  displayTitle: string;
  displayDescriptionHtml: string;
}

export interface CheckoutMealPlan {
  percentDiscountRewards: number;
  roundedDiscountRewards: string;
  isTooltipAvailable: boolean;
  mealId: number;
  displayName: string;
  modalInfo: MealPlanModalInfo;
  preSelected: boolean;
  prices: CheckoutPrices;
  rooms: MealPlanRoomsInfo;
  discountInfo: BreakdownDiscountInfo;
  sliderImages: ImageRaw[];
}

export interface MealPlansResponse {
  status: string;
  options: CheckoutMealPlan[];
}

export interface PaymentOption {
  id: string;
  isCvvRequired: boolean;
  isSaveCardVisible: boolean;
  displayTitle: string;
  displaySubtitle: string;
  displayAnnounce: string;
  isActive: boolean;
  merchantId: string;
  scriptUrl: string;
}

export interface PaymentMethodsResponse {
  payNow?: PaymentOption | null;
  payHotel?: PaymentOption | null;
  payPBLGuarantee?: PaymentOption | null;
  payPBLPartialPayment?: PaymentOption | null;
  payPBLTotalPayment?: PaymentOption | null;
}

export interface CheckoutCombinedResponse {
  hashResponse: CheckoutResponse;
  mealsResponse: MealPlansResponse;
}

export type CheckoutSource = 'checkout' | 'paybylink';
