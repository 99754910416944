import { Injectable } from '@angular/core';
import { TotalPrices } from '@app/pages/hotel/search-with-dates/components/multi-room-stepper/multi-room-stepper.component';
import { CheckoutMealPlan } from '@app/shared/store/checkout/checkout.model';
import { RoomsSelectedRoomData } from '@app/shared/store/search-dates/rooms-store.model';

import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import {
  HotelWithDatesMultiroomDisplayTag,
  HotelWithDatesMultiroomEcommerceItem,
  HotelWithDatesMultiroomTrackingEvent
} from '@ga-tracking/services/tracking-sites/hotel-with-dates-multiroom/hotel-with-dates-multiroom-track.model';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { HotelRaw } from '@shared/store/hotel/hotel.model.raw';
import { SearchPackRaw, SearchPackRoomRaw, SearchRoomRaw } from '@shared/store/search/search.model.raw';
import { createMomentDate } from '@shared/utils/dates/dates.utils';
import { Moment } from 'moment/moment';
import { RegimeSelectionEcommerceItem, RegimeSelectionTrackingEvent } from '../regime-selection-track/regime-selection-track.model';
import { getHotelContinent } from '@app/shared/utils/continents/continents.utils';

@Injectable({ providedIn: 'root' })
export class HotelWithDatesMultiroomTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackHotelWithDatesMultiroomDisplay(
    hotelResponseRaw: any,
    startDate: Moment,
    endDate: Moment,
    guestQueryParams: Array<{
      [key: number]: number;
    }>,
    numberRooms: string
  ): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'ficha hotel',
      content_type: 'con fechas',
      content_tag1: GoogleTagFunctions.reduceTag(hotelResponseRaw.etiquetas_hotel[0]) ?? 'no info',
      content_tag2: GoogleTagFunctions.reduceTag(hotelResponseRaw.etiquetas_hotel[1]) ?? 'no info',
      content_category: GoogleTagFunctions.reduceTag(hotelResponseRaw.hotelType) ?? 'no info',
      content_subcategory: GoogleTagFunctions.reduceTag(hotelResponseRaw.destino) ?? 'no info',
      content_detail: GoogleTagFunctions.reduceTag(hotelResponseRaw.title) ?? 'no info',
      content_id: GoogleTagFunctions.reduceTag(hotelResponseRaw.backcode) ?? 'no info',
      content_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelResponseRaw.backcode)) ?? 'no info',
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(Number(hotelResponseRaw.estrellas))) ?? 'no info',
      content_score: GoogleTagFunctions.numberTag(Math.round(hotelResponseRaw.info_review_pro?.rating?.OVERALL * 10) / 10) ?? 'no info',
      content_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
      content_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
      content_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
      content_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
      content_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days') + 1) ?? 'no info',
      content_room_nights: GoogleTagFunctions.numberTag(endDate.diff(startDate, 'days')) ?? 'no info',
      content_room_quantity: GoogleTagFunctions.numberTag(numberRooms) ?? 'no info',
      content_n_adult: GoogleTagFunctions.numberTag(guestQueryParams.reduce((sum, guest) => sum + (guest['10'] ?? 0), 0)),
      content_n_child: GoogleTagFunctions.numberTag(guestQueryParams.reduce((sum, guest) => sum + (guest['8'] ?? 0), 0)),
      content_n_junior: GoogleTagFunctions.numberTag(guestQueryParams.reduce((sum, guest) => sum + (guest['9'] ?? 0), 0)),
      content_n_baby: GoogleTagFunctions.numberTag(guestQueryParams.reduce((sum, guest) => sum + (guest['7'] ?? 0), 0)),
      title: `catalonia hotels | ficha con fechas ${GoogleTagFunctions.reduceTag(hotelResponseRaw.title)}`,
      path: `/fechas_${GoogleTagFunctions.reduceTag(hotelResponseRaw.title).replace(' ', '_')}`
    };
    this.trackingService.pushEventClickFromExternalSources<HotelWithDatesMultiroomDisplayTag>(eventData, '1_search');
  }

  public trackClickHotelSummaryOptions(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'hotel summary',
      element: 'link',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewHotelSummaryOptionsModal(section: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: `hotel summary_${GoogleTagFunctions.reduceTag(section)}`,
      element: 'modal',
      link_text: GoogleTagFunctions.reduceTag(section)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickHotelSummaryOptionsInModal(section: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: `hotel summary_${GoogleTagFunctions.reduceTag(section)}`,
      element: 'modal_secciones',
      link_text: GoogleTagFunctions.reduceTag(section)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickHotelSummaryContactInfo(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'hotel summary_informacion',
      element: 'modal_contacto',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickHotelSummaryButtonReserve(section: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: `hotel summary_${section}`,
      element: 'modal_cta_button',
      link_text: 'reserva_habitacion'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickHotelSummaryButtonTransferReserve(section: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: `modal_hotel summary_${GoogleTagFunctions.reduceTag(section)}`,
      element: 'cta_button',
      link_text: 'reserva_transfer',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewBannerRewards(rewardsActive: string, discount: string, r1: string, r2: string, r3: string, r4: string, r5: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: `rewards_${rewardsActive}_${GoogleTagFunctions.prepareDiscountTag(discount)}`,
      element: 'banner',
      r1: GoogleTagFunctions.reduceTag(r1),
      r2: GoogleTagFunctions.reduceTag(r2),
      r3: GoogleTagFunctions.reduceTag(r3),
      r4: GoogleTagFunctions.reduceTag(r4),
      r5: GoogleTagFunctions.reduceTag(r5)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickBannerRewardsLink(rewardsActive: string, discount: string, link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: `rewards_${rewardsActive}_${GoogleTagFunctions.prepareDiscountTag(discount)}`,
      element: 'link',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewBannerRewardsTooltip(rewardsActive: string, discount: string, link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: `rewards_${rewardsActive}_${GoogleTagFunctions.prepareDiscountTag(discount)}`,
      element: 'info_tooltip',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewTariffsRoomsPack(
    pack: SearchPackRaw,
    hotelData: HotelRaw,
    room: SearchPackRoomRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    index: number,
    promoCode?: string
  ) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewItem,
      ecommerce: {
        items: [
          this.setHotelWithDatesMultiroomEcommerceItemPackRooms(pack, hotelData, room, startDate, endDate, allGuestInRooms, rewardsActive, index, promoCode)
        ]
      }
    };

    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackTariffsRoomsPack(
    hotelData: HotelRaw,
    pack: SearchPackRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    promoCode?: string
  ): void {
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({
      event: COMMON_TAG_EVENTS.selectItem,
      ecommerce: {
        items: pack.groupRooms.map((r, index) =>
          this.setHotelWithDatesMultiroomEcommerceItemPackRooms(pack, hotelData, r, startDate, endDate, allGuestInRooms, rewardsActive, index, promoCode)
        )
      }
    });
  }

  public trackClickInfoTariff(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'pack tarifa recomendada',
      element: 'info_button',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewInfoTariff(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      section: 'pack tarifa recomendada',
      element: 'info_button',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomPack(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'pack habitaciones recomendadas',
      element: 'link',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackSelectRoom(
    hotelData: HotelRaw,
    selectedIndexPlan: number,
    room: SearchRoomRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    index: number,
    promoCode?: string,
    multiRoomIndex?: number
  ) {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectItem,
      ecommerce: {
        items: [
          this.setHotelWithDatesMultiroomEcommerceItemIndividualRoom(
            hotelData,
            selectedIndexPlan,
            room,
            startDate,
            endDate,
            allGuestInRooms,
            rewardsActive,
            index,
            promoCode,
            multiRoomIndex
          )
        ]
      }
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewRoom(
    hotelData: HotelRaw,
    selectedIndexPlan: number,
    room: SearchRoomRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    index: number,
    promoCode?: string
  ) {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewItem,
      ecommerce: {
        items: [
          this.setHotelWithDatesMultiroomEcommerceItemIndividualRoom(
            hotelData,
            selectedIndexPlan,
            room,
            startDate,
            endDate,
            allGuestInRooms,
            rewardsActive,
            index,
            promoCode
          )
        ]
      }
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoom(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'link',
      section: 'card habitacion',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewInfoRoomModal(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}`,
      link_text: GoogleTagFunctions.reduceTag(sectionView)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomModalReserveButton(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}_${GoogleTagFunctions.reduceTag(sectionView)}`,
      link_text: 'reserva habitacion'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomModalSection(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: `info habitacion_${GoogleTagFunctions.reduceTag(roomSelected)}_${GoogleTagFunctions.reduceTag(sectionView)}`,
      link_text: GoogleTagFunctions.reduceTag(sectionView)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomTariffInfo(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'card tarifa',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackViewInfoRoomTariffInfo(roomSelected: string, sectionView: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: `info tarifa_${GoogleTagFunctions.reduceTag(roomSelected)}`,
      link_text: GoogleTagFunctions.reduceTag(sectionView)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomModalTariffReserveButton(tariffSelected: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: `info tarifa_${GoogleTagFunctions.reduceTag(tariffSelected)}`,
      link_text: 'reserva habitacion'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickInfoRoomRewardsInfo(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'rewards',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  // TODO: Currently this develop not exist
  public trackViewInfoRoomInactivity(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: '15 minutos inactividad',
      link_text: 'cambio disponibilidad'
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  // TODO: Currently this develop not exist
  public trackClickInfoRoomInactivity(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal',
      section: '15 minutos inactividad',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickContinueButton(section: string, link_url: string): void {
    try {
      this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>({
        event: COMMON_TAG_EVENTS.selectContent,
        element: 'cta_button',
        section: GoogleTagFunctions.reduceTag(section),
        link_text: 'continuar',
        link_url: GoogleTagFunctions.reduceTag(link_url)
      });
    } catch (e) {}
  }

  public trackClickRoomSelection(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'cta_button',
      section: 'resumen habitaciones',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  public trackClickDeleteRoomInCart(num_room: string, room_type: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'eliminar',
      section: 'resumen habitaciones',
      link_text: `room:${num_room}_${GoogleTagFunctions.reduceTag(room_type)}`
    };
    this.trackingService.pushEventClick<HotelWithDatesMultiroomTrackingEvent>(eventData);
  }

  private setHotelWithDatesMultiroomEcommerceItemPackRooms(
    pack: SearchPackRaw,
    hotelData: HotelRaw,
    room: SearchPackRoomRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    index: number,
    promoCode?: string
  ): HotelWithDatesMultiroomEcommerceItem {
    const item_n_adult = GoogleTagFunctions.reduceTag(allGuestInRooms.reduce((sum, guest) => sum + (guest['10'] ?? 0), 0));
    const item_n_child = GoogleTagFunctions.reduceTag(allGuestInRooms.reduce((sum, guest) => sum + (guest['8'] ?? 0), 0));
    const item_n_junior = GoogleTagFunctions.reduceTag(allGuestInRooms.reduce((sum, guest) => sum + (guest['9'] ?? 0), 0));
    const item_n_babie = GoogleTagFunctions.reduceTag(allGuestInRooms.reduce((sum, guest) => sum + (guest['7'] ?? 0), 0));
    return {
      item_name: GoogleTagFunctions.reduceTag(room.rateTitle) ?? 'no info',
      item_id: GoogleTagFunctions.reduceTag(rewardsActive ? room.ratePlanCodeRewards : room.ratePlanCode) ?? 'no info',
      item_regimen: '',
      item_regimen2: '',
      price: rewardsActive ? GoogleTagFunctions.numberTag(room.priceRewards ?? 0) : GoogleTagFunctions.numberTag(room.price ?? 0),
      discount: promoCode || rewardsActive ? GoogleTagFunctions.numberTag(room.discount * -1) ?? 0 : 0,
      item_brand: GoogleTagFunctions.reduceTag(hotelData?.ciudad) ?? 'no info',
      item_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelData?.backcode)) ?? 'no info',
      item_category: hotelData?.hotelType ?? 'no info',
      item_category2: GoogleTagFunctions.reduceTag(room.rateSubtitle) ?? 'no info',
      item_category3: GoogleTagFunctions.reduceTag(hotelData?.estrellas) ?? 'no info',
      item_category4: GoogleTagFunctions.reduceTag(room.roomTags.map((p) => p.text).join('_')) ?? 'no info',
      item_category5: GoogleTagFunctions.numberTag(endDate.diff(startDate, 'days')) ?? 0,
      item_category6: hotelData?.adults_only ? '1' : '0',
      item_category7: GoogleTagFunctions.numberTag(room.priceRewards ?? 0),
      item_rw_discount: GoogleTagFunctions.prepareDiscountTag((room.discountRewards * 100) / room.price) ?? 0,
      item_variant: GoogleTagFunctions.reduceTag(room.roomName) ?? 'no info',
      item_variant2: GoogleTagFunctions.reduceTag(room.roomTypeCode) ?? 'no info',
      item_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
      item_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
      item_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
      item_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
      item_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0,
      item_n_adult: GoogleTagFunctions.numberTag(item_n_adult) ?? 0,
      item_n_child: GoogleTagFunctions.numberTag(item_n_child) ?? 0,
      item_n_junior: GoogleTagFunctions.numberTag(item_n_junior) ?? 0,
      item_n_babie: GoogleTagFunctions.numberTag(item_n_babie) ?? 0,
      item_list_name: `ficha con fechas_${GoogleTagFunctions.reduceTag(hotelData?.title)}_pack recomendado`,
      item_list_id: `ficha con fechas_${GoogleTagFunctions.reduceTag(hotelData?.backcode)}_pack recomendado`,
      index: GoogleTagFunctions.numberTag(index + 1),
      quantity: 1
    };
  }

  private setHotelWithDatesMultiroomEcommerceItemIndividualRoom(
    hotelData: HotelRaw,
    selectedIndexPlan: number,
    room: SearchRoomRaw,
    startDate: Moment,
    endDate: Moment,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    rewardsActive: boolean,
    index: number,
    promoCode?: string,
    multiRoomIndex: number = 0
  ): HotelWithDatesMultiroomEcommerceItem {
    const item_n_adult = GoogleTagFunctions.reduceTag(allGuestInRooms[multiRoomIndex]['10'] ?? 0);
    const item_n_child = GoogleTagFunctions.reduceTag(allGuestInRooms[multiRoomIndex]['8'] ?? 0);
    const item_n_junior = GoogleTagFunctions.reduceTag(allGuestInRooms[multiRoomIndex]['9'] ?? 0);
    const item_n_babie = GoogleTagFunctions.reduceTag(allGuestInRooms[multiRoomIndex]['7'] ?? 0);

    const firstMealPlan = room.ratePlans[selectedIndexPlan].mealPlans[0];
    const rewardsPrice = firstMealPlan.priceRewards ? GoogleTagFunctions.numberTag(firstMealPlan.priceRewards ?? 0) : null;
    const price = firstMealPlan.price ? GoogleTagFunctions.numberTag(firstMealPlan.price ?? 0) : null;

    return {
      item_name: GoogleTagFunctions.reduceTag(room.ratePlans[selectedIndexPlan].rateTitle) ?? 'no info',
      item_id:
        GoogleTagFunctions.reduceTag(rewardsActive ? room.ratePlans[selectedIndexPlan].ratePlanCodeRewards : room.ratePlans[selectedIndexPlan].ratePlanCode) ??
        'no info',
      price: rewardsActive ? rewardsPrice ?? 0 : price ?? 0,
      discount: promoCode || rewardsActive ? GoogleTagFunctions.numberTag(firstMealPlan.discount * -1) ?? 0 : 0,
      item_regimen: '',
      item_regimen2: '',
      item_brand: GoogleTagFunctions.reduceTag(hotelData?.ciudad) ?? 'no info',
      item_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelData?.backcode)) ?? 'no info',
      item_category: GoogleTagFunctions.reduceTag(hotelData.hotelType) ?? 'no info',
      item_category2: GoogleTagFunctions.reduceTag(room.ratePlans[selectedIndexPlan].rateSubtitle) ?? 'no info',
      item_category3: GoogleTagFunctions.reduceTag(Math.floor(Number(hotelData.estrellas))) ?? 'no info',
      item_category4: GoogleTagFunctions.reduceTag(room.roomTags.map((p) => p.text).join('_')) ?? 'no info',
      item_category5: GoogleTagFunctions.numberTag(endDate.diff(startDate, 'days')) ?? 0,
      item_category6: hotelData?.adults_only ? '1' : '0',
      item_category7: rewardsPrice ?? 0,
      item_rw_discount: GoogleTagFunctions.prepareDiscountTag((firstMealPlan.discountRewards * 100) / firstMealPlan.price) ?? 0,
      item_variant: GoogleTagFunctions.reduceTag(room.roomName) ?? 'no info',
      item_variant2: GoogleTagFunctions.reduceTag(room.roomTypeCode) ?? 'no info',
      item_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
      item_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
      item_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
      item_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
      item_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0,
      item_n_adult: GoogleTagFunctions.numberTag(item_n_adult) ?? 0,
      item_n_child: GoogleTagFunctions.numberTag(item_n_child) ?? 0,
      item_n_junior: GoogleTagFunctions.numberTag(item_n_junior) ?? 0,
      item_n_babie: GoogleTagFunctions.numberTag(item_n_babie) ?? 0,
      index: GoogleTagFunctions.numberTag(index + 1),
      quantity: 1,
      item_list_name: `ficha con fechas_${GoogleTagFunctions.reduceTag(hotelData?.title)}_room${index + 1}`,
      item_list_id: `ficha con fechas_${GoogleTagFunctions.reduceTag(hotelData?.backcode)}_room${index + 1}`
    };
  }

  public trackClickAddToCart(
    totals: TotalPrices | null,
    selectedIndexPlan: number,
    rooms: RoomsSelectedRoomData[],
    calendar: { startDate: string; endDate: string },
    mealPlan: CheckoutMealPlan,
    isRewardsActive: boolean,
    hotelData: HotelRaw,
    numberOfNights: number,
    allGuestInRooms: Array<{
      [key: number]: number;
    }>,
    promoCode: string = ''
  ): void {
    const startDate = createMomentDate(calendar.startDate);
    const endDate = createMomentDate(calendar.endDate);
    const promotions = mealPlan.discountInfo.discounts.filter((d) => d.promotionCode !== 'REWARDS07');

    const eventData = {
      event: COMMON_TAG_EVENTS.addToCart,
      ecommerce: {
        currency: totals?.currency === 'US$' || totals?.currency === '$' ? 'USD' : 'EUR',
        value: isRewardsActive
          ? GoogleTagFunctions.numberTag(mealPlan.prices.totalPriceRewards ?? 0)
          : GoogleTagFunctions.numberTag(mealPlan.prices.totalPrice ?? 0),
        items: rooms.map((room, index) => {
          return {
            item_name: GoogleTagFunctions.reduceTag(room.room?.ratePlans[selectedIndexPlan].rateTitle) ?? 'no info',
            item_id:
              GoogleTagFunctions.reduceTag(
                isRewardsActive ? room.room?.ratePlans[selectedIndexPlan].ratePlanCodeRewards : room.room?.ratePlans[selectedIndexPlan].ratePlanCode
              ) ?? 'no info',
            price: isRewardsActive
              ? GoogleTagFunctions.numberTag(mealPlan.rooms.children[index].prices.totalPriceRewards ?? 0)
              : GoogleTagFunctions.numberTag(mealPlan.rooms.children[index].prices.totalPrice ?? 0),
            discount: isRewardsActive || promoCode !== '' ? GoogleTagFunctions.numberTag(Math.abs(mealPlan.rooms.children[index].discountRewards)) : 0,
            item_regimen: GoogleTagFunctions.reduceTag(mealPlan?.displayName) ?? 'no info',
            item_regimen2: GoogleTagFunctions.reduceTag(mealPlan?.mealId) ?? 'no info',
            item_brand: GoogleTagFunctions.reduceTag(hotelData.ciudad) ?? 'no info',
            item_place: GoogleTagFunctions.reduceTag(getHotelContinent(hotelData.backcode)) ?? 'no info',
            item_category: GoogleTagFunctions.reduceTag(hotelData.hotelType) ?? 'no info',
            item_category2: GoogleTagFunctions.reduceTag(room.room?.ratePlans[selectedIndexPlan].rateSubtitle.trim()),
            item_category3: GoogleTagFunctions.reduceTag(Math.floor(Number(hotelData.estrellas))) ?? 'no info',
            item_category4: GoogleTagFunctions.reduceTag(promotions.map((p) => p.displayText).join('_')) ?? 'no info',
            item_category5: GoogleTagFunctions.numberTag(numberOfNights) ?? 0,
            item_category6: hotelData.adults_only ? '1' : '0',
            item_category7: GoogleTagFunctions.numberTag(mealPlan.rooms.children[index].prices.totalPriceRewards ?? 0),
            item_rw_discount: GoogleTagFunctions.prepareDiscountTag(Number(mealPlan.discountInfo.percent)) ?? 0,
            item_variant: GoogleTagFunctions.reduceTag(room.room?.roomName) ?? 'no info',
            item_variant2: GoogleTagFunctions.reduceTag(room.room?.roomTypeCode) ?? 'no info',
            item_chekin_date: startDate.format('YYYYMMDD') ?? 'no info',
            item_chekin_day: GoogleTagFunctions.reduceTag(startDate.locale('es').format('dddd')) ?? 'no info',
            item_chekout_date: endDate.format('YYYYMMDD') ?? 'no info',
            item_chekout_day: GoogleTagFunctions.reduceTag(endDate.locale('es').format('dddd')) ?? 'no info',
            item_chekin_advance_days: GoogleTagFunctions.numberTag(startDate.diff(createMomentDate(), 'days')) ?? 0, // 34 y deberia ser 31
            item_n_adult: GoogleTagFunctions.numberTag(allGuestInRooms[index]['10'] ?? 0),
            item_n_child: GoogleTagFunctions.numberTag(allGuestInRooms[index]['8'] ?? 0),
            item_n_junior: GoogleTagFunctions.numberTag(allGuestInRooms[index]['9'] ?? 0),
            item_n_babie: GoogleTagFunctions.numberTag(allGuestInRooms[index]['7'] ?? 0),
            item_list_name: `selector regimen_${GoogleTagFunctions.reduceTag(hotelData.title)}`,
            item_list_id: `selector regimen_${GoogleTagFunctions.reduceTag(hotelData.backcode)}`,
            index: GoogleTagFunctions.numberTag(index + 1),
            quantity: 1
          };
        }) as RegimeSelectionEcommerceItem[]
      }
    };
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<RegimeSelectionTrackingEvent>(eventData);
  }
}
