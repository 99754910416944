<button [ngClass]="classes" (click)="onClick($event)">
  <app-icon-item *ngIf="icon && align === 'left'" [icon]="icon"></app-icon-item>
  <ng-container *ngIf="label">
    <span class="label">
      {{ label }}
    </span>
  </ng-container>
  <ng-content select="img[data-social-img]"></ng-content>
  <app-icon-item *ngIf="icon && align === 'right'" [icon]="icon"></app-icon-item>
</button>
