import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconItemComponent } from '../icon-item/icon-item.component';
import { NgClass, NgIf } from '@angular/common';

const getStyle = (...args: string[]) => ['cds-btn', ...args].filter(Boolean);
/* Solve conflicts -- review */
export type BtnSize = 'cds-btn--l' | 'cds-btn--m';
@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, IconItemComponent],
  host: {
    class: 'cds-btn'
  }
})
export class ButtonComponent {
  @Input()
  singin = false;

  @Input() label!: string | null;

  @Input()
  type: 'cds-btn--regular' | 'cds-btn--highlight' | 'cds-btn--link-icon' | 'cds-btn--link' | 'cds-btn--link-inline' = 'cds-btn--regular';

  @Input()
  fontType: '' | 'cds-btn--link-f-s' | 'cds-btn--link-f-xs' | 'cds-btn--link-f-xxs' | undefined;

  @Input()
  fontWeight: '' | 'cds-btn--fw-light' | 'cds-btn--fw-regular' | 'cds-btn--fw-semibold' | undefined;

  @Input()
  // size = 'cds-btn--l';
  size: BtnSize = 'cds-btn--l';

  @Input()
  icon = '';

  @Input()
  disabled = false;

  @Input()
  fullWidth = false;

  @Input()
  fillMobile = false;

  @Input()
  dark = false;

  @Input()
  align: 'left' | 'right' = 'left';

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter();

  @Input()
  fill = false;

  public get classes(): string[] {
    const dark = this.dark ? 'cds-btn--light' : 'cds-btn--dark';
    const atr = this.disabled ? 'disabled' : '';
    const fullWidth = this.fullWidth ? 'cds-btn--f-w' : '';
    const fill = this.fill ? 'cds-btn--fill' : '';
    const width = this.fullWidth ? 'f-width' : '';
    const fillMobile = this.fillMobile ? 'cds-btn--fill-mobile' : '';
    const singin = this.singin ? 'cds-link--singin' : '';
    return [
      'cds-btn',
      dark,
      fullWidth,
      atr,
      `${this.size}`,
      `${this.type}`,
      `${this.fontWeight}`,
      `${this.align}`,
      `${this.fontType}`,
      fill,
      fillMobile,
      singin
    ];
  }
  onClick(ev: Event) {
    if (!this.disabled) {
      this.buttonClick.emit(ev);
    }
  }
}
