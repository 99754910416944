<div class="cds-mat-input" [class.input-error]="errors">
  <mat-form-field [ngClass]="classes" class="cds-input-selector">
    <mat-label></mat-label>
    <mat-select (blur)="onBlur()" [disabled]="isDisabled" [value]="value" [placeholder]="placeholder" (selectionChange)="selectionChange($event)">
      <ng-container *ngFor="let option of options">
        <mat-option [value]="option.code">{{ option.name }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="errors">
    <div class="error-block">
      <p class="error-message">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9974 18.3346C14.5998 18.3346 18.3307 14.6037 18.3307 10.0013C18.3307 5.39893 14.5998 1.66797 9.9974 1.66797C5.39502 1.66797 1.66406 5.39893 1.66406 10.0013C1.66406 14.6037 5.39502 18.3346 9.9974 18.3346ZM9.42936 5.45581H10.5657V12.274H9.42936V5.45581ZM9.42936 13.4103H10.5657V14.5467H9.42936V13.4103Z"
            fill="#DD554D"
          />
        </svg>
        <ng-container *ngIf="errors.required">
          <span>{{ literals.required_input }}</span>
        </ng-container>
      </p>
    </div>
  </ng-container>
</div>
