import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';

import { ButtonComponent } from '@atom/button/button.component';
import { TagComponent } from '@atom/tag/tag.component';
import { HotelWithDatesMultiroomTrackService } from '@ga-tracking/services/tracking-sites/hotel-with-dates-multiroom/hotel-with-dates-multiroom-track.service';
import { CdkDialogModule } from '@molecules/cdk-dialog/cdk-dialog.component.module';
import { Gallery, GalleryComponent } from '@molecules/gallery/gallery.component';
import { RoomModalInfoComponent } from '@molecules/modals/room-modal/room-modal-info/room-modal-info.component';
import { PluralizePipe } from '@pipes/words/pluralize-words.pipe';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { ImageRaw, Literals, Tag } from '@shared/logic/models/common.model';
import { getFileType } from '@shared/utils/files/files.utils';

export interface RoomModalInput {
  literals?: Literals;
  room: {
    displayName: string;
    tags?: Tag[];
    info: any;
    images: any;
  };
  actionButton?: {
    price: number;
    currency: string;
    totalNights?: number;
  };
}

@Component({
  standalone: true,
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    NgForOf,
    NgClass,
    TagComponent,
    ButtonComponent,
    DecimalPipe,
    MatTabsModule,
    RoomModalInfoComponent,
    CdkDialogModule,
    PluralizePipe,
    GalleryComponent
  ]
})
export class RoomModalComponent extends ModalElement<RoomModalComponent, RoomModalInput> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('hotelAward') hotelAward!: ElementRef;
  @ViewChild('modalHead') modalHead!: ElementRef;
  @ViewChild('tabs') modalTab!: ElementRef;
  @ViewChild('scrollBlockElement') scrollBlockElement!: ElementRef;

  public isHeadScrolling = false;
  public modalHeadClass = '';
  public data: RoomModalInput = this.getDialogData();
  public gallery: Gallery[] = this.transformToGallery(this.data.room.images);
  private _headHeight: number;
  private _tabSelected = 'informacion';

  constructor(
    @Inject(DIALOG_DATA) dialogData: RoomModalInput,
    dialogRef: DialogRef<any, RoomModalComponent>,
    private _renderer: Renderer2,
    private trackService: HotelWithDatesMultiroomTrackService
  ) {
    super(dialogData, dialogRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this._trackingGA();
  }

  public ngAfterViewInit(): void {
    this._headHeight = Math.floor(this.modalHead.nativeElement.getBoundingClientRect().height);
    this._renderer.listen(this.scrollBlockElement.nativeElement, 'scroll', () => this.onScroll());
  }

  // TODO: Remove someday when a normalizer do this.
  public transformToGallery(data: { [key: string]: ImageRaw[] | undefined }): Gallery[] {
    const gallery: Gallery[] = [];
    for (const category in data) {
      const currentGallery: Gallery = {
        category: category,
        img: '',
        galleryImages: [],
        images: []
      };

      data[category]?.forEach((imageData: ImageRaw) => {
        const type = getFileType(imageData.webp);
        const description = imageData.atributos.descriptivo;
        // add imageRaw
        currentGallery.images.push(imageData);
        currentGallery.galleryImages.push({
          type: type,
          source: imageData.webp,
          description: description
        });
      });

      if (currentGallery.galleryImages.length > 0) {
        currentGallery.img = data[category]?.[0]?.webp || '';
        currentGallery.imgRaw = data[category]?.[0];
        gallery.push(currentGallery);
      }
    }
    return gallery;
  }

  public onScroll(): void {
    const tabVariable = this.modalTab.nativeElement.getBoundingClientRect().y;

    if (tabVariable <= 0) {
      this.modalHeadClass = 'head--sticky-in';
      this.isHeadScrolling = true;
    } else {
      this.modalHeadClass = '';
      this.isHeadScrolling = false;
    }
  }

  public onSendDataSelectedForGetHash(): void {
    this.trackService.trackClickInfoRoomModalReserveButton(this.data.room.displayName, this._tabSelected);
    this.onAccept();
  }

  public changeTabSelected(tabEvent: MatTabChangeEvent): void {
    this._tabSelected = tabEvent.tab.textLabel;
    this.trackService.trackClickInfoRoomModalSection(this.data.room.displayName, this._tabSelected);
  }

  public onAccept(): void {
    this.closeDialog({ success: true });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }

  private _trackingGA(): void {
    this.trackService.trackViewInfoRoomModal(this.data.room.displayName, 'informacion');
  }
}
