import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cdk-dialog-wrapper',
  templateUrl: './cdk-dialog-wrapper.component.html',
  styleUrls: ['./cdk-dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CdkDialogWrapperComponent {
  @Output() readonly ev_close = new EventEmitter<void>();
  public close(): void {
    this.ev_close.emit();
  }
}
