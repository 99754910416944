import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ButtonComponent } from '@atom/button/button.component';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { CdkDialogWrapperNewModalInput, ModalResponse } from '@shared/logic/models/common.model';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';

export enum ActionType {
  Accept = 'Accept',
  Cancel = 'Cancel'
}

interface ModalAction {
  text: string;
  action: ActionType;
}

export interface SimpleModalInput extends CdkDialogWrapperNewModalInput {
  messages: string[]; // Also prepared to receive Message objet with extra info from outside. Pending GDI
  actions?: ModalAction[];
}

@Component({
  standalone: true,
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
  imports: [CdkDialogWrapperNewModule, ButtonComponent, NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleModalComponent extends ModalElement<SimpleModalComponent, SimpleModalInput> {
  public readonly Action = ActionType;
  // Default action, check default literal
  public actions: ModalAction[] = this.getDialogData().actions || [{ text: 'Aceptar', action: ActionType.Accept }];

  private readonly _actionHandler: { [key in ActionType]: () => void } = {
    [ActionType.Accept]: () => this.onAccept(),
    [ActionType.Cancel]: () => this.cancelAction(false)
  };

  constructor(@Inject(DIALOG_DATA) dialogData: SimpleModalInput, dialogRef: DialogRef<ModalResponse, SimpleModalComponent>) {
    super(dialogData, dialogRef);
  }

  public activeAction(action: ActionType): void {
    if (this._actionHandler[action]) {
      this._actionHandler[action]();
    }
  }

  public cancelAction(wrapperAction: boolean): void {
    this.closeDialog({ success: false, customData: { wrapperAction } });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }

  public onAccept(): void {
    this.closeDialog({ success: true });
  }
}
