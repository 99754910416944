import { FileCategory, ImageFunctions } from '@shared/logic/functions/image.functions';
import { HeroBanner, HeroBannerRaw } from '@shared/store/home/hero/hero.model';

export class HeroNormalizer {
  static normalize(heroBanners: HeroBannerRaw[]): HeroBanner[] {
    return heroBanners.map((i) => HeroNormalizer.normalizeHeroBanner(i));
  }

  static normalizeHeroBanner(heroRaw: HeroBannerRaw): HeroBanner {
    const item = {} as HeroBanner;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const fileType = HeroNormalizer.extractFileTypeFromOriginal(heroRaw.media.original);

    item.user = heroRaw.user;
    item.title = heroRaw.titulo;
    item.subtitle = heroRaw.descripcion;
    item.image = heroRaw.media;
    item.logo = heroRaw.logo_header;
    item.links = heroRaw.enlaces;

    item.mediaType = heroRaw.media.hasOwnProperty('video') ? 'video' : 'image';

    if (item.mediaType === 'video') {
      // @ts-ignore
      item.videoSrc = heroRaw.media.video;
    } else {
      item.altTxt = heroRaw.media.atributos.titulo;
    }

    item.gtmTracking = {
      hotelName: 'na', // TBD on back side
      hotelId: 'na', // TBD on back side
      hotelCity: 'na', // TBD on back side
      hotelCategory: 'na', // TBD on back side =>  // urbano, vacacional, resort...
      hotelStars: 'na', // TBD on back side
      promotionId: `banner hero home_${fileType}`,
      promotionName: heroRaw.datalayer,
      ctaDisplayText: heroRaw.enlaces.desktop[0].title, // TODO: This will change to an object in future.
      displayedContentLocation: 'top home',
      index: null // To be filled on ngFor side...
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static extractFileTypeFromOriginal(originalMedia: string): 'gif' | 'foto' | 'video' | 'UNKNOWN' {
    const fileType: FileCategory | null = ImageFunctions.classifyFileType(originalMedia);
    if (!fileType) {
      return 'UNKNOWN';
    }
    switch (fileType) {
      case 'IMAGE':
        return 'foto';
      case 'GIF':
        return 'gif';
      case 'VIDEO':
        return 'video';
      default:
        return 'UNKNOWN';
    }
  }
}
