import { CardTokenizedResponseRaw } from '@app/shared/ns_components/datatrans-widget/secure-fields.model';

import { UserCardPayment } from './payment.model';
import { UserCardPaymentRaw } from './payment.model.raw';

export class PaymentDenormalizer {
  public static denormalizeUserCardPayment(card: UserCardPayment): UserCardPaymentRaw {
    const itemRaw = {} as UserCardPaymentRaw;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    itemRaw.CardType = card.brandCode;
    itemRaw.Token = card.token;
    itemRaw.TokenMask = card.cardMask;
    itemRaw.Holder = card.holderName;
    itemRaw.ExpirationDate = card.expirationDate;
    itemRaw.Block = card.block;
    itemRaw.IsDefault = card.isSetAsDefault;
    itemRaw.RegisteredDate = card.registrationDate;
    itemRaw.RegisteredTime = card.registrationTime;
    itemRaw.Fingerprint = card.fingerprint;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return itemRaw;
  }

  public static denormalizeCardTokenized(
    holderName: string,
    expireDate: string,
    cardTokenized: CardTokenizedResponseRaw
  ) {
    const now = new Date();
    return {
      CardType: cardTokenized.data.paymentMethod,
      Token: cardTokenized.data.alias,
      TokenMask: cardTokenized.data.maskedCard,
      Holder: holderName,
      ExpirationDate: expireDate,
      Block: '', // TODO: check what is this
      IsDefault: false, // TODO: check when it must be set to true
      RegisteredDate: `/Date(${now.getTime()})/`,
      RegisteredTime: `PT${now.getHours()}H${now.getMinutes()}M${now.getSeconds()}S`,
      Fingerprint: cardTokenized.data.fingerprint
    } as UserCardPaymentRaw;
  }

  public static denormalizeAddCardsRequest(
    idMember: string,
    holderName: string,
    expireDate: string,
    cards: UserCardPayment[],
    cardTokenized: CardTokenizedResponseRaw
  ): UserCardPaymentRaw[] {
    const denormalizedCards = cards.map(PaymentDenormalizer.denormalizeUserCardPayment);

    const newCard = PaymentDenormalizer.denormalizeCardTokenized(holderName, expireDate, cardTokenized);

    return [...denormalizedCards, newCard];
  }

  public static denormalizeRemoveCardRequest(cards: UserCardPayment[], cardToken: string): UserCardPaymentRaw[] {
    return cards.filter((c) => c.token !== cardToken).map(PaymentDenormalizer.denormalizeUserCardPayment);
  }
}
