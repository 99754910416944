import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { CarouselBaseDirective } from './carousel-base.directive';

@NgModule({
  imports: [SharedModule],
  declarations: [CarouselBaseDirective],
  exports: [CarouselBaseDirective],
  schemas: []
})
export class CarouselDirectiveModule {}
