import { createAction } from '@ngrx/store';

import { AuthProviderData, User } from './auth.model';

export const enum AuthActionsTypes {
  SetAuthStatus = '[Auth] Set Auth Status',
  SetAuthUser = '[Auth] Set Auth User',
  SetCurrencyPreferred = '[Auth] Set Currency Preferred',
  SetTravelCashUSD = '[Auth] Set Travel Cash Usd',
  SetAuthProviderData = '[Auth] Set Auth Provider Data'
}

export const setAuthStatus = createAction(AuthActionsTypes.SetAuthStatus, ({ isLoggedIn }: { isLoggedIn: boolean }) => ({
  payload: { isLoggedIn }
}));

export const setAuthUser = createAction(AuthActionsTypes.SetAuthUser, ({ user }: { user: User | null }) => ({
  payload: { user }
}));

export const setAuthProviderData = createAction(AuthActionsTypes.SetAuthProviderData, ({ providerData }: { providerData: AuthProviderData | null }) => ({
  payload: { providerData }
}));

export const setCurrencyPreferred = createAction(AuthActionsTypes.SetCurrencyPreferred, ({ currency }: { currency: string }) => ({
  payload: { currency }
}));

export const setTravelCashUSD = createAction(AuthActionsTypes.SetTravelCashUSD, ({ travelCashUSD }: { travelCashUSD: number | undefined }) => ({
  payload: { travelCashUSD }
}));
