import { Injectable } from '@angular/core';

import { PreFooter } from '@app/shared/store/pre-footer/pre-footer.model';
import { PreFooterApiService } from '@shared/logic/http/api/pre-footer.api.service';
import { PreFooterResponseRaw } from '@shared/store/pre-footer/pre-footer.model.raw';
import { PreFooterNormalizer } from '@shared/store/pre-footer/pre-footer.normalizer';
import { PreFooterStoreAdapter } from '@shared/store/pre-footer/pre-footer.store.adapter';
import { forkJoin, map, Observable, tap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

enum PreFooterConstants {
  SPAIN = '0',
  EUROPE = '1',
  AMERICA = '2',
  AFRICA = '3'
}

@Injectable({
  providedIn: 'root'
})
export class PreFooterService {
  constructor(
    private readonly _preFooterApiService: PreFooterApiService,
    private readonly _preFooterStoreAdapter: PreFooterStoreAdapter
  ) {}

  public loadPreFooter(): void {
    forkJoin([
      this._getPreFooterById$(PreFooterConstants.SPAIN).pipe(
        map((r) => (r as PreFooterResponseRaw).data?.destinos ?? [])
      ),
      this._getPreFooterById$(PreFooterConstants.EUROPE).pipe(
        map((r) => (r as PreFooterResponseRaw).data?.destinos ?? [])
      ),
      this._getPreFooterById$(PreFooterConstants.AMERICA).pipe(
        map((r) => (r as PreFooterResponseRaw).data?.destinos ?? [])
      ),
      this._getPreFooterById$(PreFooterConstants.AFRICA).pipe(
        map((r) => (r as PreFooterResponseRaw).data?.destinos ?? [])
      )
    ])
      .pipe(
        map(([spain, europe, america, africa]) => ({ spain, europe, america, africa })),
        tap((preFooter) => {
          this._preFooterStoreAdapter.setPreFooter(preFooter);
          // Needed to separate logic from preFooter and search. Delete bottom the day Search has been refactored
          this._preFooterStoreAdapter.setPreFooterNormalized({
            spain: preFooter.spain.map(PreFooterNormalizer.normalizeDestiny),
            europe: preFooter.europe.map(PreFooterNormalizer.normalizeDestiny),
            america: preFooter.america.map(PreFooterNormalizer.normalizeDestiny),
            africa: preFooter.africa.map(PreFooterNormalizer.normalizeDestiny)
          });
        })
      )
      .subscribe();
  }

  // Uncomment mapper line the day Search has been refactored
  private _getPreFooterById$(id: string, normalize = false): Observable<PreFooter | PreFooterResponseRaw> {
    return this._preFooterApiService.getPreFooterById$(id).pipe(
      map((response: PreFooterResponseRaw) => (normalize ? PreFooterNormalizer.normalize(response, id) : response)),
      shareReplay()
    );
  }
}
