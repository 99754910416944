<section class="cds-form-register">
  <div class="cds-form-register__form">
    <div class="form-login">
      <div class="title">
        <p>{{ data.createAccount.titleLogin }}</p>
      </div>
      <form class="form" [formGroup]="registerForm">
        <!-- Input Nombre -->
        <div class="form-input-div">
          <gdi-cat-input-text
            [size]="'cds-input-text--m'"
            [style]="'cds-input-text--regular'"
            formControlName="name"
            [required]="true"
            placeholder="{{ data.createAccount.formData.inputName }}"
            noNumbersOrSpecialChars
          ></gdi-cat-input-text>
        </div>

        <!-- Input Apellidos -->
        <div class="form-input-div">
          <gdi-cat-input-text
            [size]="'cds-input-text--m'"
            [style]="'cds-input-text--regular'"
            formControlName="surname"
            [required]="true"
            placeholder="{{ data.createAccount.formData.inputSurname }}"
            noNumbersOrSpecialChars
          ></gdi-cat-input-text>
        </div>

        <!-- Input Email -->
        <div class="form-input-div">
          <gdi-cat-input-text
            [size]="'cds-input-text--m'"
            [style]="'cds-input-text--regular'"
            formControlName="email"
            [required]="true"
            placeholder="{{ data.createAccount.formData.inputMail }}"
          ></gdi-cat-input-text>
        </div>

        <!-- Input Pais -->
        <div class="form-input-div">
          <!-- <app-input-select [formInput]="true" [multiple]="false" placeholder="prueba" defaultValue=""></app-input-select> -->
          <gdi-cat-input-select
            formControlName="country"
            [formInput]="true"
            [options]="countriesSig()"
            placeholder="{{ data.createAccount.formData.inputCountry }}"
          ></gdi-cat-input-select>
        </div>

        <div class="form-input-div">
          <gdi-cat-input-password
            [size]="'cds-input-text--m'"
            [style]="'cds-input-text--regular'"
            formControlName="password"
            placeholder="{{ literals.newPassword }}"
          ></gdi-cat-input-password>
        </div>

        <div class="form-input-div">
          <gdi-cat-input-password
            #repeatPassword
            [size]="'cds-input-text--m'"
            [style]="'cds-input-text--regular'"
            formControlName="repeatPassword"
            placeholder="{{ literals.repeatPassword }}"
            errorMsg="{{ this.registerForm.errors?.passwordNotMatch && !!repeatPassword.value ? literals.error_pwd_mismatch : null }}"
          ></gdi-cat-input-password>
        </div>

        <div class="form-cta">
          <mat-checkbox class="checkbox-btn checkbox-as-checkbox" formControlName="termsAndConditionsAccepted" (click)="checkErrorFormFieldsToGATracking()">
            <p
              class="text-checkbox"
              [innerHTML]="literals.accept_term_conditions.replace('%link%', urlRewardsConditionLanguage()).replace('%link2%', urlPrivacyPolicyLanguage())"
            ></p>
          </mat-checkbox>
          <mat-checkbox class="checkbox-btn checkbox-btn--reverse checkbox-as-checkbox" formControlName="communicationsRejected">
            <p class="text-checkbox text-checkbox--long">
              {{ data.createAccount.leganText }}
              <!-- <span><app-tooltip tooltipText="Tooltiptext" desc="" title=""></app-tooltip></span> -->
            </p>
          </mat-checkbox>
        </div>

        <app-button
          (buttonClick)="onClickRegister()"
          type="cds-btn--highlight"
          [fill]="true"
          size="cds-btn--m"
          label="{{ data.createAccount.ctaButton }}"
          [disabled]="registerForm.invalid"
        ></app-button>
      </form>
      <div class="separator">{{ data.basicInfo.separator }}</div>
      <div class="social-login">
        <div class="row">
          <button class="btn-social-login" (click)="onClickSocialProvider('google')">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.6 10.2273C19.6 9.51825 19.5364 8.83643 19.4182 8.18188H10V12.0501H15.3818C15.15 13.3001 14.4455 14.3592 13.3864 15.0682V17.5773H16.6182C18.5091 15.8364 19.6 13.2728 19.6 10.2273Z"
                fill="#4285F4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99886 19.9999C12.6989 19.9999 14.9625 19.1044 16.617 17.5772L13.3852 15.0681C12.4898 15.6681 11.3443 16.0226 9.99886 16.0226C7.39432 16.0226 5.18977 14.2635 4.40341 11.8999H1.0625V14.4908C2.70795 17.759 6.08977 19.9999 9.99886 19.9999Z"
                fill="#34A853"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.40455 11.8999C4.20455 11.2999 4.09091 10.659 4.09091 9.99994C4.09091 9.34085 4.20455 8.69994 4.40455 8.09994V5.50903H1.06364C0.386364 6.85903 0 8.38631 0 9.99994C0 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.8999Z"
                fill="#FBBC05"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99886 3.97727C11.467 3.97727 12.7852 4.48182 13.8216 5.47273L16.6898 2.60455C14.958 0.990909 12.6943 0 9.99886 0C6.08977 0 2.70795 2.24091 1.0625 5.50909L4.40341 8.1C5.18977 5.73636 7.39432 3.97727 9.99886 3.97727Z"
                fill="#EA4335"
              />
            </svg>
            <span>{{ data.createAccount.formSocialLogin.input01 }}</span>
          </button>

          <app-button
            class="app-button app-button--facebook"
            type="cds-btn--regular"
            [fill]="true"
            size="cds-btn--m"
            label="{{ data.createAccount.formSocialLogin.input02 }}"
            icon="{{ data.createAccount.formSocialLogin.inputIcon02 }}"
            (click)="onClickSocialProvider('facebook')"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</section>
