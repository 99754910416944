import { Terms } from '@app/shared/ns_components/modals/hotel-modal/hotel-modal.component';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CurrencyTypeSymbol, DiscountRaw } from '@shared/logic/models/common.model';
import { BreakdownDiscountInfo } from '@shared/ns_components/breakdowns/models/breakdown.model';
import { ResultsWarning } from '@shared/ns_components/gdi_extracted/results-warning/results-warning.component';
import { ExtendedBooking } from '@shared/store/booking/models/booking-extended.model';
import { LandingResultsWarning } from '@shared/store/landing/model/destiny-seo.model';
import { Offer } from '@shared/store/search/search.model';

const DEFAULT_OFFERS = [
  {
    icon: 'descuento-generico',
    title: '-20% en todas las habitaciones',
    description: ['Descripción A de la oferta, lorem ipsum dolor sit amet, consectetur adipiscing elit.']
  },
  {
    icon: 'ninos-gratis',
    title: '1 niño gratis',
    description: ['Descripción B de la oferta, lorem ipsum dolor sit amet, consectetur adipiscing elit.']
  },
  {
    icon: 'habitacion-superior',
    title: '-100 € en habitaciones superiores',
    description: ['Descripción C de la oferta, lorem ipsum dolor sit amet, consectetur adipiscing elit.']
  }
];

export class CommonGdiHelper {
  static createDefaultModalOffer() {
    return {
      promoPosition: true,
      promo: true,
      offerDiscount: '99',
      offers: DEFAULT_OFFERS
    };
  }

  static createModalOfferList(items?: Offer[]): Terms[] {
    if (!GeneralFunctions.isArrayLike(items, 1)) {
      return DEFAULT_OFFERS;
    }

    return items!.map((i) => ({
      icon: i.displayIcon,
      title: i.displayTitle,
      description: [i.displayDescription]
    }));
  }

  static createResultsWarning(itemRaw: LandingResultsWarning | ExtendedBooking, type: 'landing' | 'booking'): ResultsWarning {
    if (type === 'booking') {
      const { data, literals } = itemRaw as ExtendedBooking;
      return {
        icon: 'Confirmation',
        title: literals.booking_is_yours,
        subtitle: literals.mail_sent_txt.replace('%VALUE%', data.email), // TODO another ñapa
        email: data.email,
        content: {
          label: literals.dont_you_received_mail,
          url: '#'
        }
      };
    }
    if (type === 'landing') {
      const { title, subtitle, subtitleLongDescription } = itemRaw as LandingResultsWarning;
      return { title, subtitle, subtitleLongDescription };
    }

    return {}; // ñapa
  }

  static createBreakdownDiscountInfo(
    percent: number,
    totalDiscountRounded: number,
    discounts: DiscountRaw[] = [],
    currency: CurrencyTypeSymbol,
    userSubtitleInsteadOfTitle = false
  ): BreakdownDiscountInfo {
    const filteredDiscounts = discounts
      .filter(({ amountAfterTax }) => amountAfterTax < 0)
      .map(({ amountAfterTax, subtitleOffer, iconOffer, titleOffer, offerConditions, promotionCode, value }) => ({
        value: amountAfterTax,
        displayText: subtitleOffer,
        iconItem: iconOffer,
        titleItem: userSubtitleInsteadOfTitle ? subtitleOffer : titleOffer,
        itemConditions: offerConditions,
        promotionCode: promotionCode,
        percent: value
      }));

    return { percent, totalDiscountRounded, discounts: filteredDiscounts, currency };
  }
}
