<div class="cds-dialog">
  <div class="cds-dialog__container">
    <button class="cds-dialog__close-icon" (click)="close()">
      <app-icon-item icon="close"></app-icon-item>
    </button>
    <div class="cds-dialog__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
