import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

const isPasswordLengthValid = (password: string) => password && password.length >= 8;
const containsNumber = (password: string) => /\d/.test(password);
const containsUpperCase = (password: string) => /[A-Z]/.test(password);
const containsSpecialCharacter = (password: string) => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.value;
  if (!password) {
    return null;
  }

  return isPasswordLengthValid(password) &&
    containsNumber(password) &&
    containsUpperCase(password) &&
    containsSpecialCharacter(password)
    ? null
    : { passwordError: true };
};

// export const passwordValidator = (): ValidatorFn => {
//     return (control: AbstractControl): ValidationErrors | null => {
//         const password = control.value;
//         if (!password) {
//             return null;
//         }
//
//         return isPasswordLengthValid(password) &&
//         containsNumber(password) &&
//         containsUpperCase(password) &&
//         containsSpecialCharacter(password)
//             ? null
//             : { passwordError: true };
//     };
// };
