import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { catchError, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GeneralFunctions } from '../../../logic/functions/general.functions';
import { Literals } from '../../../logic/models/common.model';
import { CommonStoreAdapter } from '../../../store/common/common.store.adapter';
import { CommonService } from '../../services/common.service';

export const LITERAL_SECTION_KEY = 'lsk';

export const LiteralsResolver: ResolveFn<Record<string, Literals | null> | Literals | null> = (route: ActivatedRouteSnapshot) => {
  const sections = route.data[LITERAL_SECTION_KEY];
  if (!sections) {
    console.log('Could not load literals due to missing section key. Please add key value into routing file');
    return null;
  }
  const literals: Literals = inject(CommonStoreAdapter).selectLiteralsBySectionSig(sections)();
  if (literals) {
    return literals;
  }

  // Enable receiving more than one kind of literal
  if (Array.isArray(sections)) {
    const literalsRequests = sections.map((section) => {
      const literalsFromStore = inject(CommonStoreAdapter).selectLiteralsBySectionSig(section)();
      if (literalsFromStore) {
        return of({ [section]: literalsFromStore });
      }

      return inject(CommonService)
        .getLiteralsBySection$(section)
        .pipe(
          map((literals) => {
            if (!literals || GeneralFunctions.isArrayLike(literals)) {
              throw Error('No response or the format is not an object');
            }
            return { [section]: literals as Literals };
          }),
          catchError((error) => {
            console.log(`Error requesting literals for ${section}`, error);
            return of({ [section]: null });
          })
        );
    });

    return forkJoin(literalsRequests).pipe(
      map((results) => {
        // It receives an array of objects and returns an object with each literal title as key, and the literals object as value.
        return results.reduce<Record<string, Literals | null>>((acc, curr) => ({ ...acc, ...curr }), {});
      }),
      catchError((error) => {
        console.log('Error loading all literals', error);
        return of({});
      })
    );
  } else {
    // for one literal.
    return inject(CommonService)
      .getLiteralsBySection$(sections)
      .pipe(
        map((literals) => {
          if (!literals || GeneralFunctions.isArrayLike(literals)) {
            throw Error('No response or the format is not an object');
          }

          return literals as Literals;
        }),
        catchError((error) => {
          console.log(`Error requesting literals for ${sections}`, error);
          return of(null);
        })
      );
  }
};
