import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, RouteConfigLoadStart, Router } from '@angular/router';

import { RoutingPaths } from '@core/routing/routing.const';
import { OneTrustService } from '@services/one-trust/one-trust.service';
import { LoadingService } from '@shared/logic/services/loading.service';
import { PreFooterService } from '@shared/logic/services/pre-footer.service';
import { WindowRefService } from '@shared/logic/services/window-ref.service';
import { HeaderService } from '@shared/store/header/header.service';
import { Observable, tap } from 'rxjs';
import { UiStoreAdapter } from './shared/store/ui/ui.store.adapter';
import { HeaderStoreAdapter } from './shared/store/header/header.store.adapter';
import { HeaderStyleService } from './shared/store/header/header.styles.service';
import { LanguageDataService } from './core/services/languages/language-data.service';
import { ConstantsHeaderClass } from './shared/store/header/header.const';
import { ScrollConfig } from './shared/store/ui/ui.model';
import { MainContainerService } from './core/services/sections/container/main-container.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  public loading$: Observable<boolean> = this._loadingService.loading$;

  private readonly hiddenPaths = [RoutingPaths.Login, RoutingPaths.Maintenance];

  // ex layout properties
  public sbnameClass: string | undefined = ConstantsHeaderClass.HEADER_TRANSPARENT;
  public insertClass: string;
  public activeLoginExpand = false;
  public isSearchActive: boolean | null = null;

  // main container
  @ViewChild('mainContainer') mainContainer: ElementRef<HTMLElement>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private _windowRefService: WindowRefService,
    private _preFooterService: PreFooterService,
    private _oneTrustService: OneTrustService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _headerService: HeaderService,
    private readonly uiStoreAdapter: UiStoreAdapter,
    private readonly headerStoreAdapter: HeaderStoreAdapter,
    private readonly headerStyleService: HeaderStyleService,
    private languageDataService: LanguageDataService,
    private readonly mainContainerService: MainContainerService
  ) {}

  public ngOnInit(): void {
    this._preFooterService.loadPreFooter();
    this.document.documentElement.lang = this.languageDataService.getLang();

    if (isPlatformBrowser(this.platformId)) {
      this._initRouterEvents();
      this._windowRefService.initWindowListeners();

      // layout section
      this.insertClass = this.headerStyleService.setStyleBasedOnPath$();
      this._headerActive();
      this._scrollConfig();
    }
  }

  ngAfterViewInit(): void {
    this.mainContainerService.initContainer(this.mainContainer.nativeElement);

    if (isPlatformBrowser(this.platformId)) {
      // Load the OneTrust scripts when the component initializes
      this._oneTrustService.loadScript();

      // Start observing, reordering , and remove repeated scripts
      this._oneTrustService.removeDuplicateScripts();
      this._oneTrustService.observeAndReorderScripts();
    }
  }

  private _initRouterEvents(): void {
    this._router.events
      .pipe(
        tap((event) => {
          if (event instanceof RouteConfigLoadStart) {
            this._loadingService.loadingOn();
          } else if (event instanceof NavigationEnd) {
            if (this._loadingService.activeRequests === 0) {
              this._loadingService.loadingOff();
            }
            this.determineLoginVisibility(event.url);
          }
        })
      )
      .subscribe();
  }

  private determineLoginVisibility(url: string): void {
    const isUrlWithLoginHidden = this.hiddenPaths.some((path) => url.includes(path));
    this._headerService.showOptionsContainerSig.set(!isUrlWithLoginHidden);
  }

  // ex layout functions

  private _headerActive(): void {
    this.headerStoreAdapter.selectHeaderActive$().subscribe((response: boolean | null) => {
      if (response !== null) {
        this.isSearchActive = response;
        this.headerStyleService.blockScroll(!response);
        this.insertClass = this.headerStyleService.showHideHeader(response);
      }
    });
  }

  private _scrollConfig(): void {
    this.uiStoreAdapter.selectScrollConfig$().subscribe((res: ScrollConfig | null) => {
      if (res === null || res === undefined) {
        return;
      }
      this.sbnameClass = res.nameClass;
    });
  }

  public handleScroll(event: Event): void {
    this.headerStyleService.handleScroll(event, this.activeLoginExpand, !!this.isSearchActive);
  }
}
