import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { ProfitRaw } from '@shared/logic/models/common.model';
import { LinkRaw } from '@shared/store/home/home-common.model.raw';
import { BannerReward, BannerRewardRaw, LeftBlock, RightBlock, Tooltip } from '@shared/store/home/rewards/rewards.model';

export class RewardsNormalizer {
  static normalize(itemRaw: BannerRewardRaw[]): BannerReward[] {
    // TODO: FIX
    if (!itemRaw) {
      return [];
    }
    return itemRaw.map((i) => RewardsNormalizer.normalizeRewardBanner(i));
  }

  private static normalizeRewardBanner(itemRaw: BannerRewardRaw): BannerReward {
    const item = {} as BannerReward;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.user = itemRaw.user;
    const tooltipList = itemRaw.imagen_texto_tooltip.map((i) => RewardsNormalizer.normalizeTooltip(i));
    item.leftBlock = RewardsNormalizer.extractLeftBlock(itemRaw, tooltipList);
    item.rightBlock = RewardsNormalizer.extractRightBlock(itemRaw);

    let displayText = item.rightBlock.actionButton.displayText;
    item.gtmTracking = {
      ctaText: displayText ? GoogleTagFunctions.reduceTag(displayText) : null,
      ctaLink: item.rightBlock.actionButton.uri || null
    };
    if (itemRaw.endDateOffer) {
      item.endDateOffer = itemRaw.endDateOffer;
    }
    if (itemRaw.startDateOffer) {
      item.startDateOffer = itemRaw.startDateOffer;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // Needed on landing rewards
    if (itemRaw.percentOfferSeal) {
      item.percentOfferSeal = itemRaw.percentOfferSeal;
    }
    if (itemRaw.textOfferSeal) {
      item.textOfferSeal = itemRaw.textOfferSeal;
    }
    if (itemRaw.textOfferRibbon) {
      item.textOfferRibbon = itemRaw.textOfferRibbon;
    }
    if (itemRaw.modalOfferRibbon) {
      item.modalOfferRibbon = itemRaw.modalOfferRibbon;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static normalizeTooltip(itemRaw: ProfitRaw): Tooltip {
    const item = {} as Tooltip;
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const text = itemRaw.texto;
    const tooltipText = itemRaw.tooltip;

    item.displayText = text;
    item.image = itemRaw.imagen;
    item.alt = itemRaw.imagen?.atributos?.titulo;
    item.tooltip = !!tooltipText;
    item.tooltipText = tooltipText;
    item.gtmTracking = {
      displayTextTooltip: tooltipText ? GoogleTagFunctions.reduceTag(tooltipText) : null,
      displayText: text
    };
    item.hotelType = GeneralFunctions.isArrayLike(itemRaw.hotelType, 1) ? itemRaw.hotelType.map((i: { value: string }) => i.value) : [];
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static extractLeftBlock(itemRaw: BannerRewardRaw, tooltipList: Tooltip[]): LeftBlock {
    return { logo: itemRaw.logo, membershipLogo: itemRaw.logo_membresia, tooltipList };
  }

  private static extractRightBlock(itemRaw: BannerRewardRaw): RightBlock {
    const actionButton: LinkRaw = itemRaw.enlaces_bloque_derecho?.desktop?.[0];
    return {
      displayTitle: itemRaw.titulo_bloque_derecho,
      displayDescription: itemRaw.descripcion_bloque_derecho,
      actionButton: actionButton
        ? {
            displayText: actionButton?.title,
            uri: GeneralFunctions.extractFinalUrl(actionButton.uri || '')
          }
        : { displayText: 'CTA', uri: 'javascript:void(0)' }
    };
  }
}
