import { LocalStorageService } from '@app/shared/logic/services/storage/local-storage.service';
import { Destination } from '@models/sections';
import { on } from '@ngrx/store';

import { KeyLocalStorage } from '@shared/logic/const/key-local-storage.const';
import { setPreFooter, setPreFooterNormalized } from '@shared/store/pre-footer/pre-footer.actions';

import { PreFooterDestiny } from '@shared/store/pre-footer/pre-footer.model';

import { AppConfig } from '@app/app.config';
import { createRehydrateReducer } from '../rehydrate.reducer';

export interface State {
  preFooter: Record<string, Destination[]> | null;
  preFooterNormalized: Record<string, PreFooterDestiny[]> | null;
}

const initialState: State = {
  preFooter: null,
  preFooterNormalized: null
};

const CONFIG = {
  ttl: AppConfig.config.ttl.preFooter
};

export const reducer = (storage: LocalStorageService) =>
  createRehydrateReducer<State>(storage, CONFIG)(
    KeyLocalStorage.PreFooter,
    initialState,
    on(setPreFooter, (state, action) => ({
      ...state,
      preFooter: action.payload.preFooter
    })),
    on(setPreFooterNormalized, (state, action) => ({
      ...state,
      preFooterNormalized: action.payload.preFooter
    }))
  );
