<div class="cds-mat-input" [class.input-error]="errors">
  <mat-form-field [ngClass]="classes" class="cds-input-text">
    <mat-label></mat-label>
    <input [type]="type" (blur)="onBlur()" (input)="onInput($event)" [value]="value" [disabled]="isDisabled" type="text" matInput [placeholder]="placeholder" />
  </mat-form-field>
  <ng-container *ngIf="errors">
    <div class="error-block">
      <div class="error-message">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9974 18.3346C14.5998 18.3346 18.3307 14.6037 18.3307 10.0013C18.3307 5.39893 14.5998 1.66797 9.9974 1.66797C5.39502 1.66797 1.66406 5.39893 1.66406 10.0013C1.66406 14.6037 5.39502 18.3346 9.9974 18.3346ZM9.42936 5.45581H10.5657V12.274H9.42936V5.45581ZM9.42936 13.4103H10.5657V14.5467H9.42936V13.4103Z"
            fill="#DD554D"
          />
        </svg>
        <div class="errors-container">
          <ng-container *ngIf="errors.required">
            <span>{{ literals.required_input }}</span>
          </ng-container>
          <ng-container *ngIf="!errors.required && errors.email">
            <span>{{ literals.invalid_email }}</span>
          </ng-container>
          <ng-container *ngIf="!errors.required && errors.invalidFormat">
            <span>{{ literals.label_invalid_format }}</span>
          </ng-container>
          <ng-container *ngIf="!errors.required && errors.onlyText">
            <span>{{ literals.label_invalid_format }}</span>
          </ng-container>
          <ng-container *ngIf="!errors.required && !errors.invalidFormat && errors.maxlength">
            <span>{{ literals.invalid_length.replace('%max_length%', errors.maxlength.requiredLength) }} </span>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
