import { createReducer, on } from '@ngrx/store';

import { Literals } from '@shared/logic/models/common.model';
import { setCountries, setGenres, setLanguages, setLiterals } from '@shared/store/common/common.actions';
import { Country, Genre, Language } from '@shared/store/common/common.model';

export interface State {
  languages: Language[];
  countries: Country[];
  genres: Genre[];
  literals: Record<string, Literals>;
}

const initialState: State = {
  languages: [],
  countries: [],
  genres: [],
  literals: {}
};

export const reducer = createReducer<State>(
  initialState,
  on(setLanguages, (state, action) => ({
    ...state,
    languages: action.payload.languages
  })),
  on(setCountries, (state, action) => ({
    ...state,
    countries: action.payload.countries
  })),
  on(setGenres, (state, action) => ({
    ...state,
    genres: action.payload.genres
  })),
  on(setLiterals, (state, action) => ({
    ...state,
    literals: {
      ...state.literals,
      [action.payload.section]: action.payload.literals
    }
  }))
);
