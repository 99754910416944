import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';

import { CarouselBaseDirective } from '@molecules/carousel-base/carousel-base.directive';
import { CarouselDirectiveModule } from '@molecules/carousel-base/carousel.directive.module';
import { CarouselDotComponent } from '@shared/ns_components/gdi_extracted/carousel/carousel-dot/carousel-dot.component';
import { SliderArrowComponent } from '@shared/ns_components/gdi_extracted/carousel/slider-arrow/slider-arrow.component';
import { KeenSliderOptions } from 'keen-slider';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  host: {
    class: 'cds-carousel'
  },
  imports: [NgIf, NgFor, NgClass, CarouselDirectiveModule, CarouselDotComponent, SliderArrowComponent],
  standalone: true
})
export class CarouselComponent implements OnInit, OnDestroy {
  /* 👇 The title prop is optional.
   * See https://storybook.js.org/docs/react/configure/overview#configure-story-loading
   * to learn how to generate automatic titles
   */
  @Output() currentSlideChange = new EventEmitter<number>();

  @ViewChild(CarouselBaseDirective) carousel!: CarouselBaseDirective;

  @Input() hadArrow = false;
  @Input() hadDots = false;
  @Input() currentSlide = 0;
  @Input() timeChangeSlade = 0;
  @Input() configCarousel: KeenSliderOptions = {};
  @Input() numberDotsView = 4;

  private slideInterval: any;
  protected animationDuration = '';
  private dotWidth = 8;
  private dotGap = 8;
  private dotWidthMax = this.dotWidth * this.numberDotsView + this.dotGap * (this.numberDotsView - 1);
  // private dotWidthAll = 0;
  protected styleDots = '';
  protected styleContentDots = '';
  private currentLastSlide = 0;
  private arrayDotCustom = {
    dotIni: 0,
    dotEnd: 3
  };
  protected isNextArrowHidden = false;
  protected isPrevArrowHidden = false;

  goTo(index: number) {
    this.carousel.goTo(index);
  }

  ngOnInit(): void {
    this.arrayDotCustom.dotIni = 0;
    this.arrayDotCustom.dotEnd = this.numberDotsView - 1;
    this.isPrevArrowHidden = true;

    if (this.numberDotsView) {
      this.styleContentDots = `width: ${this.dotWidthMax}px; height: ${this.dotWidth + 2}px;`;
    }
  }

  changeSlide(index: number) {
    this.currentSlideChange.emit(index);
    this.currentLastSlide = this.currentSlide;
    this.currentSlide = index;
    this.isPrevArrowHidden = false;
    this.isNextArrowHidden = false;

    this.customDots(index);
  }

  customDots(index: number): void {
    this.adjustDotCustom(index);
    this.hideArrows(index);
  }

  adjustDotCustom(index: number) {
    if (this.currentLastSlide !== this.currentSlide) {
      if (this.currentLastSlide < this.currentSlide) {
        if (index > -1 && index < this.numberDotsView - 1) {
          this.arrayDotCustom.dotIni = 0;
          this.arrayDotCustom.dotEnd = this.numberDotsView - 1;
          this.styleDots = 'margin-left:0px';
        } else {
          this.arrayDotCustom.dotIni = this.arrayDotCustom.dotIni + 1;
          this.arrayDotCustom.dotEnd = this.arrayDotCustom.dotEnd + 1;
          if (this.arrayDotCustom.dotEnd !== this.carousel.dotHelper.length) {
            this.styleDots = `margin-left:-${this.arrayDotCustom.dotIni * (this.dotWidth + this.dotGap)}px`;
          }
        }
      } else if (this.arrayDotCustom.dotIni > 0) {
        this.arrayDotCustom.dotIni = this.arrayDotCustom.dotIni - 1;
        this.arrayDotCustom.dotEnd = this.arrayDotCustom.dotEnd - 1;
        this.styleDots = `margin-left:-${this.arrayDotCustom.dotIni * (this.dotWidth + this.dotGap)}px`;
      } else {
        this.arrayDotCustom.dotIni = 0;
        this.arrayDotCustom.dotEnd = this.numberDotsView - 1;
      }
    }
  }

  hideArrows(index: number) {
    if (index === 0) {
      this.isPrevArrowHidden = true;
    }

    if (index === this.carousel.dotHelper.length - 1) {
      this.isNextArrowHidden = true;
    }
  }

  getIndicatorClass(ref: number): string {
    if (this.arrayDotCustom.dotIni > 0 && this.currentSlide === this.carousel.dotHelper.length - 1 && ref === this.arrayDotCustom.dotIni - 1) {
      return 'small';
    }

    if (
      this.arrayDotCustom.dotIni > 0 &&
      this.arrayDotCustom.dotIni < this.carousel.dotHelper.length - (this.numberDotsView - 1) &&
      ref === this.arrayDotCustom.dotIni
    ) {
      return 'small';
    }

    if (this.arrayDotCustom.dotEnd < this.carousel.dotHelper.length - 1 && ref === this.arrayDotCustom.dotEnd) {
      return 'small';
    }

    return '';
  }

  ngOnDestroy(): void {
    clearInterval(this.slideInterval);
  }
}
