import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { PreFooter, PreFooterDestiny, PreFooterHotel, PreFooterName } from '@shared/store/pre-footer/pre-footer.model';
import { PreFooterDestinyRaw, PreFooterHotelRaw, PreFooterRaw } from '@shared/store/pre-footer/pre-footer.model.raw';

enum PreFooterId {
  Spain = 0,
  Europe = 1,
  America = 2,
  Africa = 3
}

export class PreFooterNormalizer {
  // static normalizePreFooters(preFooters: PreFooterRaw[]): PreFooter[] {
  //   return preFooters.map((p) => PreFooterNormalizer.normalizePreFooter(p));
  // }

  static normalize(preFooterRaw: PreFooterRaw, preFooterId: string): PreFooter {
    const item = {} as PreFooter;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.id = Number(preFooterId);
    item.name = PreFooterNormalizer.getPreFooterNameFromPreFooterId(item.id);
    item.destinations = preFooterRaw.data?.destinos?.map((_) => PreFooterNormalizer.normalizeDestiny(_));
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  static normalizeDestiny(destinyRaw: PreFooterDestinyRaw): PreFooterDestiny {
    const item = {} as PreFooterDestiny;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.country = destinyRaw.country;
    item.city = destinyRaw.destino;
    item.link = destinyRaw.enlace;
    item.searchDisplayImage = destinyRaw.imagen_buscador;
    item.searchKeywords = destinyRaw.keywords;
    item.hotelList = destinyRaw.hoteles.map((_) => PreFooterNormalizer.normalizeHotel(_, destinyRaw));
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  static normalizeHotel(hotelRaw: PreFooterHotelRaw, destinyRaw?: PreFooterDestinyRaw): PreFooterHotel {
    const item = {} as PreFooterHotel;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.name = hotelRaw.nombre;
    item.code = hotelRaw.back_code;
    item.isNewHotel = hotelRaw.hotel_nuevo;
    item.closedUntilDate = hotelRaw.cerrado_hasta;
    item.closedMonthList = hotelRaw.closed_momths;
    item.isTemporarilyClosed = hotelRaw.cerrado_temporalmente;
    item.startDate = hotelRaw.fecha_inicio_tag; // TODO: Start date of what?
    item.endDate = hotelRaw.fecha_fin_tag; // TODO: End date of what?
    item.guestCodes = hotelRaw.guest;
    item.searchKeywords = hotelRaw.keywords;
    item.type = hotelRaw.tipo_hotel; // resort, urbano, vacacional => Take into account for filtering in a future
    item.admissionType = hotelRaw.tipo_huesped; // Solo Adultos | Familias con niños
    item.internalUrl = hotelRaw.url_hotel; // TODO: Be careful with this
    item.email = hotelRaw.email;
    item.destination = hotelRaw.destination;
    item.country = hotelRaw.country;
    item.virtual = hotelRaw.virtual;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.gtmTracking = destinyRaw ? PreFooterNormalizer.getGtmTrackingParams(hotelRaw, destinyRaw) : undefined;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  private static getPreFooterNameFromPreFooterId(id: number): keyof typeof PreFooterName {
    switch (id) {
      case PreFooterId.Spain:
        return PreFooterName.Spain;
      case PreFooterId.Europe:
        return PreFooterName.Europe;
      case PreFooterId.America:
        return PreFooterName.America;
      case PreFooterId.Africa:
        return PreFooterName.Africa;
      default:
        return PreFooterName.UNKNOWN;
    }
  }

  // TODO: Name and type properly in a future
  private static getGtmTrackingParams(hotelRaw: PreFooterHotelRaw, destinyRaw: PreFooterDestinyRaw): Record<string, string> {
    const item = {} as any;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const country: string = GoogleTagFunctions.reduceTag(destinyRaw.country);
    const title: string = GoogleTagFunctions.reduceTag(destinyRaw.destino);
    item.param1 = `${country}_${title}`;
    item.param2 = GoogleTagFunctions.reduceTag(hotelRaw.back_code);
    item.param3 = GoogleTagFunctions.reduceTag(hotelRaw.nombre);
    item.param4 = hotelRaw.url_hotel;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }
}
