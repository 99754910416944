<div class="cds-image-slider" *ngIf="images.length">
  <app-carousel [timeChangeSlade]="timeChangeSlade" [hadArrow]="hadArrow" [hadDots]="hadDots" [configCarousel]="configCarousel">
    <ng-container *ngFor="let image of images; trackBy: trackByImageId; let i = index">
      <div class="slide keen-slider__slide">
        <div class="slide__content">
          <ng-container *ngIf="!isStickerHidden && image?.atributos?.titulo as imageTitle">
            <div class="sticker">{{ imageTitle }}</div>
          </ng-container>
          <ng-container *ngIf="hasInitView && image">
            <img dynamic-image [imageRaw]="image" [alt]="image.atributos?.descriptivo ?? ''" [src]="image.talla_uno" />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </app-carousel>
</div>
