<div #scrollBlockElement class="cds-expanded-image">
  <button class="cds-expanded-image__back-icon" (click)="onCancel()">
    <app-icon-item icon="back"></app-icon-item>
  </button>
  <div class="cds-expanded-image__container">
    <div class="cds-expanded-image__image-container">
      <div class="cds-expanded-image__image-list" *ngFor="let catImage of galleryMosaic; let catIndex = index">
        <p class="cds-expanded-image__category-text">{{ catImage.category }}</p>
        <ng-container *ngFor="let image of catImage.images; let i = index; trackBy: trackByImage">
          <div class="cds-expanded-image__item">
            <ng-container *ngIf="isImage(image); else videoBlock">
              <img
                [id]="catIndex === categoryIndex && i === imageIndex ? 'category-focusable' : ''"
                [src]="image.webp"
                [alt]="image.atributos.descriptivo"
                class="block__expanded block--image"
                [ngClass]="setOrientation(image.webp)"
                (load)="getImageSize(image.webp)"
              />
            </ng-container>
            <ng-template #videoBlock>
              <ng-container *ngIf="isVideo(image); else otherBlock">
                <video [id]="catIndex === categoryIndex && i === imageIndex ? 'category-focusable' : ''" #videoPlayer class="video-container" controls>
                  <source [src]="image.video" type="video/mp4" />
                </video>
              </ng-container>
            </ng-template>
            <ng-template #otherBlock>
              <ng-container *ngIf="isVideo(image)">
                <div class="iframe-container" style="background: #1e71e3; width: 100%; height: 100%; aspect-ratio: 5/3"></div>
              </ng-container>
              <!--<ng-container *ngIf="image.type === 'tour'">
                              <div class="tour-container" style="background: #1e71e3; width: 100%; height: 100%; aspect-ratio: 5/3">
                                &lt;!&ndash;  <img  [src]="images.source" class="block__expanded block&#45;&#45;tour" [alt]="images.description"> &ndash;&gt;
                                <div class="tour-tag">
                                  <app-icon-item icon="360"></app-icon-item>
                                  <span>Tour virtual</span>
                                </div>
                              </div>
                            </ng-container>-->
            </ng-template>
            <p class="image-title">{{ image.atributos.titulo }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
