import { Injectable } from '@angular/core';

import { FacebookAuthProvider, GoogleAuthProvider } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { LanguageDataService } from '@app/core/services/languages/language-data.service';
import { LocalStorageService } from '@app/shared/logic/services/storage/local-storage.service';
import { RoutingService } from '@core/routing/routing.service';
import { environment } from '@env/environment';
import { RegisterTrackService } from '@ga-tracking/services/tracking-sites/register/register-track.service';
import { UserTrackService } from '@ga-tracking/services/tracking-sites/user/user-track.service';
import { FirebaseAuthError, FirebaseFunctions } from '@shared/logic/functions/firebase.functions';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { AuthApiService } from '@shared/logic/http/api/auth.api.service';
import { ModalService } from '@shared/logic/services/modals/modal.service';
import { Login, Register, SocialProvider, User } from '@shared/store/auth/auth.model';
import { GetUserByOtpResponseRaw, UpdateUserPreferencesRaw, UserDataByUiResponseRaw } from '@shared/store/auth/auth.model.raw';
import { AuthNormalizer } from '@shared/store/auth/auth.normalizer';
import { AuthStoreAdapter } from '@shared/store/auth/auth.store.adapter';
import { UiStoreAdapter } from '@shared/store/ui/ui.store.adapter';
import { catchError, EMPTY, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { AuthDenormalizer } from './auth.denormalizer';
import { CommonStoreAdapter } from '../common/common.store.adapter';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _authExpKey = 'authexp';

  constructor(
    private angularFireAuth: AngularFireAuth,
    private authApiService: AuthApiService,
    private asa: AuthStoreAdapter,
    private uiStoreAdapter: UiStoreAdapter,
    private commonStoreAdapter: CommonStoreAdapter,
    private localStorageService: LocalStorageService,
    private languageService: LanguageDataService,
    private _routingService: RoutingService,
    private readonly _uiStoreAdapter: UiStoreAdapter,
    private _modalService: ModalService,
    private readonly _registerTrackService: RegisterTrackService,
    private _userTrackService: UserTrackService
  ) {}

  public init(): Promise<void> {
    this.angularFireAuth.onAuthStateChanged(async (user) => {
      if (this._hasRemainingTime() !== null) {
        if (user && this._hasRemainingTime()) {
          this.getUserByToken$(await user.getIdToken()).subscribe();
        } else {
          this.logout$().subscribe(() => this.logoutTracking());
        }
      }
      this._userTrackService.trackUserEvent();
      this.asa.setAuthProviderData((user?.multiFactor as any)?.user?.providerData[0] ?? null);
    });
    return this.angularFireAuth
      .setPersistence(GeneralFunctions.hasSessionStorageSupport() ? 'local' : 'none')
      .catch(() => this.angularFireAuth.setPersistence('none'))
      .finally(() => this.initializeFirebaseListeners());
  }

  public signInWithEmailAndPassword$({ email, password }: Login): Observable<any> {
    return from(this.angularFireAuth.signInWithEmailAndPassword(email, password)).pipe(
      switchMap((cred) => from(cred.user!.getIdToken()).pipe(switchMap((gipToken) => this.getUserByToken$(gipToken)))),
      map(() => {
        this._setSessionExpiration();
        return true;
      }),
      catchError((error) => throwError(() => this._handleAuthError(error)))
    );
  }

  public signUpManually$(register: Register): Observable<void> {
    return from(this.angularFireAuth.createUserWithEmailAndPassword(register.email, register.password!)).pipe(
      switchMap((cred) => from(cred.user!.getIdToken()).pipe(switchMap((token) => this._registerUserOnTMS$(register, token)))),
      catchError((e) => {
        if (e.message.includes(FirebaseAuthError.EMAIL_ALREADY_IN_USE)) {
          return this._registerUserOnTMS$(register).pipe(
            switchMap(() => {
              this._registerTrackService.trackViewModalSuccess();
              this._modalService.displayMessageModal$({
                displayTitle: '¡Ya casi está!',
                messages: ['Activa tu cuenta en el email que te acabamos de enviar.'],
                icon: 'Confirmation'
              });
              this._registerTrackService.trackClickBtnModalSuccess();
              this._routingService.GO_HOME();
              this._uiStoreAdapter.setHeaderLoginDisplayStatus(false);
              return EMPTY;
            }),
            catchError((error) => throwError(() => this._handleAuthError(error)))
          );
        }
        console.error('Error registering user in Firebase', e.message);
        return EMPTY;
      })
    );
  }

  public signInWithProvider$(providerId: SocialProvider): Observable<void> {
    const provider = this.getRRSSProvider(providerId);

    return from(this.angularFireAuth.signInWithPopup(provider)).pipe(
      switchMap((cred) => {
        if (!!cred.additionalUserInfo?.isNewUser) {
          const [name, surname] = cred.user?.displayName?.split(' ') ?? '';
          return from(cred.user!.getIdToken()).pipe(
            switchMap((token) =>
              this._registerUserOnTMS$(
                {
                  name,
                  surname,
                  email: cred.user!.email!
                },
                token
              ).pipe(map(() => cred))
            )
          );
        }
        return of(cred);
      }),
      switchMap((cred) => from(cred.user!.getIdToken()).pipe(switchMap((gipToken) => this.getUserByToken$(gipToken)))),
      map(() => this._setSessionExpiration()),
      catchError((error) => throwError(() => this._handleAuthError(error)))
    );
  }

  public logout$(): Observable<void> {
    return from(this.angularFireAuth.signOut()).pipe(
      catchError(() => {
        console.error('User signOut error');
        return EMPTY;
      })
    );
  }

  public logoutTracking(): void {
    // The logout method is called from different places. Avoid send the event if the user is already null.
    if (this.asa.selectAuthUserSig() && this.asa.selectAuthStatusSig()) {
      this.asa.setAuthStatus(false);
      this.asa.setAuthUser(null);
      this._userTrackService.trackUserEvent();
      this._clearSessionExpiration();
    }
  }

  private _handleAuthError(error: any): { code: string } | void {
    console.error('[FAUTHE] =>', error);
    const firebaseAuthError = FirebaseFunctions.getErrorFromMessage(error.message);
    if ([FirebaseAuthError.USER_NOT_FOUND, FirebaseAuthError.WRONG_PASSWORD].includes(firebaseAuthError)) {
      return { code: firebaseAuthError };
    }
    // Fallback for the rest of the errors (consider moving to component). Pending to add literals to the error messages.
    const errorObj = {
      displayTitle: 'Error',
      messages: ['Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.']
    };
    // @ts-ignore
    const errorMessages: Record<FirebaseAuthError, { displayTitle: string; messages: string[] }> = {
      [FirebaseAuthError.USER_DISABLED]: {
        displayTitle: 'Usuario deshabilitado',
        messages: ['El usuario ha sido deshabilitado.']
      },
      [FirebaseAuthError.INVALID_EMAIL]: {
        displayTitle: 'Email no válido',
        messages: ['El email introducido no es válido.']
      },
      [FirebaseAuthError.EMAIL_ALREADY_IN_USE]: { displayTitle: 'Email en uso', messages: ['El email ya está en uso.'] }
    };
    if (firebaseAuthError && errorMessages[firebaseAuthError]) {
      errorObj.displayTitle = errorMessages[firebaseAuthError].displayTitle;
      errorObj.messages = errorMessages[firebaseAuthError].messages;
    }
    this._modalService.displayMessageModal$({ displayTitle: errorObj.displayTitle, messages: errorObj.messages });
  }

  private _setSessionExpiration() {
    this.localStorageService.set(this._authExpKey, new Date().getTime() + environment.sessionExpiration);
  }

  private _clearSessionExpiration() {
    this.localStorageService.remove(this._authExpKey);
  }

  private _hasRemainingTime(): boolean | null {
    const exp = this.localStorageService.get<string>(this._authExpKey);
    if (exp === null) return null;
    return +exp >= new Date().getTime();
  }

  private _registerUserOnTMS$(register: Register, token?: string) {
    return this.authApiService.registerUser$(AuthDenormalizer.denormalizeUserCreation(register, this.commonStoreAdapter.selectCountriesSig(), token)).pipe(
      catchError((e) => {
        console.error('Error registering user in TMS', e.message);
        return EMPTY;
      })
    );
  }

  private initializeFirebaseListeners(): void {
    this.angularFireAuth.authState
      .pipe(
        tap((fireAuthUser) => (!fireAuthUser ? this.logout$().pipe(tap(() => this.logoutTracking())) : EMPTY)),
        catchError(() => this.logout$().pipe(tap(() => this.logoutTracking())))
      )
      .subscribe();
  }

  private getRRSSProvider(providerId: SocialProvider) {
    const providers = {
      google: () => new GoogleAuthProvider(),
      facebook: () => new FacebookAuthProvider()
    };
    return providers[providerId]?.();
  }

  public getUserByToken$(gipToken: string | null): Observable<User> {
    if (!gipToken) {
      this._getUserByTokenErrorModal();
      return EMPTY;
    }

    return this.authApiService.getUserByToken$(gipToken).pipe(
      ///////////////////////////////////////////////////////////////////
      map((u) => AuthNormalizer.normalize(u.data, gipToken)),
      tap((user: User) => {
        console.log('[Token] user', user);
        this.asa.setAuthStatus(!!user);
        this.asa.setAuthUser(user);
        if (user) {
          this.uiStoreAdapter.setRewardsToggleStatus(true);
          this._userTrackService.trackUserEvent();
        }
      }),
      catchError((e) => {
        console.error('Error retrieving user data from api', e.message);
        this._userTrackService.trackUserEvent();
        this.logout$().subscribe(() => this.logoutTracking());
        this._getUserByTokenErrorModal();
        return EMPTY;
      })
    );
  }

  private _getUserByTokenErrorModal(): void {
    this._modalService
      .displayMessageModal$({
        messages: ['No se ha podido iniciar sesión automáticamente, inténtalo de forma manual'],
        icon: 'error-red'
      })
      .subscribe(() => this._routingService.GO_HOME());
  }

  public confirmEmail$(token: string) {
    return this.authApiService.confirmEmail$(token).pipe(
      switchMap((response) => {
        return from(this.angularFireAuth.signInWithCustomToken(response.tokenInfo.data));
      }),
      switchMap((cred) => from(cred.user!.getIdToken()).pipe(switchMap((gipToken) => this.getUserByToken$(gipToken)))),
      tap(() => this._setSessionExpiration()),
      catchError((e) => {
        console.log('Error confirming user email', e);
        return EMPTY;
      })
    );
  }

  public resetPassword$(token: string, ott: string, password: string) {
    return this.authApiService.resetPassword$({
      token,
      ott,
      password,
      language: this.languageService.getLang()
    });
  }

  public recoverEmailPassword$(email: string) {
    return this.authApiService
      .recoverEmailPassword$({
        email,
        language: this.languageService.getLang()
      })
      .pipe(
        catchError((e) => {
          console.log('Error recovering email password', e);
          return EMPTY;
        })
      );
  }

  public initSessionEmail(email: string, redirect: string) {
    return this.authApiService.initSessionEmail$({
      email,
      redirect,
      language: this.languageService.getLang()
    });
  }

  public loginSessionWithOtp(token: string, ott: string): Observable<void> {
    return this.authApiService
      .loginSessionWithOtp({
        token,
        ott,
        language: this.languageService.getLang()
      })
      .pipe(
        map((u: GetUserByOtpResponseRaw) => AuthNormalizer.normalize(u.userInfo, u.tokenInfo.data)),
        tap((user: User) => {
          this.asa.setAuthStatus(!!user);
          this.asa.setAuthUser(user);
          if (user) {
            this.uiStoreAdapter.setRewardsToggleStatus(true);
          }
        }),
        map(() => this._setSessionExpiration())
      );
  }

  public verifyTokenValid(ott: string, email: string): Observable<void> {
    return this.authApiService.verifyTokenValid({
      ott,
      email
    });
  }

  public getUserDataByUid(uid: string, llid: string): Observable<UserDataByUiResponseRaw> {
    return this.authApiService.getUserDataByUid({
      uid,
      llid
    });
  }

  public updateUserPreferences(preferences: UpdateUserPreferencesRaw): Observable<void> {
    return this.authApiService.updateUserPrerences(preferences);
  }
}
