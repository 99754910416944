import { Injectable } from '@angular/core';

import { LandingApiService } from '@shared/logic/http/api/landing.api.service';
import { RewardsTable } from '@shared/store/landing/model/rewards.model';
import { LandingCollectivesRaw } from '@shared/store/landing/model-raw/collectives.model.raw';
import { LandingCommercialRaw } from '@shared/store/landing/model-raw/commercial.model.raw';
import { LandingDestinySeoRaw } from '@shared/store/landing/model-raw/destiny-seo.model.raw';
import { LandingRewardsRaw } from '@shared/store/landing/model-raw/rewards.model.raw';
import { LandingCollectivesNormalizer } from '@shared/store/landing/normalizers/landing-collectives.normalizer';
import { LandingCommercialNormalizer } from '@shared/store/landing/normalizers/landing-commercial.normalizer';
import { LandingRewardsNormalizer } from '@shared/store/landing/normalizers/landing-rewards.normalizer';
import { map, Observable } from 'rxjs';

import { LandingDestinySeoNormalizer } from './normalizers/landing-destiny-seo.normalizer';

export interface LandingData {
  type: LandingType;
  data: any;
  landingCoreId: string;
}
export enum LandingType {
  Hotel = 'hotel', // Only in front side. Used to maintain coherence
  Destination = 'destination',
  Seo = 'seo',
  SeoType = 'seo-type',
  Commercial = 'commercial',
  Rewards = 'rewards',
  Collectives = 'collectives'
}

@Injectable({ providedIn: 'root' })
export class LandingService {
  constructor(private _landingApiService: LandingApiService) {}

  public getLanding$(landingCoreId: string): Observable<LandingData | null> {
    return this._landingApiService.getLandingById$(landingCoreId).pipe(
      map((response) => {
        if ('errors' in response) {
          throw response.errors;
        }
        const { type, data } = response;
        switch (type) {
          case LandingType.Destination:
          case LandingType.Seo:
          case LandingType.SeoType:
            const _nLandingDSST = LandingDestinySeoNormalizer.normalize(data as LandingDestinySeoRaw);
            return { landingCoreId, type, data: _nLandingDSST };
          case LandingType.Commercial:
            const _nCommercial = LandingCommercialNormalizer.normalize(data as LandingCommercialRaw);
            return { landingCoreId, type, data: _nCommercial };
          case LandingType.Rewards:
            const _nRewards = LandingRewardsNormalizer.normalize(data as LandingRewardsRaw);
            return { landingCoreId, type, data: _nRewards };
          case LandingType.Collectives:
            const _nCollectives = LandingCollectivesNormalizer.normalize(data as LandingCollectivesRaw);
            return { landingCoreId, type, data: _nCollectives };
          default:
            return null;
        }
      })
    );
  }

  public getLandingRewardsTable$(): Observable<RewardsTable[]> {
    return this._landingApiService.getLandingRewardsTable$().pipe(map((r) => LandingRewardsNormalizer.normalizeRewardsTables(r.data)));
  }
}
