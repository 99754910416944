import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { createMomentDate } from '@shared/utils/dates/dates.utils';
import {
  CheckoutDisplayTag,
  CheckoutEcommerceData,
  CheckoutEcommerceItem,
  CheckoutTrackingEvent
} from '@ga-tracking/services/tracking-sites/checkout/checkout-track.model';
import { CheckoutCombinedResponse, CheckoutMealPlan, SPaymentOption } from '@shared/store/checkout/checkout.model';
import { User } from '@shared/store/auth/auth.model';
import { getHotelContinent } from '@app/shared/utils/continents/continents.utils';

@Injectable({ providedIn: 'root' })
export class CheckoutTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackCheckoutDisplay(checkoutResponse: CheckoutCombinedResponse, user: User | null): void {
    const allGuestInRooms = checkoutResponse.hashResponse.rooms.children.flatMap((r) => r.guestCounts);
    const meal: CheckoutMealPlan | undefined = checkoutResponse.mealsResponse?.options.find((m) => m.preSelected);
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'checkout',
      content_type: 'datos personales',
      order_id: 'na',
      content_tag1: user ? 'logado' : 'no logado',
      content_tag2: checkoutResponse.hashResponse.isRewardsActive ? 'rewards' : 'sin rewards',
      content_category: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelType) ?? 'no info',
      content_subcategory: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelDestination) ?? 'no info',
      content_detail: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name) ?? 'no info',
      content_id: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.code) ?? 'no info',
      content_place: GoogleTagFunctions.reduceTag(getHotelContinent(checkoutResponse.hashResponse.hotelInfo.code)) ?? 'no info',
      content_stars: GoogleTagFunctions.reduceTag(Math.floor(checkoutResponse.hashResponse.hotelInfo.stars)) ?? 'no info',
      content_score: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.hotelInfo.hotelRating) ?? 'no info',
      content_chekin_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).format('YYYYMMDD') ?? 'no info',
      content_chekin_day:
        GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).locale('es').format('dddd')) ?? 'no info',
      content_chekout_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).format('YYYYMMDD') ?? 'no info',
      content_chekout_day:
        GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).locale('es').format('dddd')) ?? 'no info',
      content_chekin_advance_days:
        GoogleTagFunctions.numberTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).diff(createMomentDate(), 'days')) ?? 0,
      content_room_nights: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.totalNights) ?? 0,
      content_room_quantity: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.rooms.children.length) ?? 0,
      content_n_adult:
        GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 10).reduce((sum, guest) => sum + guest.count, 0)) ?? 0,
      content_n_child: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 8).reduce((sum, guest) => sum + guest.count, 0)) ?? 0,
      content_n_junior:
        GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 9).reduce((sum, guest) => sum + guest.count, 0)) ?? 0,
      content_n_baby: GoogleTagFunctions.numberTag(allGuestInRooms.filter((g) => g.ageQualifyingCode === 7).reduce((sum, guest) => sum + guest.count, 0)) ?? 0,
      title: `catalonia hotels | datos personales ${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name)}`,
      path: `/checkout_datos_${GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name)}`,
      discount:
        checkoutResponse.hashResponse.isRewardsActive || checkoutResponse.hashResponse.promoCode !== ''
          ? GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.discountInfo.totalDiscountRounded * -1)
          : 0,
      price: checkoutResponse.hashResponse.isRewardsActive
        ? GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPriceRewards)
        : GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPrice)
    };
    this.trackingService.pushEventClickFromExternalSources<CheckoutDisplayTag>(eventData, '1_search');
  }

  public trackClickReturnSelectMeal(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'step',
      section: 'checkout_datos_personales',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickLogin(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'card tarifa',
      element: 'info_button',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickGuestToggle(status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'tu habitacion',
      element: 'toggle',
      link_text: 'no soy el huesped',
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewErrorField(section: string, link_text: string, status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewAlert,
      section: GoogleTagFunctions.reduceTag(section),
      element: 'input_text',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickViewErrorConfirmReserve(status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.errorNavigate,
      section: 'confirmar reserva',
      element: 'modal',
      link_text: 'envio de solicitud',
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickButtonRetryConfirmReserve(status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      section: 'confirmar reserva',
      element: 'modal_cta button',
      link_text: 'error envio solicitud_intentar de nuevo',
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickConfirmReservePBL(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'paybylink_confirmacion reserva',
      link_text: 'volver a inicio'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewModalConfirmReservePBL(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'paybylink_confirmacion reserva',
      link_text: 'pago completado'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewAddCard(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'metodo pago',
      link_text: 'anadir nueva tarjeta'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewAddCardErrorField(link_text: string, status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewAlert,
      element: 'modal_input_text',
      section: 'metodo de pago',
      link_text: `anadir tarjeta_${GoogleTagFunctions.reduceTag(link_text)}`,
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickButtonSaveNewCard(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'metodo de pago',
      link_text: 'anadir tarjeta_guardar tarjeta'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewModalDeleteCard(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'metodo de pago',
      link_text: 'eliminar tarjeta'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickDeleteCardConfirm(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'metodo de pago',
      link_text: 'eliminar tarjeta_eliminar'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  // TODO: Not implemented
  public trackViewHowIsCVV(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'metodo de pago',
      link_text: 'info CVV'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewPolicyPrivacity(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'legal',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickButtonPolicyPrivacity(link_text: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'legal',
      link_text: GoogleTagFunctions.reduceTag(link_text)
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickButtonSubscribeRewards(link_text: string, r1: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'radio_button',
      section: 'rewards',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      r1: GoogleTagFunctions.reduceTag(GoogleTagFunctions.prepareDiscountTag(r1))
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackViewPersonalData(event: string, checkoutResponse: CheckoutCombinedResponse, paymentType: string): void {
    const meal: CheckoutMealPlan | undefined = checkoutResponse.mealsResponse?.options.find((m) => m.preSelected);
    const promotions = checkoutResponse.mealsResponse?.options.flatMap((m) => m.discountInfo.discounts).filter((d) => d.promotionCode !== 'REWARDS07');
    const paymentTypeTag = paymentType === SPaymentOption.PayNow ? 'pago ahora' : paymentType === SPaymentOption.PayHotel ? 'pago en hotel' : 'na';
    const eventData = {
      event: event,
      hotel_name: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.name),
      hotel_id: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.code),
      hotel_price: checkoutResponse.hashResponse.isRewardsActive
        ? GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPriceRewards)
        : GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPrice) ?? 0,
      total_pax: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.rooms.children.reduce((sum, r) => sum + r.guestInfo.persons, 0)),
      total_rooms: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.rooms.children.length),
      ecommerce: {
        currency: checkoutResponse.hashResponse.currency === '€' ? 'EUR' : 'USD',
        value: checkoutResponse.hashResponse.isRewardsActive
          ? GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPriceRewards)
          : GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.prices.totalPrice) ?? 0,
        coupon: checkoutResponse.hashResponse.promoCode !== 'undefined' ? GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.promoCode) : '',
        items: checkoutResponse.hashResponse.rooms.children.map((r, index) => {
          return {
            item_name: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.rate.title) ?? 'no info',
            item_id: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.isRewardsActive ? r?.ratePlanCodeRewards : r?.ratePlanCode) ?? 'no info',
            price: checkoutResponse.hashResponse.isRewardsActive
              ? GoogleTagFunctions.numberTag(r.prices.totalPriceRewards)
              : GoogleTagFunctions.numberTag(r.prices.totalPrice) ?? 0,
            discount:
              checkoutResponse.hashResponse.isRewardsActive || checkoutResponse.hashResponse.promoCode !== ''
                ? GoogleTagFunctions.numberTag(r.discount * -1)
                : 0,
            item_regimen: GoogleTagFunctions.reduceTag(meal?.displayName) ?? 'no info',
            item_regimen2: GoogleTagFunctions.reduceTag(meal?.mealId) ?? 'no info',
            item_brand: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelCity) ?? 'no info',
            item_place: GoogleTagFunctions.reduceTag(getHotelContinent(checkoutResponse.hashResponse.hotelInfo.code)) ?? 'no info',
            item_category: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.hotelInfo.hotelType) ?? 'no info',
            item_category2: GoogleTagFunctions.reduceTag(checkoutResponse.hashResponse.rate.subtitle),
            item_category3: GoogleTagFunctions.reduceTag(Math.floor(checkoutResponse.hashResponse.hotelInfo.stars)) ?? 'no info',
            item_category4: GoogleTagFunctions.reduceTag(promotions?.map((p) => p.displayText).join('_')) ?? 'no info',
            item_category5: GoogleTagFunctions.numberTag(checkoutResponse.hashResponse.totalNights) ?? 0,
            item_category6: checkoutResponse.hashResponse.hotelInfo.isAdultsOnly ? '1' : '0',
            item_category7: GoogleTagFunctions.numberTag(r?.prices.totalPriceRewards) ?? 0,
            item_rw_discount: GoogleTagFunctions.prepareDiscountTag(Number(meal?.discountInfo.percent)) ?? 0,
            item_variant: GoogleTagFunctions.reduceTag(r.roomName) ?? 'no info',
            item_variant2: GoogleTagFunctions.reduceTag(r.roomTypeCode) ?? 'no info',
            item_chekin_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).format('YYYYMMDD') ?? 'no info',
            item_chekin_day:
              GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).locale('es').format('dddd')) ?? 'no info',
            item_chekout_date: createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).format('YYYYMMDD') ?? 'no info',
            item_chekout_day:
              GoogleTagFunctions.reduceTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.endDate).locale('es').format('dddd')) ?? 'no info',
            item_chekin_advance_days:
              GoogleTagFunctions.numberTag(createMomentDate(checkoutResponse.hashResponse.bookingInfo.startDate).diff(createMomentDate(), 'days')) ?? 0,
            item_n_adult: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 10)?.count) ?? 0,
            item_n_child: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 8)?.count) ?? 0,
            item_n_junior: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 9)?.count) ?? 0,
            item_n_babie: GoogleTagFunctions.numberTag(r.guestCounts.find((g) => g.ageQualifyingCode === 7)?.count) ?? 0,
            item_list_name: `checkout ${event === COMMON_TAG_EVENTS.addPaymentInfo ? 'pago' : 'datos'}_${GoogleTagFunctions.reduceTag(
              checkoutResponse.hashResponse.hotelInfo.name
            )}`,
            item_list_id: `checkout ${event === COMMON_TAG_EVENTS.addPaymentInfo ? 'pago' : 'datos'}_${GoogleTagFunctions.reduceTag(
              checkoutResponse.hashResponse.hotelInfo.code
            )}`,
            index: GoogleTagFunctions.numberTag(index + 1),
            quantity: 1
          };
        }) as CheckoutEcommerceItem[]
      } as CheckoutEcommerceData
    };
    if (paymentType !== 'na') {
      eventData.ecommerce['payment_type'] = GoogleTagFunctions.reduceTag(paymentTypeTag);
    }
    this.trackingService.pushEventClick<CheckoutTrackingEvent>({ ecommerce: null });
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  public trackClickButtonOpenModalRoom(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'info_button',
      section: 'habitacion',
      link_text: 'mas info'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  // TODO: Not implemented
  public trackViewYouIsRewardsModal(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'rewards',
      link_text: 'ya formas parte'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }

  // TODO: Not implemented
  public trackClickButtonLoginInYouIsRewardsModal(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'rewards',
      link_text: 'ya formas parte_iniciar sesion'
    };
    this.trackingService.pushEventClick<CheckoutTrackingEvent>(eventData);
  }
}
