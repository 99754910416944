import moment, { MomentFormatSpecification, MomentInput } from 'moment';

export class DateFunctions {
  static isSameOrBeforeToday(date: MomentInput, format?: MomentFormatSpecification, granularity?: moment.unitOfTime.StartOf) {
    return moment(date, format).isSameOrBefore(moment(), granularity);
  }

  static fromDateToDDMMYYYY(date: Date, dateSeparator: string = '/'): string {
    const day: string = date.getDate().toString().padStart(2, '0');
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    const year: string = date.getFullYear().toString();
    return `${day}${dateSeparator}${month}${dateSeparator}${year}`;
  }

  static fromDDMMYYYYToYYYYMMDD(dateAsString: string, dateAsStringSeparator: string = '/', dateSeparator: string = '-'): string {
    const [day, month, year] = dateAsString.split(dateAsStringSeparator);
    return [year, month, day].join(dateSeparator);
  }

  static fromDateToSearchCalendar(date: Date | null): string | null {
    return date ? DateFunctions.fromDDMMYYYYToYYYYMMDD(DateFunctions.fromDateToDDMMYYYY(date)) : null;
  }

  static getNow(): number {
    return new Date().getTime();
  }

  static isDateBetweenMoment(dateToCheck: Date, startDate: Date, endDate: Date): boolean {
    return moment(dateToCheck).isBetween(startDate, endDate, undefined, '[]');
  }

  static addDaysToDate(date: Date, days: number): Date {
    const dateWithAddingDays: Date = date;
    dateWithAddingDays.setDate(dateWithAddingDays.getDate() + days);
    return dateWithAddingDays;
  }
}
