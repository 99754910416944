import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

import { LanguageDataService } from '@services/languages/language-data.service';
import { LiteralSections } from '@shared/logic/const/common';
import { ModalResponse } from '@shared/logic/models/common.model';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export abstract class ModalAbstractService {
  public readonly literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common);
  public readonly searchLiterals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Search);

  constructor(protected dialog: Dialog, protected languageDataService: LanguageDataService, protected readonly _commonStoreAdapter: CommonStoreAdapter) {}

  protected openModal$<I, O = ModalResponse>(component: ComponentType<unknown>, data?: I): Observable<O> {
    const dialogRef: DialogRef<O, InstanceType<typeof component>> = this.dialog.open(component, { data });
    return dialogRef.closed as Observable<O>;
  }
}
