const EMAIL_REGEXP: RegExp =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class GeneralFunctions {
  static hasSessionStorageSupport(): boolean {
    try {
      return 'sessionStorage' in window && window.sessionStorage !== null;
    } catch (e) {
      return false;
    }
  }

  static isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
  }

  static isArrayLike(object: any, minLength?: number): boolean {
    return object != null && typeof object !== 'string' && typeof object[Symbol.iterator] === 'function' && (minLength ? object.length >= minLength : true);
  }

  // improve this function name
  static extractFinalUrl(url: string | undefined): string {
    if (!url || url === '#') {
      return '';
    }
    // This function extracts the final URL from a URI that may contain an internal link. I.e.: internal:#, internal:/catalonia-ramblas...
    // First we determine if the URI is an internal link by checking if it contains the string 'internal'.
    const isInternal = GeneralFunctions.isInternalUrl(url);
    // If the URI is an internal link, we split the string by the colon character and return the second part of the split.
    return isInternal ? url.split(':')[1] : url;
  }

  static isInternalUrl(url: string): boolean {
    return url.indexOf('internal') !== -1;
  }

  // We need a way to replace html text to add it to the meta description since there is no property to do so.
  static replaceHtml(htmlText?: string): string {
    if (!htmlText) {
      return '';
    }
    return htmlText.replace(/<[^>]*>/g, '');
  }
}
