<div class="cds-start-range">
  <div class="cds-start-range__container">
    <ul class="cds-start-range__stars">
      <ng-container *ngFor="let star of [].constructor(starsAsValidArray); trackBy: trackByFn">
        <li>
          <app-icon-item icon="start"></app-icon-item>
        </li>
      </ng-container>
    </ul>
    <ng-container *ngIf="category">
      <div class="cds-start-range__category">
        <span>{{ category }}</span>
      </div>
    </ng-container>
  </div>
</div>
