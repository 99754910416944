import { Component, Input } from '@angular/core';
interface List {
  title: string;
  measure: string;
  distance: string;
}
@Component({
  selector: 'app-point-list',
  templateUrl: './point-list.component.html',
  styleUrls: ['./point-list.component.scss']
})
export class PointListComponent {
  @Input() list: List[] = [];
  @Input() numColumns: 'one-col' | 'two-col' | 'three-col' = 'two-col';
}
