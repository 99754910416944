export class AppConfig {
  static lang = 'es';
  static get config() {
    return {
      language: {
        default: AppConfig.lang,
        all: ['es', 'ca', 'en', 'fr', 'de', 'it', 'pt', 'nl']
      },
      // h * m * s * 1000
      ttl: {
        preFooter: 1 * 60 * 60 * 1000,
        hotel: 0.5 * 60 * 60 * 1000
      },
      loaderDisplayTimeMs: 5000,
      headerMenuDisplayTimeMs: 10000
    };
  }

  static setConfigDefaultLang(): string {
    const defaultLang = typeof navigator !== 'undefined' ? navigator.language.split('-')[0] : undefined;
    AppConfig.lang = defaultLang && AppConfig.config.language.all.includes(defaultLang) ? defaultLang : AppConfig.config.language.default;
    return AppConfig.lang;
  }
}
