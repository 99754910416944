import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SetPrevContentInfo, SetFirstSearchType, SetFirstSearchDoneStatus } from '@shared/store/ga/ga.actions';
import { FirstSearchType, PrevContentInfo } from '@shared/store/ga/ga.model';
import { GaSelectors } from '@shared/store/ga/ga.selectors';
import { Observable, take, tap } from 'rxjs';

import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsStoreAdapter {
  constructor(private store: Store<State>) {}

  public setPrevContentInfo(prevContentInfo: PrevContentInfo): void {
    this.store.dispatch(new SetPrevContentInfo({ prevContentInfo }));
  }

  // Consider move this to a future common service
  public loadFirstSearchType(type: FirstSearchType): void {
    this.selectFirstSearchDoneStatus$()
      .pipe(
        take(1),
        tap((isFirstSearchDone: boolean) => {
          if (!isFirstSearchDone) {
            this.setFirstSearchDoneStatus(true);
            this.setFirstSearchType(type);
          }
        })
      )
      .subscribe();
  }

  private setFirstSearchDoneStatus(status: boolean): void {
    this.store.dispatch(new SetFirstSearchDoneStatus({ status }));
  }

  private setFirstSearchType(type: FirstSearchType): void {
    this.store.dispatch(new SetFirstSearchType({ type }));
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  public readonly selectPrevContentInfo$ = (): Observable<PrevContentInfo> =>
    this.store.select(GaSelectors.getPrevContentInfo);

  public readonly selectFirstSearchDoneStatus$ = (): Observable<boolean> =>
    this.store.select(GaSelectors.getFirstSearchDoneStatus);

  public readonly selectFirstSearchType$ = (): Observable<FirstSearchType | null> =>
    this.store.select(GaSelectors.getFirstSearchType);
}
