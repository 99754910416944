export const checkTokenResponse = {
  result: 'success',
  data: {
    CustomerID: '10501952',
    Email: 'teststar2019@gmail.com',
    ZNivelMember_KUT: 'CIRCLE',
    ZProximoniveladadehoy_KUT: '',
    FirstName: 'Test Star',
    LastName: 'Sanchez Perezz',
    FormattedName: 'Test Star Sanchez Perezz',
    GenderCode: '2',
    GenderCodeText: 'Female',
    LanguageCode: 'ES',
    LanguageCodeText: 'Spanish',
    Street: 'Calle1',
    City: 'Ciudad',
    StreetPostalCode: '08080',
    CountryCode: 'ES',
    CountryCodeText: 'Spain',
    BirthDate: '1973-02-01T00:00:00+00:00',
    NationalityCountryCode: '',
    NationalityCountryCodeText: '',
    ZIDLoyalty_KUT: '0900011821',
    ZRolHuesped_KUT: false,
    ZRolMember_KUT: true,
    ZClienteaEmarsys_KUT: true,
    ZTravelCashmiembro_KUT: '0.00',
    ZOptin_KUT: false,
    ZNoches_KUT: '0',
    ZNochesparaproximocambiodenivel_KUT: '0',
    ZNumerodeReservasDirectas_KUT: '0',
    ZNumerodeReservas_KUT: '0',
    TravelCashprximoacaducar_KUT: '0.00',
    ZEstadocomomiembro_KUT: '3',
    ZFechadeCambiodeNivelActual_KUT: null,
    ZFechadebajaenprograma_KUT: null,
    ZFechadecreacindeTMS_KUT: '2024-01-26T00:00:00+00:00',
    ZTelefono_no_verificado_KUT: '703005023',
    EntityLastChangedOn: '2024-03-01T11:43:22+00:00',
    ZCurrencypreferente_KUT: '',
    ZCheckinonlinefinalizado_KUT: false,
    ZemailUpdated_KUT: '',
    ZTimeStamp_KUT: '2024-03-01T11:43:19Z',
    CountryText: 'España',
    phonePrefix: '+34',
    phoneNumber: '703005023',
    pendingUpdateOps: 0
  },
  sapMessage: null
};
