import { Injectable } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { COMMON_TAG_EVENTS } from '@ga-tracking/services/tracking-builder/tracking-builder.const';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { RegisterTag, RegisterTrackingEvent } from '@ga-tracking/services/tracking-sites/register/register-track.model';
import { LoginTrackingEvent } from '@ga-tracking/services/tracking-sites/login/login-track.model';

@Injectable({ providedIn: 'root' })
export class RegisterTrackService {
  constructor(private readonly trackingService: TrackingService) {}

  public trackRegisterPageDisplay(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.virtualPage,
      content_group: 'login y registro',
      content_type: 'registro',
      content_category: 'na',
      content_subcategory: 'na',
      content_detail: 'na',
      content_id: 'na',
      title: 'catalonia hotels | registro',
      path: 'registro'
    };
    this.trackingService.pushEventClickFromExternalSources<RegisterTag>(eventData, '1_search');
  }

  public trackRegisterSuccess(method: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.signUp,
      method: GoogleTagFunctions.reduceTag(method),
      section: 'registro',
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }

  public trackClickAnyActionRegisterPage(link_text: string, link_url: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'cta_button',
      section: 'registro',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      link_url: GoogleTagFunctions.reduceTag(link_url)
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }

  public trackViewErrorFieldInRegisterForm(element: string, link_text: string, status: string): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.errorNavigate,
      element: GoogleTagFunctions.reduceTag(element),
      section: 'registro',
      link_text: GoogleTagFunctions.reduceTag(link_text),
      status: GoogleTagFunctions.reduceTag(status)
    };
    this.trackingService.pushEventClick<LoginTrackingEvent>(eventData);
  }

  public trackViewModalSuccess(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'registro_activa tu cuenta',
      link_text: 'activa tu cuenta en el email'
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }

  public trackClickBtnModalSuccess(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'registro_activa tu cuenta',
      link_text: 'Aceptar'
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }

  public trackViewModalWelcomeCataloniaRewards(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.viewContent,
      element: 'modal',
      section: 'registro_exito',
      link_text: 'bienvenido a rewards'
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }

  public trackClickBtnModalWelcomeCataloniaRewards(): void {
    const eventData = {
      event: COMMON_TAG_EVENTS.selectContent,
      element: 'modal_cta_button',
      section: 'registro_exito',
      link_text: 'a disfrutar'
    };
    this.trackingService.pushEventClick<RegisterTrackingEvent>(eventData);
  }
}
