<div class="feature-item feature-item--online" [ngClass]="classes">
  <div class="feature-item__info" [ngClass]="typeFeature">
    <div class="feature-item__logo">
      <img src="{{ featureIcono }}" alt="{{ featureIconoTextAlt }}" />
    </div>

    <div class="feature-item__text">
      <p class="feature-item__text__title">
        {{ featureTitle }}
        <span *ngIf="featureTitleTooltip" class="tooltip">
          <app-tooltip (click)="activeTooltip()" *ngIf="featureTitleTooltip" desc="" title=""></app-tooltip>
        </span>
      </p>
      <p class="feature-item__text__subtitle">
        {{ featureDescription }}
        <span class="tooltip">
          <app-tooltip *ngIf="featureDescriptionTooltip" desc="" title=""></app-tooltip>
        </span>
      </p>
      <div *ngIf="featureTag" class="feature-item__text__extra">
        <app-tag type="{{ featureTagType }}" size="cds-tag--l" label="{{ featureTagText }}"></app-tag>
      </div>
    </div>
  </div>
  <div *ngIf="featureTitleCtaLink || featureTitleCtaButton || featureExtraInfo" class="feature-item__url">
    <!-- <div  class="feature-item__url"> -->
    <app-link
      *ngIf="featureTitleCtaLink"
      [singin]="false"
      href=""
      label="{{ featureCta }}"
      variant="cds-link--primary"
      [icon]="false"
      [disabled]="false"
      [reverse]="false"
    ></app-link>

    <app-button *ngIf="featureTitleCtaButton" type="cds-btn--regular" label="{{ featureCta }}"></app-button>

    <div *ngIf="featureExtraInfo" class="code-container">
      <img src="assets/icon/rewards/child-aproved.svg" alt="" />
      <p class="cta-code__text">{{ featureCta }}</p>
    </div>
  </div>
</div>
