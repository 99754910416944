import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

import { TypographyComponent } from '@app/shared/components/atoms/typography/typography.component';
import { DescriptionItemComponent } from '@app/shared/components/molecules/description-item/description-item.component';

import { ButtonComponent } from '@atom/button/button.component';
import { LinkComponent } from '@atom/link/link.component';
import { TagComponent } from '@atom/tag/tag.component';
import { SafeHtmlDirectiveModule } from '@directives/safe-html/safe-html.directive.module';
import { HotelWithDatesMultiroomTrackService } from '@ga-tracking/services/tracking-sites/hotel-with-dates-multiroom/hotel-with-dates-multiroom-track.service';
import { HotelWithoutDatesTrackService } from '@ga-tracking/services/tracking-sites/hotel-without-dates-track/hotel-without-dates-track.service';
import { Gallery, GalleryComponent } from '@molecules/gallery/gallery.component';
import { PointListModule } from '@molecules/point-list/point-list.component.module';
import { SingleDecimalPipe } from '@pipes/numbers/single-decimal.pipe';
import { GoogleMapsLoaderService } from '@services/maps/google-maps.service';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { AvoidHrefDirectiveModule } from '@shared/logic/directives/avoid-href/avoid-href.directive.module';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer, Item } from '@shared/logic/helpers/common/common.normalizer';
import { ImageRaw, ModalResponse } from '@shared/logic/models/common.model';
import { Metrics, MetricComponent } from '@shared/ns_components/gdi_extracted/app-metric/metric.component';
import { HotelAward, HotelAwardComponent } from '@shared/ns_components/gdi_extracted/hotel-award/hotel-award.component';
import { HotelAwardsComponent } from '@shared/ns_components/gdi_extracted/hotel-awards/hotel-awards.component';
import { MainScore, MainScoreV2Component } from '@shared/ns_components/gdi_extracted/main-score/main-score.component';
import { ReviewCommentComponent } from '@shared/ns_components/gdi_extracted/review-comment/review-comment.component';
import { DEFAULT_ZOOM, MapComponent } from '@shared/ns_components/map/map.component';
import { CdkDialogWrapperModule } from '@shared/ns_components/modals/cdk-dialog-wrapper/cdk-dialog-wrapper.module';
import { StarsRangeComponent } from '@shared/ns_components/stars-range/stars-range.component';
import { GalleryMosaicItem } from '@shared/store/hotel/hotel.model';
import { HotelRaw } from '@shared/store/hotel/hotel.model.raw';
import { HotelNormalizer } from '@shared/store/hotel/hotel.normalizer';
import { getFileType } from '@shared/utils/files/files.utils';
import { debounceTime, fromEvent } from 'rxjs';

export interface HotelModalInput {
  hotelData: HotelRaw;
  index?: number;
  displayPrice?: number | null;
  currency?: string | null;
}

export interface Terms {
  icon?: string;
  title: string;
  description: string[];
}

@Component({
  standalone: true,
  selector: 'app-hotel-modal',
  templateUrl: './hotel-modal.component.html',
  styleUrls: ['./hotel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CdkDialogWrapperModule,
    NgClass,
    NgIf,
    ButtonComponent,
    MatTabsModule,
    TagComponent,
    SafeHtmlDirectiveModule,
    LinkComponent,
    PointListModule,
    NgForOf,
    DecimalPipe,
    HotelAwardComponent,
    StarsRangeComponent,
    AvoidHrefDirectiveModule,
    MapComponent,
    ReviewCommentComponent,
    DescriptionItemComponent,
    TypographyComponent,
    IconItemComponent,
    HotelAwardsComponent,
    SingleDecimalPipe,
    MetricComponent,
    MainScoreV2Component,
    GalleryComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelModalComponent extends ModalElement<HotelModalComponent, HotelModalInput> implements OnInit, AfterViewInit {
  @ViewChild('modalHead') modalHead!: ElementRef;
  @ViewChild('tabs') modalTab!: ElementRef;
  @ViewChild('scrollBlockElement', { static: true }) scrollBlockElement!: ElementRef;

  public isHeadScrolling = false;
  public sblastScrollTop = 0;
  public modalHeadClass = '';
  public scrollPosition = 0;
  public displayPrice: number;
  public currency: string;
  public hotelData!: HotelRaw;
  public selectedIndex: number = 0;
  public sections: string[] = [
    this.hotelData?.literales.label_information,
    this.hotelData?.literales.label_gallery,
    this.hotelData?.literales.label_map,
    this.hotelData?.literales.label_ratings
  ];

  public readonly isGMapsLoadedSig = toSignal(this._gmls.gMapsLoadedStatus$);

  public hotelServices: { highlighted: Item[] | null; secondary: Item[] | null } = {
    highlighted: null,
    secondary: null
  };
  public stars: number;
  public usefulInfo: Terms[] | null = null;
  public paymentsInfo: Terms[] | null = null;
  public transportations: Terms[] | null = null;
  public coordinates: { lat: number; lng: number };
  public awardsItems: HotelAward[] | null = null;
  public reviews: any[] | null = null;
  public pointOfInterest: any[] | null = null;
  public metrics: Metrics[] | null = null;
  public mainScoreData?: MainScore;
  public galleryMosaic?: GalleryMosaicItem[];
  public gallery: Gallery[];

  constructor(
    @Inject(DIALOG_DATA) dialogData: HotelModalInput,
    dialogRef: DialogRef<ModalResponse, HotelModalComponent>,
    private cdRef: ChangeDetectorRef,
    private readonly hotelWithoutDatesTrackService: HotelWithoutDatesTrackService,
    private hotelWithDatesMultiroomTrackService: HotelWithDatesMultiroomTrackService,
    private readonly _gmls: GoogleMapsLoaderService
  ) {
    super(dialogData, dialogRef);
  }

  // This should disappear someday on full hotel normalization (START)
  get ratingKeys() {
    return Object.keys(this.hotelData?.info_review_pro?.rating || {});
  }
  get fullAddress(): string {
    return HotelNormalizer.normalizeAddress(this.hotelData);
  }
  // This should disappear someday on full hotel normalization (END)

  public override ngOnInit(): void {
    super.ngOnInit();

    const { hotelData, index, displayPrice, currency } = this.getDialogData();
    this.hotelData = hotelData;

    this.normalizeData();

    if (index) {
      this.selectedIndex = index;
    }
    if (displayPrice) {
      this.displayPrice = displayPrice;
    }
    if (currency) {
      this.currency = currency;
    }
    this._trackingGASectionInfo(this.selectedIndex, false);
  }

  public ngAfterViewInit(): void {
    fromEvent(this.scrollBlockElement.nativeElement, 'scroll')
      .pipe(this.untilIsDestroyed(), debounceTime(100))
      .subscribe((event: any) => {
        const scrollOffset = (event?.target as HTMLElement).scrollTop || 0;
        this.scrollPosition = scrollOffset;

        if (scrollOffset >= this.modalHead.nativeElement.getBoundingClientRect().height) {
          this.modalHeadClass = 'head--sticky-out';
          this.isHeadScrolling = true;
          if (this.modalTab.nativeElement.getBoundingClientRect().y <= 0) {
            this.modalHeadClass = 'head--sticky-in';
            this.sblastScrollTop = scrollOffset;
          }
        } else {
          this.isHeadScrolling = false;
          this.modalHeadClass = '';
        }
        this.cdRef.markForCheck();
      });
  }

  public normalizeData(): void {
    // This logic should be in a normalizer some day...

    this.stars = Number(this.hotelData?.estrellas) ?? 0;
    this.coordinates = HotelNormalizer.normalizeCoords(this.hotelData);

    const ratingInfo = HotelNormalizer.normalizeRatingInfo(this.hotelData);
    this.mainScoreData = {
      valueScore: ratingInfo.totalScore,
      valueIconText: ratingInfo.displayText,
      valueIcon: ratingInfo.displayIcon,
      minimal: false
    };

    if (GeneralFunctions.isArrayLike(this.hotelData.info_util, 1)) {
      this.usefulInfo = this.hotelData?.info_util?.map((i: any) => ({
        title: i.title,
        description: [i.description],
        icon: i.clase_css
      }));
    }
    if (GeneralFunctions.isArrayLike(this.hotelData.servicios_destacados, 1)) {
      this.hotelServices.highlighted = CommonNormalizer.normalizeItems(this.hotelData.servicios_destacados);
    }
    if (GeneralFunctions.isArrayLike(this.hotelData.servicios_ficha_hotel, 1)) {
      this.hotelServices.secondary = CommonNormalizer.normalizeItems(this.hotelData.servicios_ficha_hotel);
    }
    if (GeneralFunctions.isArrayLike(this.hotelData.info_util_pagos?.description, 1)) {
      this.paymentsInfo = [
        {
          title: this.hotelData.info_util_pagos.title,
          description: this.hotelData.info_util_pagos.description,
          //@ts-ignore
          icon: this.hotelData.info_util_pagos.icon
        }
      ];
    }
    if (GeneralFunctions.isArrayLike(this.hotelData.transportes)) {
      this.transportations = this.hotelData?.transportes?.map((i) => ({
        title: i.title,
        description: [i.description],
        icon: i.clase_css
      }));
    }
    if (GeneralFunctions.isArrayLike(this.hotelData?.premios, 1)) {
      this.awardsItems = HotelNormalizer.normalizeAwards(this.hotelData);
    }
    if (GeneralFunctions.isArrayLike(this.hotelData?.info_review_pro?.reviews, 1)) {
      this.reviews = this.hotelData?.info_review_pro?.reviews;
    }
    if (GeneralFunctions.isArrayLike(this.hotelData?.puntos_interes, 1)) {
      this.pointOfInterest = this.hotelData?.puntos_interes;
    }

    if (GeneralFunctions.isArrayLike(this.hotelData.imagenes_asociadas, 1)) {
      this.galleryMosaic = HotelNormalizer.normalizeMosaicImages(this.hotelData.imagenes_asociadas);
      this.gallery = this._convertToGallery(this.galleryMosaic);
    }

    const aspectScores = HotelNormalizer.normalizeRatingsDynamically(this.hotelData.info_review_pro.rating);
    this.metrics = Object.keys(aspectScores)
      .map((key) => ({
        id: key,
        typeMetric: this.hotelData.literales[`reviewpro-${key}`],
        numberMetric: Math.round(Number(aspectScores[key])),
        compressed: true
      }))
      .filter((i) => i.id !== 'overall');
  }

  // TODO: this method (_convertToGallery) should be deleted someday, and it's component refactored
  private _convertToGallery(galleryMosaic: GalleryMosaicItem[]): Gallery[] {
    const gallery: Gallery[] = [];
    galleryMosaic.forEach((galleryItem) => {
      const currentGallery: Gallery = {
        category: galleryItem.category,
        img: '',
        galleryImages: [],
        images: []
      };

      galleryItem.images?.forEach((imageData: ImageRaw) => {
        if (!imageData?.webp) return;

        const type = getFileType(imageData.webp);
        const description = imageData.atributos.descriptivo;
        currentGallery.images.push(imageData);
        currentGallery.galleryImages.push({ type: type, source: imageData.webp, description: description });
      });
      if (currentGallery.galleryImages.length > 0) {
        currentGallery.img = galleryItem.images?.[0]?.webp;
        currentGallery.imgRaw = galleryItem.images?.[0];
        gallery.push(currentGallery);
      }
    });
    return gallery;
  }

  public markerActionClick(): void {
    this.closeDialog({ success: true, customData: { reason: 'HOTEL_NAV' } });
  }

  public navigateToMap(): void {
    this.selectedIndex = 2;
    this.trackContactDataClick('mapa', '');
  }

  public openGoogleMapsWithIndications(): void {
    const { lat, lng } = this.coordinates;
    window.open(`https://www.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=${DEFAULT_ZOOM}`, '_blank');
  }

  public onClickReservation(): void {
    this.hotelWithDatesMultiroomTrackService.trackClickHotelSummaryButtonReserve(this.sections[this.selectedIndex]);
    this.onAccept();
  }

  public onAccept(): void {
    this.closeDialog({ success: true, customData: { reason: 'HOTEL_ROOMS' } });
  }

  public onCancel(): void {
    this.closeDialog({ success: false });
  }

  public changeTab(event: MatTabChangeEvent) {
    this._trackingGASectionInfo(event.index, true);
  }

  public reserveTransfer(): void {
    this.hotelWithDatesMultiroomTrackService.trackClickHotelSummaryButtonTransferReserve(this.sections[this.selectedIndex], '');
  }

  public trackContactDataClick(link_text: string, link_url: string): void {
    this.hotelWithDatesMultiroomTrackService.trackClickHotelSummaryContactInfo(link_text, link_url);
  }

  private _trackingGASectionInfo(tabIndex: number, click: boolean): void {
    // Check view reviews section
    if (tabIndex === 3) {
      this.hotelWithoutDatesTrackService.trackViewModalReviews();
    }
    this.hotelWithDatesMultiroomTrackService.trackViewHotelSummaryOptionsModal(this.sections[tabIndex]);
    if (click) {
      this.hotelWithDatesMultiroomTrackService.trackClickHotelSummaryOptionsInModal(this.sections[tabIndex]);
    }
  }
}
