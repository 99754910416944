<google-map
  class="cds-map__map"
  width="100%"
  [options]="mapConfig.options"
  [zoom]="mapConfig.zoom"
  [center]="mapConfig.center"
  (mapInitialized)="mapInitialized($event)"
>
  <ng-container *ngFor="let marker of markers$ | async; let i = index">
    <map-marker
      #mapMarker="mapMarker"
      [options]="selectedMarkerIndex === i ? selectedMarkerOption : defaultMarkerOption"
      [position]="marker.position"
      (mapClick)="markerClick(marker, mapMarker, i)"
    ></map-marker>
  </ng-container>
  <map-info-window (closeclick)="closeInfoWindow()">
    <ng-container *ngIf="selectedMarker$ | async as selectedMarker">
      <div class="c-window-marker" style="padding: 20px; width: 204px; max-width: 100%">
        <div>
          <img style="max-width: 88px; margin-bottom: 0.5rem" src="{{ selectedMarker.markerImg }}" alt="marker_image" />
          <p>
            <strong>{{ selectedMarker.hotelName }}</strong>
          </p>
          <ng-container *ngIf="selectedMarker.adultsOnly">
            <p>Adults Only</p>
          </ng-container>
          <p>{{ selectedMarker.hotelLocation }}</p>
          <p style="margin-top: 0.5rem">
            <a href="{{ selectedMarker.hotelURL | routing }}" (click)="markerActionClick()">
              <strong>Ver hotel</strong>
            </a>
          </p>
        </div>
      </div>
    </ng-container>
  </map-info-window>
</google-map>
