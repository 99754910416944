import { Component, forwardRef, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Literals } from '@shared/logic/models/common.model';
import { LiteralSections } from '@shared/logic/const/common';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';

@Component({
  selector: 'gdi-cat-input-password',
  templateUrl: './gdi-cat-input-password.component.html',
  styleUrls: ['./gdi-cat-input-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GdiCatInputPasswordComponent),
      multi: true
    }
  ]
})
export class GdiCatInputPasswordComponent implements ControlValueAccessor {
  @Input() size: 'cds-input-text--l' | 'cds-input-text--m' = 'cds-input-text--l';
  @Input() style: 'cds-input-text--regular' | 'cds-input-text--highlight' = 'cds-input-text--regular';
  @Input() placeholder: string;
  @Input() oneField = false;
  @Input() errorMsg: string | null = null;

  @Input() isAclaration = false;

  /////////////////////
  public hide = true;
  public value: string;
  public isDisabled: boolean;
  public errors: any;
  // public isTouched = false;
  /////////////////////

  public literals: Literals = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common)();

  constructor(private injector: Injector, private _commonStoreAdapter: CommonStoreAdapter) {}

  public get classes(): string[] {
    const atr = this.isDisabled ? 'disabled' : '';
    const oneField = this.oneField ? 'one-field' : '';
    return [`${this.style}`, `${this.size}`, atr, oneField];
  }

  public get inputState(): string[] {
    const isError = this.errors || this.errorMsg ? 'input-error' : '';
    const isAclaration = this.errors || this.errorMsg ? '' : this.isAclaration ? 'input-aclaration' : '';
    return [isAclaration, isError];
  }

  public onInput(event: any): void {
    this.value = event.target.value;
    this.onTouch();
    this.onChange(this.value);
    this.retrieveErrors();
  }

  public onBlur(): void {
    // this.isTouched = true;
    this.retrieveErrors();
  }

  private retrieveErrors(): void {
    this.errors = this.injector.get(NgControl).errors;
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange = (_: any) => {};
  onTouch = () => {};
}
