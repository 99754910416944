import { isPlatformBrowser, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit, PLATFORM_ID, signal, Signal, WritableSignal } from '@angular/core';

import { AppConfig } from '@app/app.config';
import { LanguageDataService } from '@app/core/services/languages/language-data.service';
import { LiteralSections } from '@app/shared/logic/const/common';
import { ImageRaw, Literals } from '@shared/logic/models/common.model';
import { AdvRewardsComponent } from '@shared/ns_components/gdi_extracted/rewards/adv-rewards/adv-rewards.component';
import { CommonStoreAdapter } from '@shared/store/common/common.store.adapter';
import { Tooltip } from '@shared/store/home/rewards/rewards.model';
import { timer } from 'rxjs';

type PopUpTranslation = {
  text_title: string;
  content_text_inital: string;
  content_text_second: string;
};

@Component({
  standalone: true,
  selector: 'app-loader',
  template: `
    <div class="c-loader">
      <div class="c-loader__content wrapper">
        <div class="c-loader__content__dots"></div>
        <div class="c-loader__content__text">{{ isPromoVisible ? popUpLang()?.content_text_inital : popUpLang()?.content_text_second }}</div>
        <ng-container *ngIf="isPromoVisible">
          <div class="c-loader__content__banner-rewards">
            <div class="c-loader__content__banner-rewards__text">
              <div class="c-loader__content__banner-rewards__text__logo">
                <img src="assets/img/logo/rewards-logo.svg" alt="logo" />
              </div>
              <div class="c-loader__content__banner-rewards__text__title">{{ popUpLang()?.text_title }}</div>
            </div>
            <ul class="c-loader__content__banner-rewards__items">
              <ng-container *ngFor="let item of rewardItems">
                <li class="c-loader__content__banner-rewards__items__item">
                  <app-adv-rewards [tooltip]="item"></app-adv-rewards>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./main-loader.scss'],
  imports: [NgIf, AdvRewardsComponent, NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLoader implements OnInit {
  public readonly literalsSig: Signal<Literals> = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common);
  private _languageSrv = inject(LanguageDataService);
  public popUpLang: WritableSignal<PopUpTranslation | null> = signal(null);

  PopUpTranslations: Record<string, PopUpTranslation> = {
    es: {
      text_title: 'Recuerda que solo en Catalonia Rewards tendrás el precio más bajo y además:',
      content_text_inital: 'Ya falta poco...',
      content_text_second: 'Un momento...'
    },
    ca: {
      text_title: 'Recorda que només a Catalonia Rewards tindràs el preu més baix i a més:',
      content_text_inital: 'Ja falta poc...',
      content_text_second: 'Un moment...'
    },
    en: {
      text_title: 'Remember that only in Catalonia Rewards will you have the lowest price and also:',
      content_text_inital: "There's little left...",
      content_text_second: 'Wait a minute...'
    },
    fr: {
      text_title: 'Rappelez-vous que seul Catalonia Rewards vous offrira le prix le plus bas et aussi :',
      content_text_inital: 'Il reste peu...',
      content_text_second: 'Un moment...'
    },
    de: {
      text_title: 'Denken Sie daran, dass Sie nur bei Catalonia Rewards den niedrigsten Preis erhalten und außerdem:',
      content_text_inital: 'Es bleibt wenig...',
      content_text_second: 'Einen Moment...'
    },
    it: {
      text_title: 'Ricorda che solo in Catalonia Rewards avrai il prezzo più basso e anche:',
      content_text_inital: 'Manca poco...',
      content_text_second: 'Un momento...'
    },
    pt: {
      text_title: 'Lembre-se que só na Catalonia Rewards terá o preço mais baixo e também:',
      content_text_inital: 'Falta pouco...',
      content_text_second: 'Um momento...'
    },
    ru: {
      text_title: 'Помните, что только в Catalonia Rewards вы получите самую низкую цену и также:',
      content_text_inital: 'Осталось немного...',
      content_text_second: 'Подождите минуту...'
    },
    nl: {
      text_title: 'Onthoud dat alleen bij Catalonia Rewards je de laagste prijs krijgt en ook:',
      content_text_inital: 'Er is nog weinig over...',
      content_text_second: 'Wacht even...'
    }
  };

  public isPromoVisible = false;
  public readonly rewardItems: Tooltip[] = [
    {
      displayText: this.literalsSig()?.label_warranty_price,
      image: {
        atributos: {
          titulo: 'logoCatalonia'
        },
        webp: '/assets/icon/rewards/cds-r-icon-min7blue.svg'
      } as ImageRaw,
      tooltip: false,
      tooltipText: 'Tooltip example'
    },
    {
      displayText: this.literalsSig()?.label_free_cancelation,
      image: {
        atributos: {
          titulo: 'logoCatalonia'
        },
        webp: '/assets/icon/rewards/cds-r-icon-24h.svg'
      } as ImageRaw,
      tooltip: false,
      tooltipText: 'Tooltip example'
    },
    {
      displayText: this.literalsSig()?.label_win_booking,
      image: {
        atributos: {
          titulo: 'logoCatalonia'
        },
        webp: '/assets/icon/rewards/travel-cash.svg'
      } as ImageRaw,
      tooltip: false,
      tooltipText: 'Tooltip example'
    },
    {
      displayText: this.literalsSig()?.label_upgrade_free,
      image: {
        atributos: {
          titulo: 'logoCatalonia'
        },
        webp: 'assets/icon/rewards/cds-r-icon-upgrade.svg'
      } as ImageRaw,
      tooltip: false,
      tooltipText: 'Tooltip example'
    }
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: string, private _commonStoreAdapter: CommonStoreAdapter, private _cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      timer(AppConfig.config.loaderDisplayTimeMs).subscribe(() => {
        this.isPromoVisible = true;
        this._cdRef.markForCheck();
      });
    }
    this.popUpLang.set(this.getLiteral());
  }

  private getLiteral() {
    return this.PopUpTranslations[this._languageSrv.getLang()];
  }
}
