import { Injectable } from '@angular/core';

import { Destination } from '@models/sections';
import { Store } from '@ngrx/store';
import { setPreFooter, setPreFooterNormalized } from '@shared/store/pre-footer/pre-footer.actions';
import { PreFooterDestiny } from '@shared/store/pre-footer/pre-footer.model';

import { PreFooterSelectors } from './pre-footer.selectors';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class PreFooterStoreAdapter {
  constructor(private store: Store<State>) {}

  public setPreFooter(preFooter: Record<string, Destination[]>): void {
    this.store.dispatch(setPreFooter({ preFooter }));
  }

  public setPreFooterNormalized(preFooter: Record<string, PreFooterDestiny[]>): void {
    this.store.dispatch(setPreFooterNormalized({ preFooter }));
  }

  public readonly selectPreFooterSig = this.store.selectSignal(PreFooterSelectors.getPreFooter);

  public readonly selectPreFooterNormalizedSig = this.store.selectSignal(PreFooterSelectors.getPreFooterNormalized);
}
