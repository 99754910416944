import { Injectable } from '@angular/core';

interface MyWindow extends Window {
  ScarabQueue: any[];
}

declare var window: MyWindow;

@Injectable({ providedIn: 'root' })
export class EmarsysService {
  public sendEvent<T>(eventName: string, eventData: Array<T | string>): void {
    if (typeof window.ScarabQueue !== 'undefined') {
      window.ScarabQueue.push([eventName, ...eventData]);
    }
  }
}
