import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(value: number, singular: string, plural: string): string {
    if (value === 1) {
      return `${singular}`;
    } else {
      return `${plural}`;
    }
  }
}
