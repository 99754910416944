import { ImageRaw } from '@shared/logic/models/common.model';

const FILE_CATEGORIES: Record<string, string> = {
  IMAGE: 'jpg,jpeg,png,webp',
  GIF: 'gif',
  VIDEO: 'mp4,avi,mov,wmv'
};

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
const VIDEO_EXTENSIONS = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'];

export type FileCategory = keyof typeof FILE_CATEGORIES;

export class ImageFunctions {
  static classifyFileType(imageUrl: string): FileCategory | null {
    const fileFormat = this.extractImageFormatFromUrl(imageUrl);
    if (!fileFormat) {
      return null;
    }
    for (const category in FILE_CATEGORIES) {
      const acceptedFormats = FILE_CATEGORIES[category].split(',');
      if (acceptedFormats.includes(fileFormat)) {
        return category;
      }
    }
    return null;
  }

  static convertToImageGalleryArray(images: Record<string, ImageRaw[]>, singleImage?: ImageRaw): ImageRaw[] {
    const imageGallery: ImageRaw[] = [];
    if (singleImage) {
      imageGallery.push(singleImage);
    }
    for (const key in images) {
      if (images.hasOwnProperty(key)) {
        images[key].forEach((imageRaw) => imageGallery.push(imageRaw));
      }
    }
    return imageGallery;
  }

  static isImage(imageRaw: ImageRaw): boolean {
    if (!imageRaw.hasOwnProperty('video')) {
      return true;
    }
    const sourceUrl: string | undefined = imageRaw.webp?.toLowerCase();
    return IMAGE_EXTENSIONS.includes(sourceUrl);
  }

  static isVideo(imageRaw: ImageRaw): boolean {
    if (imageRaw.hasOwnProperty('video')) {
      return true;
    }

    const sourceUrl: string | undefined = imageRaw.webp?.toLowerCase();
    return VIDEO_EXTENSIONS.includes(sourceUrl);
  }

  private static extractImageFormatFromUrl(imageUrl: string): string | null {
    if (!imageUrl) {
      return null;
    }
    const segments: string[] = imageUrl.split('/');
    const fileName: string = segments[segments.length - 1];
    const fileFormat: string | undefined = fileName.split('.').pop();
    if (fileFormat) {
      return fileFormat.toLowerCase();
    } else {
      console.error('Can not determinate image format');
      return null;
    }
  }
}
