import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Inject, ElementRef, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { IconItemComponent } from '@atom/icon-item/icon-item.component';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { ImageFunctions } from '@shared/logic/functions/image.functions';
import { ImageRaw, ModalResponse } from '@shared/logic/models/common.model';
import { GalleryMosaicItem } from '@shared/store/hotel/hotel.model';

export interface ExpandedImageModalInput {
  galleryMosaic: GalleryMosaicItem[];
  categoryIndex: number;
  imageIndex?: number;
}

@Component({
  standalone: true,
  selector: 'app-expanded-image-modal',
  templateUrl: './expanded-image-modal.component.html',
  styleUrls: ['./expanded-image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, NgIf, NgClass, IconItemComponent]
})
export class ExpandedImageModalComponent extends ModalElement<ExpandedImageModalComponent, ExpandedImageModalInput> implements AfterViewInit {
  @ViewChild('scrollBlockElement') scrollBlockElementRef!: ElementRef<HTMLDivElement>;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;

  public sizes: { [url: string]: { width: number; height: number } } = {};
  public galleryMosaic: GalleryMosaicItem[] = this.getDialogData().galleryMosaic;
  public categoryIndex: number = this.getDialogData().categoryIndex;
  public imageIndex: number = this.getDialogData().imageIndex ?? 0;

  constructor(
    @Inject(DIALOG_DATA) dialogData: ExpandedImageModalInput,
    dialogRef: DialogRef<ModalResponse, ExpandedImageModalComponent>,
    private _cdRef: ChangeDetectorRef
  ) {
    super(dialogData, dialogRef);
  }

  public ngAfterViewInit(): void {
    this.focusOnSelectedCategory();
  }

  public focusOnSelectedCategory(): void {
    const scrollContainer: HTMLDivElement | null = this.scrollBlockElementRef?.nativeElement;
    if (!scrollContainer) {
      return;
    }
    const element: HTMLImageElement | null = scrollContainer.querySelector<HTMLImageElement>('#category-focusable');
    if (!element) {
      return;
    }
    const elementOffsetTop: number = element.offsetTop - scrollContainer.getBoundingClientRect().top;
    const scrollOffset: number = scrollContainer.scrollTop + elementOffsetTop - 122;
    scrollContainer.scrollTo({ top: scrollOffset });
    this._cdRef.markForCheck();
  }

  public isImage(image: ImageRaw): boolean {
    return ImageFunctions.isImage(image);
  }

  public isVideo(image: ImageRaw): boolean {
    return ImageFunctions.isVideo(image);
  }

  public override onAccept(): void {
    this.closeDialog({ success: true });
  }

  public override onCancel(): void {
    this.closeDialog({ success: false });
  }

  public trackByImage(index: number, image: any): any {
    return image.source;
  }

  public getImageSize(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const size = {
          width: img.width,
          height: img.height
        };
        this.sizes[url] = size;
        resolve(size);
      };
      img.onerror = reject;
      img.src = url;
    });
  }

  public setOrientation(url: string): string {
    const size = this.sizes[url];
    if (size && size.width < size.height) {
      return 'image--v';
    } else if (size && size.width > size.height) {
      return 'image--h';
    }
    return '';
  }
}
