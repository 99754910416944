import { isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, inject, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Literals } from '@shared/logic/models/common.model';
import { DataTransChangeEvent, SecureFieldsInstance } from '@shared/ns_components/datatrans-widget/secure-fields.model';
import { SecureFieldsService, SFInstances } from '@shared/ns_components/datatrans-widget/secure-fields.service';
import { debounceTime, Subject, take, takeUntil } from 'rxjs';

const DATATRANS_WIDGET_STYLES = {
  '*': 'font-family: "Inter", sans-serif; font-size: 1rem; color: rgba(0, 0, 0, 0.50);',
  cardNumber: 'padding: 1rem;',
  cvv: 'padding: 1rem;',
  'cardNumber:focus': 'background-color: #f2f8ff;',
  'cvv:focus': 'background-color: #f2f8ff;',
  'cardNumber::placeholder': 'color: rgba(0, 0, 0, 0.50);'
};

@Component({
  selector: 'app-datatrans-widget',
  templateUrl: './datatrans-widget.component.html',
  styleUrls: ['./datatrans-widget.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatransWidgetComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  private widgetFields = {
    cardNumber: {
      placeholderElementId: 'card-number',
      inputType: 'tel',
      placeholder: '4242 4242 4242 4242',
      ariaLabel: 'Credit Card Number',
      enabled: true
    },
    cvv: {
      placeholderElementId: 'cvv-number',
      inputType: 'tel',
      placeholder: '123',
      ariaLabel: 'CVV',
      enabled: true
    }
  };

  public _literals: Literals;
  @Input() set literals(literals: Literals) {
    if (literals) {
      this.widgetFields.cardNumber.placeholder = literals.card_number_placeholder;
      this.widgetFields.cvv.placeholder = literals.card_cvv_placeholder;
      this._literals = literals;
    }
  }

  public _hideCVV: boolean;
  @Input() set hideCVV(hideCVV: boolean) {
    if (hideCVV) {
      this.widgetFields.cvv.enabled = false;
    }
    this._hideCVV = hideCVV;
  }

  @Input() receivedFormGroup: any;
  @Input() merchantId: string;

  public messageSuccess: boolean = false; // TODO: TBD. Currently not in use, but could be.

  public fieldsErrors: any = {
    cardNumber: { length: 0, valid: null },
    cvv: { length: 0, valid: null }
  };

  private secureFields: SecureFieldsInstance | null;
  private componentDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: string, private secureFieldsService: SecureFieldsService, private cdRef: ChangeDetectorRef) {}

  get cardNumberHasError(): boolean {
    return this.fieldsErrors.cardNumber.length > 0 && !this.fieldsErrors.cardNumber.valid;
  }

  get cvvHasError(): boolean {
    return this.fieldsErrors.cvv.length > 0 && !this.fieldsErrors.cvv.valid;
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initDataTransferSecureFields();

    this.secureFieldsService.resetField$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasToReset) => {
      if (hasToReset) {
        this.receivedFormGroup.reset();
        this.secureFieldsService.destroyInstanceByName(SFInstances.CreditCardForm);
        this.secureFields = null;

        this.initDataTransferSecureFields();
      }
    });
  }

  public ngOnDestroy(): void {
    this.receivedFormGroup.reset();
    this.secureFieldsService.destroyInstanceByName(SFInstances.CreditCardForm);
    this.secureFields = null;

    this.componentDestroy$.next(true);
    this.componentDestroy$.complete();
  }

  private initDataTransferSecureFields(): void {
    this.secureFieldsService
      .loadDataTransferSecureFieldsScript$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ isLoaded }) => {
        if (!isLoaded) {
          console.error('DaTrans Script could not be loaded');
          return;
        }
        this.initializeSecureFields();
      });
  }

  private initializeSecureFields(): void {
    this.secureFields =
      this.secureFieldsService.getInstanceByName(SFInstances.CreditCardForm) ?? this.secureFieldsService.getNewInstance(SFInstances.CreditCardForm);

    this.secureFields.secureFieldsInstance.initTokenize(this.merchantId, this.getEnabledWidgetFields(), {
      styles: DATATRANS_WIDGET_STYLES
    });

    this.secureFields.changeSubject?.pipe(takeUntil(this.componentDestroy$), debounceTime(100)).subscribe((v) => this.onChange(v));

    // const cvvContainer: any = document.getElementById('cvv-container');
    // Set focus to input fields when clicking containers
    // cvvContainer?.addEventListener('click', () => this.secureFields?.secureFieldsInstance.focus('cvv'));
  }

  private getEnabledWidgetFields() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields: any = {};
    Object.keys(this.widgetFields).forEach((k) => {
      const _key = k as keyof typeof this.widgetFields;
      if (this.widgetFields[_key].enabled) {
        fields[_key] = this.widgetFields[_key];
      }
    });
    return fields as typeof this.widgetFields;
  }

  private onChange(data: DataTransChangeEvent): void {
    const { cardNumber, cvv } = data.fields;

    this.fieldsErrors.cardNumber.length = cardNumber.length;
    this.fieldsErrors.cardNumber.valid = cardNumber.valid;
    this.receivedFormGroup.get('cardNumberValid').setValue(cardNumber.valid);

    if (!this._hideCVV) {
      this.fieldsErrors.cvv.length = cvv.length;
      this.fieldsErrors.cvv.valid = cvv.valid;
      this.receivedFormGroup.get('cvvNumberValid').setValue(cvv.valid);
    }

    this.cdRef.detectChanges();
  }
}
