import { Injectable, signal } from '@angular/core';

import { AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class LanguageDataService {
  private languageSig = signal<string>(AppConfig.config.language.default);

  public setLang(language: string): void {
    this.languageSig.set(language);
  }

  public getLang(): string {
    return this.languageSig();
  }
}
