import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './payment.reducer';

const getPaymentState = createFeatureSelector<State>('payment');

export const PaymentSelectors = {
  getUserCards: createSelector(getPaymentState, (state: State) => state.userCards),
  getUserDefaultCard: createSelector(getPaymentState, (state: State) => state.userCards.find((c) => c.isSetAsDefault)),
  getUserCardsLoaded: createSelector(getPaymentState, (state: State) => state.isLoaded),
  getUserCardByToken: (token: string) =>
    createSelector(getPaymentState, (state: State) => state.userCards.find((uc) => uc.token === token)),
  getMerchantId: createSelector(getPaymentState, (state: State) => state.merchantId)
} as const;
