<div class="cds-gallery">
  <div #scrollBlockElement (scroll)="onScroll()" class="cds-gallery__container">
    <div class="cds-gallery__nav">
      <ul>
        <li *ngFor="let items of gallery; index as i; trackBy: trackByCategory">
          <a (click)="handleClick(i, items.category)" [ngClass]="{ 'anchor-active': visibleIndex === i }">{{ items.category }}</a>
        </li>
      </ul>
    </div>
    <div class="cds-gallery__image-container">
      <section
        class="cds-gallery__image-list"
        *ngFor="let items of gallery; index as k; trackBy: trackByCategory"
        [id]="items.category"
        [ngClass]="!hasGalleryLayout ? 'single-layout' : 'column-layout'"
        #listItem
      >
        <div
          class="cds-gallery__image-item"
          *ngFor="let image of items.galleryImages; index as i; trackBy: trackByImage"
          [ngClass]="
            !hasGalleryLayout
              ? 'single'
              : {
                  'one-col': i % 3 === 0,
                  'two-col': i % 3 !== 0,
                  'fix-col': fixLayout(i, items.images.length)
                }
          "
          (click)="openExpandedImage(items.category, i)"
          [attr.data-image-id]="'image-' + i"
        >
          <ng-container *ngIf="image.type === 'image'; else otherBlock">
            <img dynamic-image [imageRaw]="items.images[i]" [alt]="image.description" [src]="items.images[i].talla_uno" class="block__gallery block--image" />
          </ng-container>
          <ng-template #otherBlock>
            <div class="iframe-container" style="background: #1e71e3" *ngIf="image.type === 'video'">
              <!-- <iframe class="block__gallery block--video" [src]="getSafeVideoUrl(image.source)" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
            </div>
            <div class="tour-container" style="background: #1e71e3; width: 100%; height: 100%; aspect-ratio: 5/3" *ngIf="image.type === 'tour'">
              <!-- <img  [src]="image.source" class="block__gallery block--tour" [alt]="image.description"> -->
              <div class="tour-tag">
                <app-icon-item icon="360"></app-icon-item>
                <span>Tour virtual</span>
              </div>
            </div>
          </ng-template>
        </div>
      </section>
    </div>
  </div>
</div>
