<header class="app-header" [class.bg-color]="isHeaderLoginVisible()">
  <div class="cds-header">
    <div class="cds-header__container wrapper">
      <div class="header-block">
        <div class="app-logo">
          <a routerLink="/" class="cds-logo cds-header" [class.no-redirect]="false" (click)="logo($event)">
            <ng-container *ngIf="false && header?.displayLogoDark?.webp">
              <img class="cds-logo--show-light logo-l" [src]="header?.displayLogoLight?.webp" [alt]="header?.displayLogoLight?.atributos?.descriptivo" />
            </ng-container>
            <ng-container *ngIf="true && header?.displayLogoDark?.webp">
              <img class="cds-logo--show-dark logo-d" [src]="header?.displayLogoDark?.webp" [alt]="header?.displayLogoDark?.atributos?.descriptivo" />
            </ng-container>
          </a>
        </div>
        <div class="cds-header__container--options">
          <ng-container *ngIf="!userSig() && !isHeaderLoginVisible()">
            <ng-container *ngIf="showOptionsContainerSig()">
              <app-button
                class="btn-login"
                type="cds-btn--link"
                icon="person"
                [singin]="true"
                [label]="literals.init_sesion"
                (buttonClick)="setExpandedStatus(true)"
              ></app-button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isHeaderLoginVisible()">
            <app-button type="cds-btn--link-icon" size="cds-btn--m" icon="close" (buttonClick)="setExpandedStatus(false)"></app-button>
          </ng-container>
          <ng-container *ngIf="userSig() as user">
            <div class="cds-logged" (click)="myProfile()">
              <div class="cds-logged__container">
                <div class="cds-avatar">
                  <img src="{{ user.avatar }}" alt="user-avatar" />
                </div>
                <div class="cds-logged__info">
                  <p class="cds-logged__info--name">{{ user | loggedName : 15 : deviceSig() }}</p>
                  <p class="cds-logged__info--cash">{{ user.travelCashEUR | number : '1.2-2' }} €</p>
                </div>
              </div>
              <div class="cds-logged__menu" [class.active]="loggedMenuActive">
                <div class="cds-logged__menu__container">
                  <ul class="cds-logged__menu__sections">
                    <ng-container *ngFor="let item of loggedHeaderSections">
                      <li class="cds-logged__menu__sections__section">
                        <app-icon-item [icon]="item.icon"></app-icon-item>
                        <a (click)="clickMenuLoginOption(item.title, item.routerLink)" [routerLink]="item.routerLink | routing">{{ item.title | titlecase }}</a>
                        <ng-container *ngIf="item.isNew">
                          <div class="cds-logged__menu__sections__section__new"></div>
                        </ng-container>
                      </li>
                    </ng-container>
                  </ul>
                  <div class="cds-logged__menu__sign-off">
                    <a appAvoidHref href="" (click)="logout()">{{ literals.label_sign_off }}</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="header-expanded" [class.active]="isHeaderLoginVisible()">
    <div class="box">
      <div class="header-expanded__content">
        <app-login-form-basic class="app-login-form-basic" [ctaLoginExpanded]="true" (ev_closeLoginForm)="closeExpandedHeader()"></app-login-form-basic>
      </div>
      <div class="header-expanded__veil" (click)="setExpandedStatus(false)"></div>
    </div>
  </div>
  <div class="cds-logged__menu__bg" [class.active]="loggedMenuActive" (click)="closeExpandedHeader()"></div>
</header>
