import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoadingService } from '@shared/logic/services/loading.service';
import { finalize, Observable } from 'rxjs';

export const SkipLoading = new HttpContextToken<boolean>(() => false);

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(SkipLoading)) {
      return next.handle(req);
    }
    const requestId = req.urlWithParams;
    this.loadingService.requestStarted(requestId);
    this.loadingService.loadingOn();
    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.requestCompleted(requestId);
        if (this.loadingService.activeRequests === 0) {
          this.loadingService.loadingOff();
        }
      })
    );
  }
}
