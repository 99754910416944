import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { AppConfig } from '@app/app.config';
import { CommonService } from '@app/shared/logic/services/common.service';

import { LanguageDataService } from '@services/languages/language-data.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private _languageDataService: LanguageDataService, private _commonService: CommonService, private _location: Location) {}

  public init(): Promise<void> {
    const defaultLang = this._location.path().split('/')?.[1]?.split('?')?.[0]?.replace(/\//g, '') ?? AppConfig.setConfigDefaultLang();
    this._setLanguage(defaultLang);

    return new Promise<void>((resolve) => {
      this._commonService.getLanguages$().subscribe(() => {
        resolve();
      });
    });
  }

  private _setLanguage(language: string): void {
    this._languageDataService.setLang(language);
  }
}
