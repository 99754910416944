import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RoutingService } from '@app/core/routing/routing.service';

import { environment } from '@env/environment';
import { APIS_URL, API_KEY_SYMFONY } from '@services/http-utils/apis-url';
import { SymfonyService } from '@services/symfony/symfony.services';
import { Observable, switchMap, BehaviorSubject, filter, take, catchError, throwError, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SymfonyTokenInterceptor implements HttpInterceptor {
  private token = '';
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private isRefreshingToken = false;

  constructor(private symfonyService: SymfonyService, private _routingService: RoutingService) {
    this.tokenSubject.subscribe((token) => (this.token = token));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url: string = request.url;
    if (url.includes(APIS_URL[API_KEY_SYMFONY.GET_TOKEN].path)) {
      return next.handle(request);
    }

    // I hope I am not remembered for this.
    // Basically, in payments you have to include the symfony token.
    // Given the current construction of the interceptor it is only attached to calls to symfone, therefore we also force those calls that fail.
    // to avoid getting error in first call.

    const isValidateCode = url.includes('api/validateCode');
    const shouldForceTokenAddition =
      isValidateCode ||
      url.includes('api/payments/payment-methods') ||
      url.includes('api/payments/retrieve_card') ||
      url.includes('api/payments/pci_token') ||
      url.includes('api/payments/save_card') ||
      url.includes('api/emarsys') ||
      url.includes('api/members/');

    if (url.includes(environment.symfony) || shouldForceTokenAddition) {
      if (this.token) {
        request = this.addTokenToRequest(request, this.token);
      } else {
        if (!isValidateCode) {
          return this.handle401Error(request, next);
        }
      }
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.isRefreshingToken) {
            return this.tokenSubject.pipe(
              filter((token) => token !== ''),
              take(1),
              switchMap((token) => {
                return next.handle(this.addTokenToRequest(request, token));
              })
            );
          } else {
            this.isRefreshingToken = true;

            return this.symfonyService.getTokenSymfony(true).pipe(
              switchMap((newToken: string) => {
                this.isRefreshingToken = false;
                this.tokenSubject.next(newToken);
                return next.handle(this.addTokenToRequest(request, newToken));
              }),
              catchError((tokenError) => {
                this.isRefreshingToken = false;
                return throwError(() => tokenError);
              })
            );
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next('');

      return this.symfonyService.getTokenSymfony().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addTokenToRequest(request, newToken));
          }
          return this.redirectToHome();
        }),
        catchError((error) => {
          return this.redirectToHome();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token !== ''),
        take(1),
        switchMap((token) => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }

  private redirectToHome(): Observable<never> {
    this._routingService.GO_HOME();
    return throwError(() => 'Token expirado');
  }
}
