import { NgClass } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export interface Metrics {
  id: string;
  typeMetric: string;
  numberMetric: number;
  compressed: boolean;
}

@Component({
  standalone: true,
  selector: 'app-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass]
})
export class MetricComponent {
  @Input() metricData: Metrics;

  get metricType() {
    return this.metricData.compressed ? 'metric metric--compressed' : 'metric';
  }

  get metricWidth() {
    return `${this.metricData.numberMetric * 10}%`;
  }
}
