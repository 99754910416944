import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgForOf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatSelectModule } from '@angular/material/select';

import { ButtonComponent } from '@atom/button/button.component';
import { ModalElement } from '@shared/logic/abstract/modal.abstract';
import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { Literals, ModalResponse } from '@shared/logic/models/common.model';
import { CommonService } from '@shared/logic/services/common.service';
import { CdkDialogWrapperNewModule } from '@shared/ns_components/modals/cdk-dialog-wrapper-new/cdk-dialog-wrapper-new.module';
import { DeactivateReasonRaw } from '@shared/store/common/common.model';
import { take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-deactivate-account-modal',
  templateUrl: './deactivate-account-modal.component.html',
  imports: [CdkDialogWrapperNewModule, MatFormFieldModule, MatSelectModule, NgForOf, ButtonComponent, FormsModule],
  styleUrls: ['./deactivate-account-modal.component.scss']
})
export class DeactivateAccountModalComponent extends ModalElement<DeactivateAccountModalComponent, { literals: Literals }> implements OnInit {
  public deactivateReasons: DeactivateReasonRaw[];
  public selectedReason: number;
  public readonly literals: Literals = this.getDialogData().literals;

  constructor(
    @Inject(DIALOG_DATA) dialogData: { literals: Literals },
    dialogRef: DialogRef<ModalResponse, DeactivateAccountModalComponent>,
    private _commonService: CommonService
  ) {
    super(dialogData, dialogRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this._commonService
      .getDeactivationReasons$()
      .pipe(take(1))
      .subscribe((response) => {
        this.deactivateReasons = response;
        if (GeneralFunctions.isArrayLike(response, 1)) {
          this.selectedReason = response[0].id;
        }
      });
  }

  onAccept(): void {
    this.closeDialog({
      success: true,
      customData: { reasonId: this.selectedReason, reasonText: this.deactivateReasons.find((r) => r.id === this.selectedReason)?.name }
    });
  }

  onCancel(): void {
    this.closeDialog({ success: false });
  }
}
