<div class="opinion-card">
  <div class="opinion-card__user">
    <div class="icon">
      <app-icon-item icon="person" class="cds-icon-item"></app-icon-item>
    </div>
    <div class="user-info">
      <p class="name">{{ nameUser }}</p>
      <p class="date">{{ copyDate }}</p>
    </div>
  </div>

  <div class="opinion-card__message">
    <div class="rate">
      <p>{{ markCommentary }}<span> / 10</span></p>
    </div>
    <div class="message">
      <div class="message__title">{{ titleCommentary }}</div>
      <div class="message__text">{{ textCommentary }}</div>
      <div class="message__settings">
        <!-- <app-button type="cds-btn--link" label="+ Ver más" align="left"></app-button> -->
      </div>
    </div>
  </div>
</div>
