import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './common.reducer';

const getCommonState = createFeatureSelector<State>('common');

export const CommonSelectors = {
  getLanguages: createSelector(getCommonState, (state: State) => state.languages),
  getCountries: createSelector(getCommonState, (state: State) => state.countries),
  getGenres: createSelector(getCommonState, (state: State) => state.genres),
  getLiteralsBySection: (section: string) => createSelector(getCommonState, (state: State) => state.literals[section])
};
