import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-feature-item',
  templateUrl: './feature-item.component.html',
  styleUrls: ['./feature-item.component.scss']
})
export class FeatureItemComponent {
  @Input() type: 'feature-item__info' | 'feature-item__info--extra' = 'feature-item__info';

  //Icon feature-item
  @Input() featureIcono = './../../../assets/icon/rewards/cds-r-icon-online-discount.svg';
  @Input() featureIconoTextAlt = 'iconoRewardsPromotions';

  //Título
  @Input() featureTitle = 'Title';
  @Input() featureTitleTooltip = true;

  //Descripción
  @Input() featureDescription = 'Description';
  @Input() featureDescriptionTooltip = false;

  //CTA - Texto
  @Input() featureCta = 'Action';

  //CTA - Define el tipo (button, link o icono+texto)
  @Input() featureTitleCtaButton = false;
  @Input() featureTitleCtaLink = true;
  @Input() featureExtraInfo = false;

  //CTA - Icono + texto
  @Input() featureExtraInfoText = 'ExtraInfoText';
  @Input() featureExtraInfoIcon = 'ExtraInfoText';

  //Tag
  @Input() featureTag = true;
  @Input() featureTagText = 'Tag text';

  // Tipos de Tag: 'cds-tag--basic' | 'cds-tag--promo' | 'cds-tag--alert' | 'cds-tag--killer' = 'cds-tag--basic';
  @Input() featureTagType = 'cds-tag--promo';

  @Input() onlyTile = false;

  @Input() min = false;

  @Output() tooltipActive = new EventEmitter<boolean>();

  activeTooltip() {
    this.tooltipActive.emit(true);
  }

  public get classes(): string[] {
    if (this.featureDescription === '' && !this.featureTag) {
      this.onlyTile = true;
    }
    const onlyTile = this.onlyTile ? 'single-item' : '';
    const min = this.min ? 'feature-item-min' : '';
    return ['cds-feature-item', onlyTile, min];
  }
  public get typeFeature(): string[] {
    return [`${this.type}`];
  }
}
