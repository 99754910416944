/*
 * To date, this file contains the interfaces that we know are/are going to be constant in the application.
 * There are currently many calls that return very similar but different data model-raw
 * */

// Unify back responses

export interface ResponseRaw<T> {
  success: boolean;
  data: T;
}

// TODO: Unify model-raw in back
export interface CoordinatesRawAsString {
  latitud: string;
  longitud: string;
}

export interface CoordinatesRawAsNumber {
  latitud: number;
  longitud: number;
}

export interface ImageRaw {
  original: string;
  browser_thumbnail: string;
  cabecera_servicios: string;
  large: string;
  listado_servicios: string;
  media_library: string;
  medium: string;
  personal: string;
  privileged: string;
  talla_hero: string;
  talla_uno: string;
  talla_dos: string;
  talla_tres: string;
  talla_cuatro: string;
  talla_cinco: string;
  talla_seis: string;
  talla_siete: string;
  talla_ocho: string;
  talla_nueve: string;
  thumbnail: string;
  webp: string;
  wide: string;
  atributos: {
    descriptivo: string;
    titulo: string;
  };
  coverImage?: string;
  video?: string;
}

export interface GuestCountRaw {
  ageQualifyingCode: number;
  count: number;
}

export type Literals = Record<string, string>;

export interface ProfitRaw {
  imagen: ImageRaw;
  texto: string;
  tooltip: string;
  showConfirmationEmail?: boolean;
  priorProfit: boolean;
  hotelType: { value: string }[];
  iconClass?: string;
}

//////////////////////////////////////////////////
export interface DiscountRaw {
  accumulable?: string;
  activeFrom?: string;
  activeTo?: string;
  addTotalPercentage?: string;
  amountAfterTax: number;
  amountBeforeTax: number;
  autoApply?: number;
  colorKiller: string;
  currencyCode: string;
  currencySymbol: string;
  descriptionOffer?: string;
  descriptionTms: string;
  fromBw?: string;
  fromTw?: string;
  iconOffer: string;
  logged?: string;
  mealOffer: boolean;
  membership?: string;
  offerConditions: string;
  offerKiller?: string;
  offerType?: string;
  orderWeight?: string;
  percent: number;
  priority: any;
  promotionCode: string;
  regimeType?: string;
  sealText?: string;
  singularRecurring?: string;
  subtitleOffer: string;
  textKiller?: string;
  titleOffer: string;
  toBw?: string;
  toTw?: string;
  value?: number;
}

export type Discount = {
  displayText: string;
  value: string | number;
  iconItem: string;
  titleItem: string;
  itemConditions: string;
  promotionCode?: string;
  percent?: number;
};

//////////////////////////////////////////////////

// TODO: Use this and extends for non-common properties
export interface CdkDialogWrapperNewModalInput {
  images?: ImageRaw[];
  promo?: boolean;
  promoPosition?: boolean;
  offerDiscount?: string | number;
  icon?: string;
  displayTitle?: string;
  literals?: Literals;
}

export interface ModalResponse {
  success: boolean;
  customData?: Record<string, unknown>;
}

// Room Occupancies (from decoded url param)
export type RoomOccupancy = {
  [ageCode: number]: number;
  roomNum: number;
};

// Currency types
export enum CurrencyType {
  USD = 'USD',
  EUR = 'EUR',
  MXN = 'MXN'
}

export type CurrencyTypeSymbol = '€' | '$' | 'US$';

// Expanded type
export type ExpandedType = 'expanded' | 'closed' | 'hibrid';

export interface CollectiveBanner {
  code: string;
  displayTitle: string;
  displayDescription: string;
  type: string;
  percent: number;
  actionButton: ActionButton;
  tags: Tag[];
  bannerItems: { displayIcon: string; displayText: string }[];
  modal: {
    displayTitle: string;
    displayDescription: string;
    displayImage: ImageRaw;
    items: { displayTitle: string; displayText: string }[];
    actionButton: ActionButton;
  };
}

export interface ActionButton {
  displayText: string;
  url: string;
}

export type TagType = 'basic' | 'promo' | 'alert' | 'killer';
export type Tag = {
  text: string;
  type: TagType;
  icon?: string;
  isRewardsOffer?: boolean;
};
