import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';

import { DynamicImageDirective } from '@directives/image/dynamic-image.directive';
import { ImageRaw } from '@shared/logic/models/common.model';
import { CarouselComponent } from '@shared/ns_components/gdi_extracted/carousel/v1/carousel.component';
import { KeenSliderOptions } from 'keen-slider';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  host: {
    class: 'cds-image-slider'
  },
  imports: [NgIf, NgFor, CarouselComponent, DynamicImageDirective],
  standalone: true
})
export class ImageSliderComponent implements AfterViewInit {
  @Input() currentSlideIndex = 0;
  /* Input de la galería de PRIMENG */
  @Input({ required: true }) images: ImageRaw[];
  /*  @Input() heroSlides: HeroSlides[] = []; */
  @Input() timeChangeSlade = 5000;
  @Input() hadArrow = false;
  @Input() hadDots = false;
  @Input() configCarousel: KeenSliderOptions = {
    loop: true,
    mode: 'snap',
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 1, spacing: 0 }
      },
      '(min-width: 1280px)': {
        slides: { perView: 1, spacing: 0 }
      }
    },
    slides: { perView: 1, spacing: 0 }
  };
  @Input() isStickerHidden = false;

  public hasInitView = false;
  ngAfterViewInit(): void {
    this.hasInitView = true;
  }

  public trackByImageId(index: number): number {
    return index;
  }
}
