export enum ConstantsHeaderClass {
  HEADER_SHOW = 'app-header--show',
  HEADER_HIDE = 'app-header app-header--hide',
  SEARCH_BAR_TOP = 'app-search--top',
  SEARCH_BAR_TOP_HEADER = 'app-search--top-header',
  HEADER_TOP_STICKY = 'app-search--top sticky',
  HEADER_TRANSPARENT = 'app-header--transparent',
  HEADER_TOP_HEADER_STICKY = 'app-search--top-header sticky'
}

export enum ConstantsHeaderStepperBar {
  HEADER_MULTIROOM_STEPPER = 'cds-multiroom-stepper--show-header',
  HEADER_MULTIROOM_STEPPER_UP = 'app-multiroom-stepper cds-multiroom-stepper--show-header app-search--top-header sticky'
}

export enum ConstantsHeaderTypePosition {
  TYPE_ON_HERO = 'position-on-hero',
  TYPE_WITHOUT_HERO = 'position-without-hero'
}

export enum ConstantHeaderSize {
  HEIGHT = 64
}
