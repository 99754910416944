import { Action } from '@ngrx/store';
import { FirstSearchType, PrevContentInfo } from '@shared/store/ga/ga.model';

export enum SearchActionsTypes {
  SetPrevContentInfo = '[GA4] Set Previous Content Info',
  SetFirstSearchDoneStatus = '[GA4] Set First Search Loaded Status',
  SetFirstSearchType = '[GA4] Set First Search Type'
}

export class SetPrevContentInfo implements Action {
  readonly type = SearchActionsTypes.SetPrevContentInfo;

  constructor(readonly payload: { prevContentInfo: PrevContentInfo }) {}
}

export class SetFirstSearchDoneStatus implements Action {
  readonly type = SearchActionsTypes.SetFirstSearchDoneStatus;

  constructor(readonly payload: { status: boolean }) {}
}

export class SetFirstSearchType implements Action {
  readonly type = SearchActionsTypes.SetFirstSearchType;

  constructor(readonly payload: { type: FirstSearchType }) {}
}

export type GaActions = SetPrevContentInfo | SetFirstSearchDoneStatus | SetFirstSearchType;
