import { Routes } from '@angular/router';

import { createMatcher } from '@shared/logic/http/matcher/createMatcher';

export const enum LegalPageType {
  AboutUs = 'AboutUs',
  Legal = 'Legal',
  Privacy = 'Privacy',
  Rewards = 'Rewards',
  Cookies = 'Cookies',
  TermsContract = 'TermsContract',
  GeneralRewardsConditions = 'GeneralRewardsConditions',
  LegalWarningBerlin = 'LegalWarningBerlin',
  EthicalChannel = 'EthicalChannel',
  Expansion = 'Expansion',
  CorporateSocialResponsibility = 'CorporateSocialResponsibility',
  WorkWithUs = 'WorkWithUs',
  PrivacyPolicyForCandidates = 'PrivacyPolicyForCandidates',
  ResponsibleProcessing = 'ResponsibleProcessing',
  Rewards2 = 'Rewards2'
}

export const aboutUs: Record<string, string> = {
  es: 'sobre-nosotros',
  ca: 'sobre-nosaltres',
  en: 'about-us',
  fr: 'a-propos-de-nous',
  de: 'ueber-uns',
  it: 'chi-siamo',
  pt: 'sobre-nos',
  ru: 'about-us',
  nl: 'over-ons'
};
export const legalWarning: Record<string, string> = {
  es: 'aviso-legal',
  ca: 'avís-legal',
  en: 'legal-notice',
  fr: 'mentions-legales',
  de: 'impressum',
  it: 'avviso-legale',
  pt: 'aviso-legal',
  ru: 'legal-notice',
  nl: 'juridische-kennisgeving'
};
export const privacyPolicy: Record<string, string> = {
  es: 'politica-de-privacidad',
  ca: 'politica-de-privacitat',
  en: 'privacy-policy',
  fr: 'politique-de-confidentialite',
  de: 'datenschutzrichtlinie',
  it: 'informativa-sulla-privacy',
  pt: 'politica-de-privacidade',
  ru: 'privacy-policy',
  nl: 'privacybeleid'
};
export const rewards: Record<string, string> = {
  es: 'condiciones-rewards',
  ca: 'condicions-rewards',
  en: 'rewards-conditions',
  fr: 'conditions-rewards',
  de: 'prämienbedingungen',
  it: 'condizioni-rewards',
  pt: 'condições-de-rewards',
  ru: 'rewards-conditions',
  nl: 'beloningsvoorwaarden'
};

export const cookies: Record<string, string> = {
  es: 'politica-de-cookies',
  ca: 'politica-de-cookies',
  en: 'cookies-policy',
  fr: 'politique-de-cookies',
  de: 'cookie-richtlinie',
  it: 'politica-sui-cookie',
  pt: 'politica-de-cookies',
  ru: 'cookies-policy',
  nl: 'cookiebeleid'
};

export const termsContract: Record<string, string> = {
  es: 'condiciones-generales-contratacion',
  ca: 'condicions-generals-contractacio',
  en: 'terms-contract',
  fr: 'conditions-generales-de-vente',
  de: 'vertragsbedingungen',
  it: 'condizioni-del-contratto',
  pt: 'condicoes-do-contrato',
  ru: 'условия-договора',
  nl: 'algemene-contractvoorwaarden'
};

export const generalRewardsConditions: Record<string, string> = {
  es: 'condiciones-generales-contratacion-rewards',
  ca: 'condiciones-generales-de-contratacion-rewards',
  en: 'terms-and-conditions-catalonia-rewards-membership',
  fr: 'terms-and-conditions-catalonia-rewards-membership',
  de: 'terms-and-conditions-catalonia-rewards-membership',
  it: 'terms-and-conditions-catalonia-rewards-membership',
  pt: 'terms-and-conditions-catalonia-rewards-membership',
  ru: 'terms-and-conditions-catalonia-rewards-membership',
  nl: 'contractvoorwaarden-catalonia-rewards'
};

export const legalWarningBerlin: Record<string, string> = {
  es: 'aviso-legal-berlin',
  ca: 'avis-legal-berlin',
  en: 'disclaimer-berlin',
  fr: 'mentions-legales-berlin',
  de: 'rechtshinweis-berlin',
  it: 'avviso-legale-berlino',
  pt: 'aviso-legal-berlim',
  ru: 'pravovaya-informacia-berlin',
  nl: 'juridische-kennisgeving-berlijn'
};

export const ethicalChannel: Record<string, string> = {
  es: 'canal-etico',
  ca: 'canal-etic',
  en: 'ethical-channel',
  fr: 'chaine-ethique',
  de: 'ethical-channel',
  it: 'canale-etico',
  pt: 'canal-etico',
  ru: 'ethical-channel',
  nl: 'ethische-kanaalbeleid'
};

export const expansion: Record<string, string> = {
  es: 'expansion',
  ca: 'expansio',
  en: 'expansion',
  fr: 'expansion',
  de: 'expansion',
  it: 'espansione',
  pt: 'expansao',
  ru: 'rasshirenie',
  nl: 'uitbreiding'
};

export const corporateSocialResponsibility: Record<string, string> = {
  es: 'responsabilidad-social',
  ca: 'expansio',
  en: 'corporate-social-responsibility',
  fr: 'responsabilite-sociale',
  de: 'soziale-verantwortung',
  it: 'responsabilita-sociale',
  pt: 'responsabilidade-social',
  ru: 'socialnaya-otvetstvennost',
  nl: 'sociale-verantwoordelijkheid'
};

export const workWithUs: Record<string, string> = {
  es: 'trabaja-con-nosotros',
  ca: 'treballa-amb-nosaltres',
  en: 'work-with-us',
  fr: 'carrieres',
  de: 'stellenangebote',
  it: 'lavora-con-noi',
  pt: 'trabalhe-com-a-gente',
  ru: 'rabotaite-s-nami',
  nl: 'werk-met-ons'
};

export const privacyPolicyForCandidates: Record<string, string> = {
  es: 'politica-de-privacidad-candidatos',
  ca: 'politica-de-privacitat-candidats',
  en: 'privacy-policy-for-candidates',
  fr: 'politique-de-confidentialit-candidats',
  de: 'datenschutzrichtlinie-fur-bewerber',
  it: 'privacy-policy-candidati',
  pt: 'politica-de-privacidade-candidatos',
  ru: 'politika-konfidencial-nosti-kandidatov',
  nl: 'privacybeleid-van-de-kandidaat'
};

export const responsibleProcessing: Record<string, string> = {
  es: 'responsable-del-tratamiento',
  ca: 'responsable-de-l-tractament',
  en: 'responsible-processing',
  fr: 'responsable-du-traitement',
  de: 'datenschutzrichtlinie-fur-bewerber',
  it: 'verantwortlich-fur-die-verarbeitung',
  pt: 'responsavel-pelo-processamento',
  ru: 'responsible-processing',
  nl: 'verantwoordelijk-voor-behandeling'
};

export const rewards2: Record<string, string> = {
  es: 'condiciones-generales-contratacion-rewards-2',
  ca: 'rcondiciones-generales-contratacion-rewards-2',
  en: 'condiciones-generales-contratacion-rewards-2',
  fr: 'condiciones-generales-contratacion-rewards-2',
  de: 'condiciones-generales-contratacion-rewards-2',
  it: 'condiciones-generales-contratacion-rewards-2',
  pt: 'condiciones-generales-contratacion-rewards-2',
  ru: 'condiciones-generales-contratacion-rewards-2',
  nl: 'condiciones-generales-contratacion-rewards-2'
};

const aboutUsMatcher = createMatcher([aboutUs]);
const legalMatcher = createMatcher([legalWarning]);
const privacyMatcher = createMatcher([privacyPolicy]);
const rewardsMatcher = createMatcher([rewards]);
const cookiesMatcher = createMatcher([cookies]);
const termsContractMatcher = createMatcher([termsContract]);
const generalRewardsConditionsMatcher = createMatcher([generalRewardsConditions]);
const legalWarningBerlinMatcher = createMatcher([legalWarningBerlin]);
const ethicalChannelMatcher = createMatcher([ethicalChannel]);
const expansionMatcher = createMatcher([expansion]);
const corporateSocialResponsibilityMatcher = createMatcher([corporateSocialResponsibility]);
const workWithUsMatcher = createMatcher([workWithUs]);
const privacyPolicyForCandidatesMatcher = createMatcher([privacyPolicyForCandidates]);
const responsibleProcessingMatcher = createMatcher([responsibleProcessing]);
const rewards2Matcher = createMatcher([rewards2]);

export const legalTextRoutes: Routes = [
  {
    matcher: aboutUsMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.AboutUs }
  },
  {
    matcher: legalMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Legal }
  },
  {
    matcher: privacyMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Privacy }
  },
  {
    matcher: rewardsMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Rewards }
  },
  {
    matcher: cookiesMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Cookies }
  },
  {
    matcher: termsContractMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.TermsContract }
  },
  {
    matcher: generalRewardsConditionsMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.GeneralRewardsConditions }
  },
  {
    matcher: legalWarningBerlinMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.LegalWarningBerlin }
  },
  {
    matcher: ethicalChannelMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.EthicalChannel }
  },
  {
    matcher: expansionMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Expansion }
  },
  {
    matcher: corporateSocialResponsibilityMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.CorporateSocialResponsibility }
  },
  {
    matcher: workWithUsMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.WorkWithUs }
  },
  {
    matcher: privacyPolicyForCandidatesMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.PrivacyPolicyForCandidates }
  },
  {
    matcher: responsibleProcessingMatcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.ResponsibleProcessing }
  },
  {
    matcher: rewards2Matcher,
    loadComponent: () => import('./pages/legal-text/legal-text.component').then((p) => p.LegalTextComponent),
    data: { type: LegalPageType.Rewards2 }
  }
];
