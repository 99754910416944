import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';

import { API_KEY_DRUPAL } from '@services/http-utils/apis-url';
import { HttpUtils } from '@services/http-utils/http-utils';
import { TransferStateService } from '@shared/logic/services/transfer-state.service';
import { PreFooterResponseRaw } from '@shared/store/pre-footer/pre-footer.model.raw';
import { Observable } from 'rxjs';

const TRANSFER_STATE_KEYS = {
  GET_PRE_FOOTER_BY_ID: (id: string) => makeStateKey<PreFooterResponseRaw>('PreFooterApiService.byId.' + id.toString())
};

@Injectable({ providedIn: 'root' })
export class PreFooterApiService extends TransferStateService {
  constructor(@Inject(PLATFORM_ID) platformId: string, transferState: TransferState, private httpUtils: HttpUtils) {
    super(platformId, transferState);
  }

  public getPreFooterById$(id: string): Observable<PreFooterResponseRaw> {
    return this.useTransferState(TRANSFER_STATE_KEYS.GET_PRE_FOOTER_BY_ID(id), {} as PreFooterResponseRaw, () => {
      return this.httpUtils.get<PreFooterResponseRaw>(API_KEY_DRUPAL.GET_PREFOOTER, { id });
    });
  }
}
