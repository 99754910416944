import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { SetActiveHeader } from './header.actions';
import { HeaderSelectors } from './header.selectors';
import { State } from '../app.reducer';

@Injectable({ providedIn: 'root' })
export class HeaderStoreAdapter {
  constructor(private store: Store<State>) {}

  public setHeaderActive(headerActive: boolean): void {
    this.store.dispatch(new SetActiveHeader({ headerActive }));
  }

  public readonly selectHeaderActive$ = (): Observable<boolean | null> =>
    this.store.select(HeaderSelectors.getHeaderActive);
}
