import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';
import { LiteralSections } from '@app/shared/logic/const/common';
import { Literals } from '@app/shared/logic/models/common.model';
import { CommonStoreAdapter } from '@app/shared/store/common/common.store.adapter';

@Component({
  standalone: true,
  selector: 'app-review-comment',
  templateUrl: './review-comment.component.html',
  styleUrls: ['./review-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconItemComponent]
})
export class ReviewCommentComponent {
  @Input() nameUser: string;
  @Input() dateCommentary: string;
  @Input() markCommentary: string;
  @Input() titleCommentary: string;
  @Input() textCommentary: string;
  public readonly literalSig: Signal<Literals> = this._commonStoreAdapter.selectLiteralsBySectionSig(LiteralSections.Common);
  constructor(private _commonStoreAdapter: CommonStoreAdapter) {}

  get copyDate(): string {
    return this.literalSig()['reviewpro-comment-txt']?.replace('%FECHA%', this.dateCommentary);
  }
}
