import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, HostBinding, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { ButtonComponent } from '@atom/button/button.component';
import { CarouselComponent } from '@shared/ns_components/gdi_extracted/carousel/v1/carousel.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel-dot',
  templateUrl: './carousel-dot.component.html',
  styleUrls: ['./carousel-dot.component.scss'],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, ButtonComponent]
})
export class CarouselDotComponent implements OnInit, OnDestroy {
  @Input() slideIndex = 0;
  @Input() isDotsClick = false;
  @ContentChild(TemplateRef) contentChild!: TemplateRef<HTMLElement>;
  @HostBinding('class.active')
  protected isActiveValue = false;
  protected currentSlideChangeSubscription: Subscription;

  get isActive() {
    return this.isActiveValue;
  }

  constructor(protected readonly carousel: CarouselComponent) {
    this.currentSlideChangeSubscription = this.carousel.currentSlideChange.subscribe((index) => this.handleSlideChange(index));
  }

  /* Function para cambiar de slide a través del dot */
  /* de momento los dots solo son informativos no clickables, de no ser así habría que cambiar el 'div' por una etiqueta 'button y pasar la function handleClick */
  handleClick() {
    this.carousel.goTo(this.slideIndex);
  }

  handleSlideChange(index: number) {
    this.isActiveValue = this.slideIndex === index;
  }

  ngOnInit() {
    this.handleSlideChange(this.carousel.currentSlide);
  }

  ngOnDestroy(): void {
    this.currentSlideChangeSubscription.unsubscribe();
  }
}
