import { rest } from 'msw';
import { environment } from 'src/environments/environment';

import * as auth from '../data/auth/auth';

export default [
  rest.post(`${environment.symfony}/api/members/checkToken`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(auth.checkTokenResponse));
  })
];
