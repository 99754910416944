<div class="cds-room-modal-info info info__container tab-content">
  <h3 class="cds-room-modal-info__title">Descripción de la habitación</h3>
  <div class="cds-room-modal-info__container">
    <ng-container *ngIf="roomInfoData">
      <app-content>
        <p class="info-p" [directiveSafeHTML]="roomInfoData.description"></p>
      </app-content>
    </ng-container>
    <div class="services">
      <ng-container *ngIf="definitionListTermsPrivileged && definitionListTermsPrivileged.length > 0">
        <div class="cds-desc-list">
          <dl class="cds-desc-list__container">
            <ng-container *ngFor="let term of definitionListTermsPrivileged; index as i">
              <div class="cds-desc-list__item highlight" [style.padding-top]="i === 1 ? '32px' : ''">
                <dt class="term">
                  <ng-container *ngIf="term.icon">
                    <app-icon-item [icon]="term.icon"></app-icon-item>
                  </ng-container>
                  <ng-container *ngIf="term.title">
                    {{ term.title }}
                  </ng-container>
                </dt>
                <ng-container *ngIf="term.description">
                  <dd class="desc" *ngFor="let desc of term.description">{{ desc }}</dd>
                </ng-container>
              </div>
            </ng-container>
          </dl>
        </div>
      </ng-container>
      <div class="cds-desc-list">
        <dl class="cds-desc-list__container no-highlight">
          <div class="cds-desc-list__item" *ngFor="let term of definitionListTerms; index as i">
            <dt class="term">
              <ng-container *ngIf="term.icon">
                <app-icon-item [icon]="term.icon"></app-icon-item>
              </ng-container>
              <ng-container *ngIf="term.title">
                {{ term.title }}
              </ng-container>
            </dt>
            <ng-container *ngIf="term.description">
              <dd class="desc" *ngFor="let desc of term.description">{{ desc }}</dd>
            </ng-container>
          </div>
        </dl>
      </div>
    </div>
  </div>
</div>
