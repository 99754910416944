import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { Discount } from '@shared/logic/models/common.model';
import { LandingsLinkRaw } from '@shared/store/landing/landing-common.model';
import { Offer } from '@shared/store/search/search.model';
import { InfoModalItemRaw } from '@shared/store/search/search.model.raw';

export interface Item {
  displayTitle: string;
  displayDescription: string;
  cssClass: string;
}

export interface LandingLink {
  title: string;
  url: string;
  options?: unknown[];
}

export class CommonNormalizer {
  /**
   * Normalizes the provided data into a format suitable for the offers modal.
   *
   * @param items - An array of items containing information about icons, titles, and conditions.
   * @returns An array of normalized objects with properties for displayIcon, displayTitle, and displayDescription.
   * If no items are provided, an empty array is returned.
   */
  public static normalizeOffers(items?: InfoModalItemRaw[] | Discount[]): Offer[] {
    if (!GeneralFunctions.isArrayLike(items, 1)) {
      return [];
    }

    return items!.map(({ iconItem, titleItem, itemConditions }) => ({
      displayIcon: iconItem || '',
      displayTitle: titleItem || '',
      displayDescription: itemConditions || ''
    }));
  }

  /**
   * Normalizes backend items to a format suitable for build many GDI components.
   */
  public static normalizeItems(items: any[]): Item[] {
    if (!GeneralFunctions.isArrayLike(items, 1)) {
      return [];
    }

    return items!.map((item) => ({
      displayTitle: item.title || item.texto,
      displayDescription: item.description,
      cssClass: item.clase_css
    }));
  }

  // Currently used in landings but should be used in other places...
  public static normalizeLandingLink(item: LandingsLinkRaw): LandingLink {
    /* Know issues to change:
     * Hotel landing is using 'url' instead of 'uri' for the link.
     * */
    const urlToCheck: string | undefined = item.uri || item.url;
    return {
      title: item.title,
      url: GeneralFunctions.extractFinalUrl(urlToCheck),
      options: item.options
    };
  }
}
