import { GeneralFunctions } from '@shared/logic/functions/general.functions';
import { CommonNormalizer as CommonNormalizer2 } from '@shared/logic/helpers/common/common.normalizer';
import { CollectiveBanner } from '@shared/logic/models/common.model';
import {
  CountriesResponseRaw,
  Country,
  Footer,
  FooterRaw,
  Genre,
  GenreRaw,
  Header,
  HeaderRaw,
  Language,
  LanguageRaw,
  Link,
  LinksRaw,
  StaticBanner,
  StaticBannerProfit,
  StaticBannerProfitRaw,
  StaticBannerResponseRaw
} from '@shared/store/common/common.model';
import { CollectiveBannerRaw } from '@shared/store/common/common.model.raw';

const enum FooterCode {
  Common = 'footer-web',
  Legal = 'menu-footer-legales-web',
  RRSS = 'menu-rrss-web'
}

export class CommonStoreNormalizer {
  public static normalizeCountry(itemRaw: CountriesResponseRaw): Country[] {
    return itemRaw.paises.map(({ code, name, dialCode, currency }) => ({ code, name, dial_code: dialCode, currency }));
  }

  public static normalizeGenre(itemRaw: GenreRaw[]): Genre[] {
    return itemRaw.map(({ type, name }) => ({ code: type, name }));
  }

  public static normalizeLanguage(itemRaw: LanguageRaw[]): Language[] {
    return itemRaw.map(({ langcode, label }) => {
      // Updated label is used to ensure app always will have a language code string as display label instead of null
      const updatedLabel = Object.fromEntries(Object.entries(label).map(([key, value]) => [key, value ?? key]));
      return { code: langcode, displayLabels: updatedLabel };
    });
  }

  public static normalizeStaticBanner(itemRaw: StaticBannerResponseRaw): StaticBanner {
    const item = {} as StaticBanner;
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const normalizeProfit = (profit: StaticBannerProfitRaw): StaticBannerProfit => ({
      img: {
        src: profit.image?.webp,
        attributes: { alt: profit.image?.atributos?.descriptivo }
      },
      txt: profit.text,
      tooltipText: profit.tooltip
    });

    let profits: StaticBannerProfit[] = [];
    if (itemRaw.data && GeneralFunctions.isArrayLike(itemRaw.data.profits)) {
      profits = itemRaw.data.profits.map(normalizeProfit);
    }

    item.percent = itemRaw.data?.percentOfferSeal.toString();
    item.textPercent = itemRaw.data?.textOfferSeal;
    item.specialDiscount = itemRaw.data?.textOfferRibbon;
    item.items = profits;

    //@ts-ignore TODO: Back should provide modal as InfoModalItemRaw[]
    const offers = CommonNormalizer2.normalizeOffers(itemRaw.data.modalOfferRibbon);

    item.modalInfo = {
      items: offers
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static normalizeCollectivesBanner(itemRaw: CollectiveBannerRaw): CollectiveBanner {
    const item = {} as CollectiveBanner;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    item.code = itemRaw.code;
    item.displayTitle = itemRaw.title;
    item.displayDescription = itemRaw.description;
    item.type = itemRaw.type;
    item.percent = itemRaw.percent;
    item.actionButton = {
      displayText: itemRaw.linkModalText,
      url: '#' // should open modal
    };
    item.modal = {
      displayTitle: itemRaw.modal.title,
      displayDescription: itemRaw.modal.description,
      displayImage: itemRaw.modal.image,
      items: itemRaw.modal.items.map((i) => ({
        displayTitle: i.subtitle,
        displayText: i.text
      })),
      actionButton: {
        displayText: itemRaw.modal?.cta?.textButton,
        url: itemRaw.modal?.cta?.url
      }
    };
    item.tags = itemRaw.tags;
    item.bannerItems = GeneralFunctions.isArrayLike(itemRaw.highlights, 1)
      ? itemRaw.highlights?.map((i) => ({ displayIcon: i.icon, displayText: i.text }))
      : [];
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return item;
  }

  public static normalizeHeader(itemRaw: HeaderRaw[]): Header {
    const logoItem = itemRaw?.find((i) => i.titulo === 'Logos');
    return {
      displayLogoLight: logoItem?.Logo_claro,
      displayLogoDark: logoItem?.Logo_oscuro
    };
  }

  public static normalizeFooter(itemRaw: FooterRaw[]): Footer {
    const normalizeLink = (linkRaw: LinksRaw): Link => {
      const isInternal = GeneralFunctions.isInternalUrl(linkRaw.enlace);
      return {
        id: linkRaw.id,
        socialNetworkId: linkRaw.id,
        url: GeneralFunctions.extractFinalUrl(linkRaw.enlace),
        displayText: linkRaw.titulo,
        target: isInternal ? '' : linkRaw.atributos,
        isInternal,
        displayImage: linkRaw.Logo ? linkRaw.Logo.webp : null
      };
    };
    const normalizeLinks = (footerItems: FooterRaw[], codFooter: string) => {
      const footerItem = footerItems.find((item) => item.id === codFooter);
      return GeneralFunctions.isArrayLike(footerItem?.enlaces) ? footerItem!.enlaces.map(normalizeLink) : [];
    };
    const socialNetworkList: Link[] = normalizeLinks(itemRaw, FooterCode.RRSS);
    const logoFromSocialNetwork: Link | undefined = socialNetworkList.find((i) => i.isInternal);
    return {
      logo: logoFromSocialNetwork,
      links: {
        common: normalizeLinks(itemRaw, FooterCode.Common),
        legal: normalizeLinks(itemRaw, FooterCode.Legal),
        social: socialNetworkList.filter((i) => !i.isInternal)
      }
    };
  }
}
