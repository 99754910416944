import { DIALOG_DATA, DialogCloseOptions, DialogRef } from '@angular/cdk/dialog';
import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';

import { ModalResponse } from '@shared/logic/models/common.model';
import { filter, MonoTypeOperatorFunction, ReplaySubject, takeUntil } from 'rxjs';
const escapeKeyEvent = (event: KeyboardEvent) => event.key === 'Escape';
@Directive()
export abstract class ModalElement<C, I, O = ModalResponse> implements OnInit, OnDestroy {
  protected isDestroyed$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private onCancelHandler = (event: MouseEvent | KeyboardEvent) => {
    event?.preventDefault();
    this.onCancel();
  };
  protected constructor(@Inject(DIALOG_DATA) protected readonly dialogData: I, private readonly dialogRef: DialogRef<O, C>) {}
  public ngOnInit(): void {
    this.initKeydownListener();
    this.initBackdropListener();
  }
  public ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  abstract onAccept(): void;
  abstract onCancel(): void;
  protected closeDialog = (R: O, C?: DialogCloseOptions) => this.getDialogRef().close(R, C);
  protected untilIsDestroyed = <T>(): MonoTypeOperatorFunction<T> => takeUntil<T>(this.isDestroyed$);
  protected getDialogData = (): I => this.dialogData;
  private getDialogRef = (): DialogRef<O, C> => this.dialogRef;
  private initKeydownListener = () => this.getDialogRef().keydownEvents.pipe(this.untilIsDestroyed(), filter(escapeKeyEvent)).subscribe(this.onCancelHandler);
  private initBackdropListener = () => this.getDialogRef().backdropClick.pipe(this.untilIsDestroyed()).subscribe(this.onCancelHandler);
}
