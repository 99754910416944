import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { ButtonComponent } from '@atom/button/button.component';

import { FeatureItemComponent } from './feature-item.component';
import { LinkComponent } from '@atom/link/link.component';
import { TagComponent } from '@atom/tag/tag.component';
import { TooltipComponent } from '@atom/tooltip/tooltip.component';

@NgModule({
  imports: [SharedModule, ButtonComponent, LinkComponent, TagComponent, TooltipComponent],
  declarations: [FeatureItemComponent],
  exports: [FeatureItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeatureItemModule {}
