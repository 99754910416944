<div class="cancel-booking-modal">
  <app-cdk-dialog-wrapper-new (ev_close)="onCancel()">
    <div class="cancel-booking-modal__subtitle">
      <p>{{ literals?.disable_account_text_2 }}</p>
    </div>
    <div class="cancel-booking-modal__selector">
      <mat-form-field class="cds-input-select--form cds-input-select">
        <mat-label>{{ literals?.disable_account_choose }}</mat-label>
        <mat-select [(ngModel)]="selectedReason">
          <ng-container *ngFor="let item of deactivateReasons">
            <mat-option [value]="item.id">{{ item.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cancel-booking-modal__button">
      <app-button label="{{ literals?.cancel }}" type="cds-btn--highlight" [fillMobile]="true" (buttonClick)="onCancel()"></app-button>
      <app-button label="{{ literals?.disable_account }}" [fillMobile]="true" (buttonClick)="onAccept()"></app-button>
    </div>
  </app-cdk-dialog-wrapper-new>
</div>
