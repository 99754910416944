import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { GoogleTagManagerOverlordService } from '@ga-tracking/services/google-tag-manager/google-tag-manager-overlord.service';
import { DeviceService } from '@services/device-detection/device-detection.service';
import { LanguageDataService } from '@services/languages/language-data.service';
import { GoogleAnalyticsStoreAdapter } from '@shared/store/ga/ga.store.adapter';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthStoreAdapter } from '@shared/store/auth/auth.store.adapter';

interface BaseTrackInfo {
  device?: string;
  language?: string;
  firstSearchType?: string | null;
  previous_content_group: string | null;
  previous_content_type: string | null;
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseTrackService {
  protected constructor(
    protected readonly gtmOverlordService: GoogleTagManagerOverlordService,
    protected readonly deviceService: DeviceService,
    protected readonly languageDataService: LanguageDataService,
    protected readonly gaStoreAdapter: GoogleAnalyticsStoreAdapter,
    protected readonly asa: AuthStoreAdapter,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(PLATFORM_ID) protected platformId: string
  ) {}

  ////////////////////////////////////////////////////////////////////////
  //Pages
  ///

  protected getTrackInfoFromExternalSources(arg: string): Observable<BaseTrackInfo> {
    return combineLatest([
      this.gaStoreAdapter.selectPrevContentInfo$().pipe(take(1)),
      this.deviceService.device$.pipe(take(1)),
      of(this.languageDataService.getLang()),
      this.gaStoreAdapter.selectFirstSearchType$().pipe(take(1))
    ]).pipe(
      map(([{ prevContentGroup, prevContentType }, device, content_language, firstSearchType]) => ({
        previous_content_group: prevContentGroup ?? '',
        previous_content_type: prevContentType ?? '',
        device,
        content_language,
        [arg]: firstSearchType ?? ''
      }))
    );
  }

  protected getTrackInfo<T>(eventData: T, arg?: string): Observable<T> {
    return this.getTrackInfoFromExternalSources(arg ? arg : '').pipe(
      map((extraInfo) => {
        return {
          ...eventData,
          ...extraInfo,
          ...(extraInfo.firstSearchType && arg ? { [arg as string]: extraInfo.firstSearchType } : {})
        } as T;
      })
    );
  }
}
