import { NgIf } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ItemListComponent } from '@atom/item-list/item-list.component';
import { TooltipComponent } from '@atom/tooltip/tooltip.component';

export interface MainScore {
  valueScore: number;
  valueIcon: string;
  valueIconText: string;
  minimal: boolean;
}

@Component({
  standalone: true,
  selector: 'app-main-score',
  templateUrl: './main-score.component.html',
  styleUrls: ['./main-score.component.scss'],
  imports: [ItemListComponent, TooltipComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainScoreV2Component {
  @Input() leyendText!: string | number;
  @Input() mainScoreData: MainScore;
}
