import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, RendererFactory2, Renderer2, Inject, PLATFORM_ID } from '@angular/core';

import { environment } from '@env/environment';
import { WindowRefService } from '@shared/logic/services/window-ref.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  public gMapsLoadedStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isLoaded: boolean = false;
  private _renderer: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private _document: Document,
    private _rendererFactory: RendererFactory2,
    private windowRefService: WindowRefService
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
    if (isPlatformBrowser(this.platformId)) {
      if (this._isLoaded) {
        return;
      }
      this._startCallbackListener();
      this._loadScript();
    }
  }

  private _startCallbackListener(): void {
    (this.windowRefService.nativeWindow as Window & { initMap: () => unknown })['initMap'] = () => {
      this._isLoaded = true;
      this.gMapsLoadedStatus$.next(true);
    };
  }

  private _loadScript(): void {
    const script = this._renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&callback=initMap`;
    this._renderer.appendChild(this._document.body, script);
  }
}
