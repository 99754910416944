import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './pre-footer.reducer';

const getPreFooterState = createFeatureSelector<State>('preFooter');

export const PreFooterSelectors = {
  getPreFooter: createSelector(getPreFooterState, (state: State) => state.preFooter),
  getPreFooterNormalized: createSelector(getPreFooterState, (state: State) => state.preFooterNormalized)
};
