import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { GdiCatInputTextModule } from '@app/shared/ns_components/forms/gdi-cat-input-text/gdi-cat-input-text.module';
import { NgxIntlTelInputModule } from '@khazii/ngx-intl-tel-input';

import { DatatransWidgetComponent } from './datatrans-widget.component';
import { GdiCatInputDateComponent } from '../forms/gdi-cat-input-date/gdi-cat-input-date.component';

@NgModule({
  declarations: [DatatransWidgetComponent],
  imports: [CommonModule, ReactiveFormsModule, GdiCatInputTextModule, NgxIntlTelInputModule, GdiCatInputDateComponent],
  exports: [DatatransWidgetComponent]
})
export class DatatransWidgetModule {}
