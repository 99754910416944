import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { CdkDialogWrapperComponent } from './cdk-dialog-wrapper.component';
import { IconItemComponent } from '@atom/icon-item/icon-item.component';

@NgModule({
  declarations: [CdkDialogWrapperComponent],
  imports: [SharedModule, IconItemComponent],
  exports: [CdkDialogWrapperComponent]
})
export class CdkDialogWrapperModule {}
