<div [class.info-i]="tooltip.tooltip" class="rewards__info-item">
  <div class="icon">
    <ng-container *ngIf="tooltip?.image as image">
      <img dynamic-image [imageRaw]="image" [alt]="image.atributos?.descriptivo" [src]="image.webp" />
    </ng-container>
  </div>
  <p>
    {{ tooltip.displayText }}
    <ng-container *ngIf="tooltip.tooltip && tooltip.tooltipText">
      <app-tooltip class="tooltip" (click)="click()" (mouseenter)="onMouseEnter()" [tooltipText]="tooltip.tooltipText"></app-tooltip>
    </ng-container>
  </p>
</div>
