import { Inject, Injectable, PLATFORM_ID, Signal } from '@angular/core';
import { TrackingService } from '@ga-tracking/services/tracking-builder/tracking-builder.service';
import { User } from '@shared/store/auth/auth.model';
import { AuthStoreAdapter } from '@shared/store/auth/auth.store.adapter';
import { GoogleTagFunctions } from '@shared/logic/functions/google-tag.functions';
import { createMomentDate } from '@shared/utils/dates/dates.utils';
import { UserTrackingEvent } from '@ga-tracking/services/tracking-sites/user/user-track.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UserTrackService {
  constructor(private readonly trackingService: TrackingService, protected readonly asa: AuthStoreAdapter, @Inject(PLATFORM_ID) private _platformId: string) {}

  public trackUserEvent(): void {
    const userSig: Signal<User | null> = this.asa.selectAuthUserSig;
    const eventData = {
      user_id: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.id !== '' ? userSig()?.id : 'empty field') : 'na',
      user_login: userSig() ? '1' : '0',
      user_msh_level: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.membershipAsNumber ?? 'empty field') : 'na',
      user_msh_name: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.membership ?? 'empty field') : 'na',
      user_country: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.countryInfo.name !== '' ? userSig()?.countryInfo.name : 'empty field') : 'na',
      user_city: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.address.city !== '' ? userSig()?.address.city : 'empty field') : 'na',
      user_language: userSig() ? GoogleTagFunctions.reduceTag(userSig()?.languageInfo.name !== '' ? userSig()?.languageInfo.name : 'empty field') : 'na',
      user_nl: userSig() ? (userSig()?.userWantsNewsletter ? '1' : '0') : 'na',
      user_tc: userSig() ? GoogleTagFunctions.numberTag(userSig()?.travelCashEUR ?? 0) : 'na',
      user_tc_ex_6: userSig() ? GoogleTagFunctions.numberTag(userSig()?.travelCashToExpire ?? 0) : 'na',
      user_nights: userSig() ? GoogleTagFunctions.numberTag(userSig()?.nights ?? 0) : 'na',
      user_nights_nxt_lvl: userSig() ? GoogleTagFunctions.numberTag(userSig()?.nightsToLevelUp ?? 0) : 'na',
      user_status: userSig() ? '1' : 'na',
      user_date: userSig() ? GoogleTagFunctions.reduceTag(createMomentDate(userSig()?.createdAt).format('YYYYMMDD') ?? 'empty field') : 'na',
      user_device: 'na'
    } as UserTrackingEvent;

    if (isPlatformBrowser(this._platformId)) {
      this.trackingService.pushUserEvent(eventData);
    }
  }
}
