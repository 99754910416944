import { Action } from '@ngrx/store';

import { Breadcrumb } from './checkout.model';

export enum CheckoutActionsTypes {
  SetBreadcrumbs = '[Checkout] Set Breadcrumbs',
  SetActiveBreadcrumb = '[Checkout] Set Active Breadcrumb',
  ToggleShoppingCartExpandedStatus = '[Shopping Cart] Toggle Shopping Cart Expanded Status'
}

export class SetBreadcrumbs implements Action {
  readonly type = CheckoutActionsTypes.SetBreadcrumbs;
  constructor(readonly payload: { breadcrumbs: Breadcrumb[] }) {}
}
export class SetActiveBreadcrumb implements Action {
  readonly type = CheckoutActionsTypes.SetActiveBreadcrumb;
  constructor(readonly payload: { activeBreadcrumb: string }) {}
}

export class ToggleShoppingCartExpandedStatus implements Action {
  readonly type = CheckoutActionsTypes.ToggleShoppingCartExpandedStatus;
}

export type CheckoutActions = SetBreadcrumbs | SetActiveBreadcrumb | ToggleShoppingCartExpandedStatus;
