import { inject, Injectable } from '@angular/core';

import { BaseTrackService } from '@ga-tracking/services/tracking-builder/tracking-base.abstract';
import { Tag } from '@shared/store/ga/ga.model';
import { UserTrackingEvent } from '@ga-tracking/services/tracking-sites/user/user-track.model';
import { OneTrustService } from '@app/core/services/one-trust/one-trust.service';

@Injectable({ providedIn: 'root' })
export class TrackingService extends BaseTrackService {
  private _oneTrustService = inject(OneTrustService);
  public pushEventClick<T>(eventData: T): void {
    this.gtmOverlordService.pushTag(eventData as Partial<Tag>);
    //console.log(eventData); //view data
  }

  //Page
  public pushEventClickFromExternalSources<T>(eventData: T, arg?: string): void {
    this.getTrackInfo(eventData, arg).subscribe((allEventData) => {
      this.gtmOverlordService.pushTag(allEventData as Partial<Tag>);
    });
  }

  public pushUserEvent(eventData: UserTrackingEvent): void {
    //  Sometimes this event can be triggered before the gtm script has successfully loaded
    //  this can cause that when we call the push tag method from the library, the inner loaded flag
    //  the library has is still not correctly set and it creates another script tag :(
    const waitForGtmScript = (): Promise<void> => {
      return new Promise((resolve) => {
        // Check if GTM script is already loaded. If the datalayer is undefined, then the script hasn't even start loading
        // We can safely resolve the promise and push the tag (it will load the script)
        // If the gtm load event is there, it means it has properly loaded and calling the push won't create a new script tag.
        //@ts-ignore
        if (typeof window['dataLayer'] === 'undefined' || window['dataLayer'].some((e) => e.event === 'gtm.load')) {
          resolve();
        } else {
          const script = document.getElementById('GTMscript');
          // Asynchronous loading is eliminated
          this._oneTrustService.removeAsyncAttributeForID('GTMscript');

          if (script) {
            // There are scenarios where gtm is loaded but didn't fire the gtm.load event yet
            //@ts-ignore
            if (window['google_tag_manager']) {
              resolve();
            } else {
              const gtmLoadedHandler = () => {
                script.removeEventListener('load', gtmLoadedHandler);
                resolve();
              };
              script.addEventListener('load', gtmLoadedHandler);
            }
          }
        }
      });
    };

    const pushEvent = () => {
      this.getTrackInfo(eventData).subscribe((allEventData) => {
        const eventWithDevice = {
          ...(eventData as Partial<Tag>),
          user_device: (allEventData as Partial<Tag>).device
        };
        this.gtmOverlordService.pushTag(eventWithDevice);
      });
    };

    //@ts-ignore
    const isDataLayerUndefined = typeof window['dataLayer'] === 'undefined';
    //@ts-ignore
    const isGtmLoaded = window['dataLayer']?.some((e) => e.event === 'gtm.load');

    if (isDataLayerUndefined || isGtmLoaded) {
      pushEvent();
    } else {
      // Call waitForGtmScript and then proceed with getTrackInfo
      waitForGtmScript().then(pushEvent);
    }
  }
}
