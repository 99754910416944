import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { RoutingPaths } from '@core/routing/routing.const';
import { RoutingService } from '@core/routing/routing.service';
import { LandingService } from '@shared/store/landing/landing.service';
import { catchError, of } from 'rxjs';

export const LandingsResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const { p1, p2 } = route.params;
  let concatParams = '/';
  if (p1) {
    concatParams += p1;
  }
  if (p2) {
    concatParams += `/${p2}`;
  }

  const router = inject(RoutingService);

  return inject(LandingService)
    .getLanding$(concatParams)
    .pipe(
      catchError((err) => {
        console.error('Landings Resolver', err);
        router.navigateByUrl(RoutingPaths.NotFound);
        return of(null);
      })
    );
};
