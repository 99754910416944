import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Event as RouterEvent } from '@angular/router';

import { AppConfig } from '@app/app.config';
import { RoutingPaths } from '@app/core/routing/routing.const';
import { RoutingService } from '@app/core/routing/routing.service';

import { LanguageDataService } from '@app/core/services/languages/language-data.service';
import { ConstantHeaderSize, ConstantsHeaderClass, ConstantsHeaderStepperBar, ConstantsHeaderTypePosition } from '@shared/store/header/header.const';
import { ScrollConfig } from '@shared/store/ui/ui.model';
import { UiStoreAdapter } from '@shared/store/ui/ui.store.adapter';
import { filter, tap } from 'rxjs';

import { HeaderStoreAdapter } from './header.store.adapter';

export enum ConstantsHeaderStyle {
  COMMOM_CLASS = 'app-header app-header--show',
  CLEAN_CLASS = 'app-header app-header--show app-header-clean',
  CLEAN_TOP_CLASS = 'app-header app-header--show app-header-clean app-header-clean--top'
}

@Injectable({
  providedIn: 'root'
})
export class HeaderStyleService {
  private sbnameClass: string = ConstantsHeaderClass.HEADER_TRANSPARENT;
  private sbsearchClass: string = '';
  private searchClassMultiroom: string = ConstantsHeaderStepperBar.HEADER_MULTIROOM_STEPPER_UP;
  private sblastScrollTop = 0;
  private data: string;
  private currentRoute = '';
  public language = this._languageDataService.getLang();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly uiStoreAdapter: UiStoreAdapter,
    private readonly headerStoreAdapter: HeaderStoreAdapter,
    private _routingService: RoutingService,
    private _languageDataService: LanguageDataService
  ) {
    this._actualRoute();
  }

  public setData(data: string): void {
    this.data = data;
  }

  private _actualRoute(): void {
    this.currentRoute = this._routingService.getUrl();
    this._routingService
      .getRouter()
      .events.pipe(
        filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
        tap((event) => {
          this.currentRoute = event.url;
          this._resetStyles();
        })
      )
      .subscribe();
  }

  private _resetStyles(): void {
    this.sblastScrollTop = 0;
    this._controlHandleScroll(0, false);
    this.sbsearchClass = ConstantsHeaderClass.SEARCH_BAR_TOP_HEADER;
    this.searchClassMultiroom = ConstantsHeaderStepperBar.HEADER_MULTIROOM_STEPPER_UP;
    this.headerStoreAdapter.setHeaderActive(true);
  }

  public handleScroll(event: Event, activeLoginExpand: boolean, activeSearchBar?: boolean): void {
    const target = event.target as HTMLElement;
    const scrollOffset = target.scrollTop;

    if (activeLoginExpand) {
      return;
    }

    this._controlHandleScroll(scrollOffset, activeSearchBar);
  }

  public setStyleBasedOnPath$(): string {
    if (this.currentRoute.includes(RoutingPaths.PrivateArea)) {
      return ConstantsHeaderStyle.CLEAN_CLASS;
    } else if (this.currentRoute.includes(RoutingPaths.Checkout)) {
      return ConstantsHeaderStyle.CLEAN_TOP_CLASS;
    } else {
      return ConstantsHeaderStyle.COMMOM_CLASS;
    }
  }

  public showHideHeader(isShow: boolean): string {
    if (isShow) {
      if (this.currentRoute.includes(RoutingPaths.SearchWithoutDatesControlRoute) || this.currentRoute === `/${this.language}`) {
        return ConstantsHeaderClass.HEADER_TRANSPARENT;
      } else {
        return ConstantsHeaderStyle.CLEAN_TOP_CLASS;
      }
    } else {
      return ConstantsHeaderClass.HEADER_HIDE;
    }
  }

  public blockScroll(blockScroll: boolean): void {
    const ionContents = this.document.querySelectorAll('ion-content');

    if (ionContents.length > 1) {
      const secondIonContent = ionContents[1];

      if (secondIonContent && secondIonContent.shadowRoot) {
        const scroll = secondIonContent.shadowRoot.querySelector('.inner-scroll') as HTMLElement;
        if (scroll) {
          if (blockScroll) {
            scroll.style.overflow = 'hidden';
          } else {
            scroll.style.removeProperty('overflow');
          }
        }
      }
    }
  }

  private _controlHandleScroll(scrollOffset: number, activeSearchBar?: boolean): void {
    if (scrollOffset === 0) {
      this._handleScrollZero(activeSearchBar);
    } else if (scrollOffset > this.sblastScrollTop) {
      this._handleScrollDown(scrollOffset, activeSearchBar);
    } else if (scrollOffset < this.sblastScrollTop) {
      this._handleScrollUp(scrollOffset, activeSearchBar);
    }
    this._setScrollConfigStore(scrollOffset, !!activeSearchBar);
  }

  private _handleScrollZero(activeSearchBar?: boolean): void {
    if (
      (!activeSearchBar && this.currentRoute.includes(RoutingPaths.SearchWithoutDatesControlRoute)) ||
      (!activeSearchBar && this.currentRoute === `/${this.language}`)
    ) {
      this.sbnameClass = ConstantsHeaderClass.HEADER_TRANSPARENT;
    }
    this.sbsearchClass = '';
    this.sbnameClass = '';
    this.searchClassMultiroom = '';
  }

  private _handleScrollDown(scrollOffset: number, activeSearchBar?: boolean): void {
    if (!activeSearchBar || scrollOffset >= ConstantHeaderSize.HEIGHT) {
      this.sbnameClass = ConstantsHeaderClass.HEADER_HIDE;
    }
    this.sbsearchClass = ConstantsHeaderClass.SEARCH_BAR_TOP;
    this.searchClassMultiroom = ConstantsHeaderClass.SEARCH_BAR_TOP_HEADER;
    if (
      (this.data === ConstantsHeaderTypePosition.TYPE_ON_HERO && this.sblastScrollTop >= 800) ||
      (this.data === ConstantsHeaderTypePosition.TYPE_WITHOUT_HERO && this.sblastScrollTop >= 100)
    ) {
      this.sbsearchClass = ConstantsHeaderClass.HEADER_TOP_STICKY;
    }
    if (this.currentRoute.includes(RoutingPaths.PrivateArea) || this.currentRoute.includes(RoutingPaths.Search)) {
      this.sbsearchClass = ConstantsHeaderClass.HEADER_TOP_STICKY;
    }
  }

  private _handleScrollUp(scrollOffset: number, activeSearchBar?: boolean): void {
    if (!activeSearchBar || scrollOffset >= ConstantHeaderSize.HEIGHT) {
      this.sbnameClass = ConstantsHeaderStyle.CLEAN_TOP_CLASS;
    }
    this.sbsearchClass = ConstantsHeaderClass.SEARCH_BAR_TOP_HEADER;
    this.searchClassMultiroom = ConstantsHeaderStepperBar.HEADER_MULTIROOM_STEPPER_UP;

    if (
      (this.data === ConstantsHeaderTypePosition.TYPE_ON_HERO && this.sblastScrollTop >= 800) ||
      (this.data === ConstantsHeaderTypePosition.TYPE_WITHOUT_HERO && this.sblastScrollTop >= 100)
    ) {
      this.sbsearchClass = ConstantsHeaderClass.HEADER_TOP_HEADER_STICKY;
    }
    if (this.currentRoute.includes(RoutingPaths.PrivateArea) || this.currentRoute.includes(RoutingPaths.Search)) {
      this.sbsearchClass = ConstantsHeaderClass.HEADER_TOP_HEADER_STICKY;
    }
  }

  private _setScrollConfigStore(scrollOffset: number, activeSearchBar: boolean): void {
    this.sblastScrollTop = scrollOffset;
    const newScrollConfig: ScrollConfig = {
      nameClass: activeSearchBar
        ? this.sbnameClass
        : this.sblastScrollTop === 0
        ? ConstantsHeaderClass.HEADER_TRANSPARENT
        : ConstantsHeaderClass.HEADER_TOP_HEADER_STICKY,
      searchClass: this.sbsearchClass,
      lastScrollTop: this.sblastScrollTop,
      searchClassMultiroom: this.searchClassMultiroom
    };
    this.uiStoreAdapter.setScrollConfig(newScrollConfig);
  }
}
