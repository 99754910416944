<app-cdk-dialog>
  <div #scrollBlockElement (scroll)="onScroll()" class="dialog-wrapper">
    <div #modalHead [ngClass]="modalHeadClass" class="cds-room-modal__head">
      <div class="cds-room-modal__head-container">
        <div class="cds-room-modal__head-title">
          <h2 class="hotel-name">{{ data.room.displayName }}</h2>
          <ng-container *ngIf="!isHeadScrolling">
            <div class="tags__container">
              <ng-container *ngFor="let tag of data.room.tags">
                <app-tag class="cds-tag" label="{{ tag.text }}" size="cds-tag--m" type="cds-tag--{{ tag.type }}"></app-tag>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="data.actionButton as actionButton">
          <div class="cds-room-modal__head-actions">
            <app-button
              (click)="onSendDataSelectedForGetHash()"
              label="Reserva desde {{ actionButton.price | number : '1.0-0' }} {{ actionButton.currency }}"
              type="cds-btn--highlight"
            ></app-button>
            <ng-container *ngIf="!isHeadScrolling && actionButton.totalNights as totalNights">
              <p class="price-days__advise">Precio para {{ totalNights }} {{ totalNights | pluralize : 'noche' : 'noches' }}</p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="cds-room-modal__content">
      <div #tabs class="tabs-container">
        <mat-tab-group (selectedTabChange)="changeTabSelected($event)">
          <mat-tab label="Información">
            <ng-template matTabContent>
              <ng-container *ngIf="data.room.info as roomInfo">
                <app-room-modal-info [roomInfo]="roomInfo"></app-room-modal-info>
              </ng-container>
            </ng-template>
          </mat-tab>
          <mat-tab label="Galería">
            <ng-template matTabContent>
              <ng-container *ngIf="gallery">
                <div class="gallery gallery__container tab-content">
                  <app-gallery [gallery]="gallery" [hasGalleryLayout]="false"></app-gallery>
                </div>
              </ng-container>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <ng-container *ngIf="data.actionButton as actionButton">
      <div [ngClass]="modalHeadClass" class="cds-room-modal__footer">
        <ng-container *ngIf="actionButton.totalNights as totalNights">
          <p class="price-days__advise">Precio para {{ totalNights }} {{ totalNights | pluralize : 'noche' : 'noches' }}</p>
        </ng-container>
        <app-button
          (click)="onSendDataSelectedForGetHash()"
          [fullWidth]="true"
          class="finish-btn"
          label="Reserva desde {{ actionButton.price | number : '1.0-0' }} {{ actionButton.currency }}"
          type="cds-btn--highlight"
        ></app-button>
      </div>
    </ng-container>
  </div>
</app-cdk-dialog>
