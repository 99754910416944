import { Dialog, DialogRef, DialogModule } from '@angular/cdk/dialog';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-cdk-dialog',
  templateUrl: './cdk-dialog.component.html',
  styleUrls: ['./cdk-dialog.component.scss']
})
export class CdkDialogComponent {
  constructor(public dialogRef: DialogRef) {}
  public closeDialog() {
    this.dialogRef.close();
  }
}
