import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type Type = 'h-xxl' | 'h-xl' | 'h-l' | 'h-m' | 'b-xl' | 'b-l' | 'b-m' | 'b-s' | 'b-xs' | 'b-xxs';
export type Weight = 'light' | 'regular' | 'medium' | 'semibold' | 'bold';

@Component({
  standalone: true,
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  imports: [CommonModule],
  styleUrls: ['./typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypographyComponent {
  @Input() type: Type = 'b-m';
  @Input() weight: Weight = 'regular';
}
